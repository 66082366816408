import { ComponentRenderHooks } from "@/plugins/types";
import EditorFormField, { Properties } from "./FormField";
// import settings from "./settings";

// export const onListItems = (hook, payload, actions) => {
//   if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
//     return [settings];
//   }
// };

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    (payload.type === "FormField" || payload.type === "TextInput")
  ) {
    return [EditorFormField];
  }
  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === "TextInput"
  ) {
    return Properties(payload);
  }
};
