import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@/Apps/CRM/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.FOUR,
  layout: FormLayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyu67qy2e6c07805fbyk6cj",
    itemId: "wn8itt8ijoi",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          display: "inline-block",
          textAlign: "center",
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: "Sign Up Today for Free Instant Access",
          },
          {
            type: ComponentTypes.FORM,
            data: [
              {
                type: FormFieldTypes.TEXT,
                name: "name",
                placeholder: "Name",
                preset: FieldNamePresets.NAME,
              },
              {
                type: FormFieldTypes.TEXT,
                name: "email",
                placeholder: "Email",
                preset: FieldNamePresets.EMAIL,
              },
              {
                type: FormFieldTypes.PASSWORD,
                name: "password",
                placeholder: "Password",
                preset: FieldNamePresets.PASSWORD,
              },
              {
                type: FormFieldTypes.PASSWORD,
                name: "password_confirm",
                placeholder: "Confirm Password",
                preset: FieldNamePresets.PASSWORD,
              },
            ],
            properties: {
              containerStyle: style.FORM_FIELD.style,
              inputStyle: style.FORM_FIELD.inputStyle,
            },
          },
          {
            type: ComponentTypes.FORMFIELD,
            canHaveChildren: false,
            attributes: {
              placeholder: "Terms",
              // label: "I agree to the terms",
              name: "Terms",
              items: [
                {
                  id: 0,
                  label: "I agree to the terms",
                  value: false,
                },
              ],
            },
            fieldType: FormFieldTypes.CHECKBOXES,
            styles: {
              containerStyle: { ...style.FORM_FIELD.style, marginTop: 20 },
              // inputStyle: style.FORM_FIELD.inputStyle,
              labelStyle: style.FORM_FIELD.labelStyle,
            },
          },
          {
            type: ComponentTypes.BUTTON,
            canHaveChildren: false,
            properties: style.BUTTON.style,
            caption1: "Sign Up",
            caption1style: style.BUTTON.caption1,
          },
        ],
      },
    ],
  },
};

export default settings;
