import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { SocialProofLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.EIGHT,
  layout: SocialProofLayouts.EIGHT,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof08.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkypng982by30780ld7yaetu",
    itemId: "u5j1r64m5rf",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: `Here's what people are saying:`,
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 40,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  color: "white",
                  padding: 10,
                },
                playIconStyle: { fontSize: "56pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: lorem.generateSentences(1),
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  color: "white",
                  padding: 10,
                },
                playIconStyle: { fontSize: "56pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: lorem.generateSentences(1),
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  color: "white",
                  padding: 10,
                },
                playIconStyle: { fontSize: "56pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: lorem.generateSentences(1),
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
