import { useEffect, useState, FC, useContext } from "react";

import Wizard from "@/ui/Wizard";

import { supabase } from "@/db";

import { allQuestions } from "@/Apps/Planning/data";

import { getTokenInfo } from "@/api/auth";
import { getOnboardingData, saveOnboardingData } from "../../api/entities";
import { getSessionToken } from "@/api/auth";

import Panel from "@/ui/Panel";
import { getCampaign, updateCampaign } from "@/api/campaigns";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { useCompletion } from "ai/react";
import { setContextForView } from "../AITools/utils";
import { AppContext } from "@/AppContext";

interface TextAreaProps {
  placeholder?: string;
  onChange?: () => void;
}

export const TextArea: FC<TextAreaProps> = ({
  placeholder = "",
  onChange,
  defaultValue,
}) => {
  return (
    <div className="relative">
      <textarea
        id="description"
        className="block rounded-lg px-5 pb-4 pt-[38px] w-full text-black dark:text-white bg-white dark:bg-white/5 border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer"
        placeholder=" "
        onChange={onChange}
        defaultValue={defaultValue}
        autoFocus
      />
      {!Boolean(defaultValue.length) && <label
        htmlFor="description"
        className="absolute text-sm text-black/40 dark:text-white/40 duration-300 transform -translate-y-2 scale-90 top-6 z-10 origin-[0] left-5 peer-focus:text-black/40 dark:peer-focus:text-white/40 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-2"
      >
        {placeholder}
      </label>}
    </div>
  );
};

const Question = ({
  title,
  description,
  placeholder,
  answers,
  setAnswers,
  id,
  index,
  disabled,
  onSaveAndContinue,
  onSkip,
}) => {
  return (
    <div
      className={`${
        disabled && `opacity-40`
      } flex flex-col items-center w-full`}
    >
      <div className="rounded-full bg-lightwhite dark:bg-white/5 w-8 h-8 p-2 text-center">
        {index + 1}
      </div>
      <div className="w-1 bg-lightwhite dark:bg-white/5 h-3" />
      <Panel className="w-full">
        <div className="p-5">
          <div className="text-2xl font-bold text-center">{title}</div>
          <p className="text-center">{description}</p>
        </div>
        {!disabled && (
          <div>
            <TextArea
              placeholder={placeholder}
              onChange={(e) => setAnswers({ ...answers, [id]: e.target.value })}
              defaultValue={answers[id] || ""}
            />
            <div className="flex justify-end items-center mt-3 gap-4">
              <button onClick={onSkip} className="">
                Skip
              </button>
              <button onClick={onSaveAndContinue} className="btn">
                Save &amp; Continue
              </button>
            </div>
          </div>
        )}
      </Panel>
    </div>
  );
};

const QuestionGroup = ({ data, answers, setAnswers, onSave }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);

  return (
    <div title={data.title}>
      <div className="grid grid-cols-1 gap-4">
        {/* <h3 className="text-center text-lg font-bold">{data.title}</h3> */}
        <p className="text-center text-lg mt-7">{data.description}</p>
        {data.questions.map((question, index) => {
          return (
            <div
              className="mt-7 cursor-pointer"
              onClick={() => setCurrentQuestion(index)}
              key={index}
            >
              <Question
                title={question.title}
                description={question.description}
                placeholder={
                  question?.example ? `ex. ${question.example}` : question.title
                }
                answers={answers}
                setAnswers={setAnswers}
                id={question.id}
                index={index}
                disabled={currentQuestion !== index}
                onSaveAndContinue={(e) => {
                  e.stopPropagation();
                  setCurrentQuestion(currentQuestion + 1);
                  onSave();
                }}
                onSkip={(e) => {
                  e.stopPropagation();
                  setCurrentQuestion(currentQuestion + 1);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const Questionaire = ({ type, groupId }) => {

  const [answers, setAnswers] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const contextObj = useContext(AppContext);
  
  const { complete, isLoading } = useCompletion({
    api: `${AI_DOMAIN}/api/completion`,
    body: { type: "chat" },
  });

  const questions = allQuestions[type] || [];

  const getData = async () => {
    const token = getSessionToken();
    const keys = questions.map((q) => q.questions.map((q) => q.id)).flat();
    // console.log({ questions, groupId, keys });
    const response = await getOnboardingData(token, groupId, keys);
    console.log("Onboarding Data Response", response);
    // console.log("Question keys", keys);
    setAnswers(response);
  
    setContextForView({
      data: response,
      view: 'Planner Profile'
    }, contextObj);

  };

  const saveData = async () => {
    const token = getSessionToken();
    const response = await saveOnboardingData(token, answers, groupId);
    console.log("Onboarding Data Save Response", response);
    // setAnswers(response);
    // remove recommendations from cache
    localStorage.removeItem("recommendedFunnels");
  };

  const handleNext = async () => {
    setCurrentStep(currentStep + 1);
    await saveData();
  }

  const handlePrevious = async () => {
    setCurrentStep(currentStep - 1);
    await saveData();
  }

  const handleComplete = async () => {
    await saveData();

    // update planner description with summary of data if empty
    // get planner campaign details
    const token = getSessionToken();
    const campaignDetails = await getCampaign({ token, id: groupId });
    console.log("Campaign Details", campaignDetails);
    // check if description is empty
    if (!campaignDetails?.description || campaignDetails?.description === "") {
    // if so, update description with summary of data
      // generate summary of answers
      const flatAnswers = Object.keys(answers).map((key) => {
        return `${key}: ${answers[key]}`;
      }).join("\n");

      const prompt = `Please create a short & concise summary of the following profile. Express your response in a conversational tone, but do not prefix it with anything (important: do not under any circumstances start with the words "This profile describes"), just return a simple sentence or paragraph summarizing just the profile data and nothing more.
      
      Here is the profile data:

      '''
      ${flatAnswers}
      '''`;

      const summary = await complete(prompt);

      // update description with summary
      // const response = 
      console.log("The Settings", campaignDetails?.settings);

      await updateCampaign(groupId, {
        token,
        settings: {
          description: summary,
          settings: campaignDetails?.settings
        },
      });

      // console.log("The Prompt", prompt);
      // console.log("Update Campaign Response", response, summary);
    }
    
    alert("Thank you for completing the questionnaire! Your answers have been saved.");
    
    // if (campaignDetails?.settings?.type === "company") {
      // window.location.href = "/funnels"; // redirect to funnels page if it's a company profile
      window.location.href = "/planner"; // redirect to funnels page if it's a company profile
    // }
  }

  // const xhandleComplete = async () => {
  //   console.log("complete", answers);

  //   // get the user id from the token in the local storage
  //   const token = getSessionToken();
  //   const { id } = await getTokenInfo(token);
  //   const userId = id;

  //   // loop through each answer (in data object) and insert into supabase as a new entity
  //   Object.keys(answers).forEach(async (key) => {
  //     // only run if data[key] is not an empty string
  //     if (answers[key] === "") return;

  //     // update or insert into supabase table based on whether or not the entity already exists
  //     // return count of entities with userId and type
  //     const { count } = await supabase
  //       .from("InformationEntities")
  //       .select("*", { count: "exact" })
  //       .eq("userId", userId)
  //       .eq("type", key);
  //     if (count === 0) {
  //       // insert
  //       const { data, error } = await supabase
  //         .from("InformationEntities")
  //         .insert([{ userId, type: key, content: answers[key] }])
  //         .select();
  //       // console.log('insert response', data)
  //     } else {
  //       // update
  //       const { data, error } = await supabase
  //         .from("InformationEntities")
  //         .update({ content: answers[key] })
  //         .eq("userId", userId)
  //         .eq("type", key)
  //         .select();
  //       // console.log('update response', data)
  //     }
  //   });
  // };

  const steps = questions.map((question, index) => {
    return {
      id: String(index + 1),
      index: index + 1,
      component: (
        <QuestionGroup
          data={question}
          answers={answers}
          setAnswers={setAnswers}
          onSave={saveData}
        />
      ),
    };
  }) 

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full flex items-center justify-center p-4">
      <div className="max-w-[680px] flex-none w-full">

        <Wizard
          currentStep={currentStep}
          showProgressIndicator
          showButtons={false}
          steps={steps}
        />

        <div className="flex justify-end items-center mt-8 gap-4">
          {currentStep > 1 && (
            <button type="button" className="btn" onClick={handlePrevious}>
              Previous
            </button>
          )}

          {currentStep < steps.length && (
            <button type="button" className="btn" onClick={handleNext}>
              Next
            </button>
          )}

          {currentStep === steps.length && (
            <button type="button" className="btn" onClick={handleComplete}>
              Finish
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
