import React, { useCallback, useRef, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  MarkerType,
  Position,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  EdgeTypes,
  NodeTypes,
  Background,
  BackgroundVariant,
} from "reactflow";

import { useContextMenu } from "react-contexify";
import "react-modern-drawer/dist/index.css";

import Node from "./Node/Node";
import "reactflow/dist/style.css";

import { EditDrawer } from "./EditDrawer";
import { Menu as NodeContextMenu } from "@/ui/Menu";

import {
  createConnection,
  createObject,
  deleteConnection,
  deleteObject,
  getObjects,
  updateObject,
} from "@/api/campaigns";
import { getNodeDropdownItems, openPageInEditor } from "@/api/pages";
import { CampaignBuilderProps } from "@/ui/NodeCanvas/types";
import { getSessionToken } from "@/api/auth";

import {
  EdgeProps,
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
} from "reactflow";
import { getKPIs } from "@/api/tracking";
import { getUserIntegrations } from "@/api/integrations";
import { useTour } from "@reactour/tour";

let id = 0;
const getId = () => `dndnode_${id++}`;

export const MENU_ID = "menu-id";

const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            fontWeight: 700,
          }}
          className="nodrag nopan rounded-full w-8 h-8 flex justify-center items-center text-white bg-black dark:bg-white dark:text-black"
        >
          {label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

// Define the CampaignBuilder component
export const CampaignBuilder: React.FC<CampaignBuilderProps> = ({
  campaignData,
  id,
  readOnly = false,
  showMiniMap = true,
  showControls = true,
  showStats = false,
  direction = "horizontal",
  type = "funnel",
}) => {
  // Define state variables
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [mostRecentNodeChange, setMostRecentNodeChange] = useState([]);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [editDrawerData, setEditDrawerData] = useState({});
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [activeNodeId, setActiveNodeId] = useState(null);
  const [currentEventObject, setCurrentEventObject] = useState(null);
  const [integrations, setIntegrations] = useState([]);

  // Define refs
  const reactFlowWrapper = useRef(null);

  // Define hooks
  const navigate = useNavigate();
  const params = useParams();
  const { show, hideAll } = useContextMenu({ id: MENU_ID });
  const { setIsOpen } = useTour();

  // Define memoized variables
  const nodeTypes: NodeTypes = useMemo(() => ({ defaultNode: Node }), []);
  // const verticalNodeTypes: NodeTypes = useMemo(() => ({ defaultNode: Node }), []);
  const edgeTypes: EdgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  // Define callback functions

  // This function is called when the nodes on the graph are changed
  // It updates the nodes state with the new changes
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        setMostRecentNodeChange(changes);
        return applyNodeChanges(changes, nds);
      });
    },
    [setNodes]
  );

  // This function is called when the edges on the graph are changed
  // It updates the edges state with the new changes
  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        return applyEdgeChanges(changes, eds);
      });
      console.log(changes);
    },
    [setEdges]
  );

  // This function is called when edges are deleted from the graph
  // It loops through the array of edges to call GraphQL to remove all object connections
  const onEdgesDelete = (toDelete) => {
    console.log("onEdgesDelete", toDelete);

    for (let edge of toDelete) {
      const { source, target } = edge;
      deleteConnection({ source, target });
    }
  };

  // This function is called when a node is dragged and dropped
  // It updates the node in the database with the new position
  const onNodeDragStop = async (evt, node) => {
    console.log({ mostRecentNodeChange });
    for (let change of mostRecentNodeChange) {
      const { id, position } = change;
      if (position) {
        const { x, y } = position;

        const token = getSessionToken();
        await updateObject(id, {
          token,
          x: direction === "vertical" ? parseInt(y) : parseInt(x),
          y: direction === "vertical" ? parseInt(x) : parseInt(y),
        })
      }
    }
  };

  // This function is called when nodes are deleted from the graph
  // It loops through the array of nodes to call GraphQL to remove all provided nodes
  const onNodesDelete = async (toDelete) => {
    console.log("onNodesDelete", toDelete);

    for (let node of toDelete) {
      const { id } = node;
      await deleteObject(id);
    }
  };

  // This function is called when a new edge is created on the graph
  // It adds the new edge to the edges state and calls GraphQL to create a new connection
  const onConnect = async (params) => {
    const { source, target } = params;
    setEdges((eds) => addEdge(params, eds));
    await createConnection({ source, target });
  };

  // // This function is called when the stats are fetched from the database
  // const fetchStats = async () => {
  //   const statsResponse = await getKPIs(id);
  //   setStats(statsResponse);   
  //   return statsResponse; 
  // }

  // This function is called when the campaign is fetched from the database
  // It updates the nodes and edges state with the data from the database
  const fetchCampaign = async () => {
    const { data } = await getObjects(id);
    const stats = await getKPIs(id);

    // console.log({stats});
    
    const calculateConversionRate = (sourceId, targetId) => {
      const sourceStats = stats?.data?.find((stat) => stat.object_id === sourceId);
      const targetStats = stats?.data?.find((stat) => stat.object_id === targetId);
      return `${String((Number(sourceStats?.pageviews) / Number(targetStats?.pageviews) * 100) || 0)}%`;
    }

    if (data.objects) {
      const newNodes = data.objects.map((object) => {
        // props for the node
        return {
          id: object.id,
          type: "defaultNode",
          position: direction === "vertical" ? { x: object.y, y: object.x } : { x: object.x, y: object.y },
          data: {
            showStats,
            stats: stats?.data?.find((stat) => stat.object_id === object.id),
            label: object.name,
            direction,
            viewType: type,
            campaignId: id,
            ...object,
          },
          sourcePosition: direction === "vertical" ? Position.Bottom : Position.Right,
          targetPosition: direction === "vertical" ? Position.Top : Position.Left,
        };
      });

      let newEdges = [];

      data.objects.forEach(({ id, connectTo }) => {
        connectTo.forEach((object) => {
          let newEdge = {
            id: `${id}-${object.id}`,
            source: id,
            target: object.id,
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },            
            label: showStats && calculateConversionRate(id, object.id),
            type: showStats && "custom",
          };

          newEdges.push(newEdge);
        });
      });

      setEdges(newEdges);
      setNodes(newNodes);

      console.log("Fetched Campaigns", "Here are the Nodes I'll Render", data.objects);
    }
  };

  // This function is called when the integrations are fetched from the database
  // It updates the integrations state with the data from the database
  const fetchIntegrations = async () => {
    const integrationsResponse = await getUserIntegrations({ token: getSessionToken() });
    setIntegrations(integrationsResponse);
  };

  // This function is called when a node is dragged over the graph
  // It prevents the default behavior of the event
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // This function is called when a node is dropped onto the graph
  // It adds the new node to the nodes state and calls GraphQL to create a new object
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) return;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));

      createObject({
        name: newNode.data.label,
        x: direction === "vertical" ? parseInt(newNode.position.y) : parseInt(newNode.position.x),
        y: direction === "vertical" ? parseInt(newNode.position.x) : parseInt(newNode.position.y),
        campaignId: id,
      });
    },
    [reactFlowInstance]
  );

  // This function is called when a node is double-clicked
  // It opens the editor for the node
  const onNodeDoubleClick = async (event, node) => {
    const campaignId = campaignData.id;
    const objectId = node.data.id;
    
    if (node.data.type === "PageComponent") {
      // navigate(`/funnels/${campaignId}/${objectId}`);
      const pageId = node.data.page?.id;
      // console.log("campaign id", campaignId);
      await openPageInEditor({ campaignId, objectId, pageId, navigate });
    } else if (node.data.type === "ContentComponent") {
      navigate(`/content/${campaignId}/${objectId}`);
    } else {
      setEditDrawerOpen(true);
    }
  };

  // This function is called when a node is right-clicked
  // It opens the context menu and selects the node
  const onNodeContextMenu = (event, node) => {
    event.stopPropagation();
    setCurrentEventObject(event);

    show({ event });

    setActiveNodeId(node.id);
  };

  // This function is called when a node is clicked
  // It selects the node
  const onNodeClick = (event, node) => {
    event.stopPropagation();
    setActiveNodeId(node.id);
  };

  // This function is called when the canvas is right-clicked
  // It opens the context menu and deselects the node
  const onCanvasContextMenu = (event) => {
    console.log("onCanvasContextMenu", event);
    setActiveNodeId(null);
    setCurrentEventObject(event);
    show({
      event,
    });
  };

  // This function is called when the react flow instance is initialized
  // It sets the react flow instance state
  const onInit = (instance) => {
    setReactFlowInstance(instance);
  };

  // This effect is called when the active node ID changes
  // It selects the node with the new ID and updates the edit drawer data
  React.useEffect(() => {
    const newNodes = nodes.map((nd) => {
      if (nd.id === activeNodeId) {
        return {
          ...nd,
          selected: true,
        };
      } else {
        return {
          ...nd,
          selected: false,
        };
      }
    });

    setNodes(newNodes);

    const activeNode = nodes.find((nd) => nd.id === activeNodeId);
    if (activeNode) {
      setEditDrawerData(activeNode);
      console.log("activeNode", activeNode);
    } else {
      setEditDrawerData({});
    }
  }, [activeNodeId]);

  // This effect is called when the component mounts
  // It fetches the campaign from the database
  React.useEffect(() => {
    fetchCampaign();
    fetchIntegrations();
    // setIsOpen(true);
  }, []);

  React.useEffect(() => {
    console.log("Campaign Data Changed", "Will Fetch Campaigns")
    fetchCampaign();
  }, [showStats, campaignData]);

  // This variable stores the type of the active node
  const activeNodeType = editDrawerData?.data?.type;

  // Render the component
  return (
    <ReactFlowProvider>
      {/* Render the EditDrawer component if editDrawerOpen is true */}
      {editDrawerOpen && (
        <EditDrawer
          editDrawerOpen={editDrawerOpen}
          setEditDrawerOpen={setEditDrawerOpen}
          campaignId={id}
          data={editDrawerData}
          activeNodeType={activeNodeType}
          nodes={nodes}
          viewAs="popup"
          setNodes={setNodes}
        />
      )}
      {/* Render the ReactFlow component */}
      <div
        // className="h-[calc(100vh-174px)]"
        className="h-full"
        ref={reactFlowWrapper}
        onContextMenu={!readOnly ? onCanvasContextMenu : () => false}
        onClick={() => !readOnly ? setActiveNodeId : () => false}
        // style={{ width: editDrawerOpen ? `calc(100% - 500px)` : "" }}
      >
        {/* Render the NodeContextMenu component */}
        <NodeContextMenu
          id={MENU_ID}
          items={getNodeDropdownItems(
            {
              campaignData,
              integrations,
              nodeId: activeNodeId,
              nodes,
              setNodes,
              setEdges,
              event: currentEventObject,
              reactFlowWrapper,
              reactFlowInstance,
            },
            hideAll
          )}
        />

        {/* Render the ReactFlow component */}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={!readOnly ? onNodesChange : () => false}
          onEdgesChange={!readOnly ? onEdgesChange : () => false}
          onEdgesDelete={!readOnly ? onEdgesDelete : () => false}
          onNodeDragStop={!readOnly ? onNodeDragStop : () => false}
          onNodesDelete={!readOnly ? onNodesDelete : () => false}
          onNodeClick={!readOnly ? onNodeClick : () => false}
          onConnect={!readOnly ? onConnect : () => false}
          fitView
          proOptions={{ hideAttribution: true }}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultEdgeOptions={{ style: { opacity: 0.5 } }}
          onNodeDoubleClick={!readOnly ? onNodeDoubleClick : () => false}
          onNodeContextMenu={!readOnly ? onNodeContextMenu : () => false}
          onInit={onInit}
          onDrop={!readOnly ? onDrop : () => false}
          onDragOver={!readOnly ? onDragOver : () => false}
          connectionRadius={40}
          snapToGrid={direction === "vertical" ? true : false}
          maxZoom={7}
        >
          {/* Render the MiniMap component */}
          {showMiniMap && <MiniMap className="dark:bg-black" zoomable pannable />}
          
          {/* Render the Controls component */}
          {showControls && <Controls />}

          {/* {direction === "vertical" && <Background variant={BackgroundVariant.Dots} />} */}
          
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  );
};
