import * as React from "react";
import { get } from 'lodash';
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";
import tabSettings from "../settings";
import { CustomAlignmentSection } from "./CustomAlignmentSection";
import { CustomBorderSection } from "./CustomBorderSection";
import { CustomColorSection } from "./CustomColorSection";
import { CustomDisplaySection } from "./CustomDisplaySection";
import { TabBuilder } from "./TabBuilder";
import { addNewTab } from "../scripts";
import { TabPositions } from "../types";

export const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { properties } = tabSettings;
  const { sections } = properties.main;

  const tabPosition: TabPositions = get(settings, 'tabPosition', TabPositions.TOP);

  const withTabBuilder = updateSection(sections, "tabBuilder_settings", {
    component: (
      <TabBuilder
        {...settings}
        onChange={(items) => { updateComponentSettings(settings.id, { ...settings, items }) }}
        onAdd={() => addNewTab(props)}
      />
    )
  });

  const withColorPicker = updateSection(withTabBuilder, "custom_color", {
    component: <CustomColorSection {...props} />
  });

  const withAlignment = updateSection(withColorPicker, "custom_alignment", {
    component: <CustomAlignmentSection {...props} />
  });

  const withBorder = updateSection(withAlignment, "custom_border", {
    component: <CustomBorderSection {...props} />
  });

  const withDisplay = updateSection(withBorder, "custom_visibility", {
    component: <CustomDisplaySection {...props} />
  });

  const withoutAlignment = withDisplay.filter(section => {
    if (section.id === "custom_alignment") return tabPosition === TabPositions.TOP || tabPosition === TabPositions.BOTTOM
    return true;
  })

  const updatedSections = withoutAlignment;

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections: updatedSections },
      }}
      title="Tab Settings" />
  );
};

export default Properties;