import { ComponentRenderHooks } from "@/plugins/types";

import LiveBody from "./component";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === ComponentTypes.BODY) {
    return [LiveBody];
  }
};
