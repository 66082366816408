import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export default {
  id: "buttons",
  name: "buttons",
  thumbnail: "comp_buttons.png",
  default: {
    type: ComponentTypes.V1BUTTON,
    properties: {
      paddingTop: 20,
      paddingRight: 60,
      paddingBottom: 20,
      paddingLeft: 60,
      backgroundColor: "#2c8ac9",
      borderWidth: 0,
      borderRadius: 10,
      minWidth: 100,
      textAlign: "center",
      display: "inline-block",
      color: "white",
      fontSize: 24,
      mobile: {
        smartphone: {
          textAlign: "left",
          paddingTop: 10,
          paddingRight: 30,
          paddingBottom: 10,
          paddingLeft: 30,
          fontSize: 18
        }
      }
    },
    actions: [
      {
        behavior: "click",
        name: "Go to the next page",
        payload: {
          url: "{next_page}"
        },
        type: "TRIGGER_NEXT_NODE"
      }
    ],
    html: "Subscribe Now"
  }
};
