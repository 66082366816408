import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.THIRTEEN,
  layout: HeaderLayouts.THIRTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header13.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "xuvb76c4li",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          maxWidth: 700,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: get(content, "sub_headline.html", "..."),
          },
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
              maxWidth: 500,
              margin: "0 auto",
              marginTop: 0,
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((8 * 100) / 12),
                properties: { height: "100%", padding: 3, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.FORMFIELD,
                    canHaveChildren: false,
                    fieldType: FormFieldTypes.TEXT,
                    attributes: {
                      placeholder: "Enter Your Email",
                      // type: "Text",
                      name: "email",
                    },
                    styles: {
                      // containerStyle: style.FORM_FIELD.style,
                      inputStyle: style.FORM_FIELD.inputStyle,
                    },
                    properties: {},
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 3, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: style.BUTTON.style,
                    caption1: "Get Started",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
        ],
      },
      getImagePlaceholder({
        type: ImagePlaceholderTypes.MOBILE01,
        style: {
          width: 800,
          marginTop: 20,
          maxWidth: "100%",
          textAlign: "center",
          display: "inline-block",
        },
      }),
    ],
  },
};

export default settings;
