import { getSessionToken } from "@/api/auth";
import { GET_PROVIDER_CAMPAIGNS } from "@/api/gql/campaigns";
import {
  getUserIntegrations,
  getUserIntegrationSettings,
} from "@/api/integrations";
import { getPage, updatePage } from "@/api/pages";
import { decode, encode } from "@/Apps/Pages/utils";
import { client } from "@/client";
import { integrationProviders } from "@/Settings/IntegrationManagement";
import { IntegrationAccountList } from "@/Settings/IntegrationManagement/ConnectedAccounts";
import Button from "@/ui/Button";
// import Calout from "@/ui/Calout";
import { SettingsIcon } from "@/ui/Icons";
import InputGroup from "@/ui/InputGroup";
import { Loading } from "@/ui/Layout/Loading";
import Modal, { Title } from "@/ui/Modal";
import { FooterButtons } from "@/ui/Modal/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { Panel } from "@/ui/Panel";
import Select from "@/ui/Select";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { ButtonTypes } from "@/ui/types";
import { CheckCircle } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

const ChooseIntegration = ({
  type = "email",
  integration = {},
  setIntegration = () => {},
}) => {
  const [query, setQuery] = useState("");
  const [activeIntegrations, setActiveIntegrations] = useState([]);

  const loadIntegrations = async () => {
    const token = getSessionToken();
    const response = await getUserIntegrations({ token });
    setActiveIntegrations(response);
  };

  useEffect(() => {
    loadIntegrations();
  }, []);

  const launchOSIntegration = {
    updatedAt: new Date().toISOString().slice(0, 23) + "Z",
    createdAt: new Date().toISOString().slice(0, 23) + "Z",
    setting: {},
    id: "launchOS",
    type: "launchOS",
  };

  return (
    <div className="px-4 w-full">
      <Title>
        What {type} provider should we use to send new{" "}
        {type === "email" ? "leads" : "orders"} that come from this campaign?
      </Title>
      <Panel
        title={
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full p-2 border-none outline-none appearance-none focus:outline-none focus:ring-0 peer bg-transparent"
            placeholder={`Search for an ${type} provider`}
            autoFocus
          />
        }
        actionButton={
          <Button
            type={ButtonTypes.SOFT}
            label="+ Add another provider"
            onClick={() => window.open("/integrations", "_blank")}
          />
        }
      >
        <div className="overflow-y-auto h-96 p-2 w-full">
          {/* {JSON.stringify([launchOSIntegration, ...activeIntegrations])} */}
          <IntegrationAccountList
            activeIntegrations={[launchOSIntegration, ...activeIntegrations]}
            query={query}
            onlyShowActives
            selectable
            onSelect={(id, provider) =>
              setIntegration({ ...integration, id, provider })
            }
            type={type}
          />
        </div>
      </Panel>
    </div>
  );
};

const ChooseCampaign = ({ integration = {}, setIntegration = () => {} }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { provider, id, payload = {} } = integration;

  const loadProviderCampaigns = async () => {
    setLoading(true);
    setError(null);
    try {
      let response;
      if (id)
        response = await getUserIntegrationSettings(id, {
          token: getSessionToken(),
        });
      else {
        const allIntegrations = await getUserIntegrations({
          token: getSessionToken(),
        });
        response = allIntegrations.find(({ type }) => type === provider);
      }

      const setting = response?.setting || {};
      console.log("Provider Settings", setting);

      const { data } = await client.query({
        query: GET_PROVIDER_CAMPAIGNS,
        variables: {
          provider,
          payload: setting,
        },
      });

      console.log("Provider Campaigns", data);

      const campaignList =
        data?.getProviderCampaigns?.response?.campaigns || [];

      setIntegration({
        ...integration,
        providerCredentials: encode(JSON.stringify(setting)),
        // payload: encode(JSON.stringify(payload))
      });

      setCampaigns(
        campaignList.map((campaign) => ({
          id: campaign.campaign_id,
          label: campaign.campaign_name,
        }))
      );
    } catch (err) {
      console.error("Error loading provider campaigns:", err);
      setError("Failed to load campaigns. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCampaignSelection = (e) => {
    setIntegration({
      ...integration,
      payload: {
        ...integration?.payload,
        campaignId: e.target.value,
      },
    });
  };

  useEffect(() => {
    loadProviderCampaigns();
  }, []);

  if (loading)
    return (
      <div className="p-9">
        <Loading type="gallery" />
      </div>
    );

  return (
    <div className="flex flex-col items-center p-7">
      <img
        className="w-16"
        src={
          integrationProviders.find(
            (provider) => provider?.id === integration?.provider
          )?.img
        }
        alt="Integration Provider"
      />
      <Title>
        When there is a new lead, which campaign should we send their contact
        information to?
      </Title>
      <Select
        flavor={TextInputFlavors.MODERN}
        label="Click here to choose a campaign..."
        items={[{ id: "none", label: "Choose a campaign" }, ...campaigns]}
        onChange={handleCampaignSelection}
        className="w-full"
        value={payload?.campaignId}
      />
    </div>
  );
};

const ChooseAmount = ({ integration = {}, setIntegration = () => {} }) => {
  const [dollarAmount, setDollarAmount] = useState(integration?.amount ? Math.floor(Number(integration.amount)).toString() : "");
  const [centsAmount, setCentsAmount] = useState(integration?.amount ? (Number(integration.amount) % 1).toFixed(2).slice(2) : "");
  const [loading, setLoading] = useState(false);

  const { provider, id, payload = {} } = integration;

  const loadProviderCampaigns = async () => {
    setLoading(true);
    try {
      let response;
      if (id) response = await getUserIntegrationSettings(id, { token: getSessionToken() });
      else {
        const allIntegrations = await getUserIntegrations({ token: getSessionToken() });
        response = allIntegrations.find(({ type }) => type === provider);
      }

      const setting = response?.setting || {};

      console.log("Provider Settings", setting);

      setIntegration({
        ...integration,
        providerCredentials: encode(JSON.stringify(setting)),
        payload: { ...payload, type: provider, ...setting }
      });
      
    } catch (err) {
      console.error("Error loading provider campaigns:", err);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    loadProviderCampaigns();
  }, []);

  if (loading)
    return (
      <div className="p-9">
        <Loading type="gallery" />
      </div>
    );

  return (
    <div className="flex flex-col items-center pt-7">
      <img
        className="w-16"
        src={
          integrationProviders.find(
            (provider) => provider?.id === integration?.provider
          )?.img
        }
        alt="Integration Provider"
      />
      <Title>
        How much should we charge someone who makes a purchase on this page?
      </Title>
      <div className="w-64 mx-auto" style={{ zoom: 1.4 }}>
        {/* {JSON.stringify({ dollarAmount: Number(dollarAmount), centsAmount: Number(centsAmount) })} */}
        <InputGroup
          elements={[
            { type: "text", content: "$", leftRounded: true },
            {
              type: "input",
              placeholder: "Enter Amount",
              value: dollarAmount,
              inputType: "text",
              onChange: (e) => {
                setDollarAmount(e.target.value);
                const amount = `${Number(e.target.value)}.${Number(centsAmount)}`;
                setIntegration({
                  ...integration,
                  payload: {
                    ...integration?.payload,
                    amount,
                  },
                  amount
                });
              },
            },
            {
              type: "input",
              placeholder: ".00",
              value: centsAmount,
              rightRounded: true,
              style: { width: 50 },
              onChange: (e) => {
                setCentsAmount(e.target.value.replace(".", ""));
                const amount = `${Number(dollarAmount)}.${Number(e.target.value.replace(".", ""))}`;
                setIntegration({
                  ...integration,
                  payload: {
                    ...integration?.payload,
                    amount,
                  },
                  amount
                });
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

// const Success = ({ type = "email" }) => {
//   return (
//     <div className="flex flex-col items-center p-8">
//       <div className="flex flex-col items-center px-5">
//         <CheckCircle className="w-20 h-20" />
//         <Title>
//           Success! We've connected your {type} provider and campaign together.
//         </Title>
//         <Calout
//           title={
//             type === "email"
//               ? "After publishing this campaign, any information submitted on any page in this campaign will be sent to the corresponding campaign in your Convert Kit account."
//               : "After publishing this page, any purchases made from this campaign, will be recorded as a new order in your Stripe account."
//           }
//           // caption="In rare cases it can take up to 48 hours."
//         />
//       </div>
//     </div>
//   );
// };

export const CampaignSettingsModal = ({ setIsOpen, objectData = {} }) => {
  const [step, setStep] = useState("ChooseIntegration");
  const [integration, setIntegration] = useState();

  const handleSuccess = async () => {
    const page = await updatePage(objectData?.page?.id, {
      token: getSessionToken(),
      settings: {
        pageSettings: {
          ...objectData?.page?.pageSettings,
          email: {
            ...integration,
            payload: encode(JSON.stringify(integration?.payload || {})),
          },
        },
      },
    });

    // console.log("Updated the page settings", page)
    setIsOpen(false);
  };

  const getLatestPageSettings = async () => {
    const page = await getPage(objectData?.page?.id, {
      token: getSessionToken(),
    });
    // console.log("Latest Page", page)
    return page?.data?.pageSettings;
  };

  useEffect(() => {
    (async () => {
      const pageSettings = await getLatestPageSettings();
      const { email } = pageSettings;
      const { providerCredentials, payload } = email;
      const providerCredentialsDecoded = providerCredentials
        ? JSON.parse(decode(providerCredentials))
        : {};
      const payloadDecoded = payload ? JSON.parse(decode(payload)) : {};

      console.log({ providerCredentialsDecoded, payloadDecoded });

      setIntegration({
        ...email,
        providerCredentials: providerCredentialsDecoded,
        payload: payloadDecoded,
      });
    })();
  }, []);

  useEffect(() => {
    if (integration?.provider) setStep("ChooseCampaign");
  }, [integration?.provider]);

  return (
    <Modal
      title="Campaign Settings"
      onSuccess={handleSuccess}
      onClose={() => setIsOpen(false)}
      size={PopupSizes.XLARGE}
      icon={<SettingsIcon />}
      footer={false}
    >
      <div className="items-center mb-7">
        {step === "ChooseIntegration" && (
          <ChooseIntegration
            integration={integration}
            setIntegration={setIntegration}
            type="email"
          />
        )}
        {step === "ChooseCampaign" && (
          <div>
            <ChooseCampaign
              integration={integration}
              setIntegration={setIntegration}
            />
            <FooterButtons
              position="center"
              showBack
              showCancel={false}
              showNext={false}
              showSave={true}
              onSave={handleSuccess}
              onBack={() => {
                setIntegration({ ...integration, provider: null });
                setStep("ChooseIntegration");
              }}
              captions={{ back: "<- Choose a different provider" }}
              onCancel={() => setIsOpen(false)}
            />
          </div>
        )}
        {/* {step === "Success" && <Success />} */}
      </div>
    </Modal>
  );
};

export const PaymentSettingsModal = ({ setIsOpen, objectData = {} }) => {
  const [step, setStep] = useState("ChooseIntegration");
  const [integration, setIntegration] = useState();

  const handleSuccess = async () => {
    const page = await updatePage(objectData?.page?.id, {
      token: getSessionToken(),
      settings: {
        pageSettings: {
          ...objectData?.page?.pageSettings,
          merchant: {
            ...integration,
            payload: encode(JSON.stringify(integration?.payload || {})),
          },
        },
      },
    });

    // console.log("Updated the page settings", page)
    setIsOpen(false);
  };

  const getLatestPageSettings = async () => {
    const page = await getPage(objectData?.page?.id, {
      token: getSessionToken(),
    });
    // console.log("Latest Page", page)
    return page?.data?.pageSettings;
  };

  useEffect(() => {
    (async () => {
      const pageSettings = await getLatestPageSettings();
      console.log("pageSettings", pageSettings);
      const { merchant = {} } = pageSettings;
      const { providerCredentials, payload } = merchant;
      const providerCredentialsDecoded = providerCredentials
        ? JSON.parse(decode(providerCredentials))
        : {};
      const payloadDecoded = payload ? JSON.parse(decode(payload)) : {};

      console.log({ providerCredentialsDecoded, payloadDecoded });

      setIntegration({
        ...merchant,
        providerCredentials: providerCredentialsDecoded,
        payload: payloadDecoded,
      });
    })();
  }, []);

  useEffect(() => {
    if (integration?.provider) setStep("ChooseCampaign");
  }, [integration?.provider]);

  return (
    <Modal
      title="Payment Settings"
      onSuccess={handleSuccess}
      onClose={() => setIsOpen(false)}
      size={PopupSizes.XLARGE}
      icon={<SettingsIcon />}
      footer={false}
    >
      {/* {JSON.stringify(integration)} */}
      <div className="items-center mb-7">
        {step === "ChooseIntegration" && (
          <ChooseIntegration
            type="payment"
            integration={integration}
            setIntegration={setIntegration}
          />
        )}
        {step === "ChooseCampaign" && (
          <div>
            <ChooseAmount
              integration={integration}
              setIntegration={setIntegration}
            />
            <FooterButtons
              position="center"
              showBack
              showCancel={false}
              showNext={false}
              showSave={true}
              onSave={handleSuccess}
              onBack={() => {
                setIntegration({ ...integration, provider: null });
                setStep("ChooseIntegration");
              }}
              captions={{ back: "<- Choose a different provider" }}
              onCancel={() => setIsOpen(false)}
            />
          </div>
        )}
        {/* {step === "Success" && <Success />} */}
      </div>
    </Modal>
  );
};
