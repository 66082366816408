import { getSessionToken, getTokenInfo } from "@/api/auth";
import { getPage } from "@/api/pages";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageContent } from "./Page";
import { Loading } from "@/ui/Layout/Loading";
import { getCampaign, getObject } from "@/api/campaigns";
import { PageContext } from "./PageContext";

import "@/Apps/Pages/Page/layout.css";
import { generateFontList } from "../utils";
import WebFont from "webfontloader";

export const loadFonts = (content) => {
  const families = generateFontList(content);
  console.log("Fonts", families);
  if (families.length) WebFont.load({ google: { families } });
};

export const Preview = ({ content = [] }) => {
  const [page, setPage] = useState({});
  const [campaign, setCampaign] = useState({});
  const [object, setObject] = useState({});
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { campaignId, objectId, pageId } = useParams();

  const loadData = async (
    campaignId: string,
    objectId: string,
    pageId?: string
  ) => {
    const token = getSessionToken();
    const user = await getTokenInfo(token);

    const campaign = await getCampaign({ token, id: campaignId });
    const object = await getObject(objectId);

    console.log("Page Id", pageId, object?.data?.object?.page?.id);

    if (!pageId) pageId = object?.data?.object?.page?.id;

    const page = await getPage(pageId, { token });

    console.log("Page Data", page?.data);
    console.log("Campaign/Object Data", campaign, object);

    loadFonts(page?.data?.content);
    setPage(page?.data);
    setCampaign(campaign);
    setObject(object?.data?.object);
    setUser(user);
    setIsLoading(false);
  };

  useEffect(() => {
    if (Boolean(content.length)) {
      setIsLoading(true);
      loadData(campaignId, objectId, pageId);
    }
  }, []);

  if (isLoading)
    return (
      <div className="p-9 w-full max-w-6xl mx-auto">
        <Loading type="gallery" />
      </div>
    );

  return (
    <PageContext.Provider
      value={{
        campaignSettings: campaign?.settings,
        parseLiquid: true,
      }}
    >
      <PageContent
        id={String(pageId)}
        content={JSON.stringify(page?.content)}
        nextPage="#"
        getPageData={() => page?.content}
        trackingPayload=""
        seoPayload=""
        merchantPayload=""
        emailPayload=""
        cssPayload=""
        exitPayload=""
        objectId={object?.id}
        campaignId={campaign?.id}
        userId={user?.id}
      />
    </PageContext.Provider>
  );
};
