import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { SocialProofLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.TWO,
  layout: SocialProofLayouts.TWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkypng982by30780ld7yaetu",
    itemId: "xey4k1v7bpr",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          textAlign: "center",
          margin: "0 auto",
          width: 480,
          display: "inline-block",
          mobile: {
            desktop: { width: 480 },
            smartphone: { width: "100%" },
          },
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
            },
            html: `"Hands down the best process I've seen in the last 11 years.  Thank you!!"`,
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: `Jacob McDonald, VP Marketing`,
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              paddingLeft: 0,
              margin: "0 auto",
              maxWidth: 200,
              marginTop: 30,
              display: "inline-block",
              textAlign: "center",
            },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                html: `<p style="font-size: 24pt; padding: 0; text-align: left;"><strong>Jacob McDonald</strong></p><p></p><p style="font-size: 10pt; padding: 0; text-align: left;">VP Marketing</p>`,
                icon: IconTypes.AccountCircle,
                iconStyle: {
                  padding: 5,
                  paddingRight: 15,
                  fontSize: "28pt",
                  color: theme.backgroundColor,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
