import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@/Apps/CRM/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.SIX,
  layout: FormLayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyu67qy2e6c07805fbyk6cj",
    itemId: "9yreivl988b",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  mobile: {
                    desktop: {
                      padding: 100,
                    },
                    smartphone: {
                      padding: 20,
                    },
                  },
                  backgroundColor: theme.backgroundColor,
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: "Try it free!",
                  },
                  {
                    type: ComponentTypes.FORMFIELD,
                    canHaveChildren: false,
                    attributes: {
                      placeholder: "First Name",
                      name: "firstName",
                    },
                    fieldType: FormFieldTypes.TEXT,
                    preset: FieldNamePresets.FIRSTNAME,
                    styles: {
                      containerStyle: style.FORM_FIELD.style,
                      inputStyle: style.FORM_FIELD.inputStyle,
                    },
                  },
                  {
                    type: ComponentTypes.FORMFIELD,
                    canHaveChildren: false,
                    attributes: {
                      placeholder: "Email",
                      name: "email",
                    },
                    fieldType: FormFieldTypes.TEXT,
                    preset: FieldNamePresets.EMAIL,
                    styles: {
                      containerStyle: style.FORM_FIELD.style,
                      inputStyle: style.FORM_FIELD.inputStyle,
                    },
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: style.BUTTON.style,
                    caption1: "Free Instant Access",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [],
          },
        ],
      },
    ],
  },
};

export default settings;
