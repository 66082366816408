import { ComponentRenderHooks } from "@/plugins/types";
import EditorVideo, { Properties, SimpleVideoProperties } from './Video'
import settings from './settings'
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings]
  }
}

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === 'webcomponent' && payload.type === 'Video') {
    return [EditorVideo]
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Video") {
    return Properties(payload);
  }

  if (
    hook.id === ComponentRenderHooks.SIMPLE_PROPERTIES &&
    hook.type === ComponentTypes.VIDEO
  ) {
    return SimpleVideoProperties(payload);
  }
}