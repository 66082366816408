import React, { useContext, useEffect } from "react";

import { AppContext } from "@/AppContext";
import { Breadcrumbs } from "@/ui/Breadcrumbs";

import { SidebarToggleIcon, DarkToggleIcon, LightToggleIcon } from "@/ui/Icons";

import { isEqual, truncate } from "lodash";
import { TopbarButtonProps, TopbarProps } from "./types";
import UniversalSearch from "@/ui/UniversalSearch";
import { Sparkle } from "@phosphor-icons/react";

export const TopbarButton: React.FC<TopbarButtonProps> = ({
  children = <></>,
  onClick = () => {},
  isActive = false,
}) => {
  return (
    <div
      className={`cursor-pointer p-2.5 rounded-lg ${
        isActive
          ? "hover:dark:bg-white/10 hover:bg-black/10 hover:dark:text-white hover:text-black dark:bg-white/90 bg-black/90 dark:text-black text-white"
          : "dark:bg-white/10 bg-black/10 dark:text-white text-black hover:dark:bg-white/90 hover:bg-black/90 hover:dark:text-black hover:text-white"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const Topbar: React.FC<TopbarProps> = ({
  showLeftSidebarToggle = false,
  page = [{ label: "Default", href: "/" }],
  items = null,
  centerItems = null,
  leftItems = null,
  hideCopilotButton = false,
}) => {
  // Get the necessary context from the AppContext
  const {
    // darkMode,
    sidebarOpen,
    rightSidebarOpen,
    // setDarkMode,
    copilotOpen,
    setCopilotOpen,
    currentlyViewing,
    setSidebarOpen,
    setRightSidebarOpen,
    disableRightSidebar,
    setCurrentlyViewing,
    permissions,
  } = useContext(AppContext);

  // Toggle the left sidebar
  const toggleLeftSidebar = () => {
    localStorage.setItem("sidebarOpen", !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  // Toggle the right sidebar
  const toggleRightSidebar = () => {
    localStorage.setItem("rightSidebarOpen", !rightSidebarOpen);
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const toggleCopilot = () => {
    setCopilotOpen(!copilotOpen)
  }

  useEffect(() => {
    if (!isEqual(page, currentlyViewing)) {
      setCurrentlyViewing(page);
      console.log("Page updated", page, currentlyViewing);
    }
  }, []);

  // Render the top bar
  return (
    <div
      data-testid="Topbar"
      className="border-b border-black/10 dark:border-white/10  px-5 flex items-center justify-between"
      style={{ height: 65 }}
    >
      <div className="flex items-center gap-0">
        {/* Toggle button for the left sidebar */}
        {/* {(!copilotOpen || showLeftSidebarToggle) && ( */}
          <button
            type="button"
            className="text-black dark:text-white"
            onClick={toggleLeftSidebar}
            data-testid="Topbar-LeftSidebarToggle"
          >
            <SidebarToggleIcon />
          </button>
        {/* )} */}

        {/* Breadcrumbs */}
        <Breadcrumbs
          items={page.map((item) => ({
            label: truncate(item.label, { length: 30 }),
            href: item.href,
            icon: item.icon,
          }))}
          type="Slash"
        />
        {leftItems}
      </div>

      {centerItems && (
        <div className="flex justify-center items-center">
          {centerItems}
        </div>
      )}

      <div className="flex items-center gap-3">
        {/* Universal search */}
        {/* {permissions?.includes("copilot") && !hideCopilotButton && <UniversalSearch />} */}

        {/* Action buttons */}
        {items}
      
        {!hideCopilotButton && <div
          data-testid="Topbar-DarkModeToggle"
          className="flex items-center gap-2"
        >
          <TopbarButton
            data-testid="Topbar-RightSidebarToggle"
            onClick={toggleCopilot}
            isActive={copilotOpen}
          >
            <Sparkle className="text-lg" />
          </TopbarButton>
        </div>}
        
      </div>
    </div>
  );
};
