import { get, startCase, uniqueId } from "lodash";
import moment from "moment";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { PricingLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: PricingLayouts.THREE,
  layout: PricingLayouts.THREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/pricing/Pricing03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyd2er52ag90780rf8yda8f",
    itemId: "moxfvys9tj",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.PRICING,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
      width: 980,
      margin: "0 auto",
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  borderRadius: 5,
                  backgroundColor: "#f5f5f5",
                  padding: 30,
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: style.TEXT,
                    html: "Basic",
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: style.HEADLINE,
                    html: "$27/mo",
                  },
                  {
                    type: ComponentTypes.LIST,
                    canHaveChildren: false,
                    style: style.LIST,
                    containerStyle: style.LI.style,
                    contentStyle: style.LI.contentStyle,
                    icon: IconTypes.Check,
                    iconStyle: style.LI.iconStyle,
                    data: [
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Lorem ipsum dolor`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Sit apet consectetur`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `40 Adipiscing elit`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `25 Sed do eiusmod`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Tempor incididunt`,
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      textAlign: "center",
                    },
                    children: [
                      {
                        type: ComponentTypes.BUTTON,
                        canHaveChildren: false,
                        properties: {
                          ...style.BUTTON.style,
                          backgroundColor: "transparent",
                          border: `1px solid ${theme.backgroundColor}`,
                        },
                        caption1: "Buy Now",
                        caption1style: {
                          ...style.BUTTON.caption1,
                          color: theme.backgroundColor,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  borderRadius: 5,
                  backgroundColor: theme.backgroundColor,
                  padding: 30,
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: { ...style.TEXT, color: theme.foregroundColor },
                    html: "Standard",
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      color: theme.foregroundColor,
                    },
                    html: "$97/mo",
                  },
                  {
                    type: ComponentTypes.LIST,
                    canHaveChildren: false,
                    style: style.LIST,
                    containerStyle: style.LI.style,
                    contentStyle: {
                      ...style.LI.contentStyle,
                      color: theme.foregroundColor,
                    },
                    iconStyle: {
                      ...style.LI.iconStyle,
                      color: theme.foregroundColor,
                    },
                    icon: IconTypes.Check,
                    data: [
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Lorem ipsum dolor`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Sit apet consectetur`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `40 Adipiscing elit`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `25 Sed do eiusmod`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Tempor incididunt`,
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      textAlign: "center",
                    },
                    children: [
                      {
                        type: ComponentTypes.BUTTON,
                        canHaveChildren: false,
                        properties: {
                          ...style.BUTTON.style,
                          backgroundColor: theme.foregroundColor,
                          // border: `1px solid ${theme.backgroundColor}`
                        },
                        caption1: "Buy Now",
                        caption1style: {
                          ...style.BUTTON.caption1,
                          color: theme.backgroundColor,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  borderRadius: 5,
                  backgroundColor: "#f5f5f5",
                  padding: 30,
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: style.TEXT,
                    html: "Premium",
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: style.HEADLINE,
                    html: "$147/mo",
                  },
                  {
                    type: ComponentTypes.LIST,
                    canHaveChildren: false,
                    style: style.LIST,
                    containerStyle: style.LI.style,
                    contentStyle: style.LI.contentStyle,
                    icon: IconTypes.Check,
                    iconStyle: style.LI.iconStyle,
                    data: [
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Lorem ipsum dolor`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Sit apet consectetur`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `40 Adipiscing elit`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `25 Sed do eiusmod`,
                      },
                      {
                        id: Math.random().toString(3).slice(2),
                        caption: `Tempor incididunt`,
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      textAlign: "center",
                    },
                    children: [
                      {
                        type: ComponentTypes.BUTTON,
                        canHaveChildren: false,
                        properties: {
                          ...style.BUTTON.style,
                          backgroundColor: "transparent",
                          border: `1px solid ${theme.backgroundColor}`,
                        },
                        caption1: "Buy Now",
                        caption1style: {
                          ...style.BUTTON.caption1,
                          color: theme.backgroundColor,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: { ...style.TEXT, marginTop: 30 },
        html: `Limited offer: Save 23% for the annual plan until the 30th of ${moment().format(
          "MMMM"
        )}`,
      },
    ],
  },
};

export default settings;
