import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";

import { ContactsGallery } from "@/Apps/CRM/ContactsGallery";
import { getFilterObject } from "@/Apps/CRM/scripts";
import { ContentDashboardPage } from "../Member/ContentDashboardPage";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "overview");
  const [socialMediaCampaignData, setSocialMediaCampaignData] = useState<{
    name?: string;
  }>({ name: "" });
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  // const [activePageId, setActivePageId] = useState(null);
  const { id } = useParams();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setSocialMediaCampaignData(data);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/social" },
      { label: socialMediaCampaignData.name || "" },
    ],
    items: (
      <Button
        label="Publish Campaign"
        onClick={() => setIsPublishPopupOpen(true)}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
          unitName="Social Media Campaign"
        />
      )}
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              { label: "Dashboard", value: "overview" },
              { label: "Content", value: "content" },
              // { label: "Calendar", value: "calendar" },
              { label: "Media Assets", value: "media" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "overview" && (
            <DashboardPage
              campaignData={socialMediaCampaignData}
              unitName="post"
              widgets={[
                "kpi",
                {
                  type: "columns",
                  settings: {
                    widgets: [
                      "posts",
                      {
                        type: "group",
                        settings: { widgets: ["media", "tracking"] },
                      },
                    ],
                  },
                },
              ]}
            />
          )}
          {activeTab === "content" && (
            <ContentDashboardPage
              campaignData={socialMediaCampaignData}
              unitName="post"
            />
          )}
          {activeTab === "media" && (
            <DashboardPage
              campaignData={socialMediaCampaignData}
              unitName="Membership Site"
              widgets={["media"]}
            />
          )}
          {activeTab === "settings" && (
            <Settings
              campaignData={socialMediaCampaignData}
              setCampaignData={setSocialMediaCampaignData}
              unitName="Social Media Campaign"
              widgets={["name", "delete"]}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
