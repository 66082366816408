import { Helmet } from "react-helmet";

import { generateInlineStyle } from "@/Apps/Pages/Properties/RichEditor/utilities";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { parseValue } from "@/Apps/Pages/Page/scripts";

import style from "./style";
import { LiquidRenderer } from "@/Apps/Pages/Page/LiquidRenderer";

const HeadlineContent = props => {
  const { settings, pageId } = props;
  const padding = settings.properties ? settings.properties.padding : 0;

  return (
    <div style={{ padding }}>
      <Helmet>
        <style type="text/css">
          {generateInlineStyle(settings, EditorMode.LIVE)}
        </style>
      </Helmet>
      <div
        style={{
          display: "inline",
          ...style.content,
          ...settings.properties,
          marginTop: 0,
          padding: 0,
          // lineHeight: 0,
        }}
        // dangerouslySetInnerHTML={{
        //   __html: parseValue(settings.html) || "Enter Your Headline Here"
        // }}
        // dangerouslySetInnerHTML={{ __html: "This is a quick test!" }}
      >
        {/* {parseValue(settings.html)} */}
        {/* {process.env.NODE_ENV === `production` ? `<%= test['123'] %>` : <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: "This is a quick test" }} />} */}
        {(typeof window === "function") === "undefined" ? (
          `<%= item['${pageId}']['${settings.id}']['html'] %>`
        ) : (
          // <div
          //   style={{ display: "inline" }}
          //   dangerouslySetInnerHTML={{ __html: parseValue(settings.html) }}
          // />
          <LiquidRenderer html={settings.html} />
        )}
      </div>
    </div>
  );
}; // it's not passing the values properly here on gatsby live build!

export const Headline = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div data-testid="WC-HEADLINE-LIVE" style={{ marginTop, textAlign }}>
      <HeadlineContent {...props} />
    </div>
  );
};

export default Headline;
