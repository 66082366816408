import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CountDownTypes } from "@/Apps/Pages/WebComponents/v2/Countdown/types";
import { UrgencyLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: UrgencyLayouts.FIVE,
  layout: UrgencyLayouts.FIVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/urgency/Urgency05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkztsx5q2llj0780vbppfmr4",
    itemId: "idbzfqvsql9",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.URGENCY,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  // alignContent: "center",
                  mobile: {
                    desktop: {
                      padding: 25,
                      textAlign: "left",
                    },
                    smartphone: {
                      textAlign: "center",
                      padding: 5,
                    },
                  },
                  ...get(content, "main_headline.style", {}),
                },
                html: `The Next ${startCase(
                  lorem.generateWords(4)
                )} Workshop Starts In...`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  textAlign: "center",
                  display: "inline-block",
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      display: "inline-block",
                      textAlign: "center",
                      mobile: {
                        desktop: {
                          padding: 30,
                        },
                        smartphone: {
                          padding: 5,
                        },
                      },
                    },
                    children: [
                      {
                        type: ComponentTypes.COUNTDOWN,
                        countType: CountDownTypes.EVENT,
                        eventDate: Date.now() + 2 * 24 * 60 * 60 * 1000,
                        properties: {},
                        dimensionStyle: style.COUNTDOWN.dimensionStyle,
                        labelStyle: style.COUNTDOWN.labelStyle,
                        numberStyle: style.COUNTDOWN.numberStyle,
                        dividerStyle: style.COUNTDOWN.dividerStyle,
                        showDivider: false,
                        actions: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
