// import * as React from "react";

// Verson Two Web Components
// import * as Accordian from "./Accordian/live";
// import * as Animation from "./Animation/live";
// import * as Block from "./Block/live";
// import * as Body from "./Body/live";
import * as Button from "./Button/live";
// import * as Carousel from "./Carousel/live";
// import * as Container from "./Container/live";
import * as Countdown from "./Countdown/live";
import * as Divider from "./Divider/live";
import * as FacebookComments from "./FacebookComments/live";
import * as FacebookLogin from "./FacebookLogin/live";
import * as FormBuilder from "./FormBuilder/live";
import * as FormField from "./FormField/live";
import * as GoogleLogin from "./GoogleLogin/live";
// import * as Grid from "./Grid/live";
// import * as GridItem from "./GridItem/live";
// import * as Group from "./Group/live";
// import * as Headline from "./Headline/live";
import * as HTML from "./HTML/live";
import * as Icon from "./Icon/live";
// import * as Image from "./Image/live";
import * as List from "./List/live";
import * as ListItem from "./ListItem/live";
import * as Navigation from "./Navigation/live";
import * as OrderSummary from "./OrderSummary/live";
import * as OrderBump from "./OrderBump/live";
// import * as Popup from "./Popup/live";
import * as Progress from "./Progress/live";
// import * as Repeater from "./Repeater/live";
import * as Section from "./Section/live";

// import * as Shapes from "./Shapes/live";
// import * as ShareButton from "./ShareButton/live";
// import * as SmartContainer from "./SmartContainer/live";
import * as Spacer from "./Spacer/live";
// import * as Stacks from "./Stacks/live";
// import * as Step from "./Step/live";
// import * as Stepper from "./Stepper/live";
// import * as Switch from "./Switch/live";
import * as Tabs from "./Tabs/live";
// import * as Table from "./Table/live";
// import * as Text from "./Text/live";
// import * as Video from "./Video/live";
// import * as Row from "./Row/live";
import * as PaymentElement from "./PaymentElement/live";

// const SectionX = React.lazy(() => import("./Section/live"));
// const Section = (props) => <React.Suspense fallback={<div>Loading...</div>}><SectionX {...props} /></React.Suspense>

// const Section = [(props) => <div {...props} />];

export default [
  // Carousel,
  Countdown,
  FormBuilder,
  FormField,
  HTML,
  List,
  ListItem,
  Navigation,
  OrderSummary,
  OrderBump,
  Tabs,
  Button,
  Divider,
  FacebookComments,
  FacebookLogin,
  GoogleLogin,
  Icon,
  Progress,
  Section,
  Spacer,
  PaymentElement,
  // Row,
];
