import { has } from "lodash";
// import React from "react";

// import theme from "@launchos/components/ui/theme";
// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import V1ObjectWrapper from "../../v2/V1ObjectWrapper";
import Properties from "./Properties";

import { getColumnsInnerStyle, getColumnsWrapperStyle } from "./style";
import theme from "@/ui/theme";
import EditorObject from "@/Apps/Pages/EditorObject";

export default (props) => {
  const handleDistributeColumns = (id) => {
    const { settings, pageContent, updateContent } = props;

    if (id === "distribute") {
      const theColumns = pageContent.filter(
        (itm) => itm.parent === settings.id
      );
      const newWidth = Math.round(100 / theColumns.length);
      let newContent = pageContent;

      theColumns.forEach((column) => {
        const k = newContent.findIndex((itm) => itm.id === column.id);
        newContent[k] = {
          ...column,
          ...(has(column, "md") ? { md: newWidth } : {}),
          ...(has(column, "xs") ? { xs: newWidth } : {}),
          ...(has(column, "lg") ? { lg: newWidth } : {}),
          ...(has(column, "sm") ? { sm: newWidth } : {}),
        };
      });

      updateContent(newContent);
    }

    return true;
  };

  let settings = props.settings;
  settings.properties.maxWidth = settings.properties.width;
  settings.properties.width = "100%";

  return (
    <V1ObjectWrapper settings={settings}>
      <div style={getColumnsWrapperStyle(props)}>
        <EditorObject
          {...props}
          style={{ height: "100%", display: "block" }}
          PropertiesView={Properties}
          color={theme.altColors[1]}
          label="Row"
          dropDownItems={(items) => {
            const key = items.findIndex(({ id }) => id === "divider");

            return [
              ...items.slice(0, key),
              { id: "divider" },
              {
                id: "distribute",
                text: "Distribute Evenly",
                icon: "horizontal_distribute",
              },
              { id: "divider" },
              ...items.slice(key + 1)
            ]
          }}
          onDropDownChange={handleDistributeColumns}
        >
          <div style={getColumnsInnerStyle(props)}>{props.children}</div>
        </EditorObject>
      </div>
    </V1ObjectWrapper>
  );
};
