import { Page, Container } from "@/ui/Layout/Page";
import { CopilotHistory, loadChats } from "../AITools/CopilotGallery";
import { useEffect, useState } from "react";
import { Loading } from "@/ui/Layout/Loading";
import { EmptyState } from "@/ui/Layout";
import { Link, useNavigate } from "react-router-dom";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { cloneCampaign, updateCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";

export const Agents = ({ name = "" }) => {
  const [agentList, setAgentList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleCreateAgent = async () => {
    const name = prompt("Enter a name for your agent");
    const campaignId = "cjz0ij1qz0ems0792n1kpu5ac"; // The ID of the collection campaign template (blank)
    if (name) {
      setIsLoading(true);
      const token = getSessionToken();
      const response = await cloneCampaign(campaignId, { token, name });
      const newCampaignId = response?.data?.cloneCampaign?.response?.id;

      // update the type of the campaign to be a collections campaign
      const updateResponse = await updateCampaign(newCampaignId, {
        token,
        settings: {
          type: "agent",
        },
      });

      // console.log("newCampaignId", newCampaignId, updateResponse);

      if (newCampaignId) {
        // navigate to the new funnel
        navigate(`/agents/${newCampaignId}`);
      }
    }
  };

  useEffect(() => {
    loadChats((seqs) => {
      setAgentList(seqs.reverse());
      setIsLoading(false);
    }, "agent");
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Button
            label="+ Create a Workflow"
            type={ButtonTypes.OUTLINED}
            onClick={handleCreateAgent}
          />
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(agentList.length) && (
          <EmptyState
            title="No Agents Yet"
            description="Get started by creating a new agent."
            showImage={false}
          >
            <Button
              label="Create a new agent"
              type={ButtonTypes.DEFAULT}
              onClick={handleCreateAgent}
            />
          </EmptyState>
        )}
        <CopilotHistory list={agentList} type="agents" />
      </Container>
    </Page>
  );
};
