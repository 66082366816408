import { getSessionToken } from "@/api/auth";
import AddNewDrawer from "../AddNewDrawer";
import { ComponentRenderHooks, HookTriggers } from "@/plugins/types";
import { triggerHook } from "@/plugins/client";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { getPage, updatePage } from "@/api/pages";
import PureCanvas from "../V1Editor/PureCanvas";
import { CURSOR_ID, ISettings } from "./types";
import { Loading } from "@/ui/Layout/Loading";
import { debounce, head, isEqual, keys, last, omit } from "lodash";
import TabsComponent from "@/ui/TabsComponent";
import { TabTypes } from "@/ui/TabsComponent/types";
import * as v2editorActions from "@/Apps/Pages/V2Editor/actions";
import { EditorContext } from "./EditorContext";
import { getCampaign } from "@/api/campaigns";
import { MobileState } from "../MobileResponsiveToggle/types";
import { BrowserWrapper } from "./BrowserWrapper";
import { getResponsiveSettings } from "../Page/scripts";
import { setContextForView } from "@/Apps/AITools/utils";
import { AppContext } from "@/AppContext";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

interface FullEditorProps {
  ref?: React.Ref<any>;
  campaignId: string;
  pageId: string;
  objectId: string;
  pageContent?: ISettings[];
  mobileState?: { type: MobileState };
  setMobileState?: (state: { type: MobileState }) => void;
  isWidgetSidebarShowing?: boolean;
  isPropertiesSidebarShowing?: boolean;
  onLoad?: () => void;
}

export const cleanThisContent = (content) => {
  return content
    .filter((itm) => itm.id !== CURSOR_ID)
    .map(({ state, ...rest }) => rest);
};

export const FullEditor: React.FC<FullEditorProps> = forwardRef(
  (
    {
      campaignId,
      pageId,
      objectId,
      pageContent = [],
      mobileState = { type: MobileState.FULLSCREEN },
      setMobileState = () => {},
      onLoad = () => {},
      isWidgetSidebarShowing = true,
      isPropertiesSidebarShowing = true,
    }: FullEditorProps,
    ref
  ) => {
    //  const [propertySections, setPropertySections] = useState<IPropertyBuilderSettings>();
    const [editorIsLoading, setEditorIsLoading] = useState<boolean>(true);
    const [content, setContent] = useState<ISettings[]>(pageContent);
    const [addNewDrawerType, setAddNewDrawerType] = useState<"OBJECTS" | "SECTIONS">("OBJECTS");

    const [activeSettings, setActiveSettings] = useState({});

    const [undoStack, setUndoStack] = useState<ISettings[][]>([]);
    const [undoPosition, setUndoPosition] = useState<number>(-1);
    const contextObj = useContext(AppContext);

    const MAX_UNDO_STACK_SIZE = 50; // Adjust as needed

    const token = getSessionToken();

    const updateEditorContent = (pageId, newContent) => {
      setContent(newContent); // update the conteont

      // update the active settings
      const { id } = activeSettings;
      const item = newContent.find((itm) => itm.id === id);

      setActiveSettings(item);
    };

    const updateEditorComponent = (componentId, newComponent) => {
      // console.log("updateEditorComponent", newComponent);
      const changedContent = v2editorActions.updateComponentSettings(
        content,
        componentId,
        newComponent
      );
      updateEditorContent(pageId, changedContent);
    };

    const updateEditorComponentStyle = (componentId, newStyle) => {
      // console.log("updateEditorComponentStyle", newStyle);
      const newComponent = v2editorActions.updateComponentStyle(
        content,
        componentId,
        newStyle
      );

      // console.log("updateEditorComponentStyle", newComponent);
      updateEditorComponent(componentId, newComponent);
    };

    const addThisAfterThat = (settings, id) => {
      const newContent = v2editorActions?.addThisAfterThat(
        content,
        settings,
        id
      );
      updateEditorContent(pageId, newContent);
    };

    const handleChange = ({ content }) => {
      // console.log("Change Occurred", content.filter((item) => item.html?.indexOf("Introducing") > -1));
      setContent(content);

      const cleanedContent = content; // cleanThisContent(content);

      addToUndoStack(cleanedContent);

      // Call the debounced save function
      debouncedHandleSave({ content: cleanedContent });
    };

    const handleItemSelect = (id, settings) => {
      console.log("Settings Passed", id, settings);

      // get the original version of the settings
      // const { id } = settings;
      const item = content.find((itm) => itm.id === settings?.id);      
      setActiveSettings(item);
    };

    const loadPage = async () => {
      if (!pageId) {
        const { objects } = await getCampaign({ token, id: campaignId });
        const { page } = head(objects);
        pageId = page?.id;
      }

      const { data } = await getPage(pageId, { token });
      const { content = [], slug } = data;
      console.log("Page is loaded!");      
      
      setContent(cleanThisContent(content));
      // const cleanedContent = cleanThisContent(content);
      // cleanedContent.forEach((item, index) => {
      //   setTimeout(() => {
      //     setContent((prevContent) => [...prevContent, item]);
      //   }, index * 200);
      // });

      addToUndoStack(cleanThisContent(content));
      setEditorIsLoading(false);
    };

    const handleSave = async ({ content }: { content: ISettings[] }) => {
      const cleanedContent = cleanThisContent(content);

      try {
        // purposefully not doing await, because saves happen in the background
        updatePage(pageId, {
          token,
          settings: { content: cleanedContent },
        });

        setContextForView({ data: cleanedContent, view: "Page Content" }, contextObj);

      } catch (error) {
        console.error("Failed to save page:", error);
        // Implement user feedback for save failure
      }
    };

    const addToUndoStack = (content: ISettings[]) => {
      // only update the stack if the incoming content is different from the content at the current position
      setUndoStack((prevStack) => {
        const cleanedContent = cleanThisContent(content);
        if (
          undoPosition === -1 ||
          !isEqual(prevStack[undoPosition], cleanedContent)
        ) {
          const newStack = [
            ...prevStack.slice(0, undoPosition + 1),
            cleanedContent,
          ].slice(-MAX_UNDO_STACK_SIZE);
          setUndoPosition(newStack.length - 1);
          return newStack;
        }
        return prevStack;
      });
    };

    const doUndo = () => {
      if (canUndo()) {
        setUndoPosition((prevPosition) => {
          const newPosition = prevPosition - 1;
          setContent(undoStack[newPosition]);
          debouncedHandleSave({ content: undoStack[newPosition] });
          return newPosition;
        });
      }
    };

    const doRedo = () => {
      console.log("doRedo was called!");
      if (canRedo()) {
        setUndoPosition((prevPosition) => {
          const newPosition = prevPosition + 1;
          setContent(undoStack[newPosition]);
          debouncedHandleSave({ content: undoStack[newPosition] });
          return newPosition;
        });
      }
    };

    const canUndo = () => undoPosition > 0;
    const canRedo = () => undoPosition < undoStack.length - 1;

    const shouldIShowProperties = isPropertiesSidebarShowing && Boolean(keys(activeSettings).length);
    const rSettings = getResponsiveSettings(activeSettings, mobileState);
    const responsiveSettings = { ...rSettings, properties: omit(rSettings?.properties, 'mobile')};

    useImperativeHandle(ref, () => ({
      updateEditorComponent,
      doUndo,
      doRedo,
      canUndo,
      canRedo,
      content,
    }));

    // Debounce handleSave to trigger no more than once every three seconds
    const debouncedHandleSave = useCallback(
      debounce(({ content }) => handleSave({ content }), 3000),
      []
    );

    useEffect(() => {
      // if (!pageContent.length) loadPage();
      loadPage();
    }, []);

    if (editorIsLoading)
      return (
        <div className="p-24">
          <Loading type="gallery" />
        </div>
      );

    return (
          <DndProvider backend={HTML5Backend}>
      <EditorContext.Provider
        value={{ pageId, objectId, campaignId, content, mobileState }}
      >
        {/* <UndoTestingPanel /> */}
        <div className="w-full h-full bg-gray-50 dark:bg-black/80 flex">
          {isWidgetSidebarShowing && (
            <div className="left-0 border-r border-black/10 dark:border-white/10 h-full overflow-y-auto w-[400px] z-10">
              <div className="tabs px-7 mt-7 flex items-center justify-center mx-auto">
                <TabsComponent
                  tabs={[
                    { label: "Elements", value: "OBJECTS" },
                    { label: "Sections", value: "SECTIONS" },
                  ]}
                  activeTab={addNewDrawerType}
                  setActiveTab={setAddNewDrawerType}
                  actionSection={false}
                  type={TabTypes.BUTTON}
                />
              </div>
              <AddNewDrawer
                activeTab={addNewDrawerType}
                pageContent={content}
                // changeState={(id, state, clbk) => clbk()}
                addThisAfterThat={addThisAfterThat}
              />
            </div>
          )}

          <div
            className={`flex-1 w-full h-full text-black overflow-auto ${mobileState?.type === MobileState.DESKTOP && "p-9"}`}
            style={{
              // should be full width if both isWidgetSidebarShowing and shouldIShowProperties are false, but if one is showing (true) then minus 400, but if both are showing (true) then minus 800
              maxWidth: `calc(100vw - ${isWidgetSidebarShowing && shouldIShowProperties ? "800px" : isWidgetSidebarShowing || shouldIShowProperties ? "400px" : "0px"})`,
              margin: "0 auto",
            }}
          >
            <BrowserWrapper mobileState={mobileState}>
              {/* {JSON.stringify(content)} */}
              <PureCanvas
                content={content}
                onChange={handleChange}
                onItemSelect={handleItemSelect}
                onSave={handleSave}
                disableDefaultProperties
                setActiveAddNewTab={setAddNewDrawerType}
                mobileState={mobileState}
                setMobileState={setMobileState}
                onLoad={onLoad}
              />
            </BrowserWrapper>
          </div>

          {shouldIShowProperties && (
            <div className="right-0 border-l border-black/10 dark:border-white/10 h-full overflow-y-auto w-[400px] z-10">
              {/* <pre>{JSON.stringify(content.find(({ id }) => activeSettings?.id === id), null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(responsiveSettings, null, 2)}</pre> */}
              <RenderProperties
                settings={responsiveSettings}
                rSettings={rSettings}
                updateComponentStyle={updateEditorComponentStyle}
                updateContent={updateEditorContent}
                updateComponentSettings={updateEditorComponent}
                setMobileState={setMobileState}
                getMobileState={() => mobileState}
                currentMobileState={mobileState}
              />
            </div>
          )}
        </div>
      </EditorContext.Provider>
          </DndProvider>
    );
  }
);

export const RenderProperties = ({
  settings,
  rSettings,
  updateComponentStyle,
  updateContent,
  updateComponentSettings,
  setMobileState,
  getMobileState,
  currentMobileState,
}) => {
  // useEffect(() => { console.log("Active Settings Updated", settings) }, [settings]);
  const getSettings = () => settings;

  return triggerHook(
    HookTriggers.onComponentRender,
    {
      id: ComponentRenderHooks.PROPERTIES,
      type: settings?.type,
    },
    {
      settings,
      getSettings,
      rSettings,
      updateComponentStyle,
      updateContent,
      updateComponentSettings,
      setMobileState,
      getMobileState,
      currentMobileState,
    }
  );

  // if (Boolean(sections?.main?.sections?.length)) {
  //   return (
  //     <Generator
  //       data={sections}
  //       settings={settings}
  //       updateComponentStyle={updateComponentStyle}
  //       updateContent={updateContent}
  //       updateComponentSettings={updateComponentSettings}
  //     />
  //   );
  // } else {
  //   return triggerHook(
  //     HookTriggers.onComponentRender,
  //     {
  //       id: ComponentRenderHooks.PROPERTIES,
  //       type: settings?.type,
  //     },
  //     {
  //       settings,
  //       updateComponentStyle,
  //       updateContent,
  //       updateComponentSettings,
  //     }
  //   );
  // }
};
