// import React from "react";
import { DropTarget } from "react-dnd";
// import PageBuilder from "@launchos/modules/editor/Editor";
import PageBuilder from "./Editor";

// import { useDimensions } from "@launchos/plugins/utilities";
import { useDimensions } from "../utils";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

import Canvas from "./Canvas";

import { shallowEqual } from "../WebComponent/WebComponent";

import _ from "lodash";

import "@/Apps/Pages/Page/index.css";
import "@/Apps/Pages/Page/layout.css";

class CPureCanvas extends PageBuilder {
  constructor(props) {
    super(props);
    // this.setState = console.log
  }

  // update the state when the prop content changes
  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.content !== this.props.content) {
    if (!shallowEqual(prevProps.content, this.props.content)) {
      this.setState({ content: this.props.content });
    } else if (!shallowEqual(prevState.content, this.state.content)) {
      // console.log("Detected on page content change - should {not} save now", this.state.content)
      this.props
        .updatePageContent({
          variables: { content: this.state.content, shouldSave: false },
        })
        .then(null);
    }
  }

  render() {
    const { content } = this.state;
    const { connectDropTarget, children } = this.props;

    const editorProps = {
      hidden: false,
      content: "edit",
      pageContent: content,
      removeItem: this.removeItem,
      duplicateItem: this.duplicateItem,
      moveThisByThat: this.moveThisByThat,
      addHoverCursor: this.addHoverCursor,
      addThisAfterThat: this.addThisAfterThat,
      changeState: this.changeState,
      state: this.state.state,
      updateContent: this.updateContent,
      updateComponentStyle: this.updateComponentStyle,
      updateComponentSettings: this.updateComponentSettings,
      listAncestors: this.listAncestors,
      listComponents: this.listComponents,
      setActiveObject: this.setActiveObject,
      doUndoRedo: this.doUndoRedo,
      undoStackSize: this.state.undoStack.length - 1,
      undoPosition: this.state.undoPosition,
      setCanUndo: this.setCanUndo,
      // setMobileState: this.setMobileState,
      // getMobileState: this.getMobileState,
      // currentMobileState: this.state.mobileState,
      setMobileState: this.props?.setMobileState,
      getMobileState: () => this.props?.mobileState,
      currentMobileState: this.props?.mobileState,
      setActivePropertyWindows: this.setActivePropertyWindows,
      getActivePropertyWindows: this.getActivePropertyWindows,
      setCanDrag: this.setCanDrag,
      getCanDrag: this.getCanDrag,
    };

    return (
      <div>
        {children && children(editorProps)}
        {connectDropTarget(
          <div>
            <Canvas {...this.props} {...editorProps} />
          </div>
        )}
      </div>
    );
  }
}

const PureCanvas = ({
  content,
  connectDropTarget,
  onChange = () => null,
  onSave = () => null,
  onLoad = () => null,
  children,
  render,
  onItemSelect,
  disableDefaultProperties,
  onShowProperties,
  onHideProperties,
  mobileState,
  setActiveAddNewTab = () => {},
}) => {
  const [ref, hoverBoundingRect] = useDimensions();

  return (
    <DndProvider backend={Backend}>
      <div ref={ref} className="text-black">
        <CPureCanvas
          history={{
            location: {
              pathname: window.location.pathname,
            },
          }}
          match={{
            path: "",
            params: {
              campaignId: "cjyuopfrx00ga0792rxk0yha9",
              nodeId: "cjyuopfxq00gh07924rep6q96",
            },
          }}
          getPageQuery={{
            page: {
              type: false,
              name: "",
              id: "",
              content,
            },
            refetch: () => {
              return {
                data: { page: { content } },
              };
            },
          }}
          updatePageContent={({ variables }) => {
            return {
              then: (response) => {
                // console.log("response from updatePageContent", response);
                // console.log('updating content', variables)
                // onChange({ ...variables, shouldSave: _.get(variables, 'shouldSave', true) })
                const shouldSave = _.get(variables, "shouldSave", true);

                if (!shouldSave) onChange(variables);
                if (shouldSave) onSave(variables);
              },
            };
          }}
          content={content}
          connectDropTarget={connectDropTarget}
          ecCoordinates={hoverBoundingRect}
          children={children}
          render={render}
          onItemSelect={onItemSelect}
          onLoad={onLoad}
          disableDefaultProperties={disableDefaultProperties}
          onShowProperties={onShowProperties}
          onHideProperties={onHideProperties}
          setActiveAddNewTab={setActiveAddNewTab}
          mobileState={mobileState}
        />
      </div>
    </DndProvider>
  );
};

export default DropTarget(
  "CANVAS",
  {
    hover: (props, monitor) => {
      const { onResize } = monitor.getItem();
      onResize(props, monitor);
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(PureCanvas);
