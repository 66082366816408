import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.SIXTEEN,
  layout: BenefitLayouts.SIXTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit16.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx8c94s23wd0780cdzpdmmv",
    itemId: "u5j1r64m5rf",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      getImagePlaceholder({
        type: ImagePlaceholderTypes.MOBILE01,
        style: {
          height: "100%",
          width: 1000,
          margin: "0 auto",
          maxWidth: "100%",
          mobile: {
            desktop: {
              padding: 50,
            },
            smartphone: {
              padding: 10,
            },
          },
        },
      }),
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          width: "100%",
          maxWidth: 600,
          textAlign: "center",
          display: "inline-block",
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: get(content, "sub_headline.html", "..."),
          },
          {
            type: ComponentTypes.BUTTON,
            canHaveChildren: false,
            properties: style.BUTTON.style,
            caption1: "Get Started",
            caption1style: style.BUTTON.caption1,
          },
        ],
      },
    ],
  },
};

export default settings;
