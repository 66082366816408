export default (prompt: string): string => `   
User is requesting to create a funnel. 

Please create a json array for each funnel object, with the following properties for each array item: id: uuid(), name (label), x position, y position, type, subType, and an array of the other items it connects to (e.g. connectTo: [{id1}, {id2}].
  
  For example, a funnel with two objects {A -> B}, would have a json array like this:
  [ { id: "a1", name: "A", x: 0, y: 0, type: "TrackingSource", subType: "traffic_source_email", connectTo: ["b1"] }, { id: "b1", name: "B", x: 200, y: 0, type: "PageComponent", subType: "lead_page", connectTo: [] }]
  
  Please only return the JSON array.  Do not under any circumstances return anything else.  No introduction sentence or summaries.  The JSON array MUST adhere to the provided schema.  Do not add, change, or remove any other properties than the ones requested and provided in the example. 
  
  For the type and subType properties, please use the following values:
  
  === New Type: TrackingSource ===
  
  Name: "TrackingSource": 
  Description: This is often (not always) the first object(s) in a funnel.  They're usually found to the left, and they represent the source of the traffic flowing into the funnel.  For example an email, a social media post, a paid ad, etc.
  
  Here are the subType values for "TrackingSource" objects:
  - "traffic_source_facebook"
  - "traffic_source_adwords"
  - "traffic_source_email"
  - "traffic_source_blog"
  - "traffic_source_twitter"
  - "traffic_source_youtube"
  - "traffic_source_webinar"
  - "traffic_source_affiliate"
  - "traffic_source_organic"
  - "traffic_source_redirect"
  - "traffic_source_smartroute"
  - "traffic_source_other"
  
  === /End of TrackingSource Type ===
  
  === New Type: ABSplitComponent ===
  
  Name: "ABSplitComponent": 
  Description: Creates a link that randomly redirects its visitors between two or more destinations.  This is often used to test different landing pages, or to split traffic between two different offers.
  
  There are no subType values for "ABSplitComponent" objects.
  
  === /End of ABSplitComponent Type ===
  
  === New Type: AnotherFunnelComponent ===
  
  Name: "AnotherFunnelComponent": 
  Description: Creates a link that automatically routes its visitors to a page in another funnel.  This is often used to route traffic to a different funnel after a visitor has completed a specific action in the current funnel.
  
  There are no subType values for "AnotherFunnelComponent" objects.
  
  === /End of AnotherFunnelComponent Type ===
  
  === New Type: PageComponent ===
  
  Name: "PageComponent": 
  Description: These objects represent web pages.  They're sometimes found as destinations after a tracking source component.  For example a lead capture page, a sales page, a thank you page, etc.
  
  Here are the subType values for "PageComponent" objects:
  - "blank_page"
  - "content_page"
  - "home_page"
  - "content_page"
  - "lead_squeeze"
  - "legal_page"
  - "live_event_page"
  - "precart_check"
  - "order_page"
  - "sales_page"
  - "sales_page"
  - "thankyou_page"
  - "webinar_page"
  
  === /End of PageComponent Type ===
  
  === New Type: cart: ===
  
  Name: "cart": 
  Description: This object represents a shopping cart.  It's often found as a destination after a sales page.  There are many types of shopping carts, such as ClickBank, Keap, PayPal, SamCart, Shopify, etc.
  
  Here are the subType values for "Cart" objects:
  - "shopping_cart_1shoppingcart"
  - "shopping_cart_amazon"
  - "shopping_cart_clickbank"
  - "shopping_cart_infusionsoft"
  - "shopping_cart_jvzoo"
  - "shopping_cart_nanacast"
  - "shopping_cart_paypal"
  - "shopping_cart_samcart"
  - "shopping_cart_shopify"
  - "shopping_cart_ultracart"
  - "shopping_cart_zaxaa"
  - "shopping_cart_another"
  
  === /End of cart Type ===
  
  === New Type: ContentComponent ===
  
  Name: "ContentComponent": 
  Description: These objects represent content. For example a blog post, social media post, advertisement, an email, etc.
  
  Here are the subType values for "ContentComponent" objects:
  - "blog_post"
  - "facebook_post"
  - "twitterX_post"
  - "instagram_post"
  - "linkedIn_post"
  - "advertisement"
  - "email_content"
  - "content_post"
  
  === /End of ContentComponent Type ===
  
  === New Type: Trigger ===
  
  Name: "Trigger": 
  Description: These objects represent triggers.  When activated, they're used to trigger a task.  For example, a visitor clicking a link, a visitor submitting a form & becoming a new contact, a visitor making a purchase, a specific date, etc.
  
  Here are the subType values for "Trigger" objects:
  - "external_event"
  - "tag"
  - "new_contact"
  - "trigger_webhook"
  - "date"
  
  === /End of Trigger Type ===
  
  === New Type: Task: ===
  
  Name: "Task": 
  Description: These objects represent tasks.  They're often used to send an email, send a text message, send a postcard, etc.
  
  Here are the subType values for "Task" objects:
  - "send_email"
  - "trigger_webhook"
  - "wait"
  
  === /End of Task Type ===
  
  If unsure what type or subType to use, just choose the one that best fits the object.
  
  IMPORTANT: Do not, under any circumstances provided any json delimiters to denote that it's json.  That will be assumed.  Just respond with the json and that's it.


Use the following prompt to do so:
'''
${prompt}
'''
`;