import { ComponentRenderHooks } from "@/plugins/types";
import Body, { Properties } from "./Body";
import Live from "./component";


export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Body") {
    if (hook.mode === "live") return [Live];
    else return [Body];
  }
  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Body") {
    return Properties(payload);
  }
};

