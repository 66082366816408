import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { LoadComponent, ItemLegend } from "./components";
import {
  EditorMode,
  EditorDragMode,
  EditorProps,
  // EditorActions,
} from "./types";
// import { EditorObjectState } from "./components/EditorObject/types";
import { contentReducer } from "./reducers";

/**
 * Recursively loop through content prop to display editor content
 *
 * Load the component & pass along props (along with the edit=true prop - that way component can show editor version)
 *
 * @param content The initial content array
 * @param parent The initial parent to start the recurrsive loop with
 */

// const simpleGeneratePage = (
//   content,
//   parent = false,
//   mode = EditorMode.EDITOR
// ) =>
//   content.map((c, k) => (
//     <div data-testid="Editor-EditorObject-Item" key={k}>
//       <LoadComponent type={c.type} settings={c} mode={mode}>
//         {c.children}
//       </LoadComponent>
//     </div>
//   ));

const generatePage = (content, parent = false, mode = EditorMode.EDITOR) => {
  return content
    .filter((c) => c.parent === parent)
    .map((c, key) => (
      <div data-testid="Editor-EditorObject-Item" key={key}>
        <LoadComponent type={c.type} settings={c} mode={mode}>
          {c.children || generatePage(content, c.id, mode)}
        </LoadComponent>
      </div>
    ));
};

export const EditorContext = React.createContext({
  dispatch: ({ type, payload }) => null,
  content: [],
});

/**
 * A flexible module for quickly building powerful, drag-and-drop editors like what you see in PowerPoint, Canva, or 10 Minute Pages
 */
export const Editor:React.FC<EditorProps> = ({
// export const Editor: React.FC<EditorProps> = ({
  name = "Editor",
  mode = EditorMode.EDITOR,
  dragMode = EditorDragMode.OBJECTS,
  // snapGuidesAreEnabled = false,
  itemLegendIsVisible = false,
  // // undoStack = [],
  // // undoPosition = 0,
  // readOnly = false,
  // zoom = 1,
  initialContent = [],
  itemLegendSettings = {},
  children,
}) => {
  const [theContent, dispatch] = React.useReducer(contentReducer, {
    past: [],
    present: initialContent,
    future: [],
  });

  let content = theContent.present;

  const BuilderComponent = () => (
    <div style={{ width: "100%" }}>{generatePage(content)}</div>
  );

  const ItemLegendComponent = () => {
    return itemLegendIsVisible ? (
      <ItemLegend content={content} {...itemLegendSettings} />
    ) : (
      <div />
    );
  };

  return (
    <EditorContext.Provider value={{ dispatch, content }}>
      <DndProvider backend={HTML5Backend}>
        <div data-testid="Editor-Default">
          {children ? (
            children({
              ItemLegend: ItemLegendComponent,
              Builder: BuilderComponent,
              dispatch,
            })
          ) : (
            <>
              <BuilderComponent />
              <ItemLegendComponent />
            </>
          )}
        </div>
        {/* {children} */}
      </DndProvider>
    </EditorContext.Provider>
  );
};


export default Editor;
