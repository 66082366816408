import { fieldset } from "@/ui/theme";
import styled from "styled-components";

const f = 0.2;
export const styles = {
  input: {
    width: 310,
    margin: '10px 0',
    padding: 15,
    fontSize: '11pt',
    marginBottom: 30,
    marginLeft: -13,
    borderRadius: 3,
    border: `1px solid #CCC`,
  },
  fieldset: {
    ...fieldset,
    border: 'none',
    // borderTop: "1px solid #ccc",
  },
  label: { padding: 10, fontSize: '10pt', textTransform: "uppercase", color: "#666", fontWeight: 'bold' },
  icon: {
    display: "inline-block",
    color: "white",
    textAlign: "center",
    textShadow: `
      0px 0px 0 rgb(0,0,0,${0.29 * f}),
      1px 1px 0 rgb(0,0,0,${0.28 * f}),
      2px 2px 0 rgb(0,0,0,${0.27 * f}),
      3px 3px 0 rgb(0,0,0,${0.26 * f}),
      4px 4px 0 rgb(0,0,0,${0.25 * f}),
      5px 5px 0 rgb(0,0,0,${0.24 * f}),
      6px 6px 0 rgb(0,0,0,${0.23 * f}),
      7px 7px 0 rgb(0,0,0,${0.22 * f}),
      8px 8px 0 rgb(0,0,0,${0.21 * f}),
      9px 9px 0 rgb(0,0,0,${0.2 * f}),
      10px 10px 0 rgb(0,0,0,${0.19 * f}),
      11px 11px 0 rgb(0,0,0,${0.18 * f}),
      12px 12px 0 rgb(0,0,0,${0.17 * f}),
      13px 13px 0 rgb(0,0,0,${0.16 * f}),
      14px 14px 0 rgb(0,0,0,${0.15 * f}),
      15px 15px 0 rgb(0,0,0,${0.14 * f}),
      16px 16px 0 rgb(0,0,0,${0.13 * f}),
      17px 17px 0 rgb(0,0,0,${0.12 * f}),
      18px 18px 0 rgb(0,0,0,${0.11 * f}),
      19px 19px 0 rgb(0,0,0,${0.1 * f}),
      20px 20px 0 rgb(0,0,0,${0.09 * f}),
      21px 21px 0 rgb(0,0,0,${0.08 * f}),
      22px 22px 0 rgb(0,0,0,${0.07 * f}),
      23px 23px 0 rgb(0,0,0,${0.06 * f}),
      24px 24px 0 rgb(0,0,0,${0.05 * f}),
      25px 25px 0 rgb(0,0,0,${0.04 * f}),
      26px 26px 0 rgb(0,0,0,${0.03 * f}),
      27px 27px 0 rgb(0,0,0,${0.02 * f}),
      28px 28px 0 rgb(0,0,0,${0.01 * f}),
      29px 29px 0 rgb(0,0,0,0.00)
    `,
  }
}
export const BasicStyle = styled.div`
  cursor: move;
  width: 75px;
  height: 70px;
  margin: 8px;
  border-radius: 4px;
  background-position: top center;
  &:hover {
    background-position: bottom center;
    opacity: 0.8;
  }
`;