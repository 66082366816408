import { ComponentRenderHooks } from "@/plugins/types";
import LiveFormField from "./component";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    // (payload.type === ComponentTypes.V1FORMFIELD || payload.type === ComponentTypes.FORMFIELD || payload.type === "TextInput")
    (payload.type === "FormField" || payload.type === "TextInput")
  ) {
    return [LiveFormField];
  }
};
