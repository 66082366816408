// import { Funnels } from "@/Funnels/Funnels";
import { TreeStructure } from "@phosphor-icons/react";
import CampaignBuilder from "@/Apps/Funnels/CampaignBuilder";
import { FunnelGallery } from "@/Apps/Funnels/Funnels";
import { FunnelDashboard } from "./Dashboard";

import { CopilotHooks, HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard as PageDashboard } from "../Pages/Dashboard";
import { useEffect } from "react";
import get_funnels from "./prompts/get_funnels";
import get_objects from "./prompts/get_objects";

const Reroute = () => {
  useEffect(() => {
    window.location.href = "/crm";
  }, []);
  return null;
};

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("launchOS")) return [];
    if (permissions.includes("scroll")) return [];
    if (!permissions.includes("funnels")) return [];

    return [
      {
        label: "Funnels",
        icon: <TreeStructure className="text-lg" />,
        href: "/funnels",
        order: 2,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    // if (!permissions.includes("funnels")) return [];
    if (permissions.includes("scroll")) return [{ path: "/funnels", element: <Reroute /> }];

    return [
      {
        path: "/funnels",
        element: <FunnelGallery name="Funnels" />,
        children: [
          {
            path: ":campaignId",
            element: <FunnelDashboard name="Funnels" />,
          },
          {
            path: ":campaignId/:objectId",
            element: <PageDashboard name="Funnels" homeHref="/funnels" />,
          },
          {
            path: ":campaignId/:objectId/:destination",
            element: <PageDashboard name="Funnels" homeHref="/funnels" />,
          },
          {
            path: ":campaignId/:objectId/:destination/:type",
            element: <PageDashboard name="Funnels" homeHref="/funnels" />,
          },
        ],
      },
    ];
  }

  if (hook.id === ListItemsHooks.UNIVERSAL_SEARCH_ITEMS) {
    return [
      {
        id: "funnel",
        name: "Funnels",
        shortcut: ["f"],
        keywords: "funnel",
        perform: () => (window.location.pathname = "/funnels"),
      },
    ];
  }
};

export const onChatPrompt = (
  hook: HookObject,
  { context },
  { copilotContext }
) => {
  if (hook.id === CopilotHooks.GET_SYSTEM_PROMPT && context[0]?.label === "Funnels" && copilotContext) {
    // const { copilotContext } = actions;
    // console.log("(Funnels) About to System Prompt", context, copilotContext);
    // retrieve the page content
    let systemPrompt = "";
    
    if (context[0]?.label === "Funnels") systemPrompt += get_funnels(copilotContext['Funnels'])
    if (copilotContext['Funnel Objects']) systemPrompt += get_objects(copilotContext["Funnel Objects"]);

    return systemPrompt;
  }
};

export { FunnelGallery, FunnelDashboard, CampaignBuilder };
