import React from "react";
import { CloseButtonIcon } from "../Icons";
import { PopupPosition, PopupSizes } from "./types";
import { ModalProps } from "./types";

/**
 * A modal component that displays content in a popup.
 */
export const Modal: React.FC<ModalProps> = ({
  title,
  tabs,
  position = PopupPosition.DEFAULT,
  size = PopupSizes.MEDIUM,
  onClose = () => null,
  onSuccess = () => null,
  icon = <></>,
  children,
  childrenStyle = {},
  footer = true,
  closeOnClickOutside = false,
}) => {
  // Determine the position and size classes based on the props
  let positionClass = "items-start";
  let sizeClass = "max-w-lg";
  let containerClass = "";
  let sizeStyle = {};
  let positionStyle = {};

  switch (position) {
    case PopupPosition.DEFAULT:
      positionClass = "items-start";
      break;
    case PopupPosition.CENTERED:
      positionClass = "items-center";
      break;
    // case PopupPosition.RIGHT_SIDEBAR:
    //   positionClass = "";
    //   sizeClass = "right-0 m-0 h-screen";
    //   break;
  }

  switch (size) {
    case PopupSizes.SMALL:
      sizeClass = "max-w-sm";
      break;
    case PopupSizes.MEDIUM:
      sizeClass = "max-w-lg";
      break;
    case PopupSizes.LARGE:
      sizeClass = "max-w-2xl";
      break;
    case PopupSizes.XLARGE:
      sizeClass = "max-w-3xl";
      break;
    case PopupSizes.XXLARGE:
      sizeClass = "max-w-4xl";
      break;
    case PopupSizes.XXXLARGE:
      sizeClass = "max-w-5xl max-h-screen";
      containerClass = "max-w-full m-0 h-full";
      break;
    case PopupSizes.FULLSCREEN:
      sizeClass = "max-w-full m-0 h-screen bg-white/80";
      containerClass = "max-w-full m-0 h-full";
      sizeStyle = { margin: 0, borderRadius: 0 };
      positionStyle = { padding: 0 };
      break;
  }

  // Render the modal
  return (
    <div
      data-testid="Modal"
      className={`fixed inset-0 bg-black/60 dark:bg-white/10 z-[999] overflow-y-auto ${containerClass}`}
      onClick={() => {
        if (closeOnClickOutside && typeof onClose === "function") {
          onClose();
        }
      }}
    >
      <div
        className={`flex ${positionClass} justify-center min-h-screen px-4`}
        style={positionStyle}
      >
        <div
          // dark:bg-black/80 backdrop-blur
          className={`bg-white dark:bg-black relative shadow-3xl border-0 p-0 rounded-lg overflow-hidden my-8 w-full ${sizeClass}`}
          style={{ transition: "opacity 0.3s", ...sizeStyle }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`flex bg-white dark:bg-black items-center ${
              title && "border-b border-black/10 dark:border-white/10"
            } justify-between px-5 py-3`}
          >
            {title ? (
              <>
                {icon}
                <h5 className="font-semibold text-lg">{title}</h5>
              </>
            ) : (
              <div />
            )}
            {tabs && <div className="flex-1 gap-2">{tabs}</div>}
            <button
              type="button"
              data-testid="Modal-CloseButton"
              className="text-black/40 dark:text-white/40 hover:text-black dark:hover:text-white"
              onClick={onClose}
            >
              <CloseButtonIcon />
            </button>
          </div>
          <div className={`p-5 px-3 h-full overflow-auto`} style={childrenStyle}>
            <div className="text-sm text-black dark:text-white h-full">
              {children}
            </div>

            {footer && (
              <div className="flex justify-end items-center mt-8 gap-4">
                <button
                  type="button"
                  className="btn bg-black/10 text-black/70"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button type="button" className="btn" onClick={onSuccess}>
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * A component that displays a title in the modal.
 */
export const Title = ({ children }) => (
  <div className="p-5 mb-3 text-2xl text-center">{children}</div>
);

/*
 * Component for rendering footer buttons in a modal
 */
export const FooterButtons = ({
  position = "right", // left, center, right
  showCancel = true,
  showBack = true,
  showNext = true,
  showSave = false,
  onBack = () => null,
  onCancel = () => null,
  onNext = () => null,
  onSave = async () => null,
  captions = { cancel: "Cancel", next: "Next", back: "Back", save: "Save Settings" }
}) => {
  const getPosition = (position) => {
    switch (position) {
      case "left":
        return "justify-start";
      case "center":
        return "justify-center";
      case "right":
        return "justify-end";
    }
  };

  return (
    <div className={`flex ${getPosition(position)} items-center mt-8 gap-4`}>
      {showCancel && (
        <button
          type="button"
          // className="btn !bg-lightred !text-white"
          className="btn bg-black/10 text-black/70"
          onClick={onCancel}
        >
          {captions.cancel || "Cancel"}
        </button>
      )}
      {showBack && (
        <button type="button" className="btn" onClick={onBack}>
          {captions.back || "Back"}
        </button>
      )}
      {showNext && (
        <button type="button" className="btn" onClick={onNext}>
          {captions.next || "Next"}
        </button>
      )}
      {showSave && (
        <button type="button" className="btn" onClick={onSave}>
          {captions.save || "Save Settings"}
        </button>
      )}
    </div>
  );
};