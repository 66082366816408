import * as React from "react";
import _ from "lodash";

import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

import { Icon } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { IconTypes } from "../Icon";

import iconSettings from "./settings";

export const Properties = (props) => {
  const { updateComponentSettings, updateComponentStyle, settings } = props;
  const { properties } = iconSettings;
  const { sections } = properties.main;

  const withIcon = updateSection(sections, SectionTypes.ICONSELECTOR, {
    onChange: (e) => {
      updateComponentSettings(
        settings.id,
        { ...settings, iconType: IconTypes[e] },
        true,
        0
      );
    },
  });

  // const withSize = withIcon;
  const withSize = updateSection(withIcon, SectionTypes.BUTTONSLIDER, {
    // value: _.get(settings.properties, "fontSize", 0).replace("pt", ""),
    onChange: (e) => {
      // console.log("size", e);
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              _.get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;
          updateComponentStyle(
            settings.id,
            { fontSize: `${value}pt` },
            true,
            0
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              _.get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;
          updateComponentStyle(
            settings.id,
            { fontSize: `${value}pt` },
            true,
            0
          );
          break;
        }
        default: {
          updateComponentStyle(settings.id, { fontSize: `${e}pt` }, true, 0);
        }
      }
    },
  });

  const withColor = updateSection(withSize, SectionTypes.COLORPICKER, {
    onChange: (e) => {
      updateComponentStyle(
        settings.id,
        {
          color: e.hex,
        },
        true,
        0
      );
    },
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withColor },
  };

  return (
    <PropertiesBuilder
      {...props}
      title="Icon Settings"
      data={updatedProperties}
    />
  );
};

export const IconComponent: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;
  const properties = convertProperties(settings.properties);

  return (
    <>
      <V1ObjectWrapper settings={settings}>
        <EditorObject {...props} PropertiesView={Properties}>
          <Icon
            {...settings}
            type={_.get(settings, "iconType")}
            properties={properties}
          />
        </EditorObject>
      </V1ObjectWrapper>
    </>
  );
};
