import { ComponentRenderHooks } from "@/plugins/types";
import FacebookComments, { Properties } from "./component";
import settings from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FACEBOOKCOMMENTS
  ) {
    return [FacebookComments];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.FACEBOOKCOMMENTS
  ) {
    return Properties(payload);
  }
};
