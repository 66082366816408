// import {
//   Background,
//   PropertiesWindow,
//   Interactions
// } from "@launchos/modules/editor/Builder/Properties";
import { Background, Interactions, PropertiesWindow } from "@/Apps/Pages/Properties";

// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import EditorObject from "@/Apps/Pages/EditorObject";

// import { MobileState } from '@launchos/modules/editor/Builder/MobileResponsiveToggle/types'
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";

import { BodyContent } from "./component";

const CURSOR_ID = 1111;

const BodyEdit = (props) => {

  const { pageContent, settings, getMobileState } = props;

  const childContent = pageContent.filter(
    (itm) => itm?.parent === settings.id
  );
  let isEmpty = false;
  if (childContent.length) {
    isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
  } else isEmpty = true;

  const defaultBodyStyle = isEmpty ? { padding: 40 } : {};

  const { type } = getMobileState();

  return (
    <EditorObject
      {...props}
      PropertiesView={Properties}
      color="transparent"
      style={{
        height: "100%",
        ...settings.properties,
        minHeight: type === MobileState.DESKTOP ? "70vh" : "100vh",
      }}
      dropDownItems={(items) => items.filter(({ id }) => id === "PROPERTIES")}
    >
      <BodyContent {...props} style={defaultBodyStyle} />
    </EditorObject>
  );
};

// const Properties = () => <React.Fragment />;

const BasicPropertiesTab = (props) => (
  <>
    <Background
      {...props}
      accept="image/jpeg, image/png, video/mp4, video/ogg"
      showAlpha
      isExpanded
    />
    <Interactions
      label="Actions (When Page Loads)"
      behavior="load"
      {...props}
    // onUpdate={(action) => console.log(action)}

    >
      <div />
    </Interactions>
  </>
);

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Body", component: BasicPropertiesTab }]}
  />
);

const Body = (props) => (
  <div style={{ height: "100%" }}>
    <BodyEdit {...props} />
  </div>
);

export default Body;
