import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { BenefitLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.EIGHT,
  layout: BenefitLayouts.EIGHT,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit08.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx36rg821t10780ogx3g72b",
    itemId: "gu9gsq5407",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              getImagePlaceholder({
                // type: ImagePlaceholderTypes.MOBILE03,
                type: ImagePlaceholderTypes.NONE,
                src:
                  "https://images.unsplash.com/photo-1570563676231-d476637bc4bc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
                style: {
                  // height: "100%",
                  // backgroundColor: theme.foregroundColor,
                  // position: "relative",
                  // top: "50%",
                  // transform: "translateY(-50%)",
                  width: 300,
                  margin: "0 auto",
                  display: "inline-block",
                  textAlign: "center",
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 60,
                  mobile: {
                    desktop: { padding: 60 },
                    smartphone: { padding: 15 },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                    },
                    html: "01",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html:
                      "It's true.  Getting results is much easier than you think.",
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                    },
                    html: "02",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html:
                      "It's true.  Getting results is much easier than you think.",
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                    },
                    html: "03",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html:
                      "It's true.  Getting results is much easier than you think.",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
