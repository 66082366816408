import { ComponentRenderHooks, ListItemsHooks } from "@/plugins/types";
import EditorContainer from "./Container";
import settings from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { Properties } from "./Container";

// const properties = {
//   main: {
//     tabs: ["Basic", "Advanced"],
//     sections: [
//       // Background
//       {
//         tab: "Basic",
//         type: SectionTypes.BACKGROUND,
//         settings: {
//           accept: "image/jpeg, image/png, video/mp4, video/ogg",
//           showAlpha: true,
//           isExpanded: true,
//         },
//       },
//       // WidthHeight
//       {
//         tab: "Basic",
//         type: SectionTypes.WIDTHHEIGHT,
//         settings: {},
//       },
//       // PositionPadding
//       {
//         tab: "Basic",
//         type: SectionTypes.POSITIONPADDING,
//         settings: {},
//       },
//       // Alignment
//       {
//         tab: "Basic",
//         type: SectionTypes.ALIGNMENT,
//         settings: {},
//       },
//       // --- ADVANCED ---
//       // BorderShadow
//       {
//         tab: "Advanced",
//         type: SectionTypes.BORDERSSHADOW,
//         settings: {
//           label: "Borders & Shadow",
//           isExpanded: true,
//         },
//       },
//       // Interactions
//       {
//         tab: "Advanced",
//         type: SectionTypes.ACTIONS,
//         settings: {
//           label: "Button Actions",
//         },
//       },
//     ],
//   },
// };

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }

  // if (
  //   hook.id === ListItemsHooks.PROPERTIES &&
  //   hook.type === ComponentTypes.CONTAINER
  // ) {
  //   return properties || {};
  // }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "Container"
  ) {
    return [EditorContainer];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === "Container"
  ) {
    return Properties(payload);
  }
};
