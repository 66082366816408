import { updatePage } from "@/api/pages";
import Panel from "@/ui/Panel";
import { TextArea } from "@/ui/TextArea";
import { TextInput } from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { getSessionToken } from "@/api/auth";

export const SEO = ({
  campaignData = {},
  setCampaignData = () => {},
  objectData = {},
  unitName = "Funnel",
}: {
  campaignData: any;
  setCampaignData: any;
  unitName?: string;
}) => {

  const { page } = objectData;
  const { pageSettings } = page;
  const { seo = {} } = pageSettings || {};
  const { title = "", description = "", keywords = "" } = seo;

  const [seoTitle, setSeoTitle] = useState(title);
  const [seoDescription, setSeoDescription] = useState(description);
  const [seoKeywords, setSeoKeywords] = useState(keywords);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeoTitle(e.target.value);
  };

  const handleKeywordsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeoKeywords(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSeoDescription(e.target.value);
  };


  useEffect(() => {
    const debouncedUpdate = debounce(async () => {
      try {
        await updatePage(page.id, {
          token: getSessionToken(),
          settings: {
            pageSettings: {
              ...pageSettings,
              seo: {
                title: seoTitle,
                description: seoDescription,
                keywords: seoKeywords
              }
            }
          }
        });
      } catch (error) {
        console.error('Error updating SEO settings:', error);
      }
    }, 500); // 500ms debounce time

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [seoTitle, seoDescription, seoKeywords]);


  return (
    <Panel title={`SEO Settings`}>
      <div className="grid grid-flow-row gap-7">
        <p className="text-sm dark:text-white/50 text-black/50">
          What should the search results show when someone uses a search engine
          to find your page?
        </p>
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label={`${unitName} Title`}
          // value={name}
          value={seoTitle}
          placeholder={`${unitName} Title`}
          name="title"
          onChange={handleTitleChange}
        />
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label={`${unitName} Keywowrds`}
          // value={name}
          value={seoKeywords}
          placeholder={`${unitName} Keywowrds`}
          name="keywords"
          onChange={handleKeywordsChange}
        />
        <TextArea
          flavor={TextInputFlavors.MODERN}
          label={`${unitName} Description`}
          // value={description}
          value={seoDescription}
          placeholder={` `}
          name="description"
          onChange={handleDescriptionChange}
        />
      </div>
    </Panel>
  );
}