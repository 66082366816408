import { getSessionToken } from "@/api/auth";
import { updateCampaign } from "@/api/campaigns";
import { Calout } from "@/ui/Calout";
import { Panel } from "@/ui/Panel";
import TextArea from "@/ui/TextArea";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

export const SystemPrompt = ({
  campaignData = {},
  setCampaignData = () => {},
  unitName = "Agent",
}) => {
  const [prompt, setPrompt] = useState(campaignData?.settings?.systemPrompt)
  const { id } = campaignData;

  const saveSystemPrompt = async () => {
    const token = getSessionToken();
    if (!token) return;

    const settings = { settings: { ...campaignData?.settings, systemPrompt: prompt } };
    const response = await updateCampaign(id, { token, settings });

    console.log("response", response);
  };

  const debouncedSaveSystemPrompt = debounce(saveSystemPrompt, 500);

  useEffect(() => {
    debouncedSaveSystemPrompt();

    return () => {
      debouncedSaveSystemPrompt.cancel();
    };
  }, [prompt]);

  return (
    <Panel title={`${unitName} System Prompt`}>
      <div className="grid grid-flow-row gap-4">
        <Calout
          title={`Create a system prompt to define how this ${unitName.toLowerCase()} should behave.`}
        />
        <TextArea
          flavor={TextInputFlavors.MODERN}
          label={`${unitName} System Prompt`}
          value={prompt}
          placeholder={` `}
          name="description"
          onChange={e => setPrompt(e.target.value)}
          className="h-[275px]"
        />
      </div>
    </Panel>
  );
};
