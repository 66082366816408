import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";

import { ContactsGallery } from "@/Apps/CRM/ContactsGallery";
import { getFilterObject } from "@/Apps/CRM/scripts";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "overview");
  const [portalData, setPortalData] = useState<{ name?: string }>({ name: "" });
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  // const [activePageId, setActivePageId] = useState(null);
  const { id } = useParams();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setPortalData(data);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [{ label: name, href: "/portals" }, { label: portalData.name || "" }]
  };

  return (
    <Page topBar={topBarSettings}>
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              { label: "Overview", value: "overview" },
              { label: "Resources", value: "resources" },
              { label: "Activity", value: "crm" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "overview" && (
            <DashboardPage
              campaignData={portalData}
              unitName="portal"
              widgets={[
                {
                  type: "kpi",
                  settings: {
                    items: [
                      { label: "Clicks", number: 30 },
                      { label: "Sales", number: 5 },
                      { label: "Commissions", number: "$110.00" },
                    ],
                  },
                },
                "share",
                {
                  type: "columns",
                  settings: {
                    columns: 2,
                    widgets: ["affiliateCommissionSettings", "media"],
                  },
                },
              ]}
            />
          )}
          {activeTab === "resources" && (
            <DashboardPage
              campaignData={portalData}
              unitName="portal"
              widgets={[
                "tracking",
                "media"
              ]}
            />
          )}
          {activeTab === "crm" && (
            <DashboardPage
              campaignData={portalData}
              unitName="portal"
              widgets={[
                {
                  type: "kpi",
                  settings: {
                    items: [
                      { label: "Clicks", number: 30 },
                      { label: "Sales", number: 5 },
                      { label: "Commissions", number: "$110.00" },
                    ],
                  },
                },
                "contactList",
              ]}
            />
          )}
          {activeTab === "settings" && (
            <Settings
              campaignData={portalData}
              setCampaignData={setPortalData}
              unitName="Portal"
              widgets={["affiliateCommissionSettings", "affiliatePaymentTypes"]}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
