import { useState, useContext } from "react";
import { AppContext } from "@/AppContext";
import { Button } from "@/ui/Button";
import { TextArea } from "@/ui/TextArea";
import { updateObject } from "@/api/campaigns";
import { ButtonTypes, Variants } from "@/ui/types";
import Panel from "@/ui/Panel";
import Calout from "@/ui/Calout";
import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";

interface GenerateTextProps {
  nodeData: any;
  campaignId: string;
  setNodes: (nodes: any) => void;
  nodes: any[];
  setEditDrawerOpen: (open: boolean) => void;
}

export const GenerateText = ({
  nodeData,
  campaignId,
  setNodes,
  nodes,
  setEditDrawerOpen
}: GenerateTextProps) => {
  const { darkMode } = useContext(AppContext);
  const [prompt, setPrompt] = useState(nodeData?.data?.settings?.payload?.prompt || "");

  const handleSave = async () => {
    await updateObject(nodeData?.id, {
      settings: {
        ...nodeData?.data?.settings,
        payload: {
          ...nodeData?.data?.settings?.payload,
          prompt
        }
      }
    });

    setEditDrawerOpen(false);
    window.location.reload();
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      {/* <h3>Text Generation Prompt</h3> */}
        <Calout title="Enter the prompt that will be used to generate the text" />

        <TextArea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter your prompt here..."
          className="h-48"
          flavor={TextInputFlavors.MODERN}
        />

      <div className="flex justify-end">
        <Button
          label="Cancel"
          onClick={() => setEditDrawerOpen(false)}
          type={ButtonTypes.OUTLINED}
          variant={Variants.SECONDARY}
          className="mr-2"
        />
        <Button
          label="Save Changes"
          onClick={handleSave}
          type={ButtonTypes.FILLED}
          variant={Variants.PRIMARY}
        />
      </div>
    </div>
  );
};
