import * as React from "react";

import { IconSource } from "../Icon/types";
import { EditorComponentProps, EditorMode } from "@/Apps/Pages/Editor/types";

export enum ListItemTypes {
  INLINE,
  BLOCK,
}

export interface ListItemProps {
  id?: string;
  mode?: EditorMode;
  children?: React.ReactNode | string;
  style?: React.CSSProperties;
  properties?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  icon?: React.ReactNode | any;
  iconSource?: IconSource;
  type?: ListItemTypes;
  html?: React.ReactNode | string;
}

export interface PluginProps extends EditorComponentProps {
  settings: ListItemProps;
  match?: any;
}
