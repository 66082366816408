import { useEffect, useState } from "react";
import { BuilderArtifactProps } from "./types";
import { ButtonTypes } from "@/ui/types";
import Button from "@/ui/Button";
import { CampaignBuilder } from "@/Apps/Funnels/CampaignBuilder/CampaignBuilder";
import { cloneCampaign, createObject, getCampaign, getObjects, updateCampaign, updateObject } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { Loading } from "@/ui/Layout/Loading";
import { createFunnelObjects } from "@/Apps/Sketchpad/Sketchpad";
import { useNavigate } from "react-router-dom";
import { createOrUpdateChat } from "@/api/ai";
import { head } from "lodash";

export const CampaignBuilderArtifact: React.FC<BuilderArtifactProps> = ({
  id = "",
  chatId = "",
  title = "",
  prompt = "",
  isLoading = false,
  chatBotHelpers = {},
  setTopBarButtons = () => [],
  setArtifactOptions = () => {
    isShowing: false;
  },
  artifactExists = () => false,
  append = () => {},
}) => {
  const [campaignData, setCampaignData] = useState({});
  const [isCampaignLoading, setIsCampaignLoading] = useState(true);

  const navigation = useNavigate()

  const generateArtifact = async () => {
    const generateObjectsFromPrompt = async (prompt) => {
      const r = await fetch(`${AI_DOMAIN}/api/copilot/artifacts/campaign`, {
        method: "POST",
        body: JSON.stringify({ prompt }),
      });

      const response = await r.json();
      return response;
    }

    // const renderCampaignFromSpec = async (spec) => {}

    const existingCampaign = await artifactExists(id);

    if (existingCampaign) {
    // if (true) {
      // load the saved campaign
      const theCampaignId = existingCampaign?.settings?.campaignId;
      // const campaign = await getCampaign({ token: getSessionToken(), id: "cm37x630l0zb40811qtrcrryt" });
      const campaign = await getCampaign({ token: getSessionToken(), id: theCampaignId });
      setCampaignData(campaign);
      setIsCampaignLoading(false);

    } else {
      if (Boolean(prompt.length)) {
        const objects = await generateObjectsFromPrompt(prompt);
        console.log("Objects", objects);

        // save the campaign
        // create the campaign (blank)
        const blankCampaignId = "cjyt9x3v0001g0792aync7yje"; // The ID of the collection campaign template (blank)
        const token = getSessionToken();
        const response = await cloneCampaign(blankCampaignId, { token, name: title });
        const newCampaignId = response?.data?.cloneCampaign?.response?.id;

        await updateCampaign(newCampaignId, { token, settings: { type: "artifact", settings: { withAI: true, chatId } } });

        const newCampaignData = await getCampaign({ token, id: newCampaignId })

        // create the objects
        await createFunnelObjects(objects, newCampaignData);

        const finalCampaignData = await getCampaign({ token, id: newCampaignId });

        // load the new campaign
        setCampaignData(finalCampaignData);

        // set the campaign data
        setIsCampaignLoading(false);

        // append to message list so that it loads thte new campaign from now on
        const msg = { role: "assistant", content: JSON.stringify(objects) };
        const chatCampaignResponse = await getObjects(chatId);
        const object = head(chatCampaignResponse?.data?.objects);
        const messages = object?.settings?.messages;
        await updateObject(object?.id, {
          settings: { ...object?.settings, messages: [...messages, msg] },
        });

        // now create a new object for the artifact
        await createObject({
          name: title,
          x: 0,
          y: 0,
          campaignId: chatId,
          type: "AnotherFunnelComponent",
          screenshot: "",
          settings: { 
            artifactId: id,
            campaignId: newCampaignId,
          },
        });
      }
    }
  }
  
  const handleSmartFunnelsOpen = () => {
    navigation(`/funnels/${campaignData?.id}#builder`);
  }

  useEffect(() => {
    if (campaignData?.id) {
      setTopBarButtons([
        <Button
          type={ButtonTypes.OUTLINED}
          label="Edit in Smart Funnels"
          onClick={handleSmartFunnelsOpen}
        />,
      ]);
    }
  }, [campaignData])

  useEffect(() => {

    generateArtifact();

    
  }, [prompt, id]);

  if (isCampaignLoading) return (
    <div className="p-9">
      <Loading type="gallery" />
    </div>
  );

  return (
    <CampaignBuilder
      id={campaignData?.id}
      campaignData={campaignData}
    />
  );
};