import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import TabsEditorVersion from "./TabsComponent";
import TabEditorVersion from "./TabComponent";
import settings from "./settings";
import Properties from "./Properties";

// export const onListItems = (hook, payload, actions) => {
//   if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
//     return [settings];
//   }
// };

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.TABS
  ) {
    return [TabsEditorVersion];
  }

  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.TAB
  ) {
    return [TabEditorVersion];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.TABS
  ) {
    return Properties(payload);
  }
};
