import { getSessionToken } from "@/api/auth";
import { getCampaign, updateCampaign } from "@/api/campaigns";
import DetailsView from "@/ui/DataView/DetailsView";
// import { item } from "@/ui/DataView/DetailsView/DetailsView.stories"; // change to a different schema

const item = {
  data: [
    {
      id: "company",
      label: "Company",
      value: "",
      fieldType: "string",
    },
    {
      id: "site",
      label: "Company Site",
      value: "",
      fieldType: "string",
    },
    {
      id: "company_email",
      label: "Company Email",
      value: "",
      fieldType: "string",
    },
    {
      id: "company_phone",
      label: "Company Phone",
      value: "",
      fieldType: "string",
    },
    // {
    //   id: "benefits",
    //   label: "Benefits (one per line)",
    //   value: "",
    //   fieldType: "longtext",
    // },
    {
      id: "disclaimer",
      label: "Disclaimer",
      value: "",
      fieldType: "longtext",
    },
    // {
    //   id: "headline",
    //   label: "Headline",
    //   value: "",
    //   fieldType: "string",
    // },
    // {
    //   id: "price",
    //   label: "Amount Due ($)",
    //   value: "",
    //   fieldType: "string",
    // },
    {
      id: "cta_caption",
      label: "CTA Caption",
      value: "",
      fieldType: "string",
    },
    {
      id: "banner_background_url",
      label: "Banner Background URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "url_logo",
      label: "Logo URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "product_name",
      label: "Product Name",
      value: "",
      fieldType: "string",
    },
    {
      id: "product_description",
      label: "Product Description",
      value: "",
      fieldType: "longtext",
    },
    {
      id: "price",
      label: "Amount Due ($)",
      value: "",
      fieldType: "string",
    },
    {
      id: "purchase_agreement_text",
      label: "Checkbox text",
      value: "",
      fieldType: "string",
    },
    {
      id: "optin_text",
      label: "Optin Text",
      value: "",
      fieldType: "string",
    },
    {
      id: "headline",
      label: "Headline (1st Container)",
      value: "",
      fieldType: "string",
    },
    {
      id: "description1",
      label: "Description (Container #1)",
      value: "",
      fieldType: "longtext",
    },
    {
      id: "benefits",
      label: "Benefits (one per line)",
      value: "",
      fieldType: "longtext",
    },
    {
      id: "headline2",
      label: "Headline (Container #2)",
      value: "",
      fieldType: "string",
    },
    {
      id: "description2",
      label: "Description (Container #2)",
      value: "",
      fieldType: "longtext",
    },
    {
      id: "url_profile_image",
      label: "Image URL (Container #2)",
      value: "",
      fieldType: "string",
    },
    {
      id: "profile_name",
      label: "Text (Container #2)",
      value: "",
      fieldType: "string",
    },
    {
      id: "guarantee_headline",
      label: "Guarantee Headline",
      value: "",
      fieldType: "string",
    },
    {
      id: "guarantee_text",
      label: "Guarantee Text",
      value: "",
      fieldType: "string",
    },
    {
      id: "guarantee_image_url",
      label: "Guarantee Seal Image URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "url_earnings_disclaimer",
      label: "Earnings Disclaimer URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "url_privacy_policy",
      label: "Privacy Policy URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "url_purchase_agreement",
      label: "Purchase Agreement URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "url_terms",
      label: "Terms of Use URL",
      value: "",
      fieldType: "string",
    },
    {
      id: "url_contact",
      label: "Contact URL",
      value: "",
      fieldType: "string",
    },
  ],
};

export const CustomizePageVariables = ({ campaignData = {}, setCampaignData = () => {} }) => {
  // const { contentVariables = item?.data } = campaignData?.settings;
  const contentVariables = campaignData?.settings?.contentVariables || item?.data;

  const handleItemChange = async (id, allItems = []) => {
    const nullifyEmpties = items => items.map(itm => ({ ...itm, value: Boolean(itm?.value?.length) ? itm?.value : null }))
    console.log({ allItems: nullifyEmpties(allItems) });

    const settings = {
      settings: {
        ...(campaignData?.settings || {}),
        contentVariables: nullifyEmpties(allItems),
      },
    };

    const campaignId = campaignData?.id;
    const token = getSessionToken();

    setCampaignData({ ...campaignData, ...settings });
    const response = await updateCampaign(campaignId, { token, settings });

    // const campaignData = await getCampaign({ token, id: campaignId })

    console.log(response);
  };

  return (
    <div>
      <DetailsView
        isEditable
        title={<div className="text-lg font-semibold">Page Info</div>}
        item={{
          id: "contentVariables",
          label: "Content Variables",
          data: contentVariables,
        }}
        visibleFields={contentVariables?.map(({ id }) => id)}
        onItemChange={handleItemChange}
      />
    </div>
  );
};
