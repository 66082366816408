import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.TWELVE,
  layout: HeaderLayouts.TWELVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header12.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "be6f07xx7m",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: get(content, "main_headline.html", "..."),
      },
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUB_HEADLINE,
          ...get(content, "sub_headline.style", {}),
        },
        html: get(content, "sub_headline.html", "..."),
      },
      getVideoPlaceholder({
        type: VideoPlaceholderTypes.POPUP,
        style: style.VIDEO,
      }),
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: style.BUTTON.style,
        caption1: "Get Started",
        caption1style: style.BUTTON.caption1,
      },
    ],
  },
};

export default settings;
