import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import theme from "@/ui/theme";

const settings: IComponentSettings = {
  id: ComponentTypes.CONTAINER,
  name: ComponentTypes.CONTAINER,
  thumbnail: {
    style: {
      background: theme.altColors[7],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 11,
    },
    icon: "crop_3_2",
  },
  category: "layout",
  default: {
    type: "Container",
    canHaveChildren: true,
    properties: {
      // textAlign: "center",
      width: "100%",
      mobile: {
        fullscreen: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
};

export default settings;
