import { CSSProperties } from "react";

const theme = {
  fontFamily: "Open Sans",
}

export const style: {
  LISTITEM: {
    style: CSSProperties;
    iconStyle: CSSProperties;
    contentStyle: CSSProperties;
  };
} = {
  LISTITEM: {
    style: {
      fontFamily: theme.fontFamily,
      // padding: "10px 0",
    },
    iconStyle: {
      // borderRadius: "100%",
      padding: 10,
    },
    contentStyle: {
      padding: 10,
    },
  },
};
