import * as React from "react";
import EditorObject from "@/Apps/Pages/EditorObject";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import { Countdown } from "./live";
import { PluginProps } from "./types";

import CountdownPropertyWindow from "./Properties";

export const CountdownComponent: React.FC<PluginProps> = (props) => {
  const { mode, settings } = props;
  const properties = convertProperties(settings.properties);

  return (
    <>
      <V1ObjectWrapper settings={settings} showOverlay>
        <EditorObject {...props} PropertiesView={CountdownPropertyWindow}>
          <Countdown
            {...settings}
            mode={mode}
            numberStyle={{
              ...settings.numberStyle,
              ...properties,
            }}
          />
        </EditorObject>
      </V1ObjectWrapper>
    </>
  );
};

export default CountdownComponent;
