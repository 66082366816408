import { ActionTypes, IAction } from "./types";

export const actions: IAction[] = [
  {
    type: ActionTypes.DO_NOTHING,
    name: "Do Nothing",
    payload: false,
  },
  {
    type: ActionTypes.TRIGGER_NEXT_NODE,
    name: "Go to the next page",
    payload: {
      url: "{next_page}",
    },
  },
  {
    type: ActionTypes.GO_TO_URL,
    name: "Go to a Specific Page",
    payload: false,
  },
  {
    type: ActionTypes.SCROLL_TO_SECTION,
    name: "Scroll to Section",
    payload: false,
  },
  {
    type: ActionTypes.DOWNLOAD_FILE,
    name: "Download a File",
    payload: false,
  },
  {
    type: ActionTypes.OPEN_POPUP,
    name: "Show a Popup Window",
    payload: false,
  },
  {
    type: ActionTypes.SUBMIT_FORM,
    name: "Submit this Form",
    payload: {
      url: "{next_page}",
      urlPageName: "Go to the next page",
      useTag: false,
    },
  },
  // {
  //   type: ActionTypes.TRIGGER_WEBHOOK,
  //   name: "Trigger a Webhook",
  //   payload: {
  //     // url: "{next_page}",
  //     // urlPageName: "Then: Go to the next page",
  //     urlPageName: "Then: Do Nothing",
  //     request_method: "POST",
  //     useTag: false,
  //   },
  // },
  {
    type: ActionTypes.TRIGGER_PURCHASE,
    name: "Purchase Items (New card)",
    payload: false,
  },
  {
    type: ActionTypes.TRIGGER_UPSELL,
    name: "Purchase Items (One Click Upsell)",
    payload: false,
  },
  // {
  //     type: ActionTypes.ADD_TO_CART,
  //     name: "Add Item To Cart",
  //     payload: false
  // },
  // {
  //     type: ActionTypes.REMOVE_FROM_CART,
  //     name: "Remove Item From Cart",
  //     payload: false
  // },
  // {
  //   type: ActionTypes.TOGGLE_ADD_REMOVE_TO_CART,
  //   name: "Toggle Add/Remove To Cart",
  //   payload: false,
  // },
  // {
  //   type: ActionTypes.SHOW_AND_HIDE,
  //   name: "Show / Hide Items",
  //   payload: false,
  // },
  // {
  //   type: ActionTypes.CREATE_TAG,
  //   name: "Create / Update Tag",
  //   payload: false,
  // },
  // {
  //   type: ActionTypes.SWITCH_TABS,
  //   name: "Switch Tabs",
  //   payload: false,
  // },
  // {
  //   type: ActionTypes.ADD_TO_CALENDAR,
  //   name: "Add To Calendar (Download)",
  //   payload: false,
  // },
  {
    type: ActionTypes.SUBMIT_KEAP_FORM,
    name: "Submit Keap Order Form",
    payload: false
  }
];
