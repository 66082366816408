import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";

export const CopilotHistory = ({ list = [], amountToShow = -1, type = "copilot" }) => {
  let campaignList = amountToShow > -1 ? list.slice(0, amountToShow) : list 
  return (
    <Grid className="pb-6">
      {campaignList.map(
        (
          {
            id,
            label,
            caption,
            img = "[logo]",
            status,
            // sharedWith,
            taskDetails,
          },
          key
        ) => {
          return (
            <Card
              key={key}
              label={label}
              caption={caption}
              image={img}
              href={`/${type}/${id}`}
              status={status}
              taskDetails={taskDetails}
            />
          );
        }
      )}
    </Grid>
  );
}
// get the chatList from the server
export const loadChats = async (callbackFn = () => {}, type = "chat") => {
  const token = getSessionToken();
  const response = await getCampaigns({ token, type });
  if (response) {
    // convert the response to the format that the Card components expect
    // console.log("chats", response);
    const seqs = response
      ?.filter(({ deleted }) => !deleted)
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      .map((chat: any) => {
        return {
          id: chat.id,
          label: chat.name,
          caption: `Last Updated: ${moment(chat.updatedAt).fromNow()}`,
          img: (
            <img
              className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
              src="https://flowbite.com/docs/images/logo.svg"
              alt=""
            />
          ), // funnel.screensnhot
          status: Status.INPROGRESS,
        };
      });

      callbackFn(seqs)
  }
  // setIsLoading(false);
};

export const CopilotGallery = ({ name = "" }) => {
  const [chatList, setChatList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // load the chatList when the page loads
  useEffect(() => {
    loadChats((seqs) => {
      setChatList(seqs.reverse());
      setIsLoading(false);
    });
  }, []);
  
  return (
    <Page
      topBar={{
        showLeftSidebarToggle: true,
        page: [{ label: name }],
        items: [
          <Link to="/copilot"><Button label="+ Start a new chat" type={ButtonTypes.OUTLINED} /></Link>
        ]
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(chatList.length) && (
          <EmptyState
            title="No Chats Yet"
            description="Get started by creating a new chat."
            showImage={false}
          >
            <Link to="/copilot/">
              <Button label="Start a new chat" type={ButtonTypes.DEFAULT} />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(chatList.length) && <CopilotHistory chatList={chatList} />}
      </Container>
    </Page>
  );
};
