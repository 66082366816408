import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.ELEVEN,
  layout: HeaderLayouts.ELEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header11.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckktsa7qd1v1u078062fyqewd",
    itemId: "u5j1r64m5rf",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          width: 700,
          display: "flex",
          justifyContent: "center",
          // margin: "0 auto",
          // textAlign: "center",
          padding: 50,
          mobile: {
            desktop: { padding: 50, width: 700, maxWidth: "100%" },
            smartphone: { padding: 10, width: "100%", maxWidth: "100%" },
          },
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.PRE_HEADLINE,
              ...get(content, "pre_headline.style", {}),
            },
            html: get(content, "pre_headline.html", "..."),
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            },
            children: [
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: style.BUTTON.style,
                caption1: "Get Started  asd a",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          marginTop: 40,
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: Number((4 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                properties: { color: "white", lineHeight: "18pt" },
                html: `<p style="font-size: 12pt; color: padding: 0; text-align: left;"><strong>Feature</strong></p><p style="font-size: 10pt; padding: 0; text-align: left;">It's true.  Getting results is much easier than you think</p>`,
                icon: IconTypes.CheckCircle,
                iconStyle: {
                  padding: 5,
                  paddingRight: 15,
                  fontSize: "28pt",
                  color: theme.foregroundColor,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: Number((4 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                properties: { color: "white", lineHeight: "18pt" },
                html: `<p style="font-size: 12pt; color: padding: 0; text-align: left;"><strong>Feature</strong></p><p style="font-size: 10pt; padding: 0; text-align: left;">It's true.  Getting results is much easier than you think</p>`,
                icon: IconTypes.CheckCircle,
                iconStyle: {
                  padding: 5,
                  paddingRight: 15,
                  fontSize: "28pt",
                  color: theme.foregroundColor,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: Number((4 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                properties: { color: "white", lineHeight: "18pt" },
                html: `<p style="font-size: 12pt; color: padding: 0; text-align: left;"><strong>Feature</strong></p><p style="font-size: 10pt; padding: 0; text-align: left;">It's true.  Getting results is much easier than you think</p>`,
                icon: IconTypes.CheckCircle,
                iconStyle: {
                  padding: 5,
                  paddingRight: 15,
                  fontSize: "28pt",
                  color: theme.foregroundColor,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
