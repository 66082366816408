import React from "react";
import Loader from "react-loader-spinner";
import { get, has, head } from "lodash";

import {
  UploadGroup,
  WidthHeight,
  Background,
  PositionPadding,
  Alignment,
  Interactions,
  BorderShadow as BordersShadow,
  PropertiesWindow,
} from "@/Apps/Pages/Properties";

// import theme from "@launchos/components/ui/theme";
import theme from "@/ui/theme";

import { VideoContent } from "./component";
// import { convertFromRem, convertToRem } from '@launchos/modules/editor/Builder/MobileResponsiveToggle/style';
import { convertFromRem } from "@/Apps/Pages/MobileResponsiveToggle/style";

import style from "./style";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { VideoActions } from "./VideoActions";
import { VideoPreviewWhileDragging } from "./VideoPreviewWhileDragging";

// import { useDimensions } from "@launchos/plugins/utilities";
import { useDimensions } from "@/Apps/Pages/utils";

// import { MobileState } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";
import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";

import EditorObject from "@/Apps/Pages/EditorObject";


const VideoContentEdit = (props) => {
  const { settings, pageContent, changeState, isDragInProgress, getMobileState } = props;
  const [isDragging, setIsDragging] = React.useState(false);
  const [ref, objCoordinates] = useDimensions();

  React.useEffect(() => {
    // Dragging an iframe leads to unpredictible results.  So, we replace the iframe
    // with a placeholder while dragging

    const cursorShowing = Boolean(pageContent.filter((content) => content.type === "Cursor").length)

    if (cursorShowing || isDragInProgress) setIsDragging(true)
    else setIsDragging(false)

  }, [pageContent]);

  const isUploading = Boolean(get(settings, "isLoading", false));

  const currentMobileState = getMobileState()['type']
  const width = get(settings, 'properties.width') === "100%" ? objCoordinates.width : convertFromRem(settings.properties.width, currentMobileState);

  let mouse_state;
  // let timeout;

  return (
    <EditorObject
      {...props}
      fitContent={false}
      PropertiesView={Properties}
      onMouseDown={(e) => {
        e.stopPropagation();
        // timeout = setTimeout(() => { setIsDragging(true) }, 200);
        // changeState(settings.id, "active", () => {
        mouse_state = "down";
        // })
      }}
      onMouseMove={() => {
        if (mouse_state === "down" && !isDragging) {
          setIsDragging(true);
          // clearTimeout(timeout) 
        }
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
        mouse_state = null;
        setIsDragging(false);
        // clearTimeout(timeout)
      }}
    >
      {/* <div style={{ ...style.coverObject, position: 'absolute', zIndex: 1000, backgroundColor: "red" }} /> */}
      <div
        style={{
          ...style.coverObject,
          background: isUploading ? "black" : "transparent",
        }}
        ref={ref}
      />
      {isUploading && (
        <div style={style.coverObject}>
          <Loader
            type="MutatingDots"
            color={theme.secondaryColor}
            height="100"
            width="100"
          />
          <h3
            style={{
              background: "white",
              padding: 5,
              display: "inline-block",
            }}
          >
            Your Video is Uploading...
          </h3>
        </div>
      )}
      {/* <VideoPreviewWhileDragging width={convertToRem(width, currentMobileState)} height={convertToRem(width * (720 / 1080), currentMobileState)} /> */}
      {/* <VideoPreviewWhileDragging width={width} height={currentMobileState === MobileState.FULLSCREEN || currentMobileState === MobileState.DESKTOP ? width * (720 / 1080) : false} /> */}
      {isDragging ?
        <VideoPreviewWhileDragging width={width} height={currentMobileState === MobileState.FULLSCREEN || currentMobileState === MobileState.DESKTOP ? width * (720 / 1080) : false} />
        : <VideoContent {...props} muted />
      }
    </EditorObject>
  );
};

export default (props) => {
  const { settings } = props;

  const marginTop = get(settings, 'properties.marginTop', 0);
  const textAlign = get(settings, 'properties.textAlign', 'inherit');
  const height = "100%";

  return (
    <div style={{ marginTop, textAlign, height }}>
      <VideoContentEdit {...props} />
    </div>
  );
};

class BasicPropertiesTab extends React.Component {
  constructor(props) {
    super(props);

    this.showVideo = this.showVideo.bind(this);
  }

  showVideo(files) {
    const { updateComponentSettings, settings } = this.props;

    if (files.length) {
      const file = head(files);

      if (!has(file, "preview")) {
        Object.assign(file, { preview: URL.createObjectURL(file) });
      }

      console.log("prv", file.preview);

      updateComponentSettings(
        settings.id,
        {
          ...this.props.settings,
          isLoading: file.preview.indexOf("blob:") > -1,
          src: {
            mp4: file.preview,
          },
        },
        true,
        false
      );
    }
  }

  render() {
    const { settings, pageContent, updateComponentSettings } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <UploadGroup
          text="Video"
          settings={settings}
          url={settings.src.mp4}
          accept="video/mp4"
          highlighted
          onChange={this.showVideo}
          isExpanded
          label="Upload A Different Video"
          pageContent={pageContent}
          updateComponentSettings={updateComponentSettings}
        />
        <Background
          {...this.props}
          accept="image/jpeg, image/png"
          showAlpha
          isExpanded={false}
          label="Video Thumbnail"
          hideTabs
          theActiveTab="image"
          showExtras={false}
        />
        <WidthHeight label="Video Size" {...this.props} showHeight={false} />
        <PositionPadding {...this.props} />
        <Alignment {...this.props} verticalAlign={false} textAlign />
        <Interactions
          label="Video Actions"
          {...this.props}
          behavior="playProgress"
          onUpdate={(action) => console.log(action)}
        >
          <VideoActions
            settings={settings}
            updateComponentSettings={updateComponentSettings}
          />
        </Interactions>
      </div>
    );
  }
}

const AdvancedPropertiesTab = (props) => (
  <>
    <BordersShadow {...props} isExpanded />
  </>
);

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[
      { id: "basic", title: "Video", component: BasicPropertiesTab },
      { id: "advanced", title: "Advanced", component: AdvancedPropertiesTab },
    ]}
  />
);

export const SimpleVideoProperties = (props) => {

  const { settings, updateComponentSettings } = props;

  const showVideo = (files) => {
    const { updateComponentSettings, settings } = props;

    if (files.length) {
      const file = head(files);

      if (!has(file, "preview")) {
        Object.assign(file, { preview: URL.createObjectURL(file) });
      }

      console.log("prv", file.preview);

      updateComponentSettings(
        settings.id,
        {
          ...settings,
          isLoading: file.preview.indexOf("blob:") > -1,
          src: {
            mp4: file.preview,
          },
        },
        true,
        false
      );
    }
  }

  return (
    <div className="px-3">
      <h3 className="text-sm font-medium">{settings?.prompt}</h3>
      <div className="rounded-lg my-5" style={{}}>
        <UploadGroup
          text="Video"
          settings={settings}
          url={settings.src.mp4}
          accept="video/mp4"
          highlighted
          onChange={showVideo}
          isExpanded
          label="Upload A Different Video"
          // pageContent={pageContent}
          updateComponentSettings={updateComponentSettings}
          hideLabel
        />

        {/* <UploadGroup
          createMediaItem={props.createMediaItem}
          uploadURLToS3={props.uploadURLToS3}
          settings={props.settings}
          url={props.settings.src}
          accept="image/jpeg, image/png"
          highlighted
          onChange={(files) => showImage(files, props)}
          isExpanded
          hideLabel={true}
        /> */}
      </div>
    </div>
  );
};
