import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";
import { PricingLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: PricingLayouts.SIX,
  layout: PricingLayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/pricing/Pricing06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyd2er52ag90780rf8yda8f",
    itemId: "pat2y9nc5tl",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.PRICING,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 1200,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
              mobile: {
                desktop: {
                  marginTop: 40,
                },
                smartphone: {
                  marginTop: 0,
                },
              },
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 10, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      mobile: {
                        desktop: {
                          padding: 30,
                        },
                        smartphone: {
                          padding: 10,
                        },
                      },
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          textAlign: "left",
                        },
                        html: "Our Pricing",
                      },
                      {
                        type: ComponentTypes.TEXT,
                        canHaveChildren: false,
                        properties: {
                          ...style.TEXT,
                          leftAlign: "left",
                          marginTop: 40,
                        },
                        html:
                          "Sign up in less than 30 seconds.  Try out our risk free trial, upgrade at any time, no questions, no hastle.",
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 10, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: theme.backgroundColor,
                      // padding: 30
                    },
                    children: [
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          padding: 30,
                        },
                        children: [
                          {
                            type: ComponentTypes.COLUMNS,
                            canHaveChildren: false,
                            properties: {
                              width: "100%",
                            },
                            children: [
                              {
                                type: ComponentTypes.COLUMN,
                                canHaveChildren: true,
                                preventDelete: true,
                                md: parseInt((6 * 100) / 12),
                                properties: {
                                  height: "100%",
                                  padding: 0,
                                  minHeight: 20,
                                },
                                children: [
                                  {
                                    type: ComponentTypes.TEXT,
                                    canHaveChildren: false,
                                    properties: {
                                      ...style.TEXT,
                                      color: theme.foregroundColor,
                                      fontWeight: "bold" as "bold",
                                      textAlign: "left",
                                    },
                                    html: "Standard",
                                  },
                                ],
                              },
                              {
                                type: ComponentTypes.COLUMN,
                                canHaveChildren: true,
                                preventDelete: true,
                                md: parseInt((6 * 100) / 12),
                                properties: {
                                  height: "100%",
                                  padding: 0,
                                  minHeight: 20,
                                },
                                children: [
                                  {
                                    type: ComponentTypes.TEXT,
                                    canHaveChildren: false,
                                    properties: {
                                      ...style.TEXT,
                                      color: theme.foregroundColor,
                                      fontWeight: "bold" as "bold",
                                      textAlign: "right",
                                    },
                                    html: "$37",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              color: theme.foregroundColor,
                              fontSize: "11pt",
                            },
                            html:
                              "It's true. Getting results is much easier than you think.",
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.DIVIDER,
                        canHaveChildren: false,
                        properties: {},
                        size: 1,
                        color: "#DDD",
                      },
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          padding: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.LIST,
                            canHaveChildren: false,
                            properties: style.LIST,
                            containerStyle: style.LI.style,
                            contentStyle: {
                              ...style.LI.contentStyle,
                              color: theme.foregroundColor,
                            },
                            icon: IconTypes.Check,
                            iconStyle: {
                              ...style.LI.iconStyle,
                              color: theme.foregroundColor,
                            },
                            data: [
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `Lorem ipsum dolor`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `Sit apet consectetur`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `40 Adipiscing elit`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `25 Sed do eiusmod`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `Tempor incididunt`,
                              },
                            ],
                          },
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: theme.foregroundColor,
                              // border: `1px solid ${theme.backgroundColor}`
                            },
                            caption1: "Buy Now",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 10, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      borderRadius: 5,
                      backgroundColor: "#F0F0F0",
                      // padding: 30
                    },
                    children: [
                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          padding: 30,
                        },
                        children: [
                          {
                            type: ComponentTypes.COLUMNS,
                            canHaveChildren: false,
                            properties: {
                              width: "100%",
                            },
                            children: [
                              {
                                type: ComponentTypes.COLUMN,
                                canHaveChildren: true,
                                preventDelete: true,
                                md: parseInt((6 * 100) / 12),
                                properties: {
                                  height: "100%",
                                  padding: 0,
                                  minHeight: 20,
                                },
                                children: [
                                  {
                                    type: ComponentTypes.TEXT,
                                    canHaveChildren: false,
                                    properties: {
                                      ...style.TEXT,
                                      // color: theme.foregroundColor,
                                      fontWeight: "bold" as "bold",
                                      textAlign: "left",
                                    },
                                    html: "Premium",
                                  },
                                ],
                              },
                              {
                                type: ComponentTypes.COLUMN,
                                canHaveChildren: true,
                                preventDelete: true,
                                md: parseInt((6 * 100) / 12),
                                properties: {
                                  height: "100%",
                                  padding: 0,
                                  minHeight: 20,
                                },
                                children: [
                                  {
                                    type: ComponentTypes.TEXT,
                                    canHaveChildren: false,
                                    properties: {
                                      ...style.TEXT,
                                      // color: theme.foregroundColor,
                                      fontWeight: "bold" as "bold",
                                      textAlign: "right",
                                    },
                                    html: "$197",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: ComponentTypes.TEXT,
                            canHaveChildren: false,
                            properties: {
                              ...style.TEXT,
                              // color: theme.foregroundColor,
                              fontSize: "11pt",
                            },
                            html:
                              "It's true. Getting results is much easier than you think.",
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.DIVIDER,
                        canHaveChildren: false,
                        properties: {},
                        size: 1,
                        color: "#CCC",
                      },

                      {
                        type: ComponentTypes.CONTAINER,
                        canHaveChildren: true,
                        properties: {
                          padding: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.LIST,
                            canHaveChildren: false,
                            properties: style.LIST,
                            containerStyle: style.LI.style,
                            contentStyle: style.LI.contentStyle,
                            icon: IconTypes.Check,
                            iconStyle: style.LI.iconStyle,
                            data: [
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `Lorem ipsum dolor`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `Sit apet consectetur`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `40 Adipiscing elit`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `25 Sed do eiusmod`,
                              },
                              {
                                id: Math.random().toString(3).slice(2),
                                caption: `Tempor incididunt`,
                              },
                            ],
                          },
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Buy Now",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
