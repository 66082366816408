import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.SIXTEEN,
  layout: ContentLayouts.SIXTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content16.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyb60t929qm07804oando29",
    itemId: "bomvm6hbuj",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          border: `3px dashed ${theme.backgroundColor}`,
          padding: 50,
          mobile: {
            desktop: { padding: 50 },
            smartphone: { padding: 5 },
          },
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
            },
            html:
              "Yes! I want to Join You and 234 others<br/>at this Live Training!",
          },
          {
            type: ComponentTypes.DIVIDER,
            canHaveChildren: false,
            properties: {
              ...style.DIVIDER,
            },
            color: theme.backgroundColor,
            size: 3,
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.SUBHEADLINE,
            },
            html: "Reserve Your Ticket Today!",
          },
          {
            type: ComponentTypes.BUTTON,
            canHaveChildren: false,
            properties: style.BUTTON.style,
            caption1: "RSVP Now!",
            caption1style: style.BUTTON.caption1,
          },
        ],
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: style.TEXT,
        html: `<a href="#">No Thanks! I don't want access to this product</a>`,
      },
    ],
  },
};

export default settings;
