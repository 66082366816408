import Panel, { PanelItem } from "@/ui/Panel";

import { useEffect, useState } from "react";
import { getCatalogItems } from "@/api/catalog";

import { getSessionToken } from "@/api/auth";
import { updatePage } from "@/api/pages";
import Calout from "@/ui/Calout";
import Modal from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { Container } from "@/ui/Layout/Page";
import TabsComponent from "@/ui/TabsComponent";
import { TabTypes } from "@/ui/TabsComponent/types";
import { Loading } from "@/ui/Layout/Loading";
import { ItemGroup } from "@/Apps/Launchpad/components/ItemGroup";
import { PageTypes } from "@/ui/CRUD/AttributeBuilder/LinkSelector/types";
import { useNavigate } from "react-router-dom";

export const ChoosePageTemplateModal = ({ setIsOpen = () => {}, defaultType = "all", onSelect = () => {} }) => {
  const [type, setType] = useState(defaultType);
  const [designCampaigns, setDesignCampaigns] = useState<any[]>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const loadCatalogItems = async () => {
    const items = await getCatalogItems({ type: "designs" });
    setDesignCampaigns(items);
  };

  useEffect(() => {
    const items = designCampaigns
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)) // sort by most recent (createdAt)
      .filter((itm) => (type === "all" ? true : itm.categories === type))
      .map((item) => ({
        ...item,
        image:
          item?.image ||
          `https://sandcastleassets.s3.amazonaws.com/templates/pageid/${item?.page?.id}.png`,
      }));
    setFilteredItems(items);
  }, [type, setType, designCampaigns]);

  useEffect(() => {
    loadCatalogItems();
  }, []);

  const Tabs = (
    <div className="max-w-6xl mx-auto">
      <TabsComponent
        tabs={[
          { label: "Show All", value: "all" },
          { label: "Lead Pages", value: PageTypes.LEAD },
          { label: "OTO Pages", value: PageTypes.OTO },
          { label: "Sales Pages", value: PageTypes.SALES },
          { label: "Content Pages", value: PageTypes.CONTENT },
          { label: "Event Pages", value: PageTypes.EVENT },
          { label: "Thank You Pages", value: PageTypes.THANKYOU },
        ]}
        activeTab={type}
        setActiveTab={setType}
        actionSection={false}
        className="p-0"
        type={TabTypes.BUTTON}
      />
    </div>
  );

  return (
    <Modal
      title="Choose a Template"
      size={PopupSizes.FULLSCREEN}
      tabs={Tabs}
      onClose={() => setIsOpen(false)}
      footer={false}
    >
      <Container>
        {!designCampaigns.length ? (
          <Loading type="gallery" className="py-8" />
        ) : (
          <ItemGroup
            // title={`${type} Pages`}
            items={filteredItems}
            customCardStyles={{
              height: 225,
              transition: "background-position 2.5s ease", // Added transition property
            }}
            onItemClick={onSelect}
          />
        )}
      </Container>
    </Modal>
  );
};

export const performTemplateChange = async (template, pageId) => {
  console.log({ template });

  // Ask for confirmation before proceeding
  const confirmChange = window.confirm(
    "Are you sure you want to change the template? This action is irreversible and will completely replace your current content."
  );

  if (confirmChange) {
    // get the page content
    const { content } = template?.page;
    const pgResp = await updatePage(pageId, {
      token: getSessionToken(),
      settings: { content },
    });

    console.log("Save Page Response", pgResp);
  } else {
    console.log("Template change cancelled by user");
    return;
  }
}

export const Templates = ({ unitName = "Order Form", objectData = {}, defaultType = "all" }) => {
  const [chooseTemplatePopupIsShowing, setChooseTemplatePopupIsShowing] = useState<boolean>(false);

  // Use the hook to get the navigate function
  const navigate = useNavigate();

  const handleTemplateChange = async (template) => {
    
      const pageId = objectData?.page?.id;
      await performTemplateChange(template, pageId);

      setChooseTemplatePopupIsShowing(false);

      // Reload the page by navigating to the current location
      navigate(0);
  }

  return (
    <Panel title={`${unitName} Template`}>
      {chooseTemplatePopupIsShowing && (
        <ChoosePageTemplateModal
          defaultType={defaultType}
          setIsOpen={setChooseTemplatePopupIsShowing}
          onSelect={handleTemplateChange}
        />
      )}
      <div className="mb-4">
        <Calout
          title={`Important: Changing Your Template`}
          caption={`Selecting a new template will completely replace your current ${unitName.toLowerCase()}. This action is irreversible, so choose wisely.`}
        />
      </div>
      <PanelItem
        title={`Choose a Template`}
        description={`Choose a template for your ${unitName.toLowerCase()}.`}
        buttonLabel="Choose Template"
        onClick={() => setChooseTemplatePopupIsShowing(true)}
      />
    </Panel>
  );
};
