import { get } from "lodash";
import React from "react";
// import { useDimensions } from "@/plugins/utilities";
import { useDimensions } from "../utils";
// import { Button } from "@launchos/components/ui";

import DragHandle from "./DragHandle";
import { DropDownMenu } from "./DropDownMenu";
import Debug from "./Debug";
import { Border } from "./style";

const Overlay = ({ width, height }) => {
  return (
    <div
      style={{
        position: "absolute",
        width,
        height,
        zIndex: 1,
      }}
    />
  );
};

const ObjectContentNoPreview = (props) => {
  const [isContextMenuShowing, setIsContextMenuShowing] = React.useState(null);
  const [ref, hoverBoundingRect] = useDimensions();
  const {
    children,
    settings,
    color,
    showOverlay,
    show,
    dragHandle = true,
    onRightClick,
    borderSize,
    isDragInProgress,
    isEditing,
    changeState,
    // toggleAddNewDrawer,
    // setActiveAddNewTab
  } = props;

  const { state } = settings;

  const handleContextMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isDragInProgress && !isEditing) {
      changeState(settings.id, "active");
      setIsContextMenuShowing({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  }

  const showExtras = (state === "hover" || state === "active") && show !== "preview";

  return (
    <div ref={ref} onContextMenu={onRightClick || handleContextMenuClick} style={{ textAlign: "left", ...props.style }}>
      {(showExtras && dragHandle) && (
        <DragHandle
          {...props}
          showProperties={props.showProperties}
          isContextMenuShowing={isContextMenuShowing}
          setIsContextMenuShowing={setIsContextMenuShowing}
        />
      )}
      {showExtras && (
        <>
          <DropDownMenu
            {...props}
            anchorEl={isContextMenuShowing}
            handleDropDownClose={() => setIsContextMenuShowing(null)} />
          <Border
            borderSize={borderSize}
            show={show}
            settings={settings}
            color={typeof v === 'function' ? color(settings.state) : color}
            state={state}
          />
        </>
      )}
      <div style={props.style}>
        {get(props, "debug") && <Debug {...props} />}
        {showOverlay && <Overlay {...hoverBoundingRect} />}
        {children}
      </div>
      {/* {(showExtras && state === "hover") && (
        <div
          style={{ display: 'inline-block', width: '100%', position: 'relative', textAlign: 'center' }}
        >
          <Button
            onClick={() => { setActiveAddNewTab("SECTIONS"); toggleAddNewDrawer() }}
            style={{ zoom: 0.85, top: 17 }}
            icon
            primary
          >
            add
          </Button>
        </div>
      )} */}
    </div>
  );
};

export const ObjectContent = (props) => {
  if (get(props, "show") === "preview") return props.children;
  return <ObjectContentNoPreview {...props} />;
};
