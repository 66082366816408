// import { useState } from "react";
import PureCanvas from "./Apps/Pages/V1Editor/PureCanvas";
import {
  defContent,
  sampleContentData,
} from "./Apps/Pages/Editor/Editor.stories";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import "@/Apps/Pages/Page/index.css";
import { useEffect, useState } from "react";
import { MobileState } from "./Apps/Pages/MobileResponsiveToggle/types";
import ReactDOMServer from "react-dom/server";
import { PageContent } from "./Apps/Pages/Page/Page";
import { convertPagetoHTML } from "./Apps/Pages/utils";
import { addBodyIfNone } from "./Apps/AITools/Artifacts/PageBuilderArtifact";
import { useChat } from "ai/react";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import Markdown, { Components } from "react-markdown";
import remarkBreaks from "remark-breaks";
import rehypeRaw from "rehype-raw";
import { markdownComponents } from "./ui/CommunicationView/ChatConversation/PromptMessageContainer/markdown";
import Modal from "./ui/Modal";
import { PopupSizes } from "./ui/Modal/types";

import AceEditor from "react-ace";

const testContent = addBodyIfNone([
  {
    parent: 2540,
    id: "4noschk8e95",
    properties: {
      width: "62.5rem",
      padding: "6.375rem",
      maxWidth: "100%",
      backgroundImage:
        "url(https://sandcastleassets.s3.amazonaws.com/g1j2518gec7c-Header_Background_Image.png)",
      backgroundPosition: "Right 90%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      mobile: {
        fullscreen: {
          width: "62.5rem",
          maxWidth: "100%",
          padding: "1.75rem",
        },
        desktop: {
          backgroundPosition: "Right top",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
        },
        tablet: {
          backgroundPosition: "right Top",
          backgroundSize: "50%",
          backgroundRepeat: "no-repeat",
          padding: "2.5rem",
        },
        smartphone: {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
    type: "Section",
    canHaveChildren: true,
    label: "Header",
    oldId: "4s0qzkobfla",
    state: "normal",
  },
  {
    type: "Columns",
    label: "Row",
    canHaveChildren: false,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          width: "62.5rem",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
          height: "auto",
          overflow: "visible",
          marginTop: "0.04166666666666663rem",
        },
        smartphone: {
          maxColumnsPerRow: 1,
          marginTop: "0rem",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          maxWidth: "100%",
          height: "auto",
          overflow: "visible",
        },
        tablet: {
          width: "100%",
          maxWidth: "100%",
          marginTop: "0rem",
        },
        desktop: {
          width: "62.5rem",
          maxWidth: "100%",
        },
      },
    },
    parent: "4noschk8e95",
    oldId: "o9hnuufjk8p",
    id: "mom80k7hs18",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 29,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
      mobile: {
        smartphone: {
          padding: "0rem",
        },
        fullscreen: {
          padding: "0.35rem",
        },
      },
    },
    oldId: "vs2rs7srbck",
    parent: "mom80k7hs18",
    id: "l3zemvvxe4g",
    sm: 17,
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "0.625rem",
          overflow: "visible",
        },
      },
    },
    parent: "l3zemvvxe4g",
    oldId: "xzyjj0sse9",
    id: "es1j82h6zq4",
    state: "normal",
  },
  {
    type: "Picture",
    name: "Image",
    src: "https://sandcastleassets.s3.amazonaws.com/ab2i357ie39g-header_logo.png",
    properties: {
      textAlign: "left",
      display: "inline-block",
      width: "9.375rem",
      maxWidth: "100%",
      padding: "0rem",
      mobile: {
        fullscreen: {
          textAlign: "left",
        },
        smartphone: {
          textAlign: "center",
        },
        tablet: {
          marginTop: "0.25rem",
        },
      },
    },
    parent: "l3zemvvxe4g",
    oldId: "7zdw0v3act7",
    id: "e0b9k28gdj",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 43,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
    },
    oldId: "eo7v7oacfwo",
    parent: "mom80k7hs18",
    id: "ly26t8urt3o",
    sm: 55,
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "0.625rem",
          overflow: "visible",
        },
      },
    },
    parent: "ly26t8urt3o",
    oldId: "dpab7l46uhe",
    id: "0kqx9l1ysfn",
    state: "normal",
  },
  {
    type: "Navigation",
    canHaveChildren: false,
    linkStyle: {
      listStyle: "none",
      display: "inline-block",
      padding: "5px 10px",
      fontFamily: "Roboto",
    },
    layoutStyle: "Horizontal",
    properties: {
      padding: "0rem",
      textAlign: "center",
      display: "inline-block",
      color: "#767981",
      fontFamily: "Poppins",
      fontSize: "0.8571428571428571rem",
      marginTop: "0rem",
      mobile: {
        fullscreen: {
          fontSize: "1rem",
        },
      },
    },
    data: [
      {
        id: "156",
        caption: "Home",
        linkDisplayType: "Text",
        label: "Home",
        iKey: 0,
        isHovering: false,
      },
      {
        id: "157",
        caption: "About Us",
        linkDisplayType: "Text",
        label: "About Us",
        iKey: 1,
        isHovering: false,
      },
      {
        id: "158",
        caption: "Services",
        linkDisplayType: "Text",
        label: "Services",
        iKey: 2,
        isHovering: false,
      },
      {
        id: "159",
        caption: "Pricing",
        linkDisplayType: "Text",
        label: "Pricing",
        iKey: 3,
        isHovering: false,
      },
    ],
    parent: "ly26t8urt3o",
    oldId: "rscn7gm392q",
    id: "5setyctlyl",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 27,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
    },
    oldId: "l9591qr7rzn",
    parent: "mom80k7hs18",
    id: "g88j0ui97ps",
    sm: 27,
    state: "normal",
  },
  {
    type: "AdvancedButton",
    properties: {
      display: "inline-block",
      textAlign: "center",
      padding: "0.35416666666666663rem",
      border: "none",
      borderRadius: 7,
      backgroundColor: "rgb(0,0,0,0)",
      boxShadow: "",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#F86061",
      width: "12.261904761904761rem",
      maxWidth: "100%",
      marginTop: "0.1875rem",
      shadowStyle: "none",
    },
    hoverStyle: {},
    caption1: "Order Now",
    caption1style: {
      color: "#F86061",
      display: "block",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    caption2style: {
      color: "white",
      fontSize: "0.85rem",
    },
    actions: [
      {
        type: "GO_TO_URL",
        name: "Go to a Specific Page",
        payload: false,
        behavior: "click",
      },
    ],
    parent: "g88j0ui97ps",
    oldId: "hq2e4u058fi",
    id: "58h7waopoiq",
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "0.625rem",
          overflow: "visible",
        },
        tablet: {
          height: "2.0833333333333335rem",
          overflow: "visible",
        },
      },
    },
    parent: "4noschk8e95",
    oldId: "62qr633ry0g",
    id: "xyo89c1gzdg",
    state: "normal",
  },
  {
    type: "Container",
    canHaveChildren: true,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    parent: "4noschk8e95",
    oldId: "fs9d21eq7j9",
    id: "8gvuuwxor7d",
    state: "normal",
  },
  {
    type: "Columns",
    label: "Row",
    canHaveChildren: false,
    properties: {
      width: "100%",
    },
    parent: "8gvuuwxor7d",
    oldId: "cs7d3p6le3h",
    id: "zdkreiw2zn",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 45,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
    },
    oldId: "9uvj5s6oh7i",
    parent: "zdkreiw2zn",
    id: "lqa5s1e2jh",
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        smartphone: {
          height: "1rem",
          overflow: "visible",
        },
        fullscreen: {
          height: "2.5rem",
          overflow: "visible",
        },
        desktop: {
          height: "2.857142857142857rem",
          overflow: "visible",
        },
      },
    },
    parent: "lqa5s1e2jh",
    oldId: "sv2wext42p",
    id: "wd0ctcjd39",
    state: "normal",
  },
  {
    type: "Container",
    canHaveChildren: true,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          display: "flex",
          justifyContent: "flex-start",
          backgroundColor: "rgb(253,236,236,1)",
          borderRadius: 24,
          width: "14rem",
          maxWidth: "100%",
          padding: "0rem",
        },
        smartphone: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    parent: "lqa5s1e2jh",
    oldId: "zy397nl7vg",
    id: "10v0b9crkaj",
    state: "normal",
  },
  {
    type: "Columns",
    label: "Row",
    canHaveChildren: false,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          width: "17.125rem",
          maxWidth: "100%",
        },
        smartphone: {
          maxColumnsPerRow: 3,
        },
      },
    },
    parent: "10v0b9crkaj",
    oldId: "8gtmpxy748t",
    id: "c0yslcrk9ho",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 74,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
    },
    oldId: "007bcxtfwyvfd",
    parent: "c0yslcrk9ho",
    id: "nugd2ght18k",
    state: "normal",
  },
  {
    type: "Text",
    html: '<p><strong><span style="font-size: 0.9rem; font-family: poppins; color: #ef633e;">More than Faste</span></strong><strong><span style="font-size: 0.9rem; font-family: poppins; color: #ef633e;">r</span></strong></p>',
    properties: {
      display: "inline-block",
      padding: "0.3125rem",
      textAlign: "right",
    },
    parent: "nugd2ght18k",
    oldId: "0jdiqq1ssdpn",
    id: "4iwu76yx9m4",
    fontsUsed: [],
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 26,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
    },
    oldId: "xcnkgtq3lvo",
    parent: "c0yslcrk9ho",
    id: "jollclswel9",
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "0.3125rem",
          overflow: "visible",
        },
      },
    },
    parent: "jollclswel9",
    oldId: "3n3ecnvb0c1",
    id: "v19bcgi83m",
    state: "normal",
  },
  {
    type: "Picture",
    name: "Image",
    src: "https://sandcastleassets.s3.amazonaws.com/8bhbi485i3f06-Strawberry.png",
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
      mobile: {
        fullscreen: {
          width: "1.6875rem",
          maxWidth: "100%",
          textAlign: "left",
        },
      },
    },
    parent: "jollclswel9",
    oldId: "c4e26ue0ofr",
    id: "96dtutrbrsu",
    state: "normal",
  },
  {
    type: "Container",
    canHaveChildren: true,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          display: "flex",
          justifyContent: "flex-start",
          width: "27.42500114440918rem",
          maxWidth: "100%",
          padding: "0rem",
        },
        smartphone: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    parent: "lqa5s1e2jh",
    oldId: "z067mqpzm8j",
    id: "xe1rvpjy12",
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        smartphone: {
          height: "2rem",
          overflow: "visible",
        },
        fullscreen: {
          height: "1.25rem",
          overflow: "visible",
        },
        desktop: {
          height: "1.4285714285714286rem",
          overflow: "visible",
        },
      },
    },
    parent: "xe1rvpjy12",
    oldId: "052jbf38aa9",
    id: "rgw9v205kb8",
    state: "normal",
  },
  {
    type: "Headline",
    html: '<p style="line-height: 3rem;"><span style="font-family: poppins; color: #2e2e2e; font-size: 3.2rem;"><strong>Groceries delivered in as little as <span style="color: #49cbbf;">1 hour</span></strong></span></p>',
    properties: {
      display: "inline-block",
      padding: "0.3125rem",
      textAlign: "left",
      mobile: {
        smartphone: {
          textAlign: "center",
        },
        fullscreen: {
          textAlign: "left",
        },
      },
    },
    parent: "xe1rvpjy12",
    oldId: "z7jmi7njh2",
    id: "x8cax9i74ai",
    fontsUsed: [],
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "0.625rem",
          overflow: "visible",
        },
      },
    },
    parent: "xe1rvpjy12",
    oldId: "hi9ty7t2wqp",
    id: "zo1710ek7d9",
    state: "normal",
  },
  {
    type: "Container",
    canHaveChildren: true,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          display: "flex",
          justifyContent: "flex-start",
          width: "24.92500114440918rem",
          maxWidth: "100%",
        },
        smartphone: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    parent: "lqa5s1e2jh",
    oldId: "6z84s25hlb2",
    id: "extpby3y8ia",
    state: "normal",
  },
  {
    type: "Text",
    html: '<p style="line-height: 1.8rem;"><span style="font-size: 1rem; font-family: poppins; color: #676767;">Grocen assures fresh groceries every morning to your family without getting out&nbsp;</span></p>',
    properties: {
      display: "inline-block",
      padding: "0.3125rem",
    },
    parent: "extpby3y8ia",
    oldId: "sl3k5m8t5w",
    id: "jacr3yi6loi",
    fontsUsed: [],
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "1.875rem",
          overflow: "visible",
        },
      },
    },
    parent: "lqa5s1e2jh",
    oldId: "jy8pfpmnoi",
    id: "4jhcudxxjrx",
    state: "normal",
  },
  {
    type: "Columns",
    label: "Row",
    canHaveChildren: false,
    properties: {
      width: "100%",
      mobile: {
        fullscreen: {
          width: "27.5625rem",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "flex-start",
        },
        desktop: {
          width: "25.928571428571427rem",
          maxWidth: "100%",
        },
        tablet: {
          maxColumnsPerRow: 3,
          marginTop: "0rem",
          display: "flex",
          justifyContent: "flex-start",
          width: "26.416666666666668rem",
          maxWidth: "100%",
        },
        smartphone: {
          maxColumnsPerRow: 2,
          width: "26.6rem",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    parent: "lqa5s1e2jh",
    oldId: "0b8lpu2hz2lv",
    id: "43pc78td7lk",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 49,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
      mobile: {
        fullscreen: {
          padding: "0rem",
        },
      },
    },
    oldId: "7wqpvnrqdy8",
    parent: "43pc78td7lk",
    id: "mqbh7t2o5it",
    sm: 51,
    xs: 50,
    state: "normal",
  },
  {
    type: "AdvancedButton",
    properties: {
      display: "inline-block",
      textAlign: "left",
      padding: "0.625rem",
      border: "none",
      borderRadius: 9,
      backgroundColor: "rgb(73,203,191,1)",
      boxShadow: "",
      shadowStyle: "none",
      width: "12.375rem",
      maxWidth: "100%",
      mobile: {
        tablet: {
          width: "11.25rem",
          maxWidth: "100%",
        },
        fullscreen: {
          width: "12.375rem",
          maxWidth: "100%",
        },
        smartphone: {
          width: "13.25rem",
          maxWidth: "100%",
        },
      },
    },
    hoverStyle: {},
    caption1: "Download App",
    caption1style: {
      color: "#FFFAFA",
      display: "block",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    caption2style: {
      color: "white",
      fontSize: "0.85rem",
    },
    actions: [
      {
        type: "GO_TO_URL",
        name: "Go to a Specific Page",
        payload: false,
        behavior: "click",
      },
    ],
    parent: "mqbh7t2o5it",
    oldId: "h8x1lqfeiq",
    id: "pgis8qltad9",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 51,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
      mobile: {
        fullscreen: {
          padding: "0rem",
        },
      },
    },
    oldId: "ugjetsh561",
    parent: "43pc78td7lk",
    id: "bngmvqeekaa",
    sm: 49,
    xs: 50,
    state: "normal",
  },
  {
    type: "AdvancedButton",
    properties: {
      display: "inline-block",
      textAlign: "left",
      padding: "0.5rem",
      border: "none",
      borderRadius: 9,
      backgroundColor: "rgb(0,0,0,0)",
      boxShadow: "",
      shadowStyle: "none",
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "#49CBBF",
      mobile: {
        fullscreen: {
          width: "12.375rem",
          maxWidth: "100%",
        },
        tablet: {
          width: "11.166666666666666rem",
          maxWidth: "100%",
        },
        smartphone: {
          width: "13.166666666666666rem",
          maxWidth: "100%",
        },
      },
    },
    hoverStyle: {},
    caption1: "Order Process",
    caption1style: {
      color: "#000000",
      display: "block",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    caption2style: {
      color: "white",
      fontSize: "0.85rem",
    },
    actions: [
      {
        type: "GO_TO_URL",
        name: "Go to a Specific Page",
        payload: false,
        behavior: "click",
      },
    ],
    parent: "bngmvqeekaa",
    oldId: "owxp5slyefl",
    id: "qdgi1hglxb",
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
    },
    parent: "lqa5s1e2jh",
    oldId: "7nl96b5mrc9",
    id: "z7v6zo9y7pe",
    state: "normal",
  },
  {
    type: "Column",
    canHaveChildren: true,
    preventDelete: true,
    md: 56,
    properties: {
      height: "100%",
      padding: "0.35rem",
      minHeight: 20,
    },
    oldId: "yesjcoib4o",
    parent: "zdkreiw2zn",
    id: "t0g3wmhct7",
    state: "normal",
  },
  {
    type: "Spacer",
    properties: {
      height: 40,
      mobile: {
        fullscreen: {
          height: "4.8125rem",
          overflow: "visible",
        },
        smartphone: {
          height: "1rem",
          overflow: "visible",
        },
      },
    },
    parent: "t0g3wmhct7",
    oldId: "q92sa3ueky",
    id: "y3mdoo98t4",
    state: "normal",
  },
  {
    type: "Video",
    src: {
      mp4: "https://www.youtube.com/watch?v=OfIQW6s1-ew",
      ogg: "https://www.youtube.com/watch?v=OfIQW6s1-ew",
    },
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
      mobile: {
        fullscreen: {
          backgroundImage:
            "url(https://sandcastleassets.s3.amazonaws.com/8447g8gf8h5cc-image1.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      },
    },
    parent: "t0g3wmhct7",
    oldId: "s0utoj7ckd",
    id: "2i3zjn54z4f",
    state: "normal",
  },
]);

const Sample = ({ content = {} }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    console.log({ content });
    // Force reload component when content changes
    setKey((prev) => prev + 1);
  }, [content]);

  return (
    <PageContent
      key={key}
      id="undefined"
      content={JSON.stringify(content)}
      nextPage="#"
      getPageData={() => {}}
      trackingPayload=""
      seoPayload=""
      merchantPayload=""
      emailPayload=""
      cssPayload=""
      exitPayload=""
      objectId="cm4kr8rtd26ve08110cbkd5yh"
      campaignId="cm4kr8r4f26uz0811dhydieos"
      userId="cjyuon38x00fa0792wzw2v1lu"
    />
  );
};

export const TestChat = () => {
  const [html, setHTML] = useState("");
  const [content, setContent] = useState(testContent);
  const [tempContent, setTempContent] = useState(testContent);

  return (
    <div className="flex flex-col w-full min-h-screen bg-gray-50">
      <div className="flex-1 w-full max-w-7xl mx-auto p-4 space-y-4">
        <div className="flex gap-4">
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 shadow-md transition-colors"
            onClick={() => {
              const theHtml = convertPagetoHTML(testContent);
              setHTML(theHtml);
              console.log("html", theHtml, "content", testContent);
            }}
          >
            Generate HTML
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 shadow-md transition-colors"
            onClick={() => {
              setContent(addBodyIfNone(tempContent));
            }}
          >
            Update Content
          </button>
        </div>

        {/* <textarea
          className="w-full h-96 p-4 rounded-2xl border border-gray-200 shadow-md font-mono text-sm text-black"
          value={JSON.stringify(tempContent, null, 2)}
          onChange={(e) => setTempContent(JSON.parse(e.target.value))}
        /> */}

        <AceEditor
          mode="java"
          theme="github"
          value={JSON.stringify(tempContent, null, 2)}
          onChange={(e) => setTempContent(JSON.parse(e))}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          width="100%"
          className="w-full h-96 rounded-2xl border border-gray-200 shadow-md"
        />

        <div className="flex bg-white rounded-2xl shadow-md overflow-hidden">
          <Sample content={content} />
          {/* <div dangerouslySetInnerHTML={{__html: html.replace('```html', '').replace('```', '') }} className="border bg-white" /> */}
        </div>
      </div>
    </div>
  );
};

export const Test = () => {
  const [html, setHTML] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const { messages, input, handleInputChange, handleSubmit } = useChat({
    api: `${AI_DOMAIN}/api/copilot/artifacts/page/test`,
    body: {
      systemPrompt: `${systemPrompt}\n\n${html}`,
    },
  });
  const [content, setContent] = useState(testContent);
  const [pageIsShowing, setPageIsShowing] = useState(false);

  const JsonRenderer = ({ value }) => {
    try {
      const jsonObj = JSON.parse(value);
      return (
        <div>
          {pageIsShowing && (
            <Modal title="page" size={PopupSizes.FULLSCREEN} onClose={() => setPageIsShowing(false)}>
              <Sample content={content} />
            </Modal>
          )}
          <button
            onClick={() => {

              const mergedObj = jsonObj.map(itm => {
                if (Object.keys(itm).length === 1 && itm.id) {
                  // If item only has an id, find matching item from testContent
                  return testContent.find(testItem => testItem.id === itm.id) || itm;
                }
                return itm;
              });

              setContent(mergedObj);
              setPageIsShowing(true);
            }}
            className="px-2 py-1 bg-blue-600 text-white rounded-xl hover:bg-blue-700 shadow-sm transition-colors ml-auto text-xs"
          >
            View
          </button>
          <div
            style={{ width: 500, height: 200 }}
            className="bg-gray-100 p-4 max-w-full rounded-lg overflow-auto"
          >
            {JSON.stringify(jsonObj, null, 2)}
          </div>
        </div>
      );
    } catch (e) {
      return <code>{value}</code>;
    }
  };

  const code: Components["code"] = ({
    node,
    inline,
    className,
    children,
    ...props
  }) => {
    const match = /language-(\w+)/.exec(className || "");
    const language = match ? match[1] : "";

    if (!inline && language === "json") {
      return <JsonRenderer value={String(children).replace(/\n$/, "")} />;
    }

    if (markdownComponents.code) {
      return markdownComponents.code({
        node,
        inline,
        className,
        children,
        ...props,
      });
    }

    return (
      <div className={className} {...props}>
        {children}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-gray-50">
      <div style={{ paddingBottom: "28rem" }} className="flex-1 w-full max-w-4xl mx-auto p-4 space-y-4 overflow-y-auto pb-[28rem] border">
        {messages.map((m) => (
          <div
            key={m.id}
            className={`flex ${m.role === "user" ? "justify-end" : "justify-start"}`}
          >
            <div
              className={`flex flex-col max-w-[80%] ${m.role === "user" ? "items-end" : "items-start"}`}
            >
              <div
                className={`p-5 rounded-2xl ${
                  m.role === "user"
                    ? "bg-blue-600 text-white rounded-br-none"
                    : "bg-white text-gray-800 rounded-bl-none shadow-md"
                }`}
              >
                <Markdown
                  children={m.content}
                  remarkPlugins={[remarkBreaks]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    ...markdownComponents,
                    code,
                  }}
                />
                {/* <div
                  className={`whitespace-pre-wrap text-left ${m.role === "assistant" ? "cursor-pointer overflow-auto" : ""}`}
                  dangerouslySetInnerHTML={{ __html: m.content }}
                  // onClick={(e) => {
                  //   if (m.role === "assistant") {
                  //     const elem = e.currentTarget;
                  //     if (elem.requestFullscreen) {
                  //       elem.requestFullscreen();
                  //     } else if ((elem as any).webkitRequestFullscreen) {
                  //       (elem as any).webkitRequestFullscreen();
                  //     } else if ((elem as any).msRequestFullscreen) {
                  //       (elem as any).msRequestFullscreen();
                  //     }
                  //   }
                  // }}
                /> */}
              </div>
              <span className="text-xs text-gray-500 mt-1">
                {m.role === "user" ? "You" : "AI Assistant"}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="max-w-4xl grid grid-cols-2 gap-4 mx-auto p-4">
          <div>
            <label
              htmlFor="systemPrompt"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              System Prompt
            </label>
            <textarea
              id="systemPrompt"
              value={systemPrompt}
              onChange={(e) => setSystemPrompt(e.target.value)}
              className="w-full p-3 border text-black border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              rows={5}
              placeholder="Enter your system prompt here..."
            />
          </div>
          <div>
            <label
              htmlFor="sampleHtml"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Sample HTML
            </label>
            <textarea
              id="sampleHtml"
              value={html}
              onChange={(e) => setHTML(e.target.value)}
              className="w-full p-3 border text-black border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              rows={5}
              placeholder="Paste your sample HTML here..."
            />
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="max-w-4xl mx-auto p-4 text-black"
        >
          <div className="relative">
            <input
              className="w-full p-4 pr-16 border text-black border-gray-300 rounded-full focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              value={input}
              placeholder="Type your message..."
              onChange={handleInputChange}
            />
            <button
              type="submit"
              className="absolute right-3 top-1/2 -translate-y-1/2 p-2 text-blue-600 hover:text-blue-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Test;

// export const NewEditorTest = () => {
//   const [content, setContent] = useState(sampleContentData);

//   const handleChange = (cnt) => {
//     // console.log(cnt?.content);
//     setContent(cnt?.content);
//   };
//   const handleItemSelect = () => {};
//   const handleSave = () => {};
//   const setAddNewDrawerType = () => {};
//   const setMobileState = () => {};
//   const mobileState = { type: MobileState.FULLSCREEN };
//   const onLoad = () => {};

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div className="w-full">
//         <PureCanvas
//           content={content}
//           onChange={handleChange}
//           onItemSelect={handleItemSelect}
//           onSave={handleSave}
//           disableDefaultProperties
//           setActiveAddNewTab={setAddNewDrawerType}
//           mobileState={mobileState}
//           setMobileState={setMobileState}
//           onLoad={onLoad}
//         />
//       </div>
//     </DndProvider>
//   );
// };

// export default NewEditorTest;

// export default () => {
//   const content = sampleContentData;
//   const activeSettings = {
//     type: "HTML",
//     html: "",
//     style: { display: "inline-block", padding: 5 },
//     properties: false,
//     parent: "2d27kqn3x5m",
//     id: "679xvfdc4i5",
//     state: "active",
//   };

//   return (
//     <Page>
//       <div className="flex fixed w-full h-full">
//         <div className="w-[400px] bg-red-500" />
//         <div className="flex-1 bg-white overflow-scroll" />
//         <div className="w-[400px] bg-red-500">
//           {triggerHook(
//             HookTriggers.onComponentRender,
//             {
//               id: ComponentRenderHooks.PROPERTIES,
//               type: activeSettings.type,
//             },
//             {
//               settings: activeSettings,
//               // updateComponentStyle: updateEditorComponentStyle,
//               // updateContent: updateEditorContent,
//               // updateComponentSettings: updateEditorComponent,
//             }
//           )}
//         </div>
//       </div>
//     </Page>
//   );
// }
