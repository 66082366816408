import {
  Image,
  Microphone,
  PaperPlaneRight,
  Smiley,
  Voicemail,
} from "@phosphor-icons/react";
import { PromptMessageInputProps } from "../types";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { ContextChips } from "@/Apps/AITools/ContextChips";

// Define the PromptMessageInput component
export const PromptMessageInput: React.FC<PromptMessageInputProps> = ({
  type = "Minimal",
  placeholder = "Type a message...",
  withButtons,
  buttonSettings,
  isLoading,
  value,
  onChange,
  onKeyDown,
  suggestions = [],
  onSuggestionClick,
  onSubmitButtonClick,
  onContextChange = () => {},
}) => {
  // Render the input field
  const btnClass = `p-1 ${isLoading ? 'text-black/30 dark:text-white/30' : 'text-black dark:text-white'} rounded-lg bg-transparent hover:bg-black/5 dark:hover:bg-white/5 transition-all duration-300`;

  return (
    <div data-testid="PromptMessageInput">
      {/* Render the suggestions section if suggestions is defined */}
      {/* {Boolean(suggestions.length) && (
        <div className="suggestion-section mb-3 mt-4 flex w-full gap-x-2 overflow-auto whitespace-no-wrap text-slate-600">
          {suggestions.map((suggestion, index) => (
            <Button
              key={index}
              className="suggestion-item whitespace-nowrap"
              onClick={() =>
                onSuggestionClick && onSuggestionClick(suggestion.label)
              }
              type={ButtonTypes.SOFT}
              label={suggestion.label}
            />
          ))}
        </div>
      )} */}

      <div
        data-testid="PromptMessageInput-InputSection"
        className={`bg-lightwhite dark:bg-white/5 ${
          type === "Rounded" && "rounded-3xl"
        } p-5 flex items-center gap-4`}
      >
        {withButtons && Boolean(buttonSettings) && (
          <div className="flex gap-2 flex-none items-center">
            {buttonSettings
              .filter(({ location }) => location !== "afterInput")
              .map(({ icon, location, position, onClick }, index) => (
                <button
                  type="button"
                  className={`${location} ${position} ${btnClass}`}
                  onClick={onClick}
                  key={index}
                >
                  {icon}
                </button>
              ))}
          </div>
        )}

        <div className="w-full">
          <textarea
            ref={(input) => input && input.focus()}
            value={value}
            placeholder={placeholder}
            className="form-input p-1 text-[15px] resize-none overflow-hidden bg-transparent border-none focus:outline-none focus:ring-0 focus:border-transparent"
            // disabled={isLoading}
            onChange={(e) => {
              e.target.style.height = "auto";
              e.target.style.height = e.target.scrollHeight + "px";
              onChange(e);
            }}
            onKeyDown={onKeyDown}
            rows={1}
          />
          <div className="my-1">
            <ContextChips onChange={onContextChange} />
          </div>
        </div>

        {withButtons && Boolean(buttonSettings) && (
          <div className="flex gap-2 flex-none items-center">
            {buttonSettings
              .filter(({ location }) => location === "afterInput")
              .map(({ icon, location, position, onClick }, index) => (
                <button
                  type="button"
                  className={`${location} ${position} ${btnClass}`}
                  onClick={onClick}
                  key={index}
                >
                  {icon}
                </button>
              ))}
          </div>
        )}

        <button
          type="submit"
          // onClick={onSubmitButtonClick} // just do onSubmit instead
          className={`flex-none ${btnClass}`}
          disabled={isLoading}
        >
          <PaperPlaneRight size={20} />
        </button>
      </div>

      {/* Original Version (Tests Passing) */}
      {/* <input
        type="text"
        className={type === "Rounded" ? "rounded" : ""}
        placeholder={placeholder}
        disabled={isLoading}
        onChange={(event) => onChange && onChange(event.target.value)}
      /> */}
      {/* Render the buttons if withButtons is true and buttonSettings is defined */}
      {/* {withButtons && buttonSettings && ( */}
      {/* <div className="button-container"> */}
      {/* Map over the buttonSettings array and render each button */}
      {/* {buttonSettings.map((button, index) => ( */}
      {/* <button */}
      {/* key={index} */}
      {/* className={`button ${button.position} ${button.location}`} */}
      {/* onClick={button.onClick} */}
      {/* > */}
      {/* Render the button icon if it exists */}
      {/* {button.icon} */}
      {/* Render the button label if it exists */}
      {/* {button.label && <span>{button.label}</span>} */}
      {/* </button> */}
      {/* ))} */}
      {/* </div> */}
      {/* )} */}
      {/* Render the voice input button if withVoiceInput is true and voiceInputSettings is defined */}
      {/* {withVoiceInput && voiceInputSettings && (
        <button
          className="voice-input-button"
          aria-label="Voice Input"
          type="button"
          title="Voice Input"
          role="button"
          tabIndex={0}
          onClick={voiceInputSettings.onClick}
        >
          {voiceInputSettings.icon || <Voicemail />}
        </button>
      )} */}
      {/* Render the loading indicator if isLoading is true */}
      {/* {isLoading && <div className="loading-indicator">Loading...</div>} */}
      {/* Render the suggestions section if suggestions is defined */}
      {/* {suggestions.length && ( */}
      {/* <div className="suggestion-section"> */}
      {/* Map over the suggestions array and render each suggestion */}
      {/* {suggestions.map((suggestion, index) => ( */}
      {/* <div */}
      {/* key={index} */}
      {/* className="suggestion-item" */}
      {/* onClick={() => onSuggestionClick && onSuggestionClick(suggestion.label)} */}
      {/* > */}
      {/* {suggestion.label} */}
      {/* </div> */}
      {/* ))} */}
      {/* </div> */}
      {/* )} */}
    </div>
  );
};

export default PromptMessageInput;
