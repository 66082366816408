import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";
import { IconTypes } from "./types";

const settings: IComponentSettings = {
  id: ComponentTypes.ICON,
  name: "Icon",
  description: "",
  thumbnail: "comp_icons.png",
  default: {
    type: ComponentTypes.ICON,
    properties: {
      display: "inline-block",
      fontSize: "42pt",
      fontWeight: "bold",
      // lineHeight: "28pt",
      color: theme.darkColor,
      textAlign: "center",
    },
    iconType: IconTypes.EmojiEmotions,
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.ICONSELECTOR,
          sectionWrap: true,
          settings: {
            label: "Choose an Icon",
            icon: "insert_emoticon",
            containerStyle: { maxHeight: 500, overflowY: "scroll" },
            isExpanded: true,
          },
        },
        {
          type: SectionTypes.BUTTONSLIDER,
          sectionWrap: true,
          settings: {
            label: "Icon Size",
            icon: "aspect_ratio",
            text: "Size",
            button1: { icon: "remove", value: "SHRINK" },
            button2: { icon: "add", value: "GROW" },
            minValue: 10,
            maxValue: 250,
            value: 42,
            showAutoCheck: false,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.COLORPICKER,
          sectionWrap: true,
          settings: {
            icon: "colorize",
            label: "Icon Color",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITION,
          sectionWrap: true,
          settings: {
            label: "Icon Position",
            icon: "swap_vert",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Icon Actions",
            children: null,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
