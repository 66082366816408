import React from "react";
import { Variants } from "../types";
import { ChipProps } from "./types";

export const Chip: React.FC<ChipProps> = ({
  variant = Variants.SECONDARY,
  status = "Pending",
}: ChipProps) => {
  // Initialize variables for text color and background color
  let textColor = "";
  let bgColor = "";
  let className = "";

  // Determine text color and background color based on the variant prop
  switch (variant) {
    case Variants.PRIMARY:
      textColor = "#8A8CD9";
      bgColor = "#EDEDFF";
      break;
    case Variants.SECONDARY:
      textColor = "#59A8D4";
      bgColor = "#E2F5FF";
      break;
    case Variants.SUCCESS:
      textColor = "#FFC555";
      bgColor = "#FFFBD4";
      break;
    case Variants.INFO:
      textColor = "#8A8CD9";
      bgColor = "#EDEDFF";
      break;
    case Variants.WARNING:
      textColor = "#FFC555";
      bgColor = "#FFFBD4";
      break;
    case Variants.DANGER:
      textColor = "#59A8D4";
      bgColor = "#E2F5FF";
      break;
    case Variants.DISABLED:
      // textColor = "#CCCCCC";
      // bgColor = "#555555";
      className = "bg-black/10 text-black/50 dark:bg-white/10 dark:text-white/50";
      break;
    default:
      textColor = "#59A8D4";
      bgColor = "#E2F5FF";
      break;
  }

  // Render the chip component with the determined text color and background color
  return (
    <div
      className={`bg-${variant} px-1.5 py-0.5 text-xs rounded inline-block ${className}`}
      style={{ backgroundColor: bgColor, color: textColor }}
      data-testid="Chip"
    >
      {status}
    </div>
  );
};
