import _ from "lodash";
import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

import moment from "moment";
import { DatetimePickerTrigger } from "rc-datetime-picker";

// import { TextInput, Radio, Grid, Cell } from "@launchos/components/ui";

import { Section } from "@/Apps/Pages/Properties";

import { CountDownTypes } from "../types";

import "./datepicker.css";
import TextInput from "@/ui/TextInput";
import Radio from "@/ui/Radio";
import Grid, { Cell } from "@/ui/_old/Grid";
import { DatePicker, TimePicker } from "@/Apps/CRM/Broadcasts/components/BroadcastOptions";
import Panel from "@/ui/Panel";

export class TimeDateOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventDate: false,
      countType: _.get(props, "settings.countType", CountDownTypes.EVENT),
      moment: moment(_.get(props, "settings.eventDate", new Date())),
      firstVisitExpiration: _.get(props, "settings.firstVisitExpiration"),
    };

    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handlePostVisitChange = this.handlePostVisitChange.bind(this);
  }

  handleRadioChange(e) {
    const { updateComponentSettings, settings } = this.props;
    this.setState({ countType: e.target.value }, () => {
      console.log("Count Type Should Update", this.state.countType);
      updateComponentSettings(
        settings.id,
        {
          ...settings,
          countType: this.state.countType,
        },
        true,
        false
      );
    });
  }

  handleTimeChange(moment) {
    const { updateComponentSettings, settings } = this.props;

    this.setState({ moment }, () => {
      updateComponentSettings(
        settings.id,
        {
          ...settings,
          countType: this.state.countType,
          eventDate: this.state.moment, //.format("MM/DD/YYYY HH:mm")
        },
        true,
        false
      );
    });
  }

  handlePostVisitChange(type, value) {
    const { updateComponentSettings, settings } = this.props;
    this.setState(
      (prevState) => ({
        firstVisitExpiration: {
          ...prevState.firstVisitExpiration,
          [type]: value,
        },
      }),
      () => {
        updateComponentSettings(settings.id, {
          ...settings,
          countType: this.state.countType,
          firstVisitExpiration: this.state.firstVisitExpiration,
        });
      }
    );
  }

  render() {
    const { countType } = this.state;
    const { settings } = this.props;
    // const shortcuts = {
    //   Today: moment(),
    //   Yesterday: moment().subtract(1, "days"),
    //   Clear: ""
    // };

    return (
      <Section label="Time & Date" icon="timer" {...this.props}>
        <Panel title="Time & Date Settings">
          <RadioGroup
            aria-label="time date settings"
            name="timedate"
            value={countType}
            // onChange={this.handleRadioChange}
            onChanged={(e, value) => console.log("RadioGroup onChange Called", value)}
          >
            <FormControlLabel
              value={CountDownTypes.EVENT}
              control={<Radio checked={countType === CountDownTypes.EVENT} onChange={e => {
                console.log("Trying individual onChange call", e.target.value);
                this.handleRadioChange(e);
              }} />}
              label="Count down to an event"
            />
            {countType === CountDownTypes.EVENT && (
              <div className="flex flex-col p-4 gap-2">
                <DatePicker name="Date" label="Date" placeholder="Date" value={this.state.moment} onChange={this.handleTimeChange} />
                <TimePicker name="Time" label="Time" placeholder="Time" value={this.state.moment} onChange={this.handleTimeChange} />
                {/* <DatetimePickerTrigger
                  // shortcuts={shortcuts}
                  moment={this.state.moment}
                  onChange={this.handleTimeChange}
                >
                  <TextInput
                    iconRight="date_range"
                    value={this.state.moment.format("MM/DD/YYYY HH:mm")}
                  />
                </DatetimePickerTrigger> */}
              </div>
            )}
            <FormControlLabel
              value={CountDownTypes.VISIT}
              control={<Radio checked={countType === CountDownTypes.VISIT} onChange={e => {
                console.log("Trying individual onChange call", e.target.value);
                this.handleRadioChange(e);
              }} />}
              label="Count to time since page visit"
            />
            {countType === CountDownTypes.VISIT && (
              <Grid style={{ marginLeft: 25, width: 275 }}>
                <Cell style={{ margin: 5 }}>
                  <TextInput
                    onChange={(e) =>
                      this.handlePostVisitChange("days", e.target.value)
                    }
                    value={_.get(settings, "firstVisitExpiration.days", 0)}
                  />
                  <p>Days</p>
                </Cell>
                <Cell style={{ margin: 5 }}>
                  <TextInput
                    onChange={(e) =>
                      this.handlePostVisitChange("hours", e.target.value)
                    }
                    value={_.get(settings, "firstVisitExpiration.hours", 0)}
                  />
                  <p>Hours</p>
                </Cell>
                <Cell style={{ margin: 5 }}>
                  <TextInput
                    onChange={(e) =>
                      this.handlePostVisitChange("minutes", e.target.value)
                    }
                    value={_.get(settings, "firstVisitExpiration.minutes", 0)}
                  />
                  <p>Minutes</p>
                </Cell>
              </Grid>
            )}

            <FormControlLabel
              value={CountDownTypes.TOP_OF_HOUR}
              control={<Radio checked={countType === CountDownTypes.TOP_OF_HOUR} onChange={e => {
                console.log("Trying individual onChange call", e.target.value);
                this.handleRadioChange(e);
              }} />}
              label="Count to top of next hour"
            />
          </RadioGroup>
        </Panel>
      </Section>
    );
  }
}
