import { SignIn } from "@clerk/clerk-react"
import { Link } from "react-router-dom"

export const ClerkLogin = () => {

    const { hostname } = window.location;
    const shouldRedirect = hostname.includes('freedomsoft.com');

    if (shouldRedirect) {
        window.location.href = '/login';
        return null;
    }

    return (
        <div className="flex items-center justify-center w-full min-h-screen">
            <div>
                <SignIn 
                    signUpUrl="https://www.launchos.com" 
                    forceRedirectUrl="/authVerify"
                    appearance={{
                        elements: {
                            formFieldInput: "bg-transparent p-3 rounded"
                        }
                    }}
                />
                <p className="text-center p-4 text-black/70 dark:text-white/70">
                    Trouble Signing In? <Link to="/login" className="underline">Try the Old Login Page</Link>
                </p>
            </div>
        </div>
    )
}