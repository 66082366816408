import { useEffect, useState } from "react";
import { Calout } from "@/ui/Calout";
import { getConnectedNodes, updateObject } from "@/api/campaigns";

import { FooterButtons } from "@/ui/Modal/Modal";

import { LogicBuilder } from "./LogicBuilder";
import { getPlainEnglishOperators } from "./fields";
import { FieldData } from "@/ui/DataView/types";
import { RuleGroupType } from "react-querybuilder";
import { updatePage } from "@/api/pages";
import { getSessionToken } from "@/api/auth";
import { fields } from "./fields";

export const Properties = ({
  campaignId,
  nodeData,
  nodes,
  setNodes,
  setEditDrawerOpen,
}) => {
  const [connectedNodes, setConnectedNodes] = useState({
    outgoing: [],
    incoming: [],
  });
  const [incomingFormFields, setIncomingFormFields] = useState([]);

  const [queries, setQueries] = useState<RuleGroupType[]>(
    nodeData?.data?.settings?.smartRouterSettings || []
  );

  const saveSettings = async () => {
    // save the settings
    // update the query array to include the associated page id
    const newQueries = queries.map((query, key) => ({
      ...query,
      pageId: connectedNodes?.outgoing[key]?.page?.id,
    }));

    console.log({ queries, newQueries });

    // save the settings
    const nodeId = nodeData?.id;
    const pageId = nodeData?.data?.page?.id;
    const currentPageSettings = nodeData?.data?.page?.pageSettings || {};
    console.log({ nodeData, pageId });

    const token = getSessionToken();
    const pageSettings = {
      ...currentPageSettings,
      smartRouterSettings: newQueries,
      tracking: {
        smartRouterSettings: newQueries,
        head: "",
      },
    };

    // update the page with the new settings
    const pageResponse = await updatePage(pageId, {
      token,
      settings: { pageSettings },
    });
    console.log({ pageResponse });

    // now update the node data with the new settings

    const settings = {
      smartRouterSettings: newQueries,
    };

    const nodeResponse = await updateObject(nodeId, {
      token,
      settings,
    });

    console.log({ nodeResponse });

    const newNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          settings,
          data: {
            ...node.data,
            page: {
              ...node.data.page,
              pageSettings,
            },
          },
        };
      }
      return node;
    });

    setNodes(newNodes);

    console.log({ newNodes });

    setEditDrawerOpen(false);
    alert("Your changes have been saved");
    // refresh the page
    window.location.reload();
  };

  const getFormFields = (connectedNodes) => {
    const getInputType = (type): FieldData["fieldType"] => {
      if (type === "Text") return "string";
      return "string";
    };

    const pages = connectedNodes.incoming.map((node) => node.page);
    let formFields = [];
    pages.forEach((page) => {
      const fieldsInPage = page.content.filter(
        (item) => item.type === "Form" || item.type === "Form Field"
      );
      let fieldsMergedAndMapped = [];
      fieldsInPage.forEach((field) => {
        if (field.type === "Form") {
          fieldsMergedAndMapped = [
            ...fieldsMergedAndMapped,
            ...field.data.map((item) => ({
              ...item,
              fieldType: item.type,
              attributes: { name: item.name, items: item.items },
            })),
          ];
        } else {
          fieldsMergedAndMapped = [...fieldsMergedAndMapped, { ...field }];
        }
      });

      // console.log({ fieldsMergedAndMapped })
      formFields = [
        ...formFields,
        ...fieldsMergedAndMapped.map(({ id, attributes, fieldType }) => ({
          id,
          name: attributes.name,
          label: attributes.name, // <- parse this against schema doc
          // inputType: getInputType(fieldType),
          operators: getPlainEnglishOperators(getInputType(fieldType)),
          values: attributes.items
            ? attributes.items.map((item) => ({
                label: item.value,
                name: item.name,
              }))
            : null,
        })),
      ];
    });

    console.log({ pages, formFields });

    return formFields;
  };

  const fetchConnectedNodes = async () => {
    const token = getSessionToken();

    const response = await getConnectedNodes(nodeData?.id, {
      token,
      campaignId,
    });

    setConnectedNodes(response);

    // now look up form fields in the incoming nodes
    // and add them to the list of fields
    const formFields = getFormFields(response);
    setIncomingFormFields(formFields);
  };

  const handleSetQuery = (key, query) => {
    const newQueries = [...queries];
    newQueries[key] = query;
    setQueries(newQueries);
  };

  const allFields = [
    ...fields,
    ...incomingFormFields,
    {
      name: "default",
      label: "No other conditions are met",
      operators: [],
    },
  ];

  useEffect(() => {
    fetchConnectedNodes();
  }, []);

  return (
    <div className="grid grid-flow-row gap-7">
      <Calout
        title="Route your visitors to a different destination based on conditions you specify."
        caption="Create a condition for each of the destination this Smart Router connects to."
      />

      <div className="grid grid-flow-row gap-4">
        <h2 className="text-lg font-semibold">Routing Conditions</h2>
        {connectedNodes["outgoing"].map(
          ({ id, page, name, screenshot }, key) => (
            <div className="my-4">
              {/* <h3 className="text-md font-semibold">{name}</h3> */}
              <p className="py-2 text-md">
                Route visitors to the{" "}
                <span className="font-semibold">"{name}"</span> page when the
                following conditions are met
              </p>

              <div
                key={key}
                className="border border-gray-200 dark:border-white/10 rounded p-4 bg-white/80 dark:bg-white/5"
              >
                <div className="flex">
                  <div className="w-24">
                    {screenshot ? (
                      <img
                        src={screenshot}
                        className="rounded inline-block mx-auto"
                      />
                    ) : (
                      <div className="w-full h-16 rounded bg-gray-200 dark:bg-white/10"></div>
                    )}
                    <p className="text-xs py-2 text-center">{name}</p>
                  </div>
                  <div className="px-5">
                    {/* <p className="p-1">Go to the "{name}" page...</p> */}
                    <LogicBuilder
                      fields={allFields}
                      query={queries[key]}
                      setQuery={(q) => handleSetQuery(key, q)}
                      className="dark:bg-black/80"
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <FooterButtons
        position="center"
        showBack={false}
        showNext={false}
        showSave={true}
        onSave={saveSettings}
        onCancel={() => setEditDrawerOpen(false)}
      />
    </div>
  );
};
