import { get, head } from "lodash";
// import { convertFromRem } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/style";
import { convertFromRem } from "@/Apps/Pages/MobileResponsiveToggle/style";

import { ResizeEvent } from "./types";

let newPageContent;
let oldLeftCol;

const getInitialVariables = (monitor) => {
  const props = monitor.getItem();
  const { getMobileState } = props;
  const currentMobileState = getMobileState();

  const hash = {
    smartphone: "xs",
    tablet: "sm",
    desktop: "md",
    // fullscreen: "lg",
    fullscreen: "md", // because lg & md keep conflicting, so let's lock them together
  };
  const mKey = hash[currentMobileState.type];

  // Where is my mouse?
  const initialClientOffset = monitor.getInitialClientOffset();
  const clientOffset = monitor.getClientOffset();

  // How far have I dragged this?
  const widthChange = clientOffset.x - initialClientOffset.x;

  return { mKey, widthChange, ...props };
};

const getColumnWidths = ({
  pageContent,
  settings,
  parentCoordinates,
  mKey,
  getMobileState,
}) => {
  const siblings = pageContent.filter((itm) => itm.parent === settings.parent);
  const key = siblings.findIndex((itm) => itm.id === settings.id);
  const parentObject = head(
    pageContent.filter((itm) => itm.id === settings.parent)
  );

  const pw = convertFromRem(
    get(parentObject, "properties.width"),
    getMobileState()["type"]
  );
  const parentWidth = pw && pw !== "100%" ? pw : parentCoordinates.width;

  const singleColWidth = parentWidth / 100;

  // am i at the end? (do this to determine what is left vs right)
  const leftCol =
    key === siblings.length - 1 ? siblings[key - 1] : siblings[key];
  const leftColWidth =
    leftCol[mKey] * singleColWidth || leftCol.md * singleColWidth;

  const rightCol =
    key === siblings.length - 1 ? siblings[key] : siblings[key + 1];
  const rightColWidth =
    rightCol[mKey] * singleColWidth || rightCol.md * singleColWidth;

  return { leftCol, leftColWidth, rightCol, rightColWidth, parentWidth };
};

const getNewColumnWidths = ({
  leftColWidth,
  widthChange,
  parentWidth,
  mKey,
  rightColWidth,
}) => {
  // A. Reduce the first by the amount
  const newLeftWidth = leftColWidth + widthChange;
  const newLeftCol = Math.round(100 / (parentWidth / newLeftWidth));

  let newLeftColObj = {};
  newLeftColObj[mKey] = newLeftCol;

  // B. Increase the second by the amount
  const newRightWidth = rightColWidth - widthChange;
  const newRightCol = Math.round(100 / (parentWidth / newRightWidth));

  const newRightColObj = {};
  newRightColObj[mKey] = newRightCol;

  return { newLeftCol, newLeftColObj, newRightCol, newRightColObj };
};

const isOutOfBounds = ({ newLeftCol, newRightCol }) =>
  newLeftCol === oldLeftCol ||
  newLeftCol < 3 ||
  newLeftCol > 95 ||
  newRightCol < 3 ||
  newRightCol > 95 ||
  Math.abs(newLeftCol - oldLeftCol) < 5; // ignore if under 2%

const generateNewColumns = ({
  pageContent,
  leftCol,
  newLeftColObj,
  rightCol,
  newRightColObj,
}) => {
  // a. build first column
  const leftKey = pageContent.findIndex((itm) => itm.id === leftCol.id);
  const contentWithLeftColumnUpdated = [
    ...pageContent.slice(0, leftKey),
    {
      ...leftCol,
      ...newLeftColObj,
    },
    ...pageContent.slice(leftKey + 1),
  ];

  // b. build second column
  let contentWithRightColumnUpdated = contentWithLeftColumnUpdated;
  if (rightCol) {
    const rightKey = contentWithLeftColumnUpdated.findIndex(
      (itm) => itm.id === rightCol.id
    );
    contentWithRightColumnUpdated = [
      ...contentWithLeftColumnUpdated.slice(0, rightKey),
      {
        ...rightCol,
        ...newRightColObj,
      },
      ...contentWithLeftColumnUpdated.slice(rightKey + 1),
    ];
  }

  return contentWithRightColumnUpdated;
};

/**
 * The function called for every drag event that happens with column resizing
 * Calculates and returns the new width of each column sibling associated with the drag attempt
 * @param _
 * @param monitor
 */
export const handleResize: ResizeEvent = (_, monitor) => {
  // 1. get the initial variables to work with
  const variables = getInitialVariables(monitor);

  const { updateContent, updateDragState } = variables;

  // updateDragState(true);

  // 2. get current width of each column
  const currentColumnWidths = getColumnWidths(variables);

  // 3. calculate new widths for each column
  const newColumnWidths = getNewColumnWidths({
    ...currentColumnWidths,
    ...variables,
  });
  const { newLeftCol } = newColumnWidths;

  // 4. cancel if out of bounds
  const oob = isOutOfBounds(newColumnWidths);
  if (oob) return;
  oldLeftCol = newLeftCol;

  // console.log("Not out of bounds", newLeftCol)

  // 5. Now Update the Content (but not the database)
  newPageContent = generateNewColumns({
    ...currentColumnWidths,
    ...newColumnWidths,
    ...variables,
  });

  updateContent(newPageContent, false);
};

export const handleResizeEnd = (props) => {
  const { updateContent, updateDragState } = props;

  // updateDragState(false);

  // 6. Now update the Database
  updateContent(newPageContent, true);
};
