import { API_DOMAIN } from "@launchos/shared-utils/env";

/**
 * Retrieves domain data from the API.
 * @param {string} token - The authorization token.
 * @returns {Promise<DomainData[]>} - A promise that resolves to an array of domain data objects.
 */
export const getDomainData = async ({ token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    try {
      const response = await fetch(`${API_DOMAIN}/domains`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
      const { data } = await response.json();
      console.log("All Domains Data", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Creates a domain with the given name and optional campaign ID.
 * @param domainName - The name of the domain to create.
 * @param params - Additional parameters for the API request.
 * @returns A Promise that resolves to the created domain data.
 */
export const createDomain = async (domainName, { token, campaignId }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    try {
      let body = { name: domainName };
      if (campaignId) body = { ...body, campaignId };

      const response = await fetch(`${API_DOMAIN}/domains`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Deletes a domain by its ID.
 * @param domainId The ID of the domain to delete.
 * @param params The parameters for the request.
 * @returns A promise that resolves to the response data.
 */
export const deleteDomain = async (domainId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    try {
      const response = await fetch(`${API_DOMAIN}/domains/${domainId}`, {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Fetches the domain data from the API.
 * @param id - The ID of the domain to fetch.
 * @param token - The authorization token.
 * @returns The domain data.
 */
export const getDomain = async (id, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    try {
      const response = await fetch(`${API_DOMAIN}/domains/${id}`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
      const { data } = await response.json();
      console.log("getDomain data", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Creates an SSL for a domain.
 * @param domainId - The ID of the domain.
 * @param options - The options for creating SSL.
 * @returns A Promise that resolves to the SSL creation response.
 */
export const createSSL = async (domainId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    try {
      const response = await fetch(
        `${API_DOMAIN}/domains/${domainId}/createSSL`,
        {
          method: "POST",
          headers: myHeaders,
          body: "",
          redirect: "follow",
        }
      );
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};
