import { getTokenInfo } from "@/api/auth";
import { supabase } from "@/db";
import { API_DOMAIN } from "@launchos/shared-utils/env";
import { SupabaseClient } from "@supabase/supabase-js";
import { keys } from "lodash";

// TO DO: remove direct calls to supabase.  call the collections api instead

/**
 * Converts a filter object to a query for the database
 * @param filter The filter object to convert
 * @param collectionId The id of the collection to query
 * @returns The query to use for the database
 */
interface FilterToQueryOptions {
  filter: any;
  collectionId: string;
  selectOptions?: any;
  startIndex?: number;
  endIndex?: number;
}

export const convertFilterToQuery = ({
  filter,
  collectionId,
  selectOptions,
  startIndex,
  endIndex,
}: FilterToQueryOptions) => {
  console.log("inside", { startIndex, endIndex });

  let query: SupabaseClient<any, "public", any> | any = supabase;
  query = query.from(collectionId);
  query = query.select("*", selectOptions);

  // add where clause based on filter
  if (!filter?.rules) return query;

  filter?.rules.forEach((rule) => {
    console.log({
      field: rule.field,
      value: rule.value,
      operator: rule.operator,
    });

    // when to quit;
    if (rule.value === null || rule.value === undefined || rule.value === "")
      return;

    // "contains"
    if (rule.operator === "contains") {
      query = query.like(rule.field, `%${rule.value}%`);
      // "="
    }
    // "="
    else if (rule.operator === "=") {
      query = query.eq(rule.field, rule.value);
    }
    // "!="
    else if (rule.operator === "!=") {
      query = query.neq(rule.field, rule.value);
    }
    // "<"
    else if (rule.operator === "<") {
      query = query.lt(rule.field, rule.value);
    }
    // ">"
    else if (rule.operator === ">") {
      query = query.gt(rule.field, rule.value);
    }
    // "<="
    else if (rule.operator === "<=") {
      query = query.lte(rule.field, rule.value);
    }
    // ">="
    else if (rule.operator === ">=") {
      query = query.gte(rule.field, rule.value);
    }
    // "beginsWith"
    else if (rule.operator === "beginsWith") {
      query = query.like(rule.field, `${rule.value}%`);
    }
    // "endsWith"
    else if (rule.operator === "endsWith") {
      query = query.like(rule.field, `%${rule.value}`);
    }
    // "doesNotContain"
    else if (rule.operator === "doesNotContain") {
      query = query.notLike(rule.field, `%${rule.value}%`);
    }
    // "doesNotBeginWith"
    else if (rule.operator === "doesNotBeginWith") {
      query = query.notLike(rule.field, `${rule.value}%`);
    }
    // "doesNotEndWith"
    else if (rule.operator === "doesNotEndWith") {
      query = query.notLike(rule.field, `%${rule.value}`);
    }
    // "null"
    else if (rule.operator === "null") {
      query = query.is(rule.field, null);
    }
    // "notNull"
    else if (rule.operator === "notNull") {
      query = query.not(rule.field, null);
    }
    // "in"
    else if (rule.operator === "in") {
      query = query.in(rule.field, rule.value);
    }
    // "notIn"
    else if (rule.operator === "notIn") {
      query = query.notIn(rule.field, rule.value);
    }
    // "between"
    else if (rule.operator === "between") {
      query = query.between(rule.field, rule.value[0], rule.value[1]);
    }
    // "notBetween";
    else if (rule.operator === "notBetween") {
      query = query.notBetween(rule.field, rule.value[0], rule.value[1]);
    }
  });

  // add range
  if (Boolean(endIndex)) query = query.range(startIndex, endIndex);

  return query;
};

/**
 * Checks if a collection exists in the database
 * @param collectionId The id of the collection to check
 * @param param1 The token of the user checking the collection
 * @returns Whether the collection exists or an error
 */
export const collectionExists = async (collectionId = "crm", { token }) => {
  const { id } = await getTokenInfo(token);

  const { error } = await supabase
    .from(`${id}-${collectionId}`)
    .select("id")
    .range(0, 0);

  return !Boolean(error); // if there is an error, the collection does not exist, so return false, otherwise return true
};

/**
 * Gets all contacts from the database
 * @param param0 The start and end index of the contacts to get
 * @returns The contacts or an error
 */
export const getContacts = async ({ startIndex, endIndex, filter, token }) => {
  let userId;
  if (token == "bypass") userId = "clkx7ocb68nna08117p0x7hcq";
  else {
    const { id } = await getTokenInfo(token);
    userId = id;
  }

  console.log("the filter", filter);

  const collectionId = `${userId}-crm`; // table name

  if (!Boolean(keys(filter).length)) {
    // no filter settings

    let { data, error } = await supabase
      .from(collectionId)
      .select("*")
      // .like("hiddenTags", "%cloolb4ckfhdp0811d9sku0jw%")
      .range(startIndex, endIndex);

    if (error) {
      console.log("error", error);
      return false; // { error };
    } else {
      return data;
    }
  } else {
    // console.log("outside", { startIndex, endIndex });

    let query = convertFilterToQuery({
      filter,
      collectionId,
      startIndex,
      endIndex,
    });
    // console.log("query with a filter", query)
    let { data, error } = await query;

    if (error) {
      console.log("error", error);
      return false; // { error };
    } else {
      return data;
    }
  }
};

/**
 * Gets the number of contacts in the database
 * @param The token of the user requesting the contacts
 * @returns The number of contacts or an error
 */
export const getContactsCount = async ({
  token,
  filter,
  // startIndex,
  // endIndex,
}) => {
  // const userId = "clkx7ocb68nna08117p0x7hcq";
  const { id } = await getTokenInfo(token);
  const userId = id;

  const collectionId = `${userId}-crm`; // table name

  let query = convertFilterToQuery({
    filter,
    collectionId,
    selectOptions: { count: "exact" },
    //   startIndex,
    //   endIndex,
  });

  // query = query.select("*", { count: "exact" });

  let { count, error } = await query;

  // const { count } = await supabase
  //   .from(collectionId)
  //   .select("*", { count: "exact" });
  // // .like("hiddenTags", "%cloolb4ckfhdp0811d9sku0jw%")

  if (error) {
    console.log("error", error);
    return false; // { error };
  } else {
    return count;
  }
};

/**
 * Gets a contact from the database
 * @param id The id of the contact to get
 * @param param1 The token of the user requesting the contact
 * @returns The contact or an error
 */
export const getContact = async (id, { token }) => {
  // const userId = "clkx7ocb68nna08117p0x7hcq";
  const user = await getTokenInfo(token);
  const userId = user.id;

  const collectionId = `${userId}-crm`; // table name

  let { data, error } = await supabase
    .from(collectionId)
    .select("*")
    .eq("id", id)
    .single();
  if (error) return false; // { error };
  return data;
};

/**
 * Creates a contact in the database
 * @param contact The contact to create
 * @param param1 The token of the user creating the contact
 * @returns The contact or an error
 */
export const createContact = async (contact, { token }) => {
  // const userId = "clkx7ocb68nna08117p0x7hcq";
  const { id } = await getTokenInfo(token);
  const userId = id;

  const collectionId = `${userId}-crm`; // table name

  const { data, error } = await supabase
    .from(collectionId)
    .insert([contact])
    .select("*");

  if (error) {
    console.log({ error });
    return false;
  } // { error };
  return data[0];
};

/**
 * Updates a contact in the database
 * @param id The id of the contact to update
 * @param param1 The token of the user updating the contact
 * @returns The updated contact record or an error
 */
export const updateContact = async (id, contact, { token }) => {
  // const userId = "clkx7ocb68nna08117p0x7hcq";
  const user = await getTokenInfo(token);
  const userId = user.id;

  const collectionId = `${userId}-crm`; // table name

  const { data, error } = await supabase
    .from(collectionId)
    .update(contact)
    .eq("id", id);

  if (error) {
    // console.log("error", error);
    return false;
  } // { error };
  return data;
};

/**
 * Deletes a contact from the database
 * @param id The id of the contact to delete
 * @param param1 The token of the user deleting the contact
 * @returns The deleted contact or an error
 */
export const deleteContact = async (id, { token }) => {
  // const userId = "clkx7ocb68nna08117p0x7hcq";
  const user = await getTokenInfo(token);
  const userId = user.id;

  const collectionId = `${userId}-crm`; // table name

  let { data, error } = await supabase
    .from(collectionId)
    .delete()
    .match({ id });

  if (error) return false;
  else {
    console.log("data", data);
    return data;
  }
};

/**
 * Creates a new collection in the database
 * @param collectionId The id of the collection to create
 * @param param1 The token of the user creating the collection and the name of the schema to use
 * @returns The new collection or an error
 */
export const createCollection = async (
  collectionId,
  { token, useSchema = "default" }
) => {
  // call the collections api from the rest server
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${API_DOMAIN}/collections/create`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ collectionId, useSchema }),
      redirect: "follow",
    });

    const data = await response.json();
    console.log(data);

    return data;
  }
};
