import { startCase } from "lodash";
// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
// import theme from "@launchos/components/ui/theme";
// import { IComponentSettings, ComponentTypes } from "../../types";
// import { lorem } from "../../../misc/v2/blocks/weblayouts/utils";
// import { IconTypes } from "../Icon";
import { style } from "./style";

import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";
import { IconTypes } from "../Icon/types";
// import { lorem } from "@/Apps/Pages/utils";

const settings: IComponentSettings = {
  id: ComponentTypes.LIST,
  name: "Bullet Points",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "format_list_bulleted",
  },
  default: {
    type: ComponentTypes.LIST,
    icon: IconTypes.CheckCircleOutline,
    data: [
      {
        id: Math.random().toString(36).slice(2),
        // caption: startCase(lorem.generateWords(11)),
        caption: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do",
        style: style.LISTITEM.style,
        iconStyle: style.LISTITEM.iconStyle,
        contentStyle: style.LISTITEM.contentStyle,
      },
      {
        id: Math.random().toString(36).slice(2),
        // caption: startCase(lorem.generateWords(11)),
        caption: "Consectetur adipiscing elit ipsum dolor sit amet sed do eiusmod",
        style: style.LISTITEM.style,
        iconStyle: style.LISTITEM.iconStyle,
        contentStyle: style.LISTITEM.contentStyle,
      },
      {
        id: Math.random().toString(36).slice(2),
        // caption: startCase(lorem.generateWords(11)),
        caption: "Tempor incididunt ut labore et dolore magna aliqua ut enim",
        style: style.LISTITEM.style,
        iconStyle: style.LISTITEM.iconStyle,
        contentStyle: style.LISTITEM.contentStyle,
      },
    ],
    properties: {
      display: "inline-block",
      padding: 5,
    },
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "BulletBuilderSettings",
          sectionWrap: true,
          settings: {
            label: "Bullet Point Builder",
            icon: "construction",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.TYPOGRAPHY,
          settings: {
            label: "Typography",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ICONSELECTOR,
          sectionWrap: true,
          settings: {
            label: "Default Icon",
            icon: "insert_emoticon",
            containerStyle: { maxHeight: 500, overflowY: "scroll" },
          },
        },
        {
          // tab: "Basic",
          type: SectionTypes.BUTTONSLIDER,
          id: "customIconSize",
          sectionWrap: true,
          settings: {
            label: "Icon Size",
            icon: "aspect_ratio",
            text: "Icon Size",
            button1: { icon: "remove", value: "SHRINK" },
            button2: { icon: "add", value: "GROW" },
            minValue: 10,
            maxValue: 250,
            value: 42,
            showAutoCheck: false,
          },
        },
        {
          // tab: "Basic",
          type: SectionTypes.BUTTONSLIDER,
          id: "customIconPosition",
          sectionWrap: false,
          settings: {
            label: "Icon Position",
            icon: "aspect_ratio",
            text: "Icon Position",
            button1: { icon: "remove", value: "SHRINK" },
            button2: { icon: "add", value: "GROW" },
            minValue: -100,
            maxValue: 100,
            value: 0,
            showAutoCheck: false,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.SPACING,
          sectionWrap: true,
          settings: {
            label: "Bullet Point Spacing",
            icon: "height",
            minValue: -100,
            maxValue: 100,
            value: 0,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
