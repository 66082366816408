import * as React from "react";
import _, { get } from "lodash";

// import EditorObject from "@/Apps/Pages/EditorObject";
// import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
// import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

// import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import EditorObject from "@/Apps/Pages/EditorObject";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

import { Button } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import buttonSettings from "./settings";
// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
// import { IconTypes } from "../Icon";

import { ColorPickerSection } from "./ColorPickerSection";
import { CaptionSection } from "./CaptionSection";
import { IconTypes } from "../Icon/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { Caption } from "@/Apps/Pages/Properties";

export const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { properties } = buttonSettings;
  const { sections } = properties.main;

  const withIcon = updateSection(sections, SectionTypes.ICONSELECTOR, {
    onChange: (e) => {
      console.log(e);
      updateComponentSettings(
        settings.id,
        { ...settings, icon: IconTypes[e] },
        true,
        0
      );
    },
  });

  const withCaption = updateSection(withIcon, "custom_caption", {
    component: <CaptionSection {...props} />,
  });

  const withColorPicker = updateSection(withCaption, "custom_color_picker", {
    component: <ColorPickerSection {...props} />,
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withColorPicker },
  };

  return (
    <PropertiesBuilder
      title="Button Settings"
      data={updatedProperties}
      {...props}
      children={null}
    />
  );
};

export const SimpleButtonProperties = (props) => {
  const { updateComponentSettings, settings } = props;
  
  return (
    <div className="px-3">
      <h3 className="text-sm font-medium">{settings?.prompt}</h3>
      {/* <CaptionSection {...props} /> */}
      <Caption
        text="Line 1 Text"
        value={get(settings, "caption1", "")}
        placeholder="Enter a label for the first line of the button"
        onKeyUp={(caption1) => {
          updateComponentSettings(
            settings.id,
            {
              ...settings,
              caption1,
            },
            false,
            true
          );
        }}
        onChange={(caption1) => {
          updateComponentSettings(settings.id, {
            ...settings,
            caption1,
          });
        }}
      />
    </div>
  );
}

/**
 * A button
 */
export const EditorButton: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings} showOverlay={true}>
      <EditorObject
        // showOverlay
        label="Button"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <Button {...settings} properties={properties} />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorButton;
