import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { Grid } from "@material-ui/core";

RichEditor
import { ContentFieldProps } from "../types";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { AttributeContainer } from "@/ui/CRUD/AttributeBuilder";
import { AttributeNames } from "@/ui/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps } from "../../LiveFormField/types";
import { LiveContentField } from "./LiveContentField";

// import RichEditor from "@launchos/modules/editor/Builder/Properties/RichEditor";
import RichEditor from "@/Apps/Pages/Properties/RichEditor";

const ContentField: React.FC<ContentFieldProps> = (props) => {
  const {
    children,
    mode = EditorMode.LIVE,
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    name,
    label,
    description,
    defaultValue,
    disabled,
    icon,
    columns = 12,
    attributeContainerDataSettings = {},
  } = props;
  /**
   * Deals with any changes that comes from the various attributes that are loaded
   *
   * • Converts to LiveFormFieldProps shape
   * • Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   * @params
   */
  const handleChange = (data: LiveFormFieldProps): void => {
    onChange(data);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;
  return (
    <div
      data-testid="FormBuilder-FormFields-ContentField"
      style={containerStyle}
    >
      {mode === EditorMode.LIVE && (
        <LiveContentField children={children} defaultValue={defaultValue}></LiveContentField>
      )}
      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-ContentField-EDITOR">
          <AttributeContainer
            onChange={handleChange}
            attributes={props}
            data={[
              // { id: "label", attribute: AttributeNames.LABEL, settings: {} },
              // {
              //   id: "description",
              //   attribute: AttributeNames.DESCRIPTION,
              //   settings: {
              // hide: true,
              // ...attributeContainerDataSettings,

              // },
              // },
              {
                id: "initialvalue",
                attribute: AttributeNames.INITIALVALUE,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
            ]}
          >
            <RichEditor>{children || defaultValue}</RichEditor>
          </AttributeContainer>
        </div>
      )}
    </div>
  );
};

export default ContentField;
