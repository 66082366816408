import { useState } from "react";

import { ComponentTypes } from "@/Apps/Pages/Editor/types";

import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";
import { SwitchGroup } from "@/Apps/Pages/Properties/components/groups/SwitchGroup/SwitchGroup";
import SectionWrapper from "@/Apps/Pages/Properties/components/sections/SectionWrapper";
import {
  recursivelyRetrieveItem,
  updateComponentSettings,
} from "@/Apps/Pages/V2Editor/actions";

import { updateComponentStyle } from "@/Apps/Pages/V2Editor/actions";
import { SimpleTextProperties } from "@/Apps/Pages/WebComponents/v1/text/Text";
import { triggerHook } from "@/plugins/client";
import { HookTriggers, ComponentRenderHooks } from "@/plugins/types";

export const SectionGroup = ({
  section = {},
  content = [],
  defaultIsExpanded = false,
  setContent = () => {},
  updateEditorContent = () => {},
  updateEditorComponent = () => {},
  updateEditorComponentStyle = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleSectionDisplay = () => {
    const newSection = updateComponentStyle(content, section?.id, {
      display: isChecked ? "none" : "block",
    });

    const newContent = updateComponentSettings(content, section?.id, {
      ...newSection,
      properties: {
        ...newSection.properties,
        display: isChecked ? "none" : "block",
      },
    });

    console.log({ newContent });

    setContent(newContent); // <- not updating (see if it's reloading from db)

    setIsChecked(!isChecked);
  };

  const sectionChildren = recursivelyRetrieveItem(content, section?.id);

  return (
    <SectionWrapper
      label={`${section?.label} Section` || `SECTION ${index + 1}`}
      isExpanded={defaultIsExpanded}
    >
      <SwitchGroup
        label="Display this section"
        isChecked={isChecked}
        onClick={handleSectionDisplay}
        // onClick={() => setIsChecked(!isChecked)}
        // className="p-5"
      >
        <p>Show or hide this section.</p>
      </SwitchGroup>

      {/* display children */}
      {section?.properties?.display !== "none" && sectionChildren && (
        <div className="max-h-96 overflow-auto">
          {[...sectionChildren].map((itm: any, i: number) => {
            if (!itm?.prompt) return null;

            return (
              <div key={i}>
                {triggerHook(
                  HookTriggers.onComponentRender,
                  {
                    id: ComponentRenderHooks.SIMPLE_PROPERTIES,
                    type: itm?.type,
                  },
                  {
                    settings: itm,
                    getSettings: () => itm,
                    rSettings: itm,
                    updateComponentStyle: updateEditorComponentStyle,
                    updateContent: updateEditorContent,
                    updateComponentSettings: updateEditorComponent,
                    setMobileState: () => {},
                    getMobileState: () => MobileState.FULLSCREEN,
                    currentMobileState: MobileState.FULLSCREEN,
                  }
                )}
              </div>
            );
          })}
        </div>
      )}
    </SectionWrapper>
  );
};

export const SimpleProperties = ({
  campaignData = {},
  pageId,
  content = [],
  setCampaignData = () => {},
  setIsLoading = () => {},
  setContent = () => {},
  updateEditorContent = () => {},
  updateEditorComponent = () => {},
  updateEditorComponentStyle = () => {},
}) => {
  return (
    <div className="w-full h-full divide-y divide-black/10 dark:divide-white/10 overflow-y-auto">
      <SwitchGroup
        label="Enable this page"
        isChecked={true}
        // onClick={() => setIsChecked(!isChecked)}
        className="p-5"
      >
        <p>Display this page or hide it and redirect to your website.</p>
      </SwitchGroup>
      {content
        ?.filter((itm) => itm?.type === ComponentTypes.SECTION)
        .map((section: any, index) => (
          <SectionGroup
            key={index}
            section={section}
            content={content}
            // defaultIsExpanded={index === 0}
            setContent={setContent}
            updateEditorContent={updateEditorContent}
            updateEditorComponent={updateEditorComponent}
            updateEditorComponentStyle={updateEditorComponentStyle}
          />
        ))}

      {/* Render Widgets */}
      {/* {widgets.map((widget, key) => {
            return (
              <span key={key} className="">
                {renderWidget(widget, {
                  campaignId: campaignData?.id,
                  pageId: campaignData?.objects[0].page?.id,
                  updateContent: console.log,
                  updateComponent: () => {},
                })}
              </span>
            );
          })} */}
    </div>
  );
};
