import * as React from "react";
import EditorObject from "@/Apps/Pages/EditorObject";

import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { PluginProps } from "./types";
import { List } from "./live";
import Properties from "./Properties";

/**
 * Ability to create bulleted list
 */
export const EditorList: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings} showOverlay={true}>
      <EditorObject
        label="Bullet Points"
        id={id}
        {...props}
        PropertiesView={Properties}
      // dropDownItems={items => {
      //   const key = items.findIndex(({ id }) => id === "PROPERTIES");
      //   return [
      //     ...items.slice(0, key),
      //     { id: "ADD", text: "Add a Bullet Point", icon: "add_circle" },
      //     ...items.slice(key),
      //   ]
      // }}
      // onDropDownChange={id => {
      //   if (id === "ADD") {
      //     alert("Adding New Bullet point")
      //   }
      // }}
      >
        <List {...settings} style={properties} />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorList;
