import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { SocialProofLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.TWELVE,
  layout: SocialProofLayouts.TWELVE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof12.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkysae0n2d0l0780y3gavfbw",
    itemId: "m20nu3oamws",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((9 * 100) / 12),
            properties: { height: "100%", padding: 30, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                },
                html: "Say Hello &amp; Share Your Insights",
              },
              {
                type: ComponentTypes.FACEBOOKCOMMENTS,
                canHaveChildren: false,
                properties: {
                  marginTop: 50,
                },
                href: "http://facebook.com",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((3 * 100) / 12),
            properties: { height: "100%", padding: 30, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  marginBottom: 15,
                },
                playIconStyle: { fontSize: "28pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: startCase(lorem.generateWords(3)),
              },
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  marginBottom: 15,
                },
                playIconStyle: { fontSize: "28pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: startCase(lorem.generateWords(3)),
              },
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  marginBottom: 15,
                },
                playIconStyle: { fontSize: "28pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: startCase(lorem.generateWords(3)),
              },
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  marginBottom: 15,
                },
                playIconStyle: { fontSize: "28pt" },
              }),
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: startCase(lorem.generateWords(3)),
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
