import { useContext, useEffect, useState } from "react";
import CodeMirror from "react-codemirror";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { getPage, updatePage } from "@/api/pages";
import { FileJs } from "@phosphor-icons/react";
import { TabsComponent } from "@/ui/TabsComponent";
import { AppContext } from "@/AppContext";
import { getSessionToken } from "@/api/auth";

import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import { Loading } from "@/ui/Layout/Loading";
import { ComponentRenderHooks, HookTriggers } from "@/plugins/types";
import { triggerHook } from "@/plugins/client";

export const PageTrackingCodesModal = ({ setIsOpen, pageId }) => {
  const [activeTab, setActiveTab] = useState("head");
  const { darkMode } = useContext(AppContext);
  const [variables, setVariables] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlePageTrackingCodesSave = async () => {
    // call the api to save the page tracking codes
    const token = getSessionToken();

    // let sT = {};
    // if (variables?.head) {
    //   sT = { ...sT, head: variables?.head };
    // }
    // if (variables?.body) {
    //   sT = { ...sT, body: variables?.body };
    // }
    // if (variables?.footer) {
    //   sT = { ...sT, footer: variables?.footer };
    // }

    // const scriptTags = JSON.stringify(sT);
    const page = await getPage(pageId, { token });
    const { pageSettings } = page?.data

    const settings = {
      pageSettings: {
        ...pageSettings,
        tracking: variables,
      }
    };

    const response = await updatePage(pageId, { token, settings });

    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Page Tracking Codes Saved");
        setIsOpen(false);
        console.log(response);
      }
    }
  };

  // load in the page tracking codes from the api if there are any
  const loadPageTrackingCodes = async () => {
    setIsLoading(true);
    const token = getSessionToken();
    const response = await getPage(pageId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        console.log("Page Tracking Codes", response);
        const tracking = response?.data?.pageSettings?.tracking;
        if (tracking) {
          setVariables(tracking);
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPageTrackingCodes();
  }, []);

  return (
    <Modal
      title="Page Tracking Codes"
      onClose={() => setIsOpen(false)}
      onSuccess={handlePageTrackingCodesSave}
      icon={<FileJs />}
      size={PopupSizes.LARGE}
    >
      <Title>
        Enter any custom scripts you want placed on this page
        {triggerHook(HookTriggers.onComponentRender, {
          id: ComponentRenderHooks.DASHBOARD_PAGE_TITLE,
          type: "Page Tracking",
        })}
      </Title>

      <TabsComponent
        tabs={[
          { label: "Head", value: "head" },
          { label: "Body", value: "body" },
          { label: "Footer", value: "footer" },
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        actionSection={false}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="grid border border-black/10 dark:border-white/5">
          {activeTab === "head" && (
            <CodeMirror
              className="cdmirror"
              options={{
                mode: "htmlmixed",
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={
                variables?.head ||
                '<script type="text/javascript">\n\n\t// Replace with your head code\n\n</script>'
              }
              onChange={(editor) => {
                setVariables({ ...variables, head: editor });
              }}
              autoFocus
            />
          )}
          {activeTab === "body" && (
            <CodeMirror
              className="cdmirror"
              options={{
                mode: "htmlmixed",
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={
                variables?.body ||
                '<script type="text/javascript">\n\n\t// Replace with your body code\n\n</script>'
              }
              onChange={(editor) => {
                setVariables({ ...variables, body: editor });
              }}
              autoFocus
            />
          )}
          {activeTab === "footer" && (
            <CodeMirror
              className="cdmirror"
              options={{
                mode: "htmlmixed",
                lineNumbers: true,
                theme: darkMode ? "material-darker" : "default",
              }}
              value={
                variables?.footer ||
                '<script type="text/javascript">\n\n\t// Replace with your footer code\n\n</script>'
              }
              onChange={(editor) => {
                setVariables({ ...variables, footer: editor });
              }}
              autoFocus
            />
          )}
        </div>
      )}
    </Modal>
  );
};
