import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";

export const paymentElementSettings: IComponentSettings = {
  id: ComponentTypes.PAYMENT,
  name: "Payment Form",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 7,
    },
    icon: "shopping_cart",
  },
  category: "payment",
  default: {
    type: ComponentTypes.PAYMENT,
    properties: {
      display: "inline-block",
      width: "100%",
      textAlign: "center",
    },
  },
  properties: {
    main: {
      sections: [
        {
          type: SectionTypes.POSITION,
          sectionWrap: true,
          settings: {
            label: "Payment Element Position",
            icon: "swap_vert",
          },
        },
        {
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Payment Element Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export const addressElementSettings: IComponentSettings = {
  id: ComponentTypes.ADDRESS,
  name: "Address Form",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 7,
    },
    icon: "home",
  },
  category: "payment",
  default: {
    type: ComponentTypes.ADDRESS,
    properties: {
      display: "inline-block",
      width: "100%",
      textAlign: "center",
    },
  },
  properties: {
    main: {
      sections: [
        {
          type: SectionTypes.POSITION,
          sectionWrap: true,
          settings: {
            label: "Payment Element Position",
            icon: "swap_vert",
          },
        },
        {
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Payment Element Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default paymentElementSettings;
