import { useContext, useEffect, useState } from "react";
import CodeMirror from "react-codemirror";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { getPage, updatePage } from "@/api/pages";
import { FileCss } from "@phosphor-icons/react";
import { AppContext } from "@/AppContext";
import { getSessionToken } from "@/api/auth";

import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import { Loading } from "@/ui/Layout/Loading";

export const PageCSSCodesModal = ({ setIsOpen, pageId }) => {
  const { darkMode } = useContext(AppContext);
  const [cssCode, setCSSCode] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlePageCSSCodesSave = async () => {
    // call the api to save the page css codes
    const token = getSessionToken();

    const page = await getPage(pageId, { token });
    const { pageSettings } = page?.data

    const settings = {
      pageSettings: {
        ...pageSettings,
        css: cssCode,
      }
    };

    const response = await updatePage(pageId, { token, settings });

    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        setIsOpen(false);
        console.log(response);
      }
    }
  };

  // load in the page css codes from the api if there are any
  const loadPageCSSCodes = async () => {
    setIsLoading(true);
    const token = getSessionToken();
    const response = await getPage(pageId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        console.log("Page CSS Codes", response);
        const css = response?.data?.pageSettings?.css;
        if (css) setCSSCode(css);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPageCSSCodes();
  }, []);

  return (
    <Modal
      title="Page CSS Codes"
      onClose={() => setIsOpen(false)}
      onSuccess={handlePageCSSCodesSave}
      icon={<FileCss />}
      size={PopupSizes.LARGE}
    >
      <Title>Enter any CSS you want placed on this page</Title>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="grid border border-black/10 dark:border-white/5">
          <CodeMirror
            className="cdmirror"
            options={{
              mode: "css",
              lineNumbers: true,
              theme: darkMode ? "material-darker" : "default",
            }}
            value={String(cssCode)}
            onChange={(editor) => {
              setCSSCode(editor);
            }}
            autoFocus
          />
        </div>
      )}
    </Modal>
  );
};
