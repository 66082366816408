import * as React from "react";

import { EditorComponentProps, EditorMode } from "@/Apps/Pages/Editor/types";

import { LiveFormFieldProps } from "@/ui/CRUD/FormBuilder/LiveFormField/types";
import { ItemComponentTypes, ItemComponentProps } from "@/ui/CRUD/SortableList/types";

export enum OrderSummaryItemTypes {
  PRODUCT = "Product",
  SALESTAX = "Sales Tax",
  DISCOUNT = "Discount",
  SHIPPING = "Shipping",
  PROMOCODE = "Promo Code",
}

export enum OrderSummaryDisplayOptions {
  HEADER = "Header",
  PRODUCTS = "Products",
  SUBTOTAL = "Subtotal",
  TOTAL = "Total",
}

export interface OrderSummaryItemList extends LiveFormFieldProps {
  id: string;
  title?: string;
  caption?: string;
  value: string | number;
  itemType: OrderSummaryItemTypes;
  codes: PromoCodeItemType[];
}

export interface OrderSummaryProps {
  mode?: EditorMode;
  cart: OrderSummaryItemList[];
  showHeader?: Boolean;
  showCart?: Boolean;
  showSubtotal?: Boolean;
  showTotal?: Boolean;
  style?: any;
  cellStyle?: any;
  footerCellStyle?: any;
  type: OrderSummaryTypes;
}

export enum OrderSummaryTypes {
  STANDARD,
}

export interface OrderSummaryBuilderProps extends OrderSummaryProps {
  id: string;
  onChange: any;
  data?: OrderSummaryItemList[];
  properties?: React.CSSProperties;
  displayOptions: OrderSummaryDisplayOptions;
}

export interface PluginProps extends EditorComponentProps {
  settings: OrderSummaryBuilderProps;
}

export interface PropertiesProps extends EditorComponentProps {
  settings: OrderSummaryBuilderProps;
}

export type PromoCodeItemType = { id: string; code: string; discount: string };

export interface PromoCodeBuilderProps {
  data: PromoCodeItemType[];

  /**
   * Triggered when an item changes.
   * Returns an updated version of the data array.
   * It's up to the component provider to update the data prop
   */
  onChange?: (updatedData: ItemComponentTypes[]) => void;

  /**
   * Triggers when the edit button next to an item is clicked. Returns the data item being edited
   */
  onEdit?: (item: ItemComponentProps) => void;

  /**
   * Triggers when the [Add] button is clicked
   */
  onAdd?: () => void;

  /**
   * Triggers when the [Delete] button is clicked. Returns the data item to be deleted, as well as an updated version of the data array. It's up to the component provider to update the data prop
   */
  onDelete?: (
    item: ItemComponentProps,
    updatedData: ItemComponentProps[]
  ) => void;
}
