import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";
import { ContentDashboardPage, CreateContentPopup } from "./ContentDashboardPage";
import { CampaignBuilder } from "../Funnels";
import { handleContentSave } from "./ContentEditor";
import { AppContext } from "@/AppContext";
import { setContextForView } from "../AITools/utils";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "overview");
  const [contentPackage, setContentPackageData] = useState<{ name?: string }>({
    name: "",
  });
  const [isCreateContentPopupOpen, setIsCreateContentPopupOpen] =
     useState(false);
  const [activeObject, setActiveObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
 
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  // const [activePageId, setActivePageId] = useState(null);
  const contextObj = useContext(AppContext);

  const { id } = useParams();

  const handleOpenContentItem = (itemId) => {
    const item = contentPackage?.objects?.filter(({ id }) => id === itemId)[0];
    console.log("item id", itemId, "item", item);
    setActiveObject(item);
    setIsCreateContentPopupOpen(true);
  };

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setContentPackageData(data);
      setIsLoading(false);

      const { objects } = data;
      setContextForView({
        data: objects.map(({ page, type, __typename, width, height, connectTo, x, y, ...rest }) => rest),
        view: 'Content Dashboard'
      }, contextObj);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/content" },
      { label: contentPackage.name || "•••", id, href: `/content/${id}` },
    ],
    items: (
      <Button
        label="+ Create Content Item"
        // onClick={() => setIsCreateContentPopupOpen(true)}
        onClick={() => handleOpenContentItem(null)}
        type={ButtonTypes.OUTLINED}
        variant={Variants.INFO}
      />
    ),
  };

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
          unitName="Content Package"
        />
      )}
      {isCreateContentPopupOpen && (
        <CreateContentPopup
          setIsCreateContentPopupOpen={setIsCreateContentPopupOpen}
          activeObject={activeObject}
          unitName="Post"
          onChange={(newObject) => setActiveObject(newObject)}
          onSave={() => handleContentSave(activeObject, id)}
          campaignId={id}
        />
      )}
      <div className={`p-6 w-full h-full`}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="tabs flex flex-col">
              <TabsComponent
                tabs={[
                  { label: "Content", value: "overview" },
                  // { label: "Visual Content Builder", value: "builder" },
                  { label: "Settings", value: "settings" },
                ]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                actionSection={false}
              />
            </div>
            <div className="h-full">
              {activeTab === "overview" && (
                <ContentDashboardPage
                  campaignData={contentPackage}
                  unitName="post"
                  onOpenContentItem={handleOpenContentItem}
                  onNewContentItem={() => handleOpenContentItem(null)}
                />
              )}
              {activeTab === "builder" && (
                <div className="h-[calc(100vh-174px)]">
                  <CampaignBuilder
                    id={id}
                    campaignData={contentPackage}
                    direction="vertical"
                  />
                </div>
              )}
              {activeTab === "settings" && (
                <Settings
                  campaignData={contentPackage}
                  setCampaignData={setContentPackageData}
                  unitName="Content Package"
                  widgets={["name", "withAI", "planner", "delete"]}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Page>
  );
};
