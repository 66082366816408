import { CampaignTrackingCodesModal } from "@/Apps/Funnels/Popups/CampaignTrackingCodesModal";
import { PageCSSCodesModal } from "@/Apps/Pages/Popups/PageCSSCodesModal";
import { PageTrackingCodesModal } from "@/Apps/Pages/Popups/PageTrackingCodesModal";
import { Panel, PanelItem } from "@/ui/Panel";
import { useState } from "react";

export const ScriptsAndTracking = ({
  unitName = "Page",
  campaignData = {},
  objectData = {},
}) => {
  const [isCampaignTrackingPopupOpen, setIsCampaignTrackingPopupOpen] =
    useState(false);
  const [isPageTrackingPopupOpen, setIsPageTrackingPopupOpen] = useState(false);
  const [isPageCSSPopupOpen, setIsPageCSSPopupOpen] = useState(false);

  return (
    <>
      {Boolean(isCampaignTrackingPopupOpen) && (
        <CampaignTrackingCodesModal
          setIsOpen={setIsCampaignTrackingPopupOpen}
          campaignId={campaignData?.id}
        />
      )}

      {Boolean(isPageTrackingPopupOpen) && (
        <PageTrackingCodesModal
          setIsOpen={setIsPageTrackingPopupOpen}
          pageId={objectData?.page?.id}
        />
      )}

      {Boolean(isPageCSSPopupOpen) && (
        <PageCSSCodesModal
          setIsOpen={setIsPageCSSPopupOpen}
          pageId={objectData?.page?.id}
        />
      )}

      <Panel title="Scripts &amp; Tracking" withItems>
        <div className="grid grid-flow-row divide-y divide-black/10 dark:divide-white/10 gap-4">
          <PanelItem
            title={`${unitName} Tracking Codes`}
            description={`Add tracking codes for your ${unitName.toLowerCase()}.`}
            buttonLabel="Set Tracking Codes"
            onClick={() => {
              if (Boolean(Object.keys(objectData).length)) {
                setIsPageTrackingPopupOpen(true);
              } else {
                setIsCampaignTrackingPopupOpen(true);
              }
            }}
          />
          {/* Only Show CSS for Pages */}
          {Boolean(Object.keys(objectData).length) && (
            <PanelItem
              title={`${unitName} CSS`}
              description={`Add css code to your ${unitName.toLowerCase()}.`}
              buttonLabel="Set CSS Code"
              className="pt-4"
              onClick={() => setIsPageCSSPopupOpen(true)}
            />
          )}
        </div>
      </Panel>
    </>
  );
};
