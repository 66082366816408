import * as React from "react";
import _ from "lodash";

import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import { updateSection } from "@/Apps/Pages/Properties/components/generator";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

import { GoogleLoginComponent } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import buttonSettings from "./settings";

export const Properties = (props) => {
  const { updateComponentSettings, updateComponentStyle, settings } = props;
  const { properties } = buttonSettings;
  const { sections } = properties.main;

  const clientIdSection = updateSection(sections, "clientId", {
    value: _.get(settings, "clientId", ""),
    onChange: (clientId) => {
      updateComponentSettings(settings.id, { ...settings, clientId });
    },
  });

  const captionSection = updateSection(clientIdSection, "caption", {
    value: _.get(settings, "buttonSettings.caption1", ""),
    onChange: (caption1) => {
      updateComponentSettings(settings.id, {
        ...settings,
        buttonSettings: { ...settings.buttonSettings, caption1 },
      });
    },
  });

  const colorSection = updateSection(captionSection, SectionTypes.COLORPICKER, {
    color: _.get(settings, "properties.backgroundColor", ""),
    onChange: (color) => {
      updateComponentStyle(settings.id, { backgroundColor: color.hex });
    },
  });

  return (
    <PropertiesBuilder
      data={{
        ...properties,
        main: { ...properties.main, sections: colorSection },
      }}
      {...props}
    />
  );
};

/**
 * Google Login Component
 */

export const EditorGoogleLoginButton: React.FC<PluginProps> = (props) => {
  const { id, settings } = props;
  const properties = convertProperties(settings.properties);
  const {
    backgroundColor,
    color,
    fontSize,
    fontFamily,
    padding,
  } = settings.properties;
  const buttonSettings = {
    ...settings.buttonSettings,
    style: {
      ...properties,
      backgroundColor,
    },
    caption1style: {
      color,
      fontSize,
      fontFamily,
      padding,
    },
  };

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Google Login"
        id={id}
        {...props}
        PropertiesView={Properties}
        showOverlay
      >
        <GoogleLoginComponent
          {...settings}
          properties={properties}
          buttonSettings={buttonSettings}
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorGoogleLoginButton;
