import * as React from "react";
import { get } from "lodash";
// import ReactJson from "react-json-view";
import { PropertiesProps } from "../types";

import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

import formFieldSettings from "../settings";
import { EditFormField } from "@/ui/CRUD/FormBuilder";
// import { FormFieldTypes } from "@launchos/modules/v2/CRUD/FormBuilder/types";
// import EditFormField from "@launchos/modules/v2/CRUD/FormBuilder/EditFormField/EditFormField";

/**
 * The property window that shows for the form input field
 */
export const Properties: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    const { updateComponentSettings } = props;
    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings, true, false);
  };

  /**
   * Triggers when the <EditFormField /> component showing in the property window changes
   * @params
   */
  const handleFormFieldChange = (): void => { };

  /**
   * Triggers when the properties in the Field Size section changes
   * @params
   */
  const handleFieldSizeChange = (): void => { };

  /**
   * Triggers when the properties in the position padding section changes
   * @params
   */
  const handleFieldPositionPaddingChange = (): void => { };

  /**
   * Triggers when the properties in the borders/shadow section changes
   * @params
   */
  const handleBorderShadowChange = (): void => { };

  /**
   * Triggers when the properties in the font/typography section changes
   * @params
   */
  const handleFontChange = (): void => { };

  const { properties } = formFieldSettings;
  const { sections } = properties.main;
  const { fieldType, preset } = settings;
  const attributes = get(settings, "attributes", {});

  const withFieldSettings = updateSection(sections, "field_settings", {
    label: get(settings, "fieldType", false)
      ? `${fieldType} Field Settings`
      : "Field Settings",
    component: (
      <div>
        <EditFormField
          id="Properties"
          type={fieldType || false}
          preset={preset || false}
          fieldData={attributes}
          onBlur={(attr) => {
            console.log("blur", { attr });
            handleSettingsChange(settings.id, {
              ...settings,
              attributes: attr,
            });
          }}
          onChange={(attr) => {
            // console.log("change", { attr });
            handleSettingsChange(
              settings.id,
              {
                ...settings,
                attributes: attr,
              },
              false
            );
          }}
          onTypeChange={(type, preset, attr = {}) => {
            handleSettingsChange(settings.id, {
              ...settings,
              fieldType: type,
              preset,
              attributes: attr
                ? {
                  ...attributes,
                  ...attr,
                  items: get(attr, "items", []), // clear items when type is switched
                }
                : attributes,
            });
          }}
          isExpanded={fieldType ? true : false}
          showFieldset
        />
        {/* <fieldset style={{ border: "1px solid #DDD", margin: "20px 0" }}>
          <legend>Settings</legend>
          <ReactJson src={settings} />
        </fieldset> */}
      </div>
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withFieldSettings },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export default Properties;
