import React from "react";
import { ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import EditorObject from "../EditorObject";
import Cursor from "@/Apps/Pages/WebComponents/v1/cursor/Cursor";

const Body = ({ style = {}, mode = EditorMode.LIVE, children, id }) => <div data-testid={`WC-BODY-${mode === EditorMode.LIVE ? 'LIVE' : 'EDIT'}`} style={style}><EditorObject id={id} title="Body">{children}</EditorObject></div>;
const Container = ({ style = {}, mode = EditorMode.LIVE, children, id }) => <div data-testid={`WC-CONTAINER-${mode === EditorMode.LIVE ? 'LIVE' : 'EDIT'}`} style={style}><EditorObject id={id} title="Container">{children}</EditorObject></div>;
const Text = ({ style = {}, mode = EditorMode.LIVE, id }) => <div data-testid={`WC-TEXT-${mode === EditorMode.LIVE ? 'LIVE' : 'EDIT'}`} style={style}><EditorObject id={id} title="Text">Text</EditorObject></div>;
const Headline = ({ style = {}, mode = EditorMode.LIVE, id }) => <div data-testid={`WC-HEADLINE-${mode === EditorMode.LIVE ? 'LIVE' : 'EDIT'}`} style={style}><EditorObject id={id} title="Headline">HEADLINE</EditorObject></div>;

export const componentMap = {
  [ComponentTypes.BODY]: Body,
  [ComponentTypes.TEXT]: Text,
  [ComponentTypes.HEADLINE]: Headline,
  [ComponentTypes.CONTAINER]: Container,
  [ComponentTypes.CURSOR]: Cursor
};

interface LoadComponentProps {
  /**
   * enum(LIVE,EDITOR)
   */
  mode?: EditorMode;

  /**
   * The name of the component that should be loaded
   */
  type: ComponentTypes;

  /**
   * The settings to pass along to the component
   */
  settings?: any;

  /**
   * Mobile responsive settings
   */
  rSettings?: object;

  /**
   * Whether or not the component is currently being dragged
   */
  // isDragInProgress?: Boolean;

  children?: React.ReactNode;
}

/**
 * (Formerly WebComponent) The react component used to interpret each item in the editor.
 */
export const LoadComponent: React.FC<LoadComponentProps> = ({
  mode = EditorMode.LIVE,
  // isDragInProgress = false,
  type,
  settings = {},
  children,
}) => {

  const Component = componentMap[type];

  if (!Component) {
    console.error(`No component found for type: ${type}`);
    return null; // or return a placeholder component
  }

  return <Component {...settings} mode={mode}>{children}</Component>;

};

export default LoadComponent;
