import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { BenefitLayouts } from "../types";
import { ListItemTypes } from "@/Apps/Pages/WebComponents/v2/ListItem/types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.FIFTEEN,
  layout: BenefitLayouts.FIFTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit15.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx5he5q231g07808cui36sy",
    itemId: "a56w825im2s",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 30,
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: style.LIST,
                    children: [
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.Chat,
                        iconStyle: style.LI.iconStyle,
                        contentStyle: { padding: 10 },
                        iconType: ListItemTypes.BLOCK,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.Email,
                        iconStyle: style.LI.iconStyle,
                        contentStyle: { padding: 10 },
                        iconType: ListItemTypes.BLOCK,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 30,
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.MOBILE01,
                    style: {
                      // position: "relative",
                      // top: "50%",
                      // transform: "translateY(-50%)",
                      margin: "0 auto",
                    },
                  }),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
