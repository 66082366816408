import { parseValue } from "@/Apps/Pages/Page/scripts";
import _ from "lodash";

import LazyLoad from "react-lazyload";


export const PictureContent = props => {
  const { mode, settings, show } = props;
  const style = {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%"
  }

  // need to consider when livesite renders it
  const pic = (
    // mode === EditorMode.EDITOR || show === "preview") ? (
    // <img src={settings.src} style={style} />
    // ) : (
    <LazyLoad>
      <img
        alt=""
        src={parseValue(settings.src)}
        placeholder="blurred"
        style={style}
      />
    </LazyLoad>
  );

  const hasClickAction = _.get(settings, 'actions', []).findIndex(itm => itm.behavior === "click") > -1

  return (
    <span style={{ ...settings.properties, marginTop: 0, padding: 0, cursor: hasClickAction ? 'pointer' : 'inherit' }}>
      {_.has(settings, "href") &&
        settings.href &&
        !_.get(props, "ignoreHref", false) ? (
        <a style={{ cursor: "pointer" }} href={settings.href}>
          {pic}
        </a>
      ) : (
        pic
      )}
    </span>
  );
};

const Picture = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const padding = settings.properties ? settings.properties.padding : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";
  return (
    <div style={{ marginTop, textAlign }}>
      <div style={{ padding }}>
        <PictureContent {...props} />
      </div>
    </div>
  );
};

export default Picture;
