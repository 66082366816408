import { range, get, startCase } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
// import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

import { ClipPathTypes, getClipPath } from "@/Apps/Pages/utils";



import { SocialProofLayouts } from "../types";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.FOURTEEN,
  layout: SocialProofLayouts.FOURTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof14.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkysae0n2d0l0780y3gavfbw",
    itemId: "403hkwf6o5q",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: startCase(lorem.generateWords(4)),
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: lorem.generateParagraphs(1),
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 20,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.ICON,
                canHaveChildren: false,
                properties: style.ICON,
                iconType: IconTypes.FormatQuote,
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                  fontSize: "10pt",
                  lineHeight: "13pt",
                },
                html: `"Hands down the best process I've seen in the last 11 years. Thank you!! I really value your approach"`,
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  // margin: "0 auto",
                  marginTop: 20,
                  width: 50,
                  // height: 50,
                  textAlign: "center",
                  display: "inline-block",
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src:
                      "https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                    style: {
                      display: "block",
                      clipPath: getClipPath({ type: ClipPathTypes.CIRCLE }),
                    },
                  }),
                ],
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `- ${startCase(lorem.generateWords(2))}`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.ICON,
                canHaveChildren: false,
                properties: style.ICON,
                iconType: IconTypes.FormatQuote,
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                  fontSize: "10pt",
                  lineHeight: "13pt",
                },
                html: `"Hands down the best process I've seen in the last 11 years. Thank you!! I really value your approach"`,
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  // margin: "0 auto",
                  marginTop: 20,
                  width: 50,
                  // height: 50,
                  textAlign: "center",
                  display: "inline-block",
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src:
                      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                    style: {
                      display: "block",
                      clipPath: getClipPath({ type: ClipPathTypes.CIRCLE }),
                    },
                  }),
                ],
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `- ${startCase(lorem.generateWords(2))}`,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 25, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.ICON,
                canHaveChildren: false,
                properties: style.ICON,
                iconType: IconTypes.FormatQuote,
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                  fontSize: "10pt",
                  lineHeight: "13pt",
                },
                html: `"Hands down the best process I've seen in the last 11 years. Thank you!! I really value your approach"`,
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  // margin: "0 auto",
                  marginTop: 20,
                  width: 50,
                  // height: 50,
                  textAlign: "center",
                  display: "inline-block",
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src:
                      "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
                    style: {
                      display: "block",
                      clipPath: getClipPath({ type: ClipPathTypes.CIRCLE }),
                    },
                  }),
                ],
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: `- ${startCase(lorem.generateWords(2))}`,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
