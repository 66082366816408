import {
  CampaignSettingsModal,
  PaymentSettingsModal,
} from "@/Apps/Funnels/Popups/CampaignSettingsModal";
import { CampaignTrackingCodesModal } from "@/Apps/Funnels/Popups/CampaignTrackingCodesModal";
import { Panel, PanelItem } from "@/ui/Panel";
import { useState } from "react";

const shouldPaymentSettingsShow = (campaignData) => {
  // Is there just one page in the funnel (if > 1, then it's a funnel)
  const isSinglePage = campaignData?.objects?.length === 1;

  if (!isSinglePage) return true;

  // get the contents of the page
  const content = campaignData?.objects?.[0].page.content;

  // is there a credit card form on the page?
  const showMerchantSettings =
    content.findIndex((itm) => itm.name === "cardNumber") > -1 ||
    content.find(itm => itm?.attributes?.name === "cardNumber") ||
    content
      .filter((itm) => itm.hasOwnProperty("actions"))
      .findIndex(
        (i) =>
          i.actions.findIndex(
            (o) => o.type === "TRIGGER_UPSELL" || o.type === "TRIGGER_BOLTPAY"
          ) > -1
      ) > -1;

  return showMerchantSettings;
};

export const IntegrationSettings = ({
  unitName,
  campaignData = {},
  objectData = {},
  // setIsCampaignTrackingPopupOpen = () => null,
}) => {
  const [isCampaignTrackingPopupOpen, setIsCampaignTrackingPopupOpen] =
    useState(false);

  const [isCampaignSettingsPopupOpen, setIsCampaignSettingsPopupOpen] =
    useState(false);
  const [isPaymentSettingsPopupOpen, setIsPaymentSettingsPopupOpen] =
    useState(false);

  const showMerchantSettings = shouldPaymentSettingsShow(campaignData);

  return (
    <>
      {Boolean(isCampaignTrackingPopupOpen) && (
        <CampaignTrackingCodesModal
          setIsOpen={setIsCampaignTrackingPopupOpen}
          campaignId={campaignData?.id}
        />
      )}
      {Boolean(isCampaignSettingsPopupOpen) && (
        <CampaignSettingsModal
          setIsOpen={setIsCampaignSettingsPopupOpen}
          // campaignId={campaignData?.id}
          objectData={objectData}
        />
      )}
      {Boolean(isPaymentSettingsPopupOpen) && (
        <PaymentSettingsModal
          setIsOpen={setIsPaymentSettingsPopupOpen}
          // campaignId={campaignData?.id}
          objectData={objectData}
        />
      )}

      <Panel title={`${unitName} Integrations`} withItems>
        <div className="grid grid-flow-row divide-y divide-black/10 dark:divide-white/10 gap-4">
          <PanelItem
            title="Campaign Settings"
            description={`Choose the email provider to send new leads that come through this ${unitName.toLowerCase()}.`}
            buttonLabel="Set Campaign Settings"
            className="pt-4"
            onClick={() => setIsCampaignSettingsPopupOpen(true)}
          />
          <PanelItem
            title="Payment Settings"
            description={`Choose the merchant provider to send payment details that come through this ${unitName.toLowerCase()}.`}
            buttonLabel="Set Payment Settings"
            className={`pt-4`}
            disabled={!Boolean(showMerchantSettings)}
            onClick={() => setIsPaymentSettingsPopupOpen(true)}
          />
        </div>
      </Panel>
    </>
  );
};
