import React, { useState, useContext } from "react";

import CodeMirror from "react-codemirror";
import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/htmlmixed/htmlmixed";


// import { Button } from "@launchos/components/ui";
import Button from "@/ui/_old/Button";

import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

import EditorObject from "@/Apps/Pages/EditorObject";
import { EditorMode } from "@/Apps/Pages/Editor/types";

import htmlSettings from "./settings";
import { HTMLProps, PluginProps } from "./types";
import { V1ObjectWrapper } from "../V1ObjectWrapper";
import Calout from "@/ui/Calout";
import { AppContext } from "@/AppContext";

export const Properties = (props) => {
  const [currentHTML, setCurrentHTML] = useState<string>(props?.settings?.html);
  
  const { darkMode } = useContext(AppContext);

  const { updateComponentSettings, settings } = props;
  const { html = "" } = settings;
  const { properties } = htmlSettings;
  const { sections } = properties.main;


  const withCode = updateSection(sections, "custom_html", {
    component: (
      <div className="px-8">
        <Calout title="Please enter the HTML code you want to see in this position." />
        <div className="border mt-4 rounded border-black/10 dark:border-white/10">        
          <CodeMirror
            // value={this.state.head}
            value={currentHTML}
            className="cdmirror"
            options={{
              mode: "htmlmixed",
              lineNumbers: true,
              theme: darkMode ? "material-darker" : "default",
            }}
            onChange={(editor, data, value) => {
              // this.handleChange(editor, "head", value);
              setCurrentHTML(editor);
              // console.log(value, data, editor);
            }}
            autoFocus
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            secondary
            large
            style={{ margin: 10 }}
            onClick={() => {
              updateComponentSettings(settings.id, {
                ...settings,
                html: currentHTML,
              });
              // props.hideProperties();
            }}
          >
            Save Settings
          </Button>
        </div>
      </div>
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withCode },
  };

  return (
    <PropertiesBuilder
      data={updatedProperties}
      {...props}
      title="HTML Settings"
      children={null}
    />
  );
};

export const HTML: React.FC<HTMLProps> = (props) => {
  const { html, style, settings, mode = EditorMode.EDITOR, id } = props;
  // const { id, mode, style, settings } = props;
  // const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings} showOverlay={true}>
      <EditorObject
        // showOverlay
        label="HTML"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <div data-testid="WC-HTML-EDIT" style={style}>
          <div
            style={{
              padding: 5,
              textAlign: "center",
              color: "#999",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            <pre>&lt;HTML Code/&gt;</pre>
          </div>
        </div>
      </EditorObject>
    </V1ObjectWrapper>
  );
};

// export const HTML: React.FC<HTMLProps> = (props) => {
//   const { html, style, mode = EditorMode.EDITOR, id } = props;
//   if (mode === EditorMode.EDITOR)
//     return (
//       <EditorObject id={id} title="HTML" {...props} PropertiesView={Properties}>
//         <div data-testid="WC-HTML-EDIT" style={style}>
//           <div
//             style={{
//               padding: 5,
//               textAlign: "center",
//               color: "#999",
//               fontSize: 22,
//               fontWeight: "bold",
//             }}
//           >
//             <pre>&lt;HTML Code/&gt;</pre>
//           </div>
//         </div>
//       </EditorObject>
//     );
// };

export default HTML;
