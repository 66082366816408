import { ComponentRenderHooks } from "@/plugins/types";
import Text from "./component";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onComponentRender = (hook, payload, actions) => {
  // console.log("loading plugin")
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    (payload.type === ComponentTypes.PARAGRAPH || payload.type === ComponentTypes.TEXT)
  ) {
    return [Text];
  }
};
