import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";

const settings: IComponentSettings = {
  id: ComponentTypes.FACEBOOKCOMMENTS,
  name: "Comments",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "question_answer",
  },
  category: "social",
  default: {
    type: ComponentTypes.FACEBOOKCOMMENTS,
    properties: {
      display: "inline-block",
      width: "100%",
      textAlign: "center",
      padding: 5,
    },
  },
  properties: {
    main: {
      sections: [
        {
          type: SectionTypes.CAPTION,
          sectionWrap: true,
          settings: {
            label: "App Id",
            icon: "share",
            text: "Your App Id",
            isExpanded: true,
            help: {
              text: "How to create a facebook app id",
              url: "https://support.launchos.com/hc/launch-os-help-center/articles/1717602875-how-to-create-a-facebook-app-id ",
            },
          },
        },
        {
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          type: SectionTypes.POSITION,
          sectionWrap: true,
          settings: {
            label: "Position",
            icon: "swap_vert",
          },
        },
        {
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
