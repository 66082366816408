import { ComponentRenderHooks, ListItemsHooks } from "@/plugins/types";
import Section, { Properties } from "./component";
import settings from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }

  // if (
  //   hook.id === ListItemsHooks.PROPERTIES &&
  //   hook.type === ComponentTypes.SECTION
  // ) {
  //   return settings.properties;
  // }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "Section"
  ) {
    return [Section];
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Section") {
    return Properties(payload);
  }
};
