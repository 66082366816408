import PureCanvas from "@/Apps/Pages/V1Editor/PureCanvas";

export const PageNode = ({
  id,
  data = { },
}) => {
  const { 
    handleEditorChange = () => {},
    handleEditorItemSelect = () => {},
    handleEditorSave = () => {},
    pageId = ""
  } = data;

  const { content = [] } = data;

  return (
    <div
      style={{
        width: parseInt(1200 / 1.0),
        height: parseInt(1200 / 0.8),
      }}
      className="rounded bg-white/60 dark:bg-black/60 shadow-sm overflow-hidden"
    >

      <div className="dragHandle p-3 px-6 my-3 text-lg dark:bg-white/5 bg-white rounded-md dark:text-white/70 text-black/40 font-bold">
        {data?.label}
      </div>
      <div className="w-full h-full border-4 border-transparent">
        <PureCanvas
          content={content}
          onChange={({ content = [] }) => {
            handleEditorChange(pageId, content);
          }}
          onItemSelect={(id, settings) => handleEditorItemSelect(pageId, settings)}
          onSave={(content) => handleEditorSave(pageId, content)}
          disableDefaultProperties
        >
          {() => <div></div>}
        </PureCanvas>
      </div>
    </div>
  );
};
