import { get } from "lodash";
import { ComponentRenderHooks, ListItemsHooks } from "@/plugins/types";

import {
  tmf_pricing01,
  tmf_header03,
  tmf_header02,
  tmf_header01,
  tmf_nav01,
  tmf_guarantee01,
  tmf_footer01,
  tmf_cta02,
  tmf_cta01,
  tmf_content02,
  tmf_content01,
  tmf_benefit04,
  tmf_benefit03,
  tmf_benefit02,
  tmf_benefit01,
  tmf_benefit05,
  tmf_socialproof01,
  tmf_header04,
  tmf_benefit06,
  tmf_content03,
  tmf_form01,
  tmf_header05,
  tmf_header06,
  tmf_socialproof02,
  tmf_socialproof03,
  tmf_socialproof04,
} from "../Personal/settings";

const user = JSON.parse(sessionStorage.getItem("user"));

export const onListItems = (hook, payload, actions) => {
  if (
    hook.id === ListItemsHooks.WEB_LAYOUT &&
    hook.type === "drawer" &&
    get(user, "permissions", []).indexOf("admin") > -1
  ) {
    return [
      tmf_nav01,
      tmf_header01,
      tmf_header02,
      tmf_header03,
      tmf_header04,
      tmf_header05,
      tmf_header06,
      tmf_form01,
      tmf_benefit01,
      tmf_benefit02,
      tmf_benefit03,
      tmf_benefit04,
      tmf_benefit05,
      tmf_benefit06,
      tmf_cta01,
      tmf_cta02,
      tmf_socialproof01,
      tmf_socialproof02,
      tmf_socialproof03,
      tmf_socialproof04,
      tmf_content01,
      tmf_content02,
      tmf_content03,
      tmf_pricing01,
      tmf_guarantee01,
      tmf_footer01,
    ];
  }
};
