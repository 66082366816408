import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ComponentRenderHooks, HookObject, ListItemsHooks } from "@/plugins/types";

import EditorPicture, { Properties, SimplePictureProperties } from "./Picture";
import settings from "./settings";
import imageSettings from "../../v2/Image/settings";

const { properties } = imageSettings;

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "Picture"
  ) {
    return [EditorPicture];
  }
  
  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.PICTURE
  ) {
    return Properties(payload);
  }

  if (
    hook.id === ComponentRenderHooks.SIMPLE_PROPERTIES &&
    hook.type === ComponentTypes.PICTURE
  ) {
    return SimplePictureProperties(payload);
  }
};

export const onListItems = (hook: HookObject) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }

  // if (
  //   hook.id === ListItemsHooks.PROPERTIES &&
  //   hook.type === ComponentTypes.PICTURE // || hook.type === ComponentTypes.IMAGE)
  // ) {
  //   return properties || {};
  // }
};
