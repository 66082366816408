import React from "react";
import { FieldData, TableViewProps } from "../types";
import Pagination from "@/ui/Pagination";
// import Panel from "@/ui/Panel";
import ListDropdown from "@/ui/ListDropdown";

export const TableView: React.FC<TableViewProps> = ({
  items = [],
  visibleFields = [],
  showFooterPagination = false,
  density = "standard",
  onPageChange,
  onItemClick = () => {},
  onCellClick = () => {},
  onCellDoubleClick = () => {},
  onMenuItemClick = () => {},
  // onNewItemClick = () => {},
  showMenuItems = true,
  menuItems = [],
  menuItemElement,
}) => {
  // Define the schema for the table, which includes the label, fieldType, and value for each column
  const schema = [
    { id: "label", label: "Name", fieldType: "string", value: items[0].label },
    ...items[0].data,
  ];

  // Determine which fields to show based on the visibleFields prop, or show all fields if visibleFields is not provided
  const showTheseFields = Boolean(visibleFields.length)
    ? schema
        .filter((field) => visibleFields.includes(field.id))
        .sort(
          (a, b) => visibleFields.indexOf(a.id) - visibleFields.indexOf(b.id)
        )
    : schema;

  return (
    <div data-testid="TableView" className="overflow-x-hidden h-full">
      {/* <Panel title="Table View"> */}
      {/* Table element */}
      <table className="table-hover">
        {/* Table header */}
        <thead>
          <tr>
            {/* Table header cells */}
            {showTheseFields.map((field, key) => (
              <th key={key}>{field.label}</th>
            ))}

            {/* Table menu item */}
            {Boolean(menuItems.length) && showMenuItems && (
              <th className="w-12"></th>
            )}
          </tr>
        </thead>

        {/* Table body */}
        <tbody>
          {/* Table rows */}
          {items.map((item, key) => {
            return (
              <tr
                key={key}
                data-testid="table-row"
                className={`group text-md border-b border-black/20 dark:border-white/5 cursor-pointer ${density}`}
                onClick={() => onItemClick(item.id)}
              >
                {/* Table cells */}
                {showTheseFields.map((field, key) => {
                  // Data for each row, with the label merged in as the first item
                  const dataRow: FieldData[] = [
                    {
                      id: "label",
                      label: "Name",
                      fieldType: "string",
                      icon: item.icon,
                      value: item.label,
                    },
                    ...item.data,
                  ];
                  // console.log({ key, dataRow });
                  // Render table cell
                  return (
                    <td
                      key={key}
                      onClick={() =>
                        onCellClick(
                          field.id,
                          dataRow.find((itm) => itm.id === field.id)
                        )
                      }
                      onDoubleClick={() =>
                        onCellDoubleClick(
                          field.id,
                          dataRow.find((itm) => itm.id === field.id)
                        )
                      }
                      className="whitespace-nowrap"
                    >
                      <div className="flex items-center gap-1 py-2">
                        {dataRow.find((itm) => itm.id === field.id).icon}
                        {`${dataRow.find((itm) => itm.id === field.id).value}`}
                      </div>
                    </td>
                  );
                })}

                {/* Table menu item */}
                {Boolean(menuItems.length) && showMenuItems && (
                  <td onClick={e => e.preventDefault()}>
                    <div className="p-1 text-transparent group-hover:text-black dark:group-hover:text-white">
                      {/* Render the item dropdown menu */}
                      <ListDropdown
                        items={menuItems}
                        element={menuItemElement}
                        // onItemSelect={(id) => onMenuItemClick(String(id))}
                        onItemSelect={({id}) => onMenuItemClick(id, item)}

                        // forId={id}
                      />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination element */}
      {showFooterPagination && (
        <div data-testid="table-pagination" className="mt-4">
          <Pagination
            currentPage={1}
            totalPages={items.length}
            onPageChange={onPageChange}
            circles
            fill="outline"
            rounded
          />
        </div>
      )}

      {/* Menu item element */}
      {/* {menuItemElement} */}
      {/* </Panel> */}
    </div>
  );
};
