import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";

const settings: IComponentSettings = {
  id: ComponentTypes.HTML,
  name: "HTML",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "code",
  },
  default: {
    type: ComponentTypes.HTML,
    html: "",
    style: {
      display: "inline-block",
      padding: 5,
    },
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "custom_html",
          // sectionWrap: false,
          settings: {
            // label: "HTML",
            // icon: "code",
            // isExpanded: true,
          },
        },
      ],
    },
  },
};

export default settings;
