import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
// import { SocialProofLayouts } from "../types";
import { SocialProofLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.ONE,
  layout: SocialProofLayouts.ONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkypng982by30780ld7yaetu",
    itemId: "u5j1r64m5rf",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: { textAlign: "center", margin: "0 auto", maxWidth: 600 },
        children: [
          {
            type: ComponentTypes.PICTURE,
            canHaveChildren: false,
            properties: {
              width: 150,
              display: "inline-block",
              textAlign: "center",
            },
            src:
              "https://logos-download.com/wp-content/uploads/2016/03/Airbnb_logo.png",
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              marginTop: 40,
              ...get(content, "main_headline.style", {}),
            },
            html: `"Hands down the best process I've seen in the last 11 years.  Thank you!!"`,
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
            },
            html: "Jacob McDonald, VP Marketing",
          },
        ],
      },
    ],
  },
};

export default settings;
