import * as React from "react";
import _ from "lodash";

// import { EditorMode } from "@/Apps/Pages/Editor/types";
// import LiveFormBuilder from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormBuilder";
// import FormBuilder from "@launchos/modules/v2/CRUD/FormBuilder/FormBuilder";

import { PluginProps } from "./types";

import { generateFormComponentStyle } from '../FormField/script'

import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import LiveFormBuilder from "./LiveFormBuilder";

/**
 * The version of the <FormBuilder /> component that shows in the editor canvas
 */
export const FormBuilderPlugin: React.FC<PluginProps> = ({ settings }) => {
  const { data } = settings;
  const properties = convertProperties(settings.properties);

  // return <div data-testid="WC-FORMBUILDER-LIVE"></div>
  return (
    <V1ObjectWrapper settings={settings}>
      {/* {JSON.stringify(settings)} */}
      <LiveFormBuilder
        data={data}
        style={generateFormComponentStyle(properties)}
        globalFieldStyle={generateFormComponentStyle(properties)}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORM
  ) {
    return [FormBuilderPlugin];
  }
};

export default LiveFormBuilder;
