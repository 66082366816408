import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.SIX,
  layout: HeaderLayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckktsa7qd1v1u078062fyqewd",
    itemId: "nd8cexbq9i8",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: style.CONTAINER,
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: get(content, "sub_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                      marginTop: 40,
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((8 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 5,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.FORMFIELD,
                            canHaveChildren: false,
                            properties: {},
                            styles: {
                              containerStyle: style.FORM_FIELD.style,
                              inputStyle: style.FORM_FIELD.inputStyle,
                            },
                            attributes: {
                              placeholder: "Enter Your Email",
                              name: "email",
                            },
                            fieldType: FormFieldTypes.TEXT,
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((4 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 5,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: style.BUTTON.style,
                            caption1: "Get Started",
                            caption1style: style.BUTTON.caption1,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: { marginTop: 70 },
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
