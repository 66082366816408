import { ComponentRenderHooks } from "@/plugins/types";
import List from "./component";
import settings from "./settings";
import Properties from "./Properties";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

import { SimpleListProperties } from "./Properties/Properties";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "List"
  ) {
    return [List];
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "List") {
    return Properties(payload);
  }

  if (
    hook.id === ComponentRenderHooks.SIMPLE_PROPERTIES &&
    hook.type === ComponentTypes.LIST
  ) {
    return SimpleListProperties(payload);
  }
};
