import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.TWO,
  layout: HeaderLayouts.TWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header02.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckktsa7qd1v1u078062fyqewd",
    itemId: "cm27nh87i7",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      getVideoPlaceholder({
        type: VideoPlaceholderTypes.NONE,
        style: style.VIDEO,
        thumbnail:
          "https://images.unsplash.com/photo-1515704089429-fd06e6668458?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
      }),
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: get(content, "main_headline.html", "..."),
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "sub_headline.style", {}),
        },
        html: get(content, "sub_headline.html", "..."),
      },
    ],
  },
};

export default settings;
