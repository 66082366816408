// import * as React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
// import { action } from "@storybook/addon-actions";
// import { withKnobs, text, boolean, select } from "@storybook/addon-knobs";

// import html2canvas from "html2canvas";

import AddSectionsTab from "./AddSectionsTab";
import { SectionPreview } from "./SectionPreview/SectionPreview";

// import { PageSectionCategory } from "@launchos/plugins/misc/v2/blocks/weblayouts/types";
import { PageSectionCategory } from "../WebLayouts/types";

// import { HeaderLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/types";
import { HeaderLayouts } from "../WebLayouts/Headers/types";

// import { CTALayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/CTA/types";
import { CTALayouts } from "../WebLayouts/CTA/types";

// import { BenefitLayouts } from "@launchos/plugins/misc/v2/blocks/weblayouts/Benefits/types";
import { BenefitLayouts } from "../WebLayouts/Benefits/types";

// import headerZeroSettings from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/00/settings";
// import headerOneSettings from "@launchos/plugins/misc/v2/blocks/weblayouts/Headers/01/settings";

export default {
  title: "Plugins/Page Sections/AddSectionsTab",
  component: AddSectionsTab,
  subcomponents: { SectionPreview },
  excludeStories: /.*Data$/,
};

export const propsData = {
  sections: [
    // headerZeroSettings,
    // headerOneSettings,
    {
      label: CTALayouts.TWO,
      layout: CTALayouts.TWO,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_2_sm.jpg",
    },
    {
      label: HeaderLayouts.THREE,
      layout: HeaderLayouts.THREE,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_3_sm.jpg",
    },
    {
      label: BenefitLayouts.THREE,
      layout: BenefitLayouts.THREE,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_4_sm.jpg",
    },
    {
      label: CTALayouts.FOUR,
      layout: CTALayouts.FOUR,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_5_sm.jpg",
    },
    {
      label: HeaderLayouts.FIVE,
      layout: HeaderLayouts.FIVE,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_6_sm.jpg",
    },
    {
      label: CTALayouts.SIX,
      layout: CTALayouts.SIX,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_7_sm.jpg",
    },
    {
      label: BenefitLayouts.SIX,
      layout: BenefitLayouts.SIX,
      previewImage:
        "https://designmodo.com/startup/app/i/blocks/header_8_sm.jpg",
    },
  ],
  categories: [
    {
      type: PageSectionCategory.HEADER,
      label: `${PageSectionCategory.HEADER}`,
      selected: false,
    },
    {
      type: PageSectionCategory.BENEFIT,
      label: `${PageSectionCategory.BENEFIT}`,
      selected: false,
    },
    {
      type: PageSectionCategory.CTA,
      label: `${PageSectionCategory.CTA}`,
      selected: false,
    },
    {
      type: PageSectionCategory.CONTENT,
      label: `${PageSectionCategory.CONTENT}`,
      selected: false,
    },
    {
      type: PageSectionCategory.PRICING,
      label: `${PageSectionCategory.PRICING}`,
      selected: false,
    },
    {
      type: PageSectionCategory.SOCIALPROOF,
      label: `${PageSectionCategory.SOCIALPROOF}`,
      selected: false,
    },
    {
      type: PageSectionCategory.FORM,
      label: `${PageSectionCategory.FORM}`,
      selected: false,
    },
    {
      type: PageSectionCategory.NAVIGATION,
      label: `${PageSectionCategory.NAVIGATION}`,
      selected: false,
    },
    {
      type: PageSectionCategory.FOOTER,
      label: `${PageSectionCategory.FOOTER}`,
      selected: false,
    },
    {
      type: PageSectionCategory.GUARANTEE,
      label: `${PageSectionCategory.GUARANTEE}`,
      selected: false,
    },
    // {
    //   type: PageSectionCategory.QUIZ,
    //   label: `${PageSectionCategory.QUIZ}`,
    //   selected: false,
    // },
    {
      type: PageSectionCategory.URGENCY,
      label: `${PageSectionCategory.URGENCY}`,
      selected: false,
    },
    {
      type: PageSectionCategory.PERSONAL,
      label: `${PageSectionCategory.PERSONAL}`,
      selected: false,
    },
  ],
};

export const Default: any = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          // width: 350,
          // backgroundColor: "#EEE",
          // border: "1px solid #CCC",
          // padding: 20,
        }}
      >
        <AddSectionsTab {...propsData} />
      </div>
    </DndProvider>
  );
};

Default.story = {
  parameters: {
    jest: ["AddSectionsTab"],
  },
};

export const justSectionPreview: any = () => (
  <DndProvider backend={HTML5Backend}>
    <SectionPreview
      label={HeaderLayouts.ONE}
      layout={HeaderLayouts.ONE}
      onAddSection={(layout) => console.log(layout)}
      previewImage="https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header07.png"
    />
  </DndProvider>
);

justSectionPreview.story = {
  parameters: {
    jest: ["SectionPreview"],
  },
};
