import * as React from "react";
import { get } from "lodash";

import { generateFormComponentStyle } from "./script";

import { PluginProps } from "./types";

import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import LiveFormField from "@/ui/CRUD/FormBuilder/LiveFormField";

// import LiveFormField from "@launchos/modules/v2/CRUD/FormBuilder/LiveFormField";
// const LiveFormField = () => <></>;


const FormFieldPlugin: React.FC<PluginProps> = ({ settings }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <LiveFormField
        {...settings}
        {...get(settings, "attributes", {})}
        type={settings.fieldType}
        styles={generateFormComponentStyle(properties)}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORMFIELD
  ) {
    return [FormFieldPlugin];
  }
};

export default LiveFormField;
