import { AppContext } from "@/AppContext";
import { getSessionToken } from "@/api/auth";
import { createConnection, handleCreateNewPageFromTemplate, updateCampaign } from "@/api/campaigns";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { isJsonString } from "@/utils";
import { Excalidraw, exportToBlob, WelcomeScreen, Footer } from "@excalidraw/excalidraw";
import { RocketLaunch, Sparkle } from "@phosphor-icons/react";
import { useChat, useCompletion } from "ai/react";
import { debounce, isArray } from "lodash";
import { useContext, useState } from "react";
import { getFunnelVisionPrompt } from "../AITools/ChatBot/prompts";
import { content, pages, traffic, workflow } from "../Funnels/objects";
import { Loading } from "@/ui/Layout/Loading";

export const createFunnelObjects = async (data, campaignData) => {
  const token = getSessionToken();
  if (!token) return;

  // const data = [
  //   { "id": "uuid1", "name": "Landing Page", "x": 0, "y": 0, "type": "PageComponent", "subType": "lead_page", "connectTo": ["uuid2"] },
  //   { "id": "uuid2", "name": "Sales Page", "x": 200, "y": 0, "type": "PageComponent", "subType": "sales_page", "connectTo": ["uuid3", "uuid4"] },
  //   { "id": "uuid3", "name": "Popup", "x": 400, "y": -100, "type": "PageComponent", "subType": "blank_page", "connectTo": [] },
  //   { "id": "uuid4", "name": "Checkout Page", "x": 400, "y": 100, "type": "PageComponent", "subType": "order_page", "connectTo": ["uuid5"] },
  //   { "id": "uuid5", "name": "Thank You Page", "x": 600, "y": 100, "type": "PageComponent", "subType": "thankyou_page", "connectTo": [] }
  // ];

  // console.log("funnelData", data);

  // loop through the funnelData and create the funnel objects
  let hash = []; // build a hash that maps the old obj id to the new obj id
  for (const element of data) {
    const { object, page } = await handleCreateNewPageFromTemplate({
      position: { x: element?.x, y: element?.y },
      campaignData,
      page: {
        ...pages.find(({ id }) => id === element?.subType),
        ...traffic.find(({ id }) => id === element?.subType),
        ...content.find(({ id }) => id === element?.subType),
        ...workflow.find(({ id }) => id === element?.subType),
        type: element?.type || "PageComponent",
        name: element?.name,
      },
    });

    // store a hash of the newly created ids (to map the old ids to the new ids when creating connections)
    hash[element?.id] = object?.id;
    console.log("object", object, "page", page);
  };

  // loop through the funnelData and create the funnel connections (if any)
  console.log("hash", hash);
  for (const element of data) {
    if (element?.connectTo.length > 0) {
      for (const connectTo of element?.connectTo) {
        const response = await createConnection({ source: hash[element?.id], target: hash[connectTo] });
        console.log("response", response);
      }
    }
  }

  
}

export const executeFunnelVision = async (
  { base64data, campaignData, setIsLoading },
  onSuccess = () => {}
) => {
  // console.log("executeFunnelVision", base64data);

  const msg2send = {
    role: "user",
    content: [
      {
        type: "text",
        text: getFunnelVisionPrompt(),
      },
      {
        type: "image",
        image: base64data,
      },
      // {
      //   type: "image_url",
      //   image_url: {
      //     url: base64data,
      //   },
      // },
    ],
  };

  const complete = async (prompt) => {
    setIsLoading(true);
    const r = await fetch(`${AI_DOMAIN}/api/completion`, {
      method: "post",
      body: JSON.stringify({ type: "vision", prompt }),
    });

    // const response = await r.json();
    // console.log("response", response);
    setIsLoading(false);
    return r;
  };

  let response = await complete(msg2send.content);
  let responseData;
  const responseText = await response.text();
  try {
    responseData = JSON.parse(responseText);
  } catch (error) {
    responseData = responseText;
  }
  const rText = responseText;
  const rJson = responseData;

  // While the response is not a JSON string, keep requesting.  Try it 3 times.
  // let i = 0;
  // while (
  //   i < 3 &&
  //   !isJsonString(response) &&
  //   response !== "This does not appear to be a funnel marketing sequence."
  // ) {
  //   response = await complete(msg2send.content);
  //   i++;
  // }

  // if (response === "This does not appear to be a funnel marketing sequence.") {
  //   alert(response);
  //   return;
  // }

  // if (!response) {
  //   alert("Something went wrong. Please try again.");
  //   return;
  // }

  if (rText.indexOf("This does not appear to be a funnel marketing sequence.") > -1) {
    alert(rText);
    return;
  }
  
  const c = confirm("Finished processing. Would you like to add the funnel to the campaign?");

  if (c) {
    // create the funnel
    const funnelData = rJson;
    console.log("The Funnel", funnelData);

    // append the funnel to the campaign
    const createFunnelResponse = await createFunnelObjects(funnelData, campaignData);
    console.log("Funnel has been created", createFunnelResponse);

    onSuccess();
  }
};

const ConvertToFunnelButton = ({ excalidrawAPI, campaignData }) => {

  const { complete, isLoading } = useCompletion({
    api: `${AI_DOMAIN}/api/completion`,
    body: { type: "vision" },
  });

  if (isLoading) return <div className="p-2 px-4"><Loading type="tiny" /></div>;

  return (
    <button
      type="button"
      value="Convert to Funnel"
      onClick={async () => {

        if (excalidrawAPI) {

          try {
            const blob = await exportToBlob({
              elements: excalidrawAPI.getSceneElements(),
              mimeType: "image/png",
              // files: [],
            });

            const base64data = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                resolve(reader.result);
              };
            });
            console.log("about to call funnel vision", base64data);

            executeFunnelVision({ base64data, campaignData, complete }, () => {
              // Switch Tab to Funnel Builder
              window.location.href = `/funnels/${campaignData?.id}#builder`;
              window.location.reload();
            });
            // createFunnelObjects([], campaignData);

            // console.log(response, messages, lastMessage?.content);
            // console.log(response);
          } catch (error) {
            console.error("Error exporting image", error);
          }

        }
      }}
      className="btn cursor-pointer ml-2"
    >
      <p className="flex gap-2" style={{ padding: 2 }}>
        <Sparkle className="relative top-[2px]" /> Convert to Funnel
      </p>
    </button>
  );

};

export const Sketchpad = ({ campaignData, setCampaignData, unitName = "Funnels" }) => {
  const { darkMode } = useContext(AppContext);
  const [allElements, setElements] = useState(campaignData?.settings?.sketchPadElements?.[0] || []); // [elements, setElements]
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);

  const saveSketchpadChange = debounce(async (elements) => {
    
    // if elements are same as previous, don't proceed (deep compare)
    // if (JSON.stringify(elements) === JSON.stringify(allElements)) return;
    // if (isEqual(elements, allElements)) return;

    // console.log("saveSketchpadChange", elements);
    // Perform your database save operation here
    const token = getSessionToken();
    if (!token) return;

    // setCampaignData({
    //   ...campaignData,
    //   settings: {
    //     ...campaignData?.settings,
    //     sketchPadElements: elements,
    //   },
    // });

    const response = await updateCampaign(campaignData?.id, {
      token,
      settings: {
        settings: {
          ...campaignData?.settings,
          sketchPadElements: [elements], // only 1 sketchpad for now
        },
      },
    });

    // console.log("saveSketchpadChange saved", response);
  }, 500);

  // const debouncedSaveSketchpadChange = debounce(saveSketchpadChange, 500);

  const handleSketchpadChange = (elements, state) => {
    // console.log("Elements:", elements, "State:", state);
    setElements(elements);
    saveSketchpadChange(elements);
    // debouncedSaveSketchpadChange(elements);

  };

  {/* Welcome to the Launch OS Sketchpad! */}
  return (
    <Excalidraw
      theme={darkMode ? "dark" : "light"}
      onChange={handleSketchpadChange}
      initialData={{
        elements: allElements,
        appState: {
          viewBackgroundColor: "transparent",
        },
      }}
      UIOptions={{
        canvasActions: {
          loadScene: false,
          export: false,
          saveAsImage: false,
          changeViewBackgroundColor: false,
          clearCanvas: false,
          toggleTheme: false,
        },
        tools: { image: false },
      }}
      excalidrawAPI={setExcalidrawAPI}
    >
      <WelcomeScreen>
        <WelcomeScreen.Center>
          <WelcomeScreen.Hints.ToolbarHint />
          {/* <WelcomeScreen.Center.Logo /> */}
          <RocketLaunch size={64} />
          <WelcomeScreen.Center.Heading>
            Welcome to the
            <br />
            Launch OS Sketchpad!
          </WelcomeScreen.Center.Heading>
          <WelcomeScreen.Center.Heading>
          <p className="text-sm">
            Use this canvas to sketch out your best ideas.
          </p>
          </WelcomeScreen.Center.Heading>
        </WelcomeScreen.Center>
      </WelcomeScreen>
      {/* <Footer>
        <ConvertToFunnelButton excalidrawAPI={excalidrawAPI} campaignData={campaignData} />
      </Footer> */}
    </Excalidraw>
  );
}