import { AppContext } from "@/AppContext";
import Chip from "@/ui/Chip";
import { TopbarBreadcrumbItem } from "@/ui/Layout/Topbar/types";
import { Variants } from "@/ui/types";
import { X } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";

export const ContextChips = ({ onChange = (_newContext?: TopbarBreadcrumbItem[]) => {} }) => {
  const { currentlyViewing } = useContext(AppContext);

  const [useForContext, setUseForContext] = useState<TopbarBreadcrumbItem[]>([]);

  useEffect(() => {
    setUseForContext(currentlyViewing as TopbarBreadcrumbItem[]);
  }, [currentlyViewing]);

  if (!useForContext.length) return null;

  return (
    <div className="flex flex-wrap gap-2">
      {useForContext.map((item, index) => {
        if (item.label === "") return null;
        return (
        <Chip
          key={index}
          variant={Variants.DISABLED}
          status={
            <div className="flex items-center gap-1">
              <span>{item.label}</span>
              <X
                onClick={() => {
                  const newContext = useForContext.filter(
                    (_, i) => i !== index
                  );
                  setUseForContext(newContext);
                  onChange(newContext)
                }}
                className="text-xs cursor-pointer"
              />
            </div>
          }
        />
      )})}
    </div>
  );
};
