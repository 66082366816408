import { ComponentRenderHooks } from "@/plugins/types";
// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
import settings from "./settings";

import { HTML, Properties } from "./component";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.HTML
  ) {
    return [HTML];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.HTML
  ) {
    // console.log("Loading HTML properties", payload);
    return Properties(payload);
    // return null;
  }
};
