import { ComponentRenderHooks } from "@/plugins/types";
import {
  EditorPaymentElement,
  PaymentElementProperties,
  EditorAddressElement,
  AddressElementProperties,
} from "./component";
import { addressElementSettings, paymentElementSettings } from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [paymentElementSettings,
      addressElementSettings
    ];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.PAYMENT
  ) {
    return [EditorPaymentElement];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.PAYMENT
  ) {
    return PaymentElementProperties(payload);
  }
  
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ADDRESS
  ) {
    return [EditorAddressElement];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.PAYMENT
  ) {
    return AddressElementProperties(payload);
  }
};
