import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Container, Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";

import { Loading } from "@/ui/Layout/Loading";
import { CustomizePageVariables } from "../widgets/CustomizePageVariables";
import { GetCodePopup } from "../Popups/GetCodePopup";
import { TopbarButton } from "@/ui/Layout/Topbar/Topbar";
import { Code, Play } from "@phosphor-icons/react";
import { PublishCampaignModal } from "../Popups/PublishCampaignModal";
import { AppContext } from "@/AppContext";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(
    getActiveTabFromLocalStorage() || "overview"
  );
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [productData, setProductData] = useState<{ name?: string }>({
    name: "",
  });
  const [isGetCodePopupOpen, setIsGetCodePopupOpen] = useState(false);
  const [loading, setIsLoading] = useState(true);
  // const [activePageId, setActivePageId] = useState(null);
  const { id } = useParams();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      console.log("Product Data", data);
      setProductData(data);
      setIsLoading(false);
    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/order-forms" },
      { label: productData.name || "" },
    ],
    items: (
      <>
        <TopbarButton onClick={() => setIsGetCodePopupOpen(true)}>
          <Code />
        </TopbarButton>

        {/* <Button
          label="</> Get Code"
          onClick={() => setIsGetCodePopupOpen(true)}
          type={ButtonTypes.OUTLINED}
          variant={Variants.INFO}
        /> */}

        <TopbarButton
          onClick={() =>
            window.open(`/pages/preview/${id}/${productData?.objects?.[0]?.id}`)
          }
        >
          <Play />
        </TopbarButton>
        {/* <Button
          label="Preview"
          // onClick={() => setIsPublishPopupOpen(true)}
          onClick={() =>
            window.open(`/pages/preview/${id}/${productData?.objects?.[0]?.id}`)
          }
          type={ButtonTypes.OUTLINED}
          variant={Variants.INFO}
        /> */}
        <Button
          label="Publish"
          onClick={() => setIsPublishPopupOpen(true)}
          type={ButtonTypes.OUTLINED}
          variant={Variants.INFO}
        />
      </>
    ),
  };

  if (loading) return <Loading />;

  return (
    <Page topBar={topBarSettings}>
      {isGetCodePopupOpen && (
        <GetCodePopup
          campaignData={productData}
          onClose={() => setIsGetCodePopupOpen(false)}
        />
      )}

      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          unitName="order form"
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
          hostname = "forms.freedomsoft.com"
        />
      )}

      <Container>
        <div className={`p-6 w-full`}>
          <div className="tabs flex flex-col">
            <TabsComponent
              tabs={[
                { label: "Dashboard", value: "overview" },
                { label: "Customize", value: "customize" },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              actionSection={false}
            />
          </div>
          {activeTab === "overview" && (
            <Settings
              campaignData={productData}
              setCampaignData={setProductData}
              unitName="Order Form"
              widgets={[
                "name",
                {
                  type: "columns",
                  settings: {
                    widgets: [
                      { type: "theme", metaData: { showPages: true } },
                      "scripts",
                    ],
                  },
                },
                "delete",
              ]}
            />
          )}
          {activeTab === "customize" && (
            <CustomizePageVariables
              campaignData={productData}
              setCampaignData={setProductData}
            />
          )}
        </div>
      </Container>
    </Page>
  );
};
