import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";
import { AppContext } from "@/AppContext";
import { setContextForView } from "../AITools/utils";

export const Pages = ({ name = "" }) => {
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const contextObj = useContext(AppContext);

  // get the pages from the server
  const loadPages = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "page" });
    if (response) {
      // convert the response to the format that the Card components expect
      const pgs = response
        ?.filter(({ deleted }) => !deleted)
        .map((page: any) => {
          return {
            id: page.id,
            label: page.name,
            caption: `Last Updated: ${moment(page.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setPages(pgs);

      setContextForView({
        data: pgs.map(({ id, label, caption }) => ({ id, name: label, caption })),
        view: name,
      }, contextObj);
    }
    setIsLoading(false);
  };

  // load the pages when the page loads
  useEffect(() => {
    loadPages();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Link to="/launchpad/pages" className="justify-end">
            <Button
              label="+ Create a Landing Page"
              type={ButtonTypes.OUTLINED}
            />
          </Link>
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(pages.length) && (
          <EmptyState
            title="No Pages Yet"
            description="Get started by creating a new page."
            showImage={false}
          >
            <Link to="/launchpad/pages">
              <Button label="Create a Page" type={ButtonTypes.DEFAULT} />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(pages.length) && (
          <Grid className="pb-6">
            {pages.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/pages/${id}`}
                    status={status}
                    // sharedWith={sharedWith}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
