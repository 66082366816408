import { HeaderLayouts } from "./Headers/types";
import { BenefitLayouts } from "./Benefits/types";
import { CTALayouts } from "./CTA/types";
import { ContentLayouts } from "./Content/types";
import { PricingLayouts } from "./Pricing/types";
import { SocialProofLayouts } from "./SocialProof/types";
import { FormLayouts } from "./Forms/types";
import { NavigationLayouts } from "./Navigation/types";
import { FooterLayouts } from "./Footers/types";
import { GuaranteeLayouts } from "./Guarantee/types";
import { QuizLayouts } from "./Quiz/types";
import { UrgencyLayouts } from "./Urgency/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export enum PageSectionCategory {
  HEADER = "Header",
  BENEFIT = "Benefit",
  CTA = "CTA",
  CONTENT = "Content",
  PRICING = "Pricing",
  SOCIALPROOF = "SocialProof",
  FORM = "Form",
  NAVIGATION = "Navigation",
  FOOTER = "Footer",
  GUARANTEE = "Guarantee",
  QUIZ = "Quiz",
  URGENCY = "Urgency",
  PERSONAL = "Personal",
}

export type AllPageLayouts =
  | HeaderLayouts
  | BenefitLayouts
  | CTALayouts
  | ContentLayouts
  | PricingLayouts
  | NavigationLayouts
  | FooterLayouts
  | SocialProofLayouts
  | GuaranteeLayouts
  | QuizLayouts
  | UrgencyLayouts
  | FormLayouts;

export interface iProps {
  type: AllPageLayouts;
  data?: any;
}

export interface ILayoutSettings {
  label: AllPageLayouts | string;
  layout: AllPageLayouts;
  previewImage?: string;
  loadFromExt?: boolean;
  loadFromExtSettings?: {
    pageId: string;
    itemId: string;
  };
  defaul?: {
    label?: PageSectionCategory;
    type: ComponentTypes;
    bodyOnly: boolean;
    canHaveChildren: boolean;
    properties?: React.CSSProperties;
    children?: any[];
  };
}
