import ChatBot from "./ChatBot";
import {
  Browsers,
  NewspaperClipping,
  TreeStructure,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { CopilotHistory, loadChats } from "./CopilotGallery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "@/ui/Layout/Loading";
import TabsComponent from "@/ui/TabsComponent";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";
import { Settings } from "../Dashboards/Settings";
import { getCampaign } from "@/api/campaigns";
import { getSessionToken, isLoggedIn } from "@/api/auth";
import ArtifactContainer from "./Artifacts/ArtifactContainer";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { ArtifactOptions } from "./types";
import { TopbarButton } from "@/ui/Layout/Topbar/Topbar";
import { HistoryIcon, Plus } from "lucide-react";
import Dropdown from "@/ui/Dropdown";
import { ButtonTypes, DropDownTypes } from "@/ui/types";

export const CopilotWrapper = ({
  id,
  setCampaignName = () => {},
  setCampaignId = () => {},
  setTopBarButtons = () => [],
  children = null,
}) => {
  const [chatList, setChatList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("messages");
  const [campaignData, setCampaignData] = useState({});

  const [artifactOptions, setArtifactOptions] = useState<ArtifactOptions>({ isShowing: Boolean(children) });
  const [suggestions, setSuggestions] = useState([]);

  const [isLoadingChatList, setIsLoadingChatList] = useState([]);

  const [loggedIn, setIsLoggedIn] = useState(false);

  const currentChatBotRef = useRef();

  const navigate = useNavigate();

  const loadChat = async (id) => {
    // setIsLoading(true);
    const token = getSessionToken();
    const data = await getCampaign({ token, id });
    setCampaignData(data);
    setCampaignName(data?.name);
    setCampaignId(data?.id);
    // setIsLoading(false);
    console.log("Chat messages loaded", data);
  };

  const loadSuggestions = async () => {
    setTopBarButtons(null);
    
    const prompt = "Generate 3 prompt suggestions";
    const response = await fetch(`${AI_DOMAIN}/api/copilot/suggestions`, {
      method: "post",
      body: JSON.stringify({ prompt }),
    });
    const r = response.json();
    const data = await r;
    console.log("Suggestions", data);
    setSuggestions(data.suggestions);


  };

  const handleOpenHistoryDropdown = async () => {
    if (!Boolean(chatList.length)) {
      setIsLoadingChatList(true);
      loadChats((seqs) => {
        setChatList(seqs);
        setIsLoadingChatList(false);
      });
    }
  };

  useEffect(() => {
    (async () => {
      setActiveTab("messages");
      const isTheUserLoggedIn = await isLoggedIn();
      setIsLoggedIn(isTheUserLoggedIn);

      if (isTheUserLoggedIn) {
        if (id) loadChat(id);
        else if (children) {
          setArtifactOptions({ isShowing: true });
        } else {
          setArtifactOptions({ isShowing: false });
          loadSuggestions();
          setIsLoadingChatList(true);
          loadChats((seqs) => {
            setChatList(seqs);
            setIsLoading(false);
            setIsLoadingChatList(false);
          });
        }
      } else {
        loadSuggestions();
      }
    })()
  }, [id]);

  if (isLoading)
    return (
      <div className="w-full h-full py-96 max-w-6xl mx-auto">
        <Loading type="gallery" />
      </div>
    );

  return (
    <div
      className={`gap-6 h-full w-full ${artifactOptions?.isShowing && activeTab === "messages" ? "flex overflow-hidden" : "max-w-6xl"} mx-auto`}
    >
      <div className="h-full w-full p-6">
        {
          <div className="tabs flex justify-between">
            {id ? (
              <TabsComponent
                tabs={[
                  { label: "Messages", value: "messages" },
                  { label: "Chat Settings", value: "settings" },
                ]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                actionSection={false}
                storeActiveTab={false}
              />
            ) : (
              <div />
            )}
            {loggedIn && (
              <div className="flex gap-2 h-[38px]">
                <Dropdown
                  type={DropDownTypes.BASIC}
                  label={<HistoryIcon className="w-5 h-5" />}
                  onOpen={handleOpenHistoryDropdown}
                  isLoading={isLoadingChatList}
                  items={chatList.map(({ id, label, caption }) => ({
                    id,
                    label,
                    caption: caption.replace("Last Updated: ", ""),
                  }))}
                  onClick={(key) => {
                    setArtifactOptions({ isShowing: false });
                    if (children) setCampaignId(chatList[key]?.id);
                    else {
                      navigate(`/copilot/${chatList[key]?.id}`);
                    }
                  }}
                />
                <TopbarButton
                  onClick={() => {
                    if (children) {
                      setCampaignId(null);
                      setCampaignName(null);
                      setCampaignData({});
                    } else window.location.href = "/copilot";
                  }}
                >
                  <Plus className="w-5 h-4 max-h-4" />
                </TopbarButton>
              </div>
            )}
          </div>
        }
        {activeTab === "messages" && (
          <ChatBot
            ref={currentChatBotRef}
            showHeader={false}
            title="What can I help you launch today?"
            subtitle="Generate landing pages, sales funnels, copy, and much more."
            sessionId={id}
            pageSettings={{}}
            setCampaignName={setCampaignName}
            setCampaignId={setCampaignId}
            artifactOptions={artifactOptions}
            setArtifactOptions={setArtifactOptions}
            suggestions={suggestions.map((itm) => {
              let icon;
              if (itm?.icon === "Browsers") icon = Browsers;
              if (itm?.icon === "TreeStructure") icon = TreeStructure;
              if (itm?.icon === "NewspaperClipping") icon = NewspaperClipping;
              return { ...itm, icon };
            })}
            placeholder="Ask Launch OS a question..."
          >
            {/* {isLoading ? (
              <Loading />
            ) : (
              Boolean(chatList.length) && (
                <div className="py-12">
                  <div className="flex justify-between items-center">
                    <p className="text-lg py-6 px-3">Your Recent Chats</p>
                    <Link to="/copilot/all">
                      <p className="text-md py-6 px-3 text-blue underline">
                        See All
                      </p>
                    </Link>
                  </div>
                  <CopilotHistory amountToShow={3} chatList={chatList} />
                </div>
              )
            )} */}
          </ChatBot>
        )}
        {activeTab === "settings" &&
          campaignData &&
          Object.keys(campaignData).length > 0 && (
            <div>
              <Settings
                campaignData={campaignData}
                setCampaignData={setCampaignData}
                unitName="Chat"
                // metaData={{ templates: [] }}
                widgets={["name", "planner", "theme", "delete"]}
              />
            </div>
          )}
      </div>

      {activeTab === "messages" &&
        (id || children || artifactOptions?.isShowing) && (
          <div
            className={`w-full max-w-6xl transition-all duration-300 border-l border-black/5 dark:border-white/5 dark:bg-[rgba(22,22,22,0.8)] ${
              artifactOptions?.isShowing
                ? "translate-x-0 opacity-100 visible"
                : "translate-x-full opacity-0 invisible h-0"
            }`}
          >
            <ArtifactContainer
              id={artifactOptions?.id}
              campaignId={id}
              // campaignId={campaignData?.id}
              type={artifactOptions?.type || ""}
              prompt={artifactOptions?.prompt || ""}
              title={artifactOptions?.title || ""}
              commentary={artifactOptions?.commentary || ""}
              pageType={artifactOptions?.pageType || ""}
              setTopBarButtons={setTopBarButtons}
              setArtifactOptions={setArtifactOptions}
              chatBotHelpers={currentChatBotRef.current}
            >
              {children}
            </ArtifactContainer>
          </div>
        )}
    </div>
  );
};
