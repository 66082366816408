import { agent, collection, content, pages, traffic, workflow } from "./objects";
import { IDropdownMenuItem } from "@/ui/Menu";
import { workflowNodes } from "../Workflows/nodes/workflowNodes";
import { pageNodes } from "./nodes/pageNodes";
import { shoppingCartNodes } from "./nodes/shoppingCartNodes";
import { trackingNodes } from "./nodes/trackingNodes";
import { destinationNodes } from "./nodes/destinationNodes";
import { contentNodes } from "./nodes/contentNodes";
import { collectionNodes } from "./nodes/collectionNodes";
import { agentNodes } from "./nodes/agentNodes";

export const getIconFromType = ({ type, subType, screenshot, settings }) => {
  // get the icon for the page type, which you'll find in the objects array

  if (type === "RedirectComponent" || type === "TrackingSource") {
    return traffic.find((obj) => obj.screenshot === screenshot)?.icon;
  }

  if (type === "PageComponent") {
    return pages.find((page) => page.pageType === subType)?.icon;
  }

  if (type === "Trigger" || type === "Task") {
    return workflow.find((itm) => itm.id === settings?.type)?.icon;
  }

  if (type === "ContentComponent") {
    return content.find((itm) => itm.name === settings?.type)?.icon;
  }

  if (type === "CollectionComponent") {
    return collection.find((itm) => itm.name === settings?.type)?.icon;
  }

  if (type.startsWith("ChatComponent")) {
    return agent.find((itm) => itm.id === settings?.type)?.icon;
  }

  return false;
};

export const getImageFromType = ({ type, subType, screenshot }) => {
  // get the screenshot image for the page type, which you'll find in the objects array

  if (type === "PageComponent") {
    return pages.find((page) => page.pageType === subType)?.screenshot;
  }

  // if (type === "RedirectComponent" || type === "TrackingSource") {
  //   return traffic.find((obj) => page.pageType === subType)?.screenshot;
  // }


  if (type === "Webhook") {
    return screenshot;
  }

  return false;
};

export const getColorFromType = ({ type, subType, screenshot, settings }) => {
  // get the color for the page type, which you'll find in the objects array
  if (
    type === "RedirectComponent" ||
    type === "SmartRouteComponent" ||
    type === "TrackingSource" ||
    type === "ABSplitComponent" ||
    type === "AnotherFunnelComponent"
  ) {
    return traffic.find((obj) => obj.screenshot === screenshot)?.iconColor;
  }

  if (type === "PageComponent" || type === "cart") {
    const ret = pages.find((page) => page.pageType === subType)?.iconColor;
    if (!ret)
      return pages.find((obj) => obj.screenshot === screenshot)?.iconColor;
    return ret;
  }

  if (type === "Trigger" || type === "Task") {
    return workflow.find((itm) => itm.id === settings?.type)?.iconColor;
  }

  if (type === "ContentComponent") {
    return content.find((itm) => itm.name === settings?.type)?.iconColor;
  }

  if (type === "CollectionComponent") {
    return collection.find((itm) => itm.name === settings?.type)?.iconColor;
  }

  if (type.startsWith("ChatComponent")) {
    return agent.find((itm) => itm.id === settings?.type)?.iconColor;
  }

  return false;
};

export const getSizeFromType = (data) => {
  let width = 130;
  let height = 90;

  // make the node smaller if it's a traffic node
  if (
    data?.type === "RedirectComponent" ||
    data?.type === "SmartRouteComponent" ||
    data?.type === "TrackingSource" ||
    data?.type === "ABSplitComponent"
  ) {
    width = 80;
    height = 60;
  }

  if (data?.type === "Trigger" || data?.type === "Task" || data?.type === "Webhook") {
    width = 100;
    height = 60;
  }

  return { width, height };
};

export const getIconData = (data: any, darkMode: boolean) => {
  // console.log("getIconData", { data, darkMode });

  let Icon =
    getIconFromType({
      type: data?.type,
      subType: data?.page?.type,
      ...data,
    }) || null;

  let imageSrc =
    getImageFromType({
      type: data?.type,
      subType: data?.page?.type,
      ...data,
    }) || "https://flowbite.com/docs/images/logo.svg";

  if (data?.screenshot) imageSrc = data.screenshot;

  let defaultColor = darkMode ? `rgb(28 28 28)` : `rgba(243 244 246)`;

  let color =
    getColorFromType({
      type: data?.type,
      subType: data?.page?.type,
      ...data,
    }) || defaultColor;

  let size = getSizeFromType(data);

  return { Icon, imageSrc, color, size };
};

export const getAddNodeMenuItems = (
  data: {
    campaignData: any;
    nodes: any[];
    setNodes: (nodes: any[]) => void;
    setEdges: (edges: any[]) => void;
  }
  // callbackFtn: () => void = () => null
): IDropdownMenuItem[] => {
  const {
    campaignData,
    nodes,
    setNodes,
    event,
    reactFlowWrapper,
    reactFlowInstance,

    integrations,
  } = data;

  const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
  const position = reactFlowInstance?.project({
    x: event?.clientX - reactFlowBounds?.left,
    y: event?.clientY - reactFlowBounds?.top,
  });

  const defaultExcludes = ["workflow", "content"];

  // Workflow nodes
  if (campaignData?.type === "workflow") {
    return workflowNodes({ position, campaignData, integrations, nodes, setNodes });
  }

  // Collection nodes
  if (campaignData?.type === "collection") {
    return collectionNodes({ position, campaignData, nodes, setNodes });
  }

  if (campaignData?.type === "chat" || campaignData?.type === "agent") {
    return agentNodes({ position, campaignData, nodes, setNodes });
  }

  return [
    ...(!defaultExcludes.includes(campaignData?.type) ? pageNodes({ position, campaignData, nodes, setNodes }) : []), // prettier-ignore
    ...(!defaultExcludes.includes(campaignData?.type) ? shoppingCartNodes({ position, campaignData, nodes, setNodes }) : []), // prettier-ignore
    ...(!defaultExcludes.includes(campaignData?.type) ? trackingNodes({ position, campaignData, nodes, setNodes }) : []), // prettier-ignore
    ...(!defaultExcludes.includes(campaignData?.type) ? destinationNodes({ position, campaignData, nodes, setNodes }) : []), // prettier-ignore
    ...(campaignData?.type === "workflow" ? workflowNodes({ position, campaignData, nodes, setNodes }) : []), // prettier-ignore
    ...(campaignData?.type === "content" ? contentNodes({ position, campaignData, nodes, setNodes }) : []), // prettier-ignore
  ];
};
