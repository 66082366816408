import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { BenefitLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.ELEVEN,
  layout: BenefitLayouts.ELEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit11.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx5he5q231g07808cui36sy",
    itemId: "u5j1r64m5rf",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 900,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((6 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      ...style.LIST,
                    },
                    children: [
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((6 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      ...style.LIST,
                    },
                    children: [
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                      {
                        type: ComponentTypes.LISTITEM,
                        canHaveChildren: false,
                        properties: style.LI.style,
                        html: `<p style="font-size: 18pt"><strong>Proven Strategy</strong></p><p>_</p><p style="font-size: 11pt; padding: 0">It's true.  Getting results is much easier than you think.  The truth is</p>`,
                        icon: IconTypes.AccountCircle,
                        iconStyle: style.LI.iconStyle,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
