import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { cloneCampaign, getCampaigns, updateCampaign } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { useNavigate } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";

export const Collections = ({ name = "" }) => {
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  // get the collections from the server
  const loadCollections = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "collection" });
    
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((collection: any) => {
          return {
            id: collection.id,
            label: collection.name,
            caption: `Last Updated: ${moment(collection.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setCollections(seqs);
    }

    setIsLoading(false);
  };

  // Creates a new collection based on the collection funnel template
  const handleCreateCollection = async () => {
    const name = prompt("Enter a name for your collection");
    const campaignId = "cjz0ij1qz0ems0792n1kpu5ac"; // The ID of the collection campaign template (blank)
    if (name) {
      setIsLoading(true);
      const token = getSessionToken();
      const response = await cloneCampaign(campaignId, { token, name });
      const newCampaignId = response?.data?.cloneCampaign?.response?.id;

      // update the type of the campaign to be a collections campaign
      const updateResponse = await updateCampaign(newCampaignId, {
        token,
        settings: {
          type: "collection"
        },
      });
      
      console.log("newCampaignId", newCampaignId, updateResponse);

      if (newCampaignId) {
        // navigate to the new funnel
        navigate(`/collections/${newCampaignId}`);
      }
    }
  };

  // load the collections when the page loads
  useEffect(() => {
    loadCollections();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Button
            label="+ Create a Collection"
            type={ButtonTypes.OUTLINED}
            onClick={handleCreateCollection}
          />
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(collections.length) && (
          <EmptyState
            title="No Collections Yet"
            description="Get started by creating a new collection."
            showImage={false}
          >
            <Button
              label="Create a Collection"
              type={ButtonTypes.DEFAULT}
              onClick={handleCreateCollection}
            />
          </EmptyState>
        )}
        {!isLoading && Boolean(collections.length) && (
           <Grid className="pb-6">
            {/* Can we change to Gallery View - let's me toggle views */}
            {collections.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/collections/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
