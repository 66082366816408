import { ComponentRenderHooks } from "@/plugins/types";

import EditorButton, { Properties } from "./Button";
export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "Button"
  )
    return [EditorButton];

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Button") {
    return Properties(payload);
  }
};