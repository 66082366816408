import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/AppContext";
import { CaretDoubleRight, CircleDashed } from "@phosphor-icons/react";

import { Properties as CartProperties } from "./Nodes/ShoppingCart";
import { Properties as RedirectProperties } from "./Nodes/Redirect";
import { Properties as SmartRouterProperties } from "./Nodes/SmartRouter";
import { Properties as AnotherFunnelProperties } from "./Nodes/AnotherFunnel";
import { Properties as WorkflowSettings } from "@/Apps/Workflows/nodes/Properties";

import { Modal } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { GenerateText } from "@/Apps/Agents/nodes/GenerateText";

export const EditDrawer = ({
  setEditDrawerOpen,
  data = {},
  title,
  campaignId,
  activeNodeType,
  icon,
  nodes = [],
  viewAs = "popup", // popup or drawer
  setNodes = () => null,
}) => {
  const { darkMode, rightSidebarOpen } = useContext(AppContext);

  const TheContent = () => {
    return (
      <div>
        {activeNodeType === "cart" && (
          <CartProperties
            campaignId={campaignId}
            nodeData={data}
            setNodes={setNodes}
            nodes={nodes}
            setEditDrawerOpen={setEditDrawerOpen}
          />
        )}
        {activeNodeType === "RedirectComponent" && (
          <RedirectProperties
            campaignId={campaignId}
            nodeData={data}
            setNodes={setNodes}
            nodes={nodes}
            setEditDrawerOpen={setEditDrawerOpen}
          />
        )}
        {activeNodeType === "SmartRouteComponent" && (
          <SmartRouterProperties
            campaignId={campaignId}
            nodeData={data}
            setNodes={setNodes}
            nodes={nodes}
            setEditDrawerOpen={setEditDrawerOpen}
          />
        )}
        {activeNodeType === "AnotherFunnelComponent" && (
          <AnotherFunnelProperties
            campaignId={campaignId}
            nodeData={data}
            setNodes={setNodes}
            nodes={nodes}
            setEditDrawerOpen={setEditDrawerOpen}
          />
        )}
        {(!Boolean(activeNodeType) ||
          activeNodeType === "PageComponent" ||
          activeNodeType === "TrackingSource") && (
          <div className="text-center p-5 items-center grid grid-flow-row h-[275px]">
            <div className="text-gray-400">
              <CircleDashed className="text-center text-5xl mx-auto mb-3" />
              No Properties Available.
              <p>Select a different object on the canvas</p>
            </div>
          </div>
        )}
        {(activeNodeType === "Trigger" ||
          activeNodeType === "Task" ||
          activeNodeType === "Webhook") && (
          <WorkflowSettings
            nodeData={data}
            campaignId={campaignId}
            setNodes={setNodes}
            nodes={nodes}
            setEditDrawerOpen={setEditDrawerOpen}
          />
        )}

        {(activeNodeType === "ChatComponentText" && (
          <GenerateText
            nodeData={data}
            campaignId={campaignId}
            setNodes={setNodes}
            nodes={nodes}
            setEditDrawerOpen={setEditDrawerOpen}
          />
        ))}
      </div>
    );
  };

  if (viewAs === "popup") {
    return (
      <Modal
        title={data?.data?.label || title}
        icon={
          icon || <img src={data?.data?.screenshot} className="w-10 rounded" />
        }
        // size={PopupSizes.XLARGE}
        size={PopupSizes.XXLARGE}
        onClose={() => setEditDrawerOpen(false)}
        footer={false}
      >
        <TheContent />
      </Modal>
    );
  } else {
    // viewAs === "drawer"
    return (
      <div
        className={`absolute z-[999] shadow-sm transition-all duration-300`}
        style={{
          width: 500,
          right: rightSidebarOpen ? 0 : 500,
          height: "calc(100vh - 65px)",
          backgroundColor: darkMode
            ? `rgba(40,40,40,0.98)`
            : `rgba(255,255,255,0.98)`,
          top: 65,
          borderLeft: `1px solid rgba(0,0,0,0.1)`,
        }}
      >
        <CaretDoubleRight
          className="right-0 mt-6 mr-4 hover:opacity-50 absolute cursor-pointer"
          onClick={() => setEditDrawerOpen(false)}
        />
        <div className={`p-5 text-xl`}>
          <img
            src={data?.data?.screenshot}
            className="w-10 rounded inline-block mr-4"
          />
          {data?.data?.label || title}
        </div>
        <div className="px-5">
          {/* {children} */}

          {/* Call the appropriate sidebar component based on data type */}
          <TheContent />
        </div>
      </div>
    );
  }
};
