interface MarkdownComponentProps {
  node?: any;
  children?: React.ReactNode;
  [key: string]: any;
}

export const markdownComponents: Record<string, React.FC<MarkdownComponentProps>> = {
  h1: ({ node, ...props }) => (
    <h1 {...props} className="text-2xl font-semibold" />
  ),
  h2: ({ node, ...props }) => (
    <h2 {...props} className="text-xl font-semibold" />
  ),
  h3: ({ node, ...props }) => (
    <h3 {...props} className="text-lg font-semibold" />
  ),
  ul: ({ node, ...props }) => (
    <ul {...props} className="!list-decimal list-inside pb-3" />
  ),
  ol: ({ node, ...props }) => (
    <ol {...props} className="!list-decimal list-inside" />
  ),
  li: ({ node, ...props }) => <li {...props} className="" />,
  a: ({ node, ...props }) => <a {...props} className="font-bold underline" />,
  p: ({ node, ...props }) => <span {...props} className="text-[1.0rem]" />,
  // antthinking: ({ node, ...props } ) => <Loading type="tiny" />,
};
