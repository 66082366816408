import * as React from "react";
import { get } from 'lodash';

import { Tabs, Tab } from "@material-ui/core";

// import { FancySelector } from '@launchos/modules/editor/Builder/Properties';
// import { alignTextHorizontal } from '@launchos/modules/editor/Builder/Properties/Sections/Alignment';

import { FancySelector } from "@/Apps/Pages/Properties";
import { alignTextHorizontal } from "@/Apps/Pages/Properties/Sections/Alignment";

const Alignment: React.FC<{ onChange: () => void }> = ({ onChange = () => null }) => {
    return (
        <FancySelector
            items={alignTextHorizontal}
            onChange={onChange}
        />
    )
}

export const CustomAlignmentSection: React.FC<{
    updateComponentStyle: unknown;
    updateComponentSettings: unknown;
    settings: unknown;
}> = ({ updateComponentStyle = () => null, updateComponentSettings = () => null, settings = {} }) => {
    const [value, setValue] = React.useState<number>(0);

    const switchTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChange = id => {

        switch (id) {
            case "text_align_left":
                if (value === 0) updateComponentStyle(settings.id, { textAlign: "left" }, true, false);
                else updateComponentSettings(settings.id, { ...settings, tabStyle: { ...get(settings, 'tabStyle', {}), justifyContent: "flex-start" } });
                break;

            case "text_align_center":
                if (value === 0) updateComponentStyle(settings.id, { textAlign: "center" }, true, false);
                else updateComponentSettings(settings.id, { ...settings, tabStyle: { ...get(settings, 'tabStyle', {}), justifyContent: "center" } });
                break;

            case "text_align_right":
                if (value === 0) updateComponentStyle(settings.id, { textAlign: "right" }, true, false);
                else updateComponentSettings(settings.id, { ...settings, tabStyle: { ...get(settings, 'tabStyle', {}), justifyContent: "flex-end" } });
                break;

            default:
                break;
        }
    }

    return (
        <div>
            <Tabs value={value} onChange={switchTab}>
                <Tab label="Container" value={0} />
                <Tab label="Tabs" value={1} />
            </Tabs>
            <div style={{ padding: 10 }}>
                {value === 0 && <Alignment onChange={handleChange} />}
                {value === 1 && <Alignment onChange={handleChange} />}
            </div>
        </div>
    );
};
