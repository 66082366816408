export const AGENTS_DOMAIN = `https://staging.launchos.com:43000/api/v1/prediction`;
export const AI_DOMAIN = `https://staging.launchos.com:48011`;
export const APP_DOMAIN = `https://staging.launchos.com:43000`;
export const API_DOMAIN = `https://staging.launchos.com:48091/v1`;
export const AUTOMATION_DOMAIN = `https://staging.launchos.com:48022`;
export const CLIENT_SITE_DOMAIN = `https://staging.launchos.com:480`;
export const EDITOR_DOMAIN = `https://staging.launchos.com:43001`;
export const GQL_DOMAIN = `https://staging.launchos.com:44000`;
export const INTEGRATIONS_DOMAIN = `https://staging.launchos.com:48081`;
export const NGINX_DOMAIN = `https://staging.launchos.com:48089`;
export const PUBLISHING_DOMAIN = `https://staging.launchos.com:48080`;
export const SANDBOX_DOMAIN = `https://staging.launchos.com:48033`;
export const SITE_DOMAIN = `https://staging.launchos.com:49000`;
export const STORYBOOK_DOMAIN = `https://staging.launchos.com:46006`;
