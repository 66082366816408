import { getCampaign, getCampaigns, updateCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { BigCheck } from "@/ui/BigCheck";
import Calout from "@/ui/Calout";
import Grid from "@/ui/Grid";
import Panel from "@/ui/Panel";
import Select from "@/ui/Select";
import { Status } from "@/ui/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { useEffect, useState } from "react";
import moment from "moment";
import { getUserIntegrations } from "@/api/integrations";

export const DeploymentSettings = ({
  campaignData = {},
  setCampaignData = () => {},
  unitName = "Agent",
}) => {
  const [activeTab, setActiveTab] = useState("chatbot");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contentPackages, setContentPackages] = useState([]);

  const [webHookIntegrations, setWebHookIntegrations] = useState([]);

  // get the integrations from the server
  const loadWebHooks = async () => {
    setIsLoading(true);
    const token = getSessionToken();
    const response = await getUserIntegrations({ token });
    const hooks = response.map(({ id, setting, createdAt }) => ({
      id,
      label: setting?.name,
      caption: `Created: ${moment(createdAt).fromNow()}`,
    }));
    setWebHookIntegrations(hooks);
    setIsLoading(false);
  };

  // get the contentPackages from the server
  const loadContentPackages = async () => {
    setIsLoading(true);
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "content" });

    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((collection: any) => {
          return {
            id: collection.id,
            label: collection.name,
            caption: `Last Updated: ${moment(collection.updatedAt).fromNow()}`,
          };
        });

      setContentPackages(seqs);
    }
    setIsLoading(false);
  };

  // handle changes to the agent type
  // const handleTypeChange = async (type: string) => {
  //   setActiveTab(type);
  //   if (campaignData?.id) {
  //     const token = getSessionToken();
  //     await updateCampaign(campaignData.id, {
  //       token,
  //       settings: {
  //         ...campaignData.settings,
  //         agentType: type
  //       }
  //     });
  //   }
  // };

  // handle changes to the content package selection
  const handleContentPackageChange = async (packageId: string) => {
    if (campaignData?.id) {
      const token = getSessionToken();
      await updateCampaign(campaignData.id, {
        token,
        settings: {
          settings: {
            ...campaignData.settings,
            contentPackageId: packageId
          }
        }
      });

      // const data = await getCampaign({ token, id });
      // setCampaignData(data);
    }
  };

  // handle changes to the webhook selection  
  const handleWebhookChange = async (webhookId: string) => {
    if (campaignData?.id) {
      const token = getSessionToken();
      await updateCampaign(campaignData.id, {
        token,
        settings: {
          settings: {
            ...campaignData.settings,
            webhookId: webhookId
          }
        }
      });

      // const data = await getCampaign({ token, id });
      // setCampaignData(data);
    }
  };

  // load the contentPackages when the widget loads
  useEffect(() => {
    loadContentPackages();
    loadWebHooks();
  }, []);

  return (
    <Panel title={`${unitName} Settings`}>
      <p className="text-sm text-gray-500">
        This is where you can configure what type of agent you want to build -
        whether it's a chatbot for conversational AI, a content package for
        automated content generation, or a webhook trigger to connect with
        services like Zapier.
      </p>
      <Grid cols={3} smCols={1} lgCols={3} className="mt-7">
        <BigCheck
          label="Chatbot"
          caption="This is a conversational AI chatbot to interact with users"
          onClick={() => setActiveTab("chatbot")}
          checked={activeTab === "chatbot"}
        />
        <BigCheck
          label="Content Package"
          caption="This is an automated content generation system"
          onClick={() => setActiveTab("content")}
          checked={activeTab === "content"}
        />
        <BigCheck
          label="Webhook Trigger"
          caption="This will connect with external services like Zapier via webhooks"
          onClick={() => setActiveTab("webhook")}
          checked={activeTab === "webhook"}
        />
      </Grid>

      {activeTab === "chatbot" && (
        <div className="my-8">
          <Calout>
            Chatbot configuration options coming soon! Stay tuned for exciting
            new features.
          </Calout>
        </div>
      )}
      {activeTab === "content" && (
        <div className="my-8">
          <Calout>
            Choose which content package you want to append content to. This
            will determine where your generated content will be stored and
            managed.
          </Calout>
          {!isLoading && (
            <Select
              label="Select a Content Package"
              items={contentPackages}
              className="mt-7"
              flavor={TextInputFlavors.MODERN}
              value={campaignData?.settings?.contentPackageId}
              onChange={(e) => handleContentPackageChange(e.target.value)}
            />
          )}
        </div>
      )}
      {activeTab === "webhook" && (
        <div className="my-8">
          <Calout>
            Configure webhook triggers to connect your agent with external
            services and automate workflows.
          </Calout>
          {!isLoading && (
            <Select
              label="Select a Webhook"
              items={webHookIntegrations}
              className="mt-7"
              flavor={TextInputFlavors.MODERN}
              value={campaignData?.settings?.webhookId}
              onChange={(e) => handleWebhookChange(e.target.value)}
            />
          )}
        </div>
      )}
    </Panel>
  );
};
