import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { SocialProofLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.TEN,
  layout: SocialProofLayouts.TEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof10.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkysae0n2d0l0780y3gavfbw",
    itemId: "1tn6szzhwng",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: "As Seen In...",
      },
      {
        type: ComponentTypes.DIVIDER,
        canHaveChildren: false,
        properties: {
          margin: "0 auto",
          marginTop: 10,
          display: "inline-block",
          textAlign: "center",
          width: 100,
        },
        color: theme.backgroundColor,
        size: 3,
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 20,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {},
                src:
                  "https://sandcastleassets.s3.amazonaws.com/images/forbes.png",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: { marginTop: 10 },
                src:
                  "https://sandcastleassets.s3.amazonaws.com/images/los_angeles_times.png",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: { marginTop: 10 },
                src:
                  "https://sandcastleassets.s3.amazonaws.com/images/entrepreneur.png",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {},
                src:
                  "https://sandcastleassets.s3.amazonaws.com/images/huffington_post.png",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {},
                src:
                  "https://sandcastleassets.s3.amazonaws.com/images/usa_today.png",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {},
                src: "https://sandcastleassets.s3.amazonaws.com/images/inc.png",
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
