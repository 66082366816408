import * as React from "react";

import { Button as ButtonMUI, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Icon from '@material-ui/core/Icon'
import Fab from '@material-ui/core/Fab';

import theme from '@/ui/theme'

import { ButtonProps } from "./Button.d";
import { FixedPositions } from "../types";
import styles from './style'

/**
 * This component lets you display a button on the page with various features such as,
 * a icons, floating, colored, etc.
 * @param {object} props The props
 * @returns {function} The component
 */
const Button: React.FC<ButtonProps> = ({
  small = true,
  large = false,
  icon = false,
  fixed = false,
  floating = false,
  fixedPosition,
  transparent = false,
  primary = false,
  secondary = false,
  tertiary = false,
  disabled = false,
  negative,
  style = {},
  className = "",
  children,
  iconLeft,
  iconRight,
  onClick = () => null,
}) => {

  let color: "primary" | "secondary" | "inherit" | "default" = icon ? "inherit" : "primary";
  if (primary) color = "primary";
  if (secondary) color = "secondary";
  // if (tertiary) color = "?";
  if (negative) color = "default"

  let size: "small" | "medium" | "large" = "large"
  if (small) size = "small"
  if (large) size = "large"


  const useStyles = makeStyles({
    color: {
      ...tertiary ? { backgroundColor: theme.tertiaryColor } : {},
      ...primary ? { backgroundColor: theme.primaryColor } : {},
      ...secondary ? { backgroundColor: theme.secondaryColor } : {},
      ...negative ? { backgroundColor: theme.lightColor } : {},
    },
    root: {
      padding: "12px 50px",
      borderRadius: 30,
      ...styles.text,
      ...size === "small" ? { ...styles.smallSize, ...styles.smallTextSize } : {},
    },
    style
  });

  const classes = useStyles();

  const useStyle: React.CSSProperties = {
    ...style,
    ...fixedPosition === FixedPositions.BOTTOM_RIGHT
      ? { position: 'fixed', bottom: 55, right: 100 }
      : {}
  }

  if (icon && (fixed || fixedPosition)) return (
    <Fab className={`${classes.color} ${classes.style}`} color={color} style={useStyle} onClick={onClick} size={size}>
      <Icon>{children}</Icon>
    </Fab>
  )

  if (icon) return (
    <IconButton className={`${classes.color} ${classes.style}`} size="medium" color={color} onClick={onClick}>
      <Icon>{children}</Icon>
    </IconButton>
  )


  return <ButtonMUI
    className={`${classes.root} ${classes.color} ${classes.style}`}
    size={size}
    onClick={onClick}
    color={color}
    disabled={disabled}
    variant={!transparent ? "contained" : "text"}
    disableElevation
    startIcon={iconLeft ? <Icon>{iconLeft}</Icon> : null}
    endIcon={iconRight ? <Icon>{iconRight}</Icon> : null}
  >
    {children}
  </ButtonMUI>


}

export default Button;
