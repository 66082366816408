import { useEffect, useState, FC } from "react";

import Wizard from "@/ui/Wizard";

import { supabase } from "@/db";
import { questionnaire } from "./content";
import { getTokenInfo } from "@/api/auth";
import { getOnboardingData, saveOnboardingData } from "../../api/entities";
import { getSessionToken } from "@/api/auth";
import { Page } from "@/ui/Layout";
import { Container } from "@/ui/Layout/Page";
import TabsComponent from "@/ui/TabsComponent";
import Panel from "@/ui/Panel";

interface TextAreaProps {
  placeholder?: string;
  onChange?: () => void;
}

export const TextArea: FC<TextAreaProps> = ({
  placeholder = "",
  onChange,
  defaultValue,
  className = "",
}) => {
  return (
    <div className="relative">
      <textarea
        id="description"
        className={`block rounded-lg px-5 pb-4 pt-[38px] w-full text-black dark:text-white bg-white dark:bg-white/5 border border-black/10 dark:border-white/10 appearance-none focus:outline-none focus:ring-0 focus:border-black/10 dark:focus:border-black/10 peer ${className}`}
        placeholder=" "
        onChange={onChange}
        defaultValue={defaultValue}
      />
      <label
        htmlFor="description"
        className="absolute text-sm text-black/40 dark:text-white/40 duration-300 transform -translate-y-2 scale-90 top-6 z-10 origin-[0] left-5 peer-focus:text-black/40 dark:peer-focus:text-white/40 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-2"
      >
        {placeholder}
      </label>
    </div>
  );
};

const Question = ({ question, placeholder, answers, setAnswers, id }) => {
  return (
    <div>
      <div className="text-2xl font-bold text-center p-5">{question}</div>
      <TextArea
        placeholder={placeholder}
        onChange={(e) => setAnswers({ ...answers, [id]: e.target.value })}
        defaultValue={answers[id]}
      />
    </div>
  );
};

const AboutYouSteps = ({ answers, setAnswers, questions, currentStep = 1 }) => {
  const { brandStory, industryOrMarket } = questions;
  return (
    <div>
      {currentStep === 1 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Your Story"
          question={brandStory}
          id="brandStory"
        />
      )}
      {currentStep === 2 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Your Industry"
          question={industryOrMarket}
          id="industryOrMarket"
        />
      )}
    </div>
  );
};

const AboutYourProductSteps = ({
  answers,
  setAnswers,
  questions,
  currentStep = 1,
}) => {
  const {
    productOrService,
    description,
    features,
    pricing,
    satisfactionGuarantee,
  } = questions;
  return (
    <div>
      {currentStep === 1 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Your Product or Service"
          question={productOrService}
          id="productOrService"
        />
      )}
      {currentStep === 2 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Description"
          question={description}
          id="description"
        />
      )}
      {currentStep === 3 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Features"
          question={features}
          id="features"
        />
      )}
      {currentStep === 4 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Pricing"
          question={pricing}
          id="pricing"
        />
      )}
      {currentStep === 5 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Satisfaction Guarantee"
          question={satisfactionGuarantee}
          id="satisfactionGuarantee"
        />
      )}
    </div>
  );
};

const AboutYourCustomerSteps = ({
  answers,
  setAnswers,
  questions,
  currentStep = 1,
}) => {
  const { demographics, dreamOutcome, emotionsAndBeliefs } = questions;
  return (
    <div>
      {currentStep === 1 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Demographics"
          question={demographics}
          id="demographics"
        />
      )}
      {currentStep === 2 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="The Dream Outcome"
          question={dreamOutcome}
          id="dreamOutcome"
        />
      )}
      {currentStep === 3 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Emotions & Beliefs"
          question={emotionsAndBeliefs}
          id="emotionsAndBeliefs"
        />
      )}
    </div>
  );
};

const AboutYourMarketingSteps = ({
  answers,
  setAnswers,
  questions,
  currentStep = 1,
}) => {
  const { uniqueSolution, promise, topBelief } = questions;
  return (
    <div>
      {currentStep === 1 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Your Unique Solution"
          question={uniqueSolution}
          id="uniqueSolution"
        />
      )}
      {currentStep === 2 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Your Marketing Promise"
          question={promise}
          id="promise"
        />
      )}
      {currentStep === 3 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="The Top Belief"
          question={topBelief}
          id="topBelief"
        />
      )}
    </div>
  );
};

const AboutYourCredibilitySteps = ({
  answers,
  setAnswers,
  questions,
  currentStep = 1,
}) => {
  const {
    credibility,
    primaryBelief,
    differenceFromCompetition,
    authenticity,
    trust,
    mechanismForOutcome,
  } = questions;
  return (
    <div>
      {currentStep === 1 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Your Credibility"
          question={credibility}
          id="credibility"
        />
      )}
      {currentStep === 2 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Why Believe You"
          question={primaryBelief}
          id="primaryBelief"
        />
      )}
      {currentStep === 3 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Difference From Competition"
          question={differenceFromCompetition}
          id="differenceFromCompetition"
        />
      )}
      {currentStep === 4 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Authenticity"
          question={authenticity}
          id="authenticity"
        />
      )}
      {currentStep === 5 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Trust"
          question={trust}
          id="trust"
        />
      )}
      {currentStep === 6 && (
        <Question
          answers={answers}
          setAnswers={setAnswers}
          placeholder="Mechanism For Outcome"
          question={mechanismForOutcome}
          id="mechanismForOutcome"
        />
      )}
    </div>
  );
};

const calcNumQuestions = (data) => Object.keys(data).length;

export const Onboarding = () => {
  const [answers, setAnswers] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [aboutYouCurrentStep, setAboutYouCurrentStep] = useState(1);
  const [aboutYourProductCurrentStep, setAboutYourProductCurrentStep] =
    useState(1);
  const [aboutYourCustomerCurrentStep, setAboutYourCustomerCurrentStep] =
    useState(1);
  const [aboutYourMarketingCurrentStep, setAboutYourMarketingCurrentStep] =
    useState(1);
  const [aboutYourCredibilityCurrentStep, setAboutYourCredibilityCurrentStep] =
    useState(1);

  const getData = async () => {
    const token = getSessionToken();

    // get all the entities for this user of the desired types
    const questionnaireKeys = [
      ...Object.keys(questionnaire.aboutYou),
      ...Object.keys(questionnaire.aboutYourProduct),
      ...Object.keys(questionnaire.aboutBestCustomers),
      ...Object.keys(questionnaire.marketingPromise),
      ...Object.keys(questionnaire.whyBelieve),
    ];

    const response = await getOnboardingData(token, null, questionnaireKeys);
    console.log("Onboarding Data Response", response);
    setAnswers(response);
  };

  const saveData = async () => {
    const token = getSessionToken();
    const response = await saveOnboardingData(token, answers);
    console.log("Onboarding Data Save Response", response)
    // setAnswers(response);
  }

  const handleNext = () => {
    if (currentStep === 1) {
      if (aboutYouCurrentStep === calcNumQuestions(questionnaire.aboutYou))
        setCurrentStep(currentStep + 1);
      else setAboutYouCurrentStep(aboutYouCurrentStep + 1);
    }

    if (currentStep === 2) {
      if (
        aboutYourProductCurrentStep ===
        calcNumQuestions(questionnaire.aboutYourProduct)
      )
        setCurrentStep(currentStep + 1);
      else setAboutYourProductCurrentStep(aboutYourProductCurrentStep + 1);
    }

    if (currentStep === 3) {
      if (
        aboutYourCustomerCurrentStep ===
        calcNumQuestions(questionnaire.aboutBestCustomers)
      )
        setCurrentStep(currentStep + 1);
      else setAboutYourCustomerCurrentStep(aboutYourCustomerCurrentStep + 1);
    }

    if (currentStep === 4) {
      if (
        aboutYourMarketingCurrentStep ===
        calcNumQuestions(questionnaire.marketingPromise)
      )
        setCurrentStep(currentStep + 1);
      else setAboutYourMarketingCurrentStep(aboutYourMarketingCurrentStep + 1);
    }

    if (currentStep === 5) {
      if (
        aboutYourCredibilityCurrentStep ===
        calcNumQuestions(questionnaire.whyBelieve)
      )
        setCurrentStep(currentStep + 1);
      else
        setAboutYourCredibilityCurrentStep(aboutYourCredibilityCurrentStep + 1);
    }

    saveData();
  };

  const handlePrevious = () => {
    if (currentStep === 1) {
      if (aboutYouCurrentStep === 2)
        setAboutYouCurrentStep(aboutYouCurrentStep - 1);
      else return;
    }

    if (currentStep === 2) {
      if (aboutYourProductCurrentStep === 1) setCurrentStep(currentStep - 1);
      else setAboutYourProductCurrentStep(aboutYourProductCurrentStep - 1);
    }

    if (currentStep === 3) {
      if (aboutYourCustomerCurrentStep === 1) setCurrentStep(currentStep - 1);
      else setAboutYourCustomerCurrentStep(aboutYourCustomerCurrentStep - 1);
    }

    if (currentStep === 4) {
      if (aboutYourMarketingCurrentStep === 1) setCurrentStep(currentStep - 1);
      else setAboutYourMarketingCurrentStep(aboutYourMarketingCurrentStep - 1);
    }

    if (currentStep === 5) {
      if (aboutYourCredibilityCurrentStep === 1)
        setCurrentStep(currentStep - 1);
      else
        setAboutYourCredibilityCurrentStep(aboutYourCredibilityCurrentStep - 1);
    }
  };

  const handleComplete = async () => {
    console.log("complete", answers);

    // get the user id from the token in the local storage
    const token = getSessionToken();
    const { id } = await getTokenInfo(token);
    const userId = id;

    // loop through each answer (in data object) and insert into supabase as a new entity
    Object.keys(answers).forEach(async (key) => {
      // only run if data[key] is not an empty string
      if (answers[key] === "") return;

      // update or insert into supabase table based on whether or not the entity already exists
      // return count of entities with userId and type
      const { count } = await supabase
        .from("InformationEntities")
        .select("*", { count: "exact" })
        .eq("userId", userId)
        .eq("type", key);
      if (count === 0) {
        // insert
        const { data, error } = await supabase
          .from("InformationEntities")
          .insert([{ userId, type: key, content: answers[key] }])
          .select();
        // console.log('insert response', data)
      } else {
        // update
        const { data, error } = await supabase
          .from("InformationEntities")
          .update({ content: answers[key] })
          .eq("userId", userId)
          .eq("type", key)
          .select();
        // console.log('update response', data)
      }
    });
  };

  const steps = [
    {
      component: (
        <AboutYouSteps
          questions={questionnaire.aboutYou}
          currentStep={aboutYouCurrentStep}
          answers={answers}
          setAnswers={setAnswers}
        />
      ),
      id: "1",
      index: 1,
      // label: "About You",
    },
    {
      component: (
        <AboutYourProductSteps
          answers={answers}
          setAnswers={setAnswers}
          questions={questionnaire.aboutYourProduct}
          currentStep={aboutYourProductCurrentStep}
        />
      ),
      id: "2",
      index: 2,
      // label: "Your Product(s) or Service(s)",
    },
    {
      component: (
        <AboutYourCustomerSteps
          answers={answers}
          setAnswers={setAnswers}
          questions={questionnaire.aboutBestCustomers}
          currentStep={aboutYourCustomerCurrentStep}
        />
      ),
      id: "3",
      index: 3,
      // label: "Your Customers",
    },
    {
      component: (
        <AboutYourMarketingSteps
          answers={answers}
          setAnswers={setAnswers}
          questions={questionnaire.marketingPromise}
          currentStep={aboutYourMarketingCurrentStep}
        />
      ),
      id: "4",
      index: 4,
      // label: "Your Marketing",
    },
    {
      component: (
        <AboutYourCredibilitySteps
          answers={answers}
          setAnswers={setAnswers}
          questions={questionnaire.whyBelieve}
          currentStep={aboutYourCredibilityCurrentStep}
        />
      ),
      id: "5",
      index: 5,
      // label: "Your Credibility",
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page topBar={{ page: [{ label: "Onboarding" }] }}>
      <Container>
        <TabsComponent
          tabs={[
            { label: "Profile", value: "profile" },
            { label: "Domain Names", value: "domains" },
            { label: "Integrations", value: "integrations" },
          ]}
          activeTab={"onboarding"}
          setActiveTab={(val) => {
            window.location.href = `/${val}`;
          }}
          actionSection={false}
        />
        <Panel title="Welcome to Launch OS">
          <div className="w-full flex items-center justify-center py-16">
            <div className="max-w-[680px] flex-none w-full">
              {/* {JSON.stringify({ currentStep, aboutYouCurrentStep, aboutYourProductCurrentStep })} */}
              <Wizard
                currentStep={currentStep}
                showProgressIndicator
                showButtons={false}
                steps={steps}
              />
              <div className="flex justify-end items-center mt-8 gap-4">
                {aboutYouCurrentStep > 1 && (
                  <button
                    type="button"
                    className="btn"
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                )}

                {aboutYourCredibilityCurrentStep <
                  calcNumQuestions(questionnaire.whyBelieve) && (
                  <button type="button" className="btn" onClick={handleNext}>
                    Next
                  </button>
                )}

                {aboutYourCredibilityCurrentStep ===
                  calcNumQuestions(questionnaire.whyBelieve) && (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleComplete}
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </div>
        </Panel>
      </Container>
    </Page>
  );
};
