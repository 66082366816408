import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@/Apps/Pages/WebComponents/v2/Navigation/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FooterLayouts } from "../types";
import { IconSource } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.TEN,
  layout: FooterLayouts.TEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer10.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzr76bj2k8b0780wdwiwpk7",
    itemId: "ou7zpzz6c1s",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  maxWidth: "100%",
                  mobile: {
                    desktop: {
                      width: 400,
                    },
                    smartphone: {
                      width: "100%",
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                      mobile: {
                        desktop: {
                          textAlign: "left",
                        },
                        smartphone: {
                          textAlign: "center",
                        },
                      },
                    },
                    html: `Need more help?<br />Give us a call`,
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: style.BUTTON.style,
                    caption1: "Contact Us",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  width: 400,
                  maxWidth: "100%",
                  textAlign: "right",
                  mobile: {
                    desktop: {
                      display: "inline-block",
                    },
                    smartphone: {
                      display: "none",
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((4 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.HEADLINE,
                            canHaveChildren: false,
                            properties: {
                              ...style.SUBHEADLINE,
                              ...get(content, "main_headline.style", {}),
                            },
                            html: "Information",
                          },
                          {
                            type: ComponentTypes.NAVIGATION,
                            canHaveChildren: false,
                            linkStyle: style.NAVIGATION.linkStyle,
                            layoutStyle: LinkLayoutStyles.VERTICAL,
                            properties: {
                              padding: 20,
                            },
                            data: [
                              {
                                id: uniqueId(),
                                caption: "About Us",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                              {
                                id: uniqueId(),
                                caption: "Contact Us",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                              {
                                id: uniqueId(),
                                caption: "FAQs",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((4 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.HEADLINE,
                            canHaveChildren: false,
                            properties: {
                              ...style.SUBHEADLINE,
                              ...get(content, "main_headline.style", {}),
                            },
                            html: "Support",
                          },
                          {
                            type: ComponentTypes.NAVIGATION,
                            canHaveChildren: false,
                            linkStyle: style.NAVIGATION.linkStyle,
                            layoutStyle: LinkLayoutStyles.VERTICAL,
                            properties: {
                              padding: 20,
                            },
                            data: [
                              {
                                id: uniqueId(),
                                caption: "About Us",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                              {
                                id: uniqueId(),
                                caption: "Contact Us",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                              {
                                id: uniqueId(),
                                caption: "FAQs",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((4 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.HEADLINE,
                            canHaveChildren: false,
                            properties: {
                              ...style.SUBHEADLINE,
                              ...get(content, "main_headline.style", {}),
                            },
                            html: "Features",
                          },
                          {
                            type: ComponentTypes.NAVIGATION,
                            canHaveChildren: false,
                            linkStyle: style.NAVIGATION.linkStyle,
                            layoutStyle: LinkLayoutStyles.VERTICAL,
                            properties: {
                              padding: 20,
                            },
                            data: [
                              {
                                id: uniqueId(),
                                caption: "About Us",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                              {
                                id: uniqueId(),
                                caption: "Contact Us",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                              {
                                id: uniqueId(),
                                caption: "FAQs",
                                linkDisplayType: LinkDisplayTypes.TEXT,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          textAlign: "center",
          marginTop: 60,
          padding: 0,
          fontSize: "9pt",
          ...get(content, "sub_headline.style", {}),
        },
        html: `© ${moment().format("YYYY")} All Rights Reserved`,
      },
    ],
  },
};

export default settings;
