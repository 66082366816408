import { Page } from "@/ui/Layout";
// import { LeftSidebar } from "../Layout";
// import { RightSidebar } from "../Layout";
// import { Footer } from "../Layout";
import { Status } from "@/ui/types";
import { ProjectWidget } from "@/ui/Card/ProjectWidget";
import { Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";

const image = [
  <img
    className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
    src="/assets/images/dropbox.png"
    alt=""
  />,
  <img
    className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
    src="/assets/images/badoo.png"
    alt=""
  />,
  <img
    className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
    src="/assets/images/invision.png"
    alt=""
  />,
];

const logo = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
      fill="currentcolor"
    />
  </svg>
);

export const Sandcastle = () => {
  const allProjects = [
    {
      id: "lkdjsfdfsdklfjs",
      label: "Sandcastle Project #1",
      caption: "This is where a brief description would go",
      img: image[0],
      status: Status.INPROGRESS,
      sharedWith: logo,
      taskDetails: {
        completedNum: 13,
        totalNum: 49,
      },
    },
  ];

  return (
    <Page topBar={{ page: [{ label: "Sandcastle" }] }}>
      <Container>
         <Grid className="pb-6">
          {allProjects.map((project, key) => (
            <Card
              key={key}
              type="Task"
              label={project.label}
              caption={project.caption}
              image={project.img}
              status={project.status}
              sharedWith={project.sharedWith}
              // taskDetails={project.taskDetails}
              // href={`/sandcastleIDE/${project.id}`}
              href={`/sandcastleIDE`}
            />
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default Sandcastle;
