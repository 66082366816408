import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { doIShow, combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.NINETEEN,
  layout: HeaderLayouts.NINETEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header19.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "rq3m4u9upqc",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 15,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {
                  display: "inline-block",
                  mobile: {
                    smartphone: {
                      width: 150,
                      maxWidth: "100%",
                      textAlign: "center",
                      padding: 20,
                    },
                  },
                },
                src:
                  "http://www.clker.com/cliparts/H/L/l/s/M/J/stop-sign-md.png",
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 85,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                },
                html: "WAIT! YOUR ORDER IS NOT COMPLETE YET...",
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                },
                html: "(STEP 2 OF 3)",
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: false,
                properties: {},
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: style.TEXT,
                    html: `- Do Not Click the "Back Button" -`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
