import * as React from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { FacebookLoginProps, PluginProps } from "./types";

import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";


import Button from "../Button/live";
import buttonActions from "../Button/actions";

// import buttonActions from "@launchos/plugins/webcomponents/v1/button/actions";
// import { EditorMode } from "@/Apps/Pages/Editor/types";

export const FacebookLoginComponent: React.FC<FacebookLoginProps> = ({
  style,
  properties,
  appId = "169111273166243",
  autoLoad = false,
  fields = "name,email,picture,first_name,last_name",
  onClick = (e) => console.log("default clicked", e),
  callback = (response) => console.log(response),
  buttonSettings,
}) => {
  return (
    <div data-testid="WC-FACEBOOKLOGIN-LIVE" style={style}>
      <FacebookLogin
        appId={appId}
        fields="name,email,picture"
        // onClick={() => console.log("clicked")}
        callback={callback}
        render={(props) => (
          <Button
            {...buttonSettings}
            mode={EditorMode.LIVE}
            style={properties}
            onClick={props.onClick}
          />
        )}
      />
    </div>
  );
};

const FacebookLoginPlugin: React.FC<PluginProps> = ({
  settings,
  dispatch,
  updateComponentSettings,
  getComponentSettings,
}) => {
  const properties = convertProperties(settings.properties);
  const { actions } = settings;

  const handleClick = () => {
    console.log("clicked", actions);

    // trigger any provided actions
    if (actions) {
      // const doTheseActions = actions.filter(itm => itm.behavior === "click");
      actions.forEach((action) =>
        dispatch({
          ...action,
          settings,
          updateComponentSettings,
          getComponentSettings,
        })
      );
    }
  };

  const handleCallback = (e) => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) return null;
    // console.log(e, actions);

    const {
      status,
      name,
      email,
      first_name,
      last_name,
      accessToken,
      userID,
      signedRequest,
      id,
      graphDomain,
      expiresIn,
      data_access_expiration_time,
    } = e;

    console.log({
      status,
      name,
      email,
      first_name,
      last_name,
      accessToken,
      userID,
      signedRequest,
      id,
      graphDomain,
      expiresIn,
      data_access_expiration_time,
    });

    // handle the optin submission
    // append two input fields with name and email (if none exists)
    const body = document.getElementsByTagName("BODY")[0];

    // Full Name
    const nameInput = document.createElement("input");
    nameInput.type = "hidden";
    nameInput.name = "name";
    nameInput.value = name;
    body.appendChild(nameInput);

    // First Name
    const firstNameInput = document.createElement("input");
    firstNameInput.type = "hidden";
    firstNameInput.name = "firstName";
    firstNameInput.value = first_name;
    body.appendChild(firstNameInput);

    // Last Name
    const lastNameInput = document.createElement("input");
    lastNameInput.type = "hidden";
    lastNameInput.name = "lastName";
    lastNameInput.value = last_name;
    body.appendChild(lastNameInput);

    // Email Name
    const emailInput = document.createElement("input");
    emailInput.type = "hidden";
    emailInput.name = "email";
    emailInput.value = email;
    body.appendChild(emailInput);

    // submit / then go to url provided in action
    buttonActions.submitForm({
      behavior: "click",
      type: "SUBMIT_FORM",
      payload: {
        ...get(settings, "actions[0].payload", {}),
      },
      settings,
      updateComponentSettings,
      getComponentSettings,
    });
  };

  return (
    <V1ObjectWrapper settings={settings}>
      <FacebookLoginComponent
        {...settings}
        properties={properties}
        onClick={handleClick}
        callback={handleCallback}
      />
    </V1ObjectWrapper>
  );
};

const ConnectedFacebookLivePlugin = connect()(FacebookLoginPlugin);

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FACEBOOKLOGIN
  ) {
    return [ConnectedFacebookLivePlugin];
  }
};

export default FacebookLoginComponent;
