import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";

import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.ONE,
  layout: ContentLayouts.ONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckky7r33x28f507806a3vy0ss",
    itemId: "u5j1r64m5rf",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: "Do You Make These Mistakes In Your Business?",
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
        },
        html: `It's true.  Getting results is much easier than you think.  The truth is most people simply don't know the correct steps to take so they become frustrated and quit`,
      },
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          marginTop: 50,
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      fontSize: "18pt",
                      padding: 0,
                      mobile: {
                        desktop: {
                          fontSize: "18pt",
                        },
                        smartphone: {
                          fontSize: "12pt",
                        },
                      },
                    },
                    html: "Lorem",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html:
                      "The FOUR tried and true secrets to the having the best year!",
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      fontSize: "18pt",
                      padding: 0,
                      mobile: {
                        desktop: {
                          fontSize: "18pt",
                        },
                        smartphone: {
                          fontSize: "12pt",
                        },
                      },
                    },
                    html: "Ipsum",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html: "How To Make The Search Engines Work For You",
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((4 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      fontSize: "18pt",
                      padding: 0,
                      mobile: {
                        desktop: {
                          fontSize: "18pt",
                        },
                        smartphone: {
                          fontSize: "12pt",
                        },
                      },
                    },
                    html: "Dolor",
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                    html:
                      "THe FIVE Essential Sales Techniques for mastering your",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
