import { useChat } from "ai/react";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { getCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { ArtifactContainerProps } from "../types";

import { PageBuilderArtifact } from "./PageBuilderArtifact";
import { ContentBuilderArtifact } from "./ContentBuilderArtifact";
import { CampaignBuilderArtifact } from "./CampaignBuilderArtifact";
import { BuilderArtifactProps } from "./types";


export const ArtifactContainer: React.FC<ArtifactContainerProps> = ({
  id,
  campaignId,
  type,
  prompt,
  title,
  // pageType,
  setTopBarButtons = () => {},
  setArtifactOptions = () => {},
  chatBotHelpers = {},
  children,
}) => {

  const { messages, setMessages, isLoading, append } = useChat({
    api: `${AI_DOMAIN}/api/copilot/artifacts/${type === "page builder" ? "page" : "content"}`,
  //   onFinish: async (message) => {
  //     console.log("Done Generating Artifact!")
  //     // console.log("Appending Message with", message);
  //     if (message.toolInvocations) {
  //       const msgContent = message.toolInvocations?.map((itm) => itm.args);
  //       const msg = { role: "assistant", content: JSON.stringify(msgContent) };

  //       console.log("Appending Message with", msg, chatBotHelpers);
  //       await chatBotHelpers?.append(msg);
  //       // update the message object
  //       // await chatBotHelpers?.createOrUpdateChat(msg);
  //       const response = await getObjects(campaignId);
  //       const object = head(response?.data?.objects);
  //       const messages = object?.settings?.messages;
  //       await updateObject(object?.id, {
  //         settings: { ...object?.settings, messages: [...messages, msg] },
  //       });
  //     }
  //   },
  });

  const artifactExists = async (id) => {
    const token = getSessionToken();
    const data = await getCampaign({ token, id: campaignId });
    const { objects } = data;

    console.log(
      `ARTIFACT CONTAINER: Looking for artifactId (${id}) in`,
      objects
    );

    // Check if an object with the artifact ID already exists
    const existingObject = objects.find(
      (obj) => obj.settings?.artifactId && obj.settings?.artifactId === id
    );

    console.log(
      "ARTIFACT CONTAINER: checking if object already exists",
      existingObject
    );

    return existingObject;
  };  

  const artifactBuilderProps: BuilderArtifactProps = {
    id,
    prompt,
    chatId: campaignId,
    title,
    setTopBarButtons,
    chatBotHelpers,
    artifactExists,
    setArtifactOptions,
    append,
    isLoading,
  }

  if (!id) return <div className="h-full w-full overflow-y-auto">{children}</div>;
  else if ((type === "page builder" || type.startsWith("page")) && campaignId) {
    return <PageBuilderArtifact  {...artifactBuilderProps} />
    } else if (type === "content builder" || type.startsWith("content")) {
    return <ContentBuilderArtifact {...artifactBuilderProps} />  
    } else if (type === "funnel builder" || type.startsWith("campaign")) {
    return <CampaignBuilderArtifact {...artifactBuilderProps} />
  }
};

export default ArtifactContainer;
