import { has, isArray } from "lodash";
import apps from "@/Apps/client";

import webcomponents_v1 from "@/Apps/Pages/WebComponents/v1/client";
import webcomponents_v2 from "@/Apps/Pages/WebComponents/v2/client";

import weblayouts from "@/Apps/Pages/Sections/WebLayouts/client";

export const plugins = [
  ...apps,
  ...webcomponents_v1,
  ...webcomponents_v2,
  ...weblayouts
]

export const triggerHook = (
  name: string,
  hook: any = {}, // Replace 'any' with appropriate type for 'hook' parameter
  payload: any = {}, // Replace 'any' with appropriate type for 'payload' parameter
  actions: any = {}, // Replace 'any' with appropriate type for 'actions' parameter
  contextObj: any | null = {} // Replace 'any' with appropriate type for 'contextObj' parameter
) => {
  let toRet: any[] = [];

  try {

    for (const itm of plugins) {
      if (has(itm, name)) {
        const res = itm[name](hook, payload, actions, contextObj) || [];
        let ret = isArray(res) ? res : [res];
        // console.log({ ret, name, hook, payload, actions });
        const response: any[] = ret;
        response.forEach((itm) => toRet.push(itm));
      }
    }

    return toRet;

  } catch (error) {
    console.error("Failed", error);
    return []; // Return an empty array if an error occurs
  }
};

export const triggerAsyncHook = async (
  name: string,
  hook: any = {}, // Replace 'any' with appropriate type for 'hook' parameter
  payload: any = {}, // Replace 'any' with appropriate type for 'payload' parameter
  actions: any = {}, // Replace 'any' with appropriate type for 'actions' parameter
  contextObj: any | null = {} // Replace 'any' with appropriate type for 'contextObj' parameter
) => {
  let toRet: any[] = [];

  // const { pluginUrls }: { pluginUrls: string[] } = contextObj || {};

  try {
    // const plugins: any[] = [];

    // for (const url of pluginUrls) {
    //   try {
    //     const module = await import(url /* @vite-ignore */);
    //     // console.log(module); // Log the entire module object to inspect its structure
    //     const result = module.onListItems({ id: "AppDrawer", position: "top" });
    //     console.log(result);
    //     plugins.push(module); // Move this line inside the try block
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    // console.log({ plugins }, hook, payload, actions);

    for (const itm of plugins) {
      if (has(itm, name)) {
        const res = await itm[name](hook, payload, actions, contextObj) || [];
        let ret = isArray(res) ? res : [res];

        // console.log({ ret, name, hook, payload, actions });
        const response: any[] = ret;
        for (let i = 0; i < response.length; i++) {
          await toRet.push(response[i]);
        }
      }
    }

    return toRet;
  } catch (error) {
    console.error("Failed", error);
    return []; // Return an empty array if an error occurs
  }
};
