import { z } from "zod";

export const headlineSchema = z.object({
  type: z
    .enum(["Headline"])
    .describe(
      `the name of the element (will always be "Headline" for headlines)`
    ),
  html: z
    .string()
    .describe(
      `the inner html representing the content of the headline.  innher html often includes fonts between size 1.2rem and 2.5rem.  For example: '<p><span style="font-size: 1.8rem; color: #2a3966;"><strong>Headline Content Here</strong></span></p>'`
    ),
  properties: z
    .object({
      display: z.string().describe("inline-block"),
      padding: z.number(),
      marginTop: z.number().optional(),
      textAlign: z.enum(["left", "center", "right"]),
    })
    .describe(
      "a CSS-in-JS json object representing the css styling that should be applaied to the element"
    ),
  parent: z
    .string()
    .describe("the id of the object that this element is to be a child of"),
  id: z.string().describe("a unique string used to identify the element."),
});

export const textSchema = z.object({
  type: z
    .enum(["Text"])
    .describe(
      `the name of the element (will always be "Text" for text elements)`
    ),
  html: z
    .string()
    .describe(
      `the inner html representing the content of the text.  For example: '<p><span style="font-size: 1.1rem; font-family: roboto;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</span></p>'`
    ),
  properties: z
    .object({
      display: z.string().describe("inline-block"),
      padding: z.number(),
      marginTop: z.number().optional(),
      textAlign: z.enum(["left", "center", "right"]),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const buttonSchema = z.object({
  type: z
    .enum(["AdvancedButton"])
    .describe(
      `the name of the element (will always be "AdvancedButton" for buttons)`
    ),
  label: z
    .string()
    .optional()
    .describe(
      `a short one or two word phrase describing the button (e.g. "Button")`
    ),
  properties: z
    .object({
      display: z.string(),
      textAlign: z.enum(["left", "center", "right"]),
      padding: z.string(),
      marginTop: z.number().optional(),
      border: z.string().optional(),
      borderRadius: z.number().optional(),
      backgroundColor: z.string(),
      boxShadow: z.string().optional(),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  hoverStyle: z
    .object({})
    .optional()
    .describe(`the hover style.  ignore for now.`),
  caption1: z
    .string()
    .describe(
      `the caption to use as the primary label for the button (e.g. "Add to Cart")`
    ),
  caption1style: z
    .object({
      color: z.string(),
      display: z.string(),
      fontFamily: z.string(),
      fontSize: z.string(),
      fontWeight: z.string(),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element's primary caption`
    ),
  caption2: z
    .string()
    .optional()
    .describe(
      `the caption to use as the secondary label for the button (e.g. "I'm ready to buy")`
    ),
  caption2style: z
    .object({
      color: z.string(),
      display: z.string(),
      fontFamily: z.string(),
      fontSize: z.string(),
      fontWeight: z.string(),
    })
    .optional()
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element's secondary caption`
    ),
  actions: z
    .array(
      z.object({
        type: z
          .enum(["TRIGGER_NEXT_NODE", "GO_TO_URL"])
          .describe(`the type of action to take`),
        name: z
          .string()
          .describe(
            `The name of the action in text mode (e.g. "Go to the next page"`
          ),
        payload: z
          .union([
            z.object({
              url: z
                .string()
                .optional()
                .describe(
                  `The url or url template to send when the user performs the action (e.g. "{next_page}" or "https://www.launchos.com")`
                ),
            }),
            z.boolean(),
          ])
          .describe(`The data payload to send with the action`),
        behavior: z
          .enum(["click"])
          .describe(`The behavior that triggers the action (e.g. a "click")`),
      })
    )
    .describe(
      `an array of actions (e.g. "Go to the next page") for the button to take when a behavior (e.g. a "click" is detected)`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const pictureSchema = z.object({
  type: z
    .enum(["Picture"])
    .describe(
      `the name of the element (will always be "Picture" for text elements)`
    ),
  src: z
    .string()
    .describe(
      "the img src (url). Always use https://assets-pages.s3.amazonaws.com/drop_img-02.jpg as a placeholder."
    ),
  properties: z
    .object({
      display: z.string().describe("inline-block"),
      textAlign: z.enum(["left", "center", "right"]),
      width: z
        .string()
        .describe(
          `usually set to "100%," letting the width of the parent container contain it.`
        ),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const videoSchema = z.object({
  type: z
    .enum(["Video"])
    .describe(
      `the name of the element (will always be "Video" for text elements)`
    ),
  src: z.object({
    mp4: z
      .string()
      .describe(
        "the src url of the video in mp4 format.  Always use https://www.youtube.com/watch?v=OfIQW6s1-ew as a placeholder."
      ),
    ogg: z
      .string()
      .describe(
        "the src url of the video in ogg format.  Always use https://www.youtube.com/watch?v=OfIQW6s1-ew as a placeholder."
      ),
  }),
  properties: z
    .object({
      display: z.string().describe("inline-block"),
      textAlign: z.enum(["left", "center", "right"]),
      width: z
        .string()
        .describe(
          `usually set to "100%," letting the width of the parent container contain it.`
        ),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const dividerSchema = z.object({
  type: z
    .enum(["Divider"])
    .describe(
      `the name of the element (will always be "Divider" for text elements)`
    ),
  properties: z
    .object({
      width: z
        .string()
        .describe(
          `often set to "100%," but could be less (if less, use rem units for the width).`
        ),
      height: z
        .number()
        .describe(
          `represents the "thickness" of the divider. often set to a small number (like 3-5 pixels)`
        ),
      display: z.string().describe("inline-block"),
      textAlign: z.enum(["left", "center", "right"]),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  color: z.string().describe("the color of the divider (e.g. #f05a1f)"),
  dividerType: z.enum(["Horizontal"]),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const formFieldSchema = z.object({
  type: z
    .enum(["Form Field"])
    .describe(
      `the name of the element (will always be "Form Field" for form field elements)`
    ),
  fieldType: z.enum(["Text"]), // add more later
  properties: z
    .object({
      display: z.string().describe("inline-block"),
      borderWidth: z
        .number()
        .describe("the width of the text field border (usually set to 1)"),
      borderRadius: z
        .number()
        .describe("the border radius of the text field (usually set to 5)"),
      borderStyle: z
        .number()
        .describe(
          "the border style of the text field (usually set to 'solid')"
        ),
      width: z
        .string()
        .describe(
          `often set to "100%," but could be less (if less, use rem units for the width).`
        ),
      padding: z
        .string()
        .describe(`the padding (in rem unit format).  usually set to 0.6rem`),
      textAlign: z.enum(["left", "center", "right"]),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const progressSchema = z.object({
  type: z
    .enum(["Progress"])
    .describe(
      `the name of the element (will always be "Progress" for progress bar elements)`
    ),
  value: z
    .number()
    .min(0)
    .max(100)
    .describe(
      "The percentage of progress, represented as a number between 0 and 100"
    ),
  properties: z
    .object({
      display: z.string().describe("inline-block"),
      width: z
        .string()
        .describe(
          `often set to "100%," but could be less (if less, use rem units for the width).`
        ),
      textAlign: z.enum(["left", "center", "right"]),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const spacerSchema = z.object({
  type: z
    .enum(["Spacer"])
    .describe(
      `the name of the element (will always be "Spacer" for spacer elements)`
    ),
  properties: z
    .object({
      height: z
        .string()
        .describe(
          `the height of the spacer in rem units (e.g. "2.5rem") which determines the amount of vertical space between elements.`
        ),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const listItemSchema = z.object({
  type: z
    .enum(["ListItem"])
    .describe(
      `The type of the element (always "ListItem" for list item elements)`
    ),
  icon: z
    .string()
    .describe(
      "The name or identifier of the Material UI or Font Awesome icon to be displayed alongside the list item"
    ),
  iconSource: z
    .enum([0, 1])
    .describe(
      "The source of the icon, either from Material Icons (0) or a Font Awesome (1) custom source"
    ),
  iconStyle: z
    .object({
      fontSize: z.string().describe("e.g. 28pt"),
      padding: z.string().describe("e.g. 0 15px"),
      color: z.string().describe("e.g. #333333"),
    })
    .describe("CSS properties for styling the icon"),
  html: z
    .string()
    .describe(
      "The HTML content of the list item, typically containing text and formatting"
    ),
  properties: z
    .object({
      display: z
        .string()
        .describe("CSS display property, usually set to 'inline-block'"),
    })
    .describe("CSS properties for styling the list item container"),
  parent: z
    .string()
    .describe("The ID of the parent element that this list item belongs to"),
  id: z.string().describe("A unique identifier for this list item element"),
});

export const countdownSchema = z.object({
  type: z
    .enum(["Countdown"])
    .describe(
      `the name of the element (will always be "Countdown" for countdown timer elements)`
    ),
  countType: z
    .enum(["EVENT"])
    .describe('The type of countdown (currently only supports "EVENT")'),
  eventDate: z
    .number()
    .describe(
      "The target date for the countdown, represented as a Unix timestamp in milliseconds"
    ),
  properties: z
    .object({
      textAlign: z.enum(["left", "center", "right"]),
      display: z
        .string()
        .describe('CSS display property, usually set to "inline-block"'),
    })
    .describe(`CSS properties for styling the countdown container`),
  dimensionStyle: z
    .object({
      minWidth: z
        .number()
        .describe("Minimum width of the countdown element in pixels"),
    })
    .describe(`Dimensional styling for the countdown element`),
  labelStyle: z
    .object({
      fontWeight: z.string(),
      fontFamily: z.string(),
      fontSize: z.string(),
    })
    .describe(
      `CSS properties for styling the labels (days, hours, minutes, seconds)`
    ),
  numberStyle: z
    .object({
      textAlign: z.enum(["left", "center", "right"]),
      fontSize: z.string(),
      fontFamily: z.string(),
      fontWeight: z.string(),
      padding: z.number(),
      backgroundColor: z.string(),
      color: z.string(),
      margin: z.number(),
    })
    .describe(`CSS properties for styling the countdown numbers`),
  dividerStyle: z
    .object({
      padding: z.number(),
      fontWeight: z.string(),
      fontFamily: z.string(),
    })
    .describe(
      `CSS properties for styling the dividers between countdown units`
    ),
  showDivider: z
    .boolean()
    .describe(`Whether to show dividers between countdown units`),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const navigationSchema = z.object({
  type: z
    .enum(["Navigation"])
    .describe(
      `The name of the element (will always be "Navigation" for navigation elements)`
    ),
  canHaveChildren: z
    .boolean()
    .describe(
      `Whether or not child elements are allowed (this is always set to false for navigation)`
    ),
  linkStyle: z
    .object({
      listStyle: z.string(),
      display: z.string().describe("inline-block"),
      padding: z.string(),
      fontFamily: z.string(),
    })
    .describe(`CSS properties for styling individual navigation links`),
  layoutStyle: z
    .enum(["HORIZONTAL", "VERTICAL"])
    .describe(`The layout style of the navigation links`),
  properties: z
    .object({
      padding: z.number(),
      textAlign: z.enum(["left", "center", "right"]),
      display: z.string().describe("inline-block"),
    })
    .describe(`CSS properties for styling the navigation container`),
  data: z
    .array(
      z.object({
        id: z.string(),
        caption: z.string(),
        linkDisplayType: z.enum(["TEXT", "ICON", "BOTH"]),
      })
    )
    .describe(
      `An array of navigation items, each with an id, caption, and display type`
    ),
  parent: z
    .string()
    .describe(`The id of the object that this element is to be a child of`),
  id: z.string().describe(`A unique string used to identify the element`),
});

export const sectionSchema = z.object({
  type: z
    .enum(["Section"])
    .describe(
      `the name of the element (will always be "Section" for sections)`
    ),
  canHaveChildren: z
    .boolean()
    .describe(
      `whether or not child elements are allowed (this is always set to "true")`
    ),
  properties: z
    .object({
      display: z.string(),
      width: z
        .string()
        .describe(
          `represents the "inner width" of the section.  it's a way to force all child element never to exceed this width.  this is useful for establishing cohesive page margins`
        ),
      padding: z.string(),
      marginTop: z.number().optional(),
      backgroundColor: z.string(),
      maxWidth: z.string().describe(`this is always "100%"`),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const containerSchema = z.object({
  type: z
    .enum(["Container"])
    .describe(
      `the name of the element (will always be "Container" for containers)`
    ),
  canHaveChildren: z
    .boolean()
    .describe(
      `whether or not child elements are allowed (this is always set to "true")`
    ),
  properties: z
    .object({
      width: z.string(),
      display: z.enum(["flex"]),
      justifyContent: z.enum(["flex-start", "center", "flex-end"]),
      backgroundColor: z.string().optional(),
      maxWidth: z.string().describe(`usually always set to "100%"`),
      height: z.string().optional(),
      overflow: z.string().optional(),
      marginTop: z.number().optional(),
      padding: z.string().optional(),
      borderRadius: z.number().optional(),
      shadowStyle: z.string().optional(),
      boxShadow: z.string().optional(),
      shadowColor: z.string().optional(),
      shadowBlur: z.number().optional(),
      shadowRadius: z.number().optional(),
      shadowVerticalOffset: z.number().optional(),
      shadowHorizontalOffset: z.number().optional(),
      borderWidth: z.number().optional(),
      borderStyle: z.string().optional(),
      borderColor: z.string().optional(),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const columnSchema = z.object({
  type: z
    .enum(["Column"])
    .describe(
      `the name of the element (will always be "Column" for column elements)`
    ),
  canHaveChildren: z
    .boolean()
    .describe(
      `whether or not child elements are allowed (this is always set to "true")  `
    ),
  preventDelete: z
    .boolean()
    .describe(
      `whether or not to prevent the ability for the page editor to delete this object (this is always set to "true" for "Column" elements.`
    ),
  md: z
    .number()
    .describe(
      `the number of percentage points to use for the width of the column with respect to the parent column object (e.g. in a 2 equal-sized column, row), there will be two columns, each with their md value set to 50.`
    ),
  properties: z.object({
    height: z.string().describe(`always set to "100%"`),
    padding: z.number(),
    minHeight: z
      .number()
      .describe(
        `usually set to 20, in order for the parent columns element to be visible in the editor`
      ),
  }),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const columnsSchema = z.object({
  type: z
    .enum(["Columns"])
    .describe(
      `the name of the element (will always be "Columns" for columns/row elements)`
    ),
  label: z
    .string()
    .describe(`the label to give the element (this is usually labeled "Row")`),
  canHaveChildren: z
    .boolean()
    .describe(
      `whether or not child elements are allowed (this is always set to "true")  `
    ),
  properties: z
    .object({
      width: z.string().describe(`usually set to "100%"`),
      maxWidth: z.string(),
    })
    .describe(
      `a CSS-in-JS json object representing the css styling that should be applied to the element`
    ),
  parent: z
    .string()
    .describe(`the id of the object that this element is to be a child of`),
  id: z.string().describe(`a unique string used to identify the element.`),
});

export const allElementsSchema = {
    headlineSchema,
    textSchema,
    buttonSchema,
    pictureSchema,
    videoSchema,
    dividerSchema,
    formFieldSchema,
    progressSchema,
    spacerSchema,
    listItemSchema,
    countdownSchema,
    navigationSchema,
    sectionSchema,
    containerSchema,
    columnSchema,
    columnsSchema,
}