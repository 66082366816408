import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.FIFTEEN,
  layout: ContentLayouts.FIFTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content15.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyb60t929qm07804oando29",
    itemId: "lb9m2rg029s",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 15, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: false,
                properties: {
                  paddingRight: 30,
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                    },
                    html:
                      "Free report reveals how to get the most out of your cillum dolore eu fugiat nulla",
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 15, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                },
                html: `<p><strong>Why are we doing this?</strong></p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br /> <br /> It's true. Getting results is much easier than the relationship between the links it contains so that users understand the options they have for navigating the site.`,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
