import * as React from "react";
import Helmet from "react-helmet";
// import { getDefaultFontSize } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/style";
import { getDefaultFontSize } from "@/Apps/Pages/MobileResponsiveToggle/style";

// import {
//   MobileState,
//   MobileStateObject,
// } from "@launchos/modules/editor/Builder/MobileResponsiveToggle/types";

import { MobileResponsivenessProps } from "./types";
import { MobileState, MobileStateObject } from "@/Apps/Pages/MobileResponsiveToggle/types";

const MobileResponsiveness: React.FC<MobileResponsivenessProps> = ({
  onChange = () => null,
}) => {
  const [currentMobileState, setCurrentMobileState] = React.useState<
    MobileStateObject
  >({ type: MobileState.FULLSCREEN });

  var wdt = 0;

  const resize = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) return null;

    const d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName("body")[0],
      width = documentElement.clientWidth || body.clientWidth;

    if (width !== wdt) {
      wdt = width;

      if (width >= 1400)
        setCurrentMobileState({ type: MobileState.FULLSCREEN });
      else if (width >= 1000 && width < 1400)
        setCurrentMobileState({ type: MobileState.DESKTOP });
      else if (width >= 768 && width < 1000)
        setCurrentMobileState({ type: MobileState.TABLET });
      else if (width < 768)
        setCurrentMobileState({ type: MobileState.SMARTPHONE });
    }
  };

  React.useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) return null;

    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  React.useEffect(() => {
    onChange(currentMobileState);
  }, [currentMobileState]);

  const fontSize = getDefaultFontSize(currentMobileState.type);

  return (
    <Helmet>
      <style type="text/css">{`html { font-size: ${fontSize}px; }`}</style>
    </Helmet>
  );
};

export default MobileResponsiveness;
