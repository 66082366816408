import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { doIShow, combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.TWENTYTHREE,
  layout: HeaderLayouts.TWENTYTHREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header23.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "0zbffpbzjyj",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src:
                  "https://www.uspress.com/files/subscribers/7e7e52a8-b70b-43df-876c-112468d2144f/sites/324eaf79-40cf-42ed-b0ae-0e868b8a548b/products/389f7c35-0a05-4bef-9850-3a2a4889d847/Perfect-bound-booklet03_xlarge.png?stamp=637165994140026116",
                style: {
                  height: "100%",
                  backgroundColor: theme.backgroundColor,
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: style.HEADLINE,
                html: `Free Download: ${startCase(lorem.generateWords(3))}`,
              },

              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: style.SUBHEADLINE,
                html: `How to ${startCase(lorem.generateWords(8))}`,
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: { ...style.BUTTON.style, marginTop: 40 },
                caption1: "DOWNLOAD NOW",
                caption1style: style.BUTTON.caption1,
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: { ...style.TEXT, fontSize: "10pt" },
                html: lorem.generateSentences(1),
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
