import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { Grid } from "@material-ui/core";
import { useDimensions } from "@/Apps/Pages/utils";

import { DateFieldProps } from "../types";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { AttributeContainer } from "@/ui/CRUD/AttributeBuilder";
import { AttributeNames } from "@/ui/CRUD/AttributeBuilder/types";
import IconSelector from "../../../../Properties/components/properties/IconSelector";
import { LiveFormFieldProps } from "../../LiveFormField/types";
import { LiveDateField } from "./LiveDateField";

const DateField: React.FC<DateFieldProps> = (props) => {
  const [ref, iconDimensions] = useDimensions();
  const {
    mode = EditorMode.LIVE,
    // data = [],
    onChange = () => null,
    onBlur = () => null,
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    name,
    label,
    description,
    defaultValue,
    disabled,
    icon,
    columns = 12,
    attributeContainerDataSettings = {},
  } = props;
  /**
   * Deals with any changes that comes from the various attributes that are loaded
   *
   * • Converts to LiveFormFieldProps shape
   * • Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   * @params
   */
  const handleChange = (data: LiveFormFieldProps): void => {
    console.log(data.target.value);
    onChange(data);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;
  const inputPadding = get(inputStyle, 'padding', 'inherit')
  return (
    <div data-testid="FormBuilder-FormFields-DateField" style={containerStyle}>
      {mode === EditorMode.LIVE && (
        <LiveDateField ref={ref} styles={styles} onBlur={onBlur} iconDimensions={iconDimensions} label={label} description={description} defaultValue={defaultValue} icon={icon} handleChange={handleChange} inputPadding={inputPadding}></LiveDateField>
      )}
      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-DateField-EDITOR">
          <AttributeContainer
            onChange={handleChange}
            onBlur={onBlur}
            attributes={props}
            data={[
              {
                id: "label",
                attribute: AttributeNames.LABEL,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "description",
                attribute: AttributeNames.DESCRIPTION,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "name",
                attribute: AttributeNames.FORMITEMNAME,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "required",
                attribute: AttributeNames.REQUIRED,
                settings: {},
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "initialvalue",
                attribute: AttributeNames.INITIALVALUE,
                settings: {
                  // hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "iconSelector",
                attribute: AttributeNames.ICON,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default DateField;
