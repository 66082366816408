import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

const settings: IComponentSettings = {
  id: ComponentTypes.PROGRESS,
  name: "Progress",
  description: "",
  thumbnail: "comp_progress.png",
  default: {
    type: ComponentTypes.PROGRESS,
    value: 75,
    properties: {
      display: "inline-block",
      width: "100%",
      textAlign: "center",
    },
  },
  properties: {
    main: {
      sections: [
        {
          type: SectionTypes.BUTTONSLIDER,
          sectionWrap: true,
          settings: {
            label: "Progress Bar Amount",
            icon: "schedule", //hourglass_empty, schedule, slow_motion_video
            text: "Amount",
            button1: { icon: "remove", value: "SHRINK" },
            button2: { icon: "add", value: "GROW" },
            minValue: 0,
            maxValue: 100,
            value: 50,
            showAutoCheck: false,
            isExpanded: true,
          },
        },
        {
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Progress Bar Width",
            text: "Width",
            icon: "swap_horiz",
            showAutoCheck: false,
            // isExpanded: true,
          },
        },
        {
          type: SectionTypes.COLORPICKER,
          sectionWrap: true,
          settings: {
            label: "Progress Bar Color",
            icon: "colorize",
          },
        },
        {
          type: SectionTypes.POSITION,
          sectionWrap: true,
          settings: {
            label: "Progress Bar Position",
            icon: "swap_vert",
          },
        },
        {
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Progress Bar Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
        // {
        //   type: SectionTypes.PRESETS,
        //   settings: {},
        // },
        // {
        //   type: SectionTypes.ACTIONS,
        //   settings: {},
        // },
      ],
    },
  },
};

export default settings;
