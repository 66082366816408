import Panel from "@/ui/Panel";
import { Copy } from "@phosphor-icons/react";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { useEffect, useState } from "react";

export const QRCodeView = ({ id }) => {

  // const [data, setData] = useState<any>({});
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   // console.log("NextDestinationView");
  //   (async () => {
  //     // fetch details from http://localhost:8100/next/{id}
  //     const resp = await fetch(`https://pfoc-0e8afd4d1bb7.herokuapp.com/next/${id}`);
  //     const data = await resp.json();
  //     setData(data);
  //     console.log("next destination data", data);
  //     setLoading(false);
  //   })();
  // }, []);


  const link = `https://www.prayingforourchildren.org/print-ready-next-destination-page/?id=${id}`;

  // if (loading) return <div>Loading...</div>;

  return (
    <Panel
      title={
        <h3 className="text-sm font-semibold">QR Code & Shipping Label Link</h3>
      }
      actionButton={
        <a href={link} target="_blank">
          <Button type={ButtonTypes.SOFT} label="Open Shipping Label Link" />
        </a>
      }
    >
      <div className="grid md:grid-cols-12 gap-5 w-full">
        <div className="flex flex-col md:col-span-3">
          <img src={`https://pfoc-0e8afd4d1bb7.herokuapp.com/${id}`} />
        </div>
        <div className="flex flex-col md:col-span-9" style={{  height: 115 }}>
          <div className="grid md:grid-cols-12 h-full border border-black/10 dark:border-white/10 rounded-lg p-3 font-normal justify-between items-center bg-white/5">
            <div className="flex flex-col md:col-span-11">{link}</div>
            <div className="flex flex-col md:col-span-1"><Copy className="cursor-pointer" onClick={async ()=>{
              await navigator.clipboard.writeText(link);
              alert("Copied to clipboard!");
            }} /></div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
