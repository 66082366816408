import { AppContext } from "@/AppContext";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import Grid, { Cell } from "@/ui/_old/Grid";
import ListItem from "@/ui/_old/ListItem";

import _ from "lodash";
import { NiceMenu } from "../Helpers";
import Icon, { IconSource } from "@/Apps/Pages/WebComponents/v2/Icon/live";
import { EditorContext } from "@/Apps/Pages/Editor/EditorContext";
import { useContext } from "react";

// import { getSectionIcon } from "@launchos/plugins/misc/v2/blocks/SectionsLegend/SectionItem/SectionItem";

const getSectionIcon = () => <></>

export const ScrollToSection = (props) => {
  const { action, updateAction, pageContent = [] } = props;
  const { payload } = action;

  const { content } = useContext(EditorContext)
  // console.log({ payload });

  return (
    <AppContext.Consumer>
      {({ app }) => (
        // show dropdown menu of pages, with option to enter url
        <NiceMenu
          style={{ marginTop: 10 }}
          label={_.get(payload, "sectionType", "Choose the section...")}
        >
          {content.filter(itm => itm.type === ComponentTypes.SECTION).map((itm, key) => {
            return (
              <ListItem key={key} onClick={() => updateAction(action, {
                ...payload,
                sectionId: itm.id,
                sectionType: itm.label,
              })}>
                <Grid>
                  <Cell
                    style={{ width: 30, paddingRight: 10, textAlign: "center" }}
                  >
                    <Icon type={getSectionIcon(itm.label)} source={IconSource.FONTAWESOME} />
                  </Cell>
                  <Cell>
                    {itm.label || "Section"}
                  </Cell>
                </Grid>
              </ListItem>
            )
          })}
          <div style={{ borderTop: '1px solid #CCC' }} />
          {content.filter(itm => !itm.canHaveChildren).map((itm, key) => {
            return (
              <ListItem key={key} onClick={() => updateAction(action, {
                ...payload,
                sectionId: itm.id,
                sectionType: itm.label,
              })}>
                {itm.label || itm.type || "(N/A)"}
              </ListItem>
            )
          })}
        </NiceMenu>
      )}
    </AppContext.Consumer>
  );
};
