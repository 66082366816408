import * as React from "react";

import { EditorMode } from "@/Apps/Pages/Editor/types";

import { LiveFormFieldProps } from "./types";
import { FormFieldTypes } from "../types";

import LiveCheckboxes from "../FormFields/Checkboxes/LiveCheckboxes";
import LiveContentField from "../FormFields/ContentField/LiveContentField";
import LiveDateField from "../FormFields/DateField/LiveDateField";
import LiveDropdownField from "../FormFields/DropdownField/LiveDropdownField";
import LiveFileUpload from "../FormFields/FileUpload/LiveFileUpload";
import LiveLinearScaleField from "../FormFields/LinearScaleField/LiveLinearScaleField";
import LiveMultipleChoice from "../FormFields/MultipleChoice/LiveMultipleChoice";
import LiveTextAreaField from "../FormFields/TextAreaField/LiveTextAreaField";
import LiveTextField from "../FormFields/TextField/LiveTextField";

import { Grid } from "@material-ui/core";

const Wrapper: React.FC<{
  style?: React.CSSProperties;
  children: React.ReactNode;
  columns?: number;
}> = ({ style = {}, children, columns = 12 }) => {
  return (
    <Grid
      item
      xs={columns}
      data-testid="FormBuilder-LiveFormField"
      style={{ display: "inline-block", width: "100%", ...style }}
    >
      {children}
    </Grid>
  );
};

/**
 * A component that loads the live version of the individual form field
 *
 * This is what shows in the real web page (or editor canvas with overlay turned on)
 */
const LiveFormField: React.FC<LiveFormFieldProps> = (props) => {
  const {
    type,
    name,
    placeholder,
    items,
    label,
    description,
    defaultValue,
    styles = {
      labelStyle: {},
      inputStyle: {},
      containerStyle: {},
      descriptionStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    disabled,
    icon,
    columns,
    onChange = () => null,
    onBlur,
    onKeyUp,
    onClick,
    children,
  } = props;

  /**
   * Deal with any changes that come from the various form fields that I loads
   * @params
   */
  const handleChange = (): void => {
    onChange();
  };

  if (
    type === FormFieldTypes.TEXT ||
    type === FormFieldTypes.PASSWORD ||
    type === FormFieldTypes.NUMBER ||
    type === FormFieldTypes.CURRENCY
  ) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        {/* {JSON.stringify(props.styles)} */}
        <LiveTextField
          {...props}
          mode={EditorMode.LIVE}
          onChange={handleChange}
          onBlur={handleChange}
        />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.DATE) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveDateField
          {...props}
          mode={EditorMode.LIVE}
          onChange={handleChange}
          onBlur={handleChange}
        />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.SELECT || type === FormFieldTypes.DROPDOWN) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveDropdownField
          {...props}
          data={items}
          mode={EditorMode.LIVE}
          onChange={handleChange}
        />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.TEXTAREA) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveTextAreaField
          {...props}
          mode={EditorMode.LIVE}
          onChange={handleChange}
          onBlur={handleChange}
        />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.CHECKBOXES) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveCheckboxes
          {...props}
          mode={EditorMode.LIVE}
          onChange={handleChange}
          data={items}
        />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.MULTIPLECHOICE) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveMultipleChoice
          {...props}
          mode={EditorMode.LIVE}
          onChange={handleChange}
          data={items}
        />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.CONTENT || type === FormFieldTypes.TYPOGRAPHY) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveContentField {...props} />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.FILEUPLOAD) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveFileUpload {...props} mode={EditorMode.LIVE} onChange={handleChange} />
      </Wrapper>
    );
  }

  if (type === FormFieldTypes.LINEARSCALE) {
    return (
      <Wrapper columns={columns} style={styles.containerStyle}>
        <LiveLinearScaleField
          {...props}
          mode={EditorMode.LIVE}
          onChange={handleChange}
        />
      </Wrapper>
    );
  }

  return <Wrapper columns={columns} style={styles.containerStyle}>{children}</Wrapper>;
};

export default LiveFormField;
