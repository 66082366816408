import * as React from "react";
import { get, has, uniq } from 'lodash';
import { AlphaPicker, CirclePicker, SliderPicker, SketchPicker } from "react-color";
// import { Button } from "@launchos/components/ui";
// import { fieldset } from "@/ui/theme";

import { ColorPickerProps } from "./types";
import { style } from './style'

import { doColorConversion } from './scripts'
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { X } from "@phosphor-icons/react";
import { W } from "../../../BuildingBlocks/ButtonSliderGroup/ButtonSliderGroup";
import Select from "@/ui/Select";
import { TextInputFlavors } from "@/ui/TextInput/types";

const convertColorToObject = (str) => {

  // convert to rgb first
  const rgb = doColorConversion(str, 'rgb');
  const color = `rgb(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`; // we use rgb() vs rgba() bc alpha still works and hex converter (for input field) won't convert to hex otherwise

  const obj = {
    rgb,
    hex: doColorConversion(color, 'hex'),
    hsl: doColorConversion(color, 'hsl')
  }

  return obj;
}

/**
 * A tool for picking and settings colors
 */
const ColorPicker: React.FC<ColorPickerProps> = ({
  toolTip,
  text = "Colors",
  showCircles = true,
  showSlider = true,
  showAlpha = false,
  showHex = true,
  onChange,
  onInstantChange,
  color,
  pageContent = [],
  colorArray = [
    "#C0392B",
    "#E74C3C",
    "#9B59B6",
    "#8E44AD",
    "#2980B9",
    "#3498DB",
    "#1ABC9C",
    "#16A085",
    "#27AE60",
    "#2ECC71",
    "#F1C40F",
    "#F39C12",
    "#E67E22",
    "#D35400",
    "#ECF0F1",
    "#BDC3C7",
    "#95A5A6",
    // "#34495E",
    "#2C3E50",
    "#F7F7F7",
    "#FFFFFF",
    "#000000",
    "red",
    "green",
    "blue",
  ],
}) => {
  const [theColor, setColor] = React.useState("");
  const [theColorObj, setColorObj] = React.useState({});
  const [colorExpanded, setColorExpanded] = React.useState(true);
  const [showSketchPicker, setShowSketchPicker] = React.useState(false);
  const ref = React.useRef(null)

  React.useEffect(() => {
    const colorObject = convertColorToObject(color);
    // console.log(colorObject)
    setColorObj(colorObject);
    setColor(get(colorObject, 'hex'))
  }, [])

  const instantChange = (color) => {
    setColor(get(color, 'hex'));
    setColorObj(color)
    // console.log(color)

    if (onInstantChange) onInstantChange(color);
    else onChange(color, false);
  }

  const doChange = (color) => {
    setColor(get(color, 'hex'));
    onChange(color);
  };

  const toggleColorExpansion = () => setColorExpanded(!colorExpanded);

  // look through the document for any other colors they added
  const colorBlocks = pageContent.filter((itm) => has(itm, "properties.color") || has(itm, "properties.backgroundColor"));

  colorBlocks.forEach((itm) => {
    const isInArray = (color) => colorArray.indexOf(color) > -1;

    const color = get(itm, "properties.color", false);
    if (color && !isInArray(color)) colorArray.push(color);

    const backgroundColor = get(itm, "properties.backgroundColor", false);
    if (backgroundColor && !isInArray(backgroundColor)) colorArray.push(backgroundColor);
  });

  return (
    <fieldset
      onMouseDown={(e) => e.stopPropagation()}
      data-testid="ColorPicker"
      style={style.fieldset}
      title={toolTip}
    >
      {/* <legend>{text}</legend> */}
      {/* <Select
        label="Choose from theme"
        flavor={TextInputFlavors.MODERN}
        onChange={(selectedItem) => console.log(selectedItem.target.value)}
        items={[
          { label: "Primary Color", id: "primary" },
          { label: "Secondary Color", id: "secondary" },
          { label: "Accent Color", id: "accent" },
          { label: "Background Color", id: "background" }
        ] as Array<{ label: string; id: string }>}
        className="mb-7"
      /> */}
      <div style={{ width: 240, margin: "0 auto" }}>

        {colorExpanded ? (
          <div style={{ padding: 0, marginBottom: 0 }}>
            {showCircles && !showSketchPicker ? (
              <div
                data-testid="ColorPicker-Circles"
                style={{ marginBottom: 20 }}
              >
                <CirclePicker
                  color={get(theColorObj, "hex")}
                  colors={uniq(colorArray)}
                  onChange={(c) => instantChange(c)}
                  onChangeComplete={(c) => doChange(c)}
                />
              </div>
            ) : null}

            {showSketchPicker && (
              <div style={{ margin: "15px auto", width: 220 /*, zoom: 1.35*/ }}>
                <SketchPicker
                  color={get(theColorObj, "hsl")}
                  onChange={(c) => instantChange(c)}
                  onChangeComplete={(c) => doChange(c)}
                />
              </div>
            )}

            <div style={{ textAlign: "center" }}>
              <Button
                type={ButtonTypes.SOFT}
                className="mb-3"
                // transparent
                // style={{
                //   marginBottom: 15,
                //   color: "#333",
                // }}
                onClick={() => setShowSketchPicker(!showSketchPicker)}
                label={
                  showSketchPicker ? `Show Less Colors` : `Show More Colors`
                }
              />
            </div>

            {showSlider && !showSketchPicker ? (
              <div
                data-testid="ColorPicker-Slider"
                style={{ marginBottom: 20 }}
              >
                <SliderPicker
                  color={get(theColorObj, "hsl")}
                  onChange={(c) => instantChange(c)}
                  onChangeComplete={(c) => doChange(c)}
                />
              </div>
            ) : null}

            {/* <div>{JSON.stringify(color)}</div> */}

            {showAlpha && !showSketchPicker ? (
              <div data-testid="ColorPicker-Alpha" style={{ marginBottom: 20 }}>
                <AlphaPicker
                  color={get(theColorObj, "hsl")}
                  // color={has(color, 'rgb') ? color.rgb : { rgb: doColorConversion(color) }}
                  // color={get(color, 'rgb')}
                  // rgb={get(theColorObj, 'rgb')}
                  // {...theColorObj}
                  width={240}
                  onChange={(c) => instantChange(c)}
                  onChangeComplete={(c) => doChange(c)}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {showHex && !showSketchPicker && (
          <div
            data-testid="ColorPicker-Hex"
            style={{
              backgroundColor: "#555",
              borderRadius: 4,
              padding: "0 5px",
              display: "flex",
            }}
          >
            <label className="text-white py-5 px-2 text-sm">Color</label>
            <input
              data-testid="ColorPicker-color"
              // value={get(theColorObj, 'hex')}
              value={theColor}
              onChange={(e) => {
                const color = e.target.value;
                setColor(color);
                setColorObj(convertColorToObject(color));
              }}
              onBlur={() => {
                // doChange({ hex: theColor });
                doChange(theColorObj);
              }}
              onKeyUp={(e) => {
                e.stopPropagation();
                if (e.keyCode === 46 || e.keyCode === 8) return false;
                if (e.keyCode === 13) doChange(theColorObj);
              }}
              type="text"
              style={{ ...style.input, backgroundColor: "#666" }}
              className="rounded"
              ref={ref}
            />

            <div
              className="-ml-[30px] mt-[22px] mr-2 cursor-pointer"
              title="Clear Color"
            >
              <X
                onClick={() => {
                  doChange({ r: 0, g: 0, b: 0, a: 0 }, true);
                  setColor("");
                  ref.current.focus();
                }}
              />
            </div>

            <div
              className="mt-2 rounded"
              style={{
                ...style.pickerElement,
                backgroundColor: theColor,
                opacity: get(theColorObj, "hsl.a", 1),
              }}
              onClick={toggleColorExpansion}
            />
          </div>
        )}
      </div>
    </fieldset>
  );
};

export default ColorPicker;
