import { fromPairs, get, map, omit } from "lodash";
// import settings from "../../../../../../apps/livesite/src/config";
import settings from "../config";

export default async (jsonData, callbackFn, action) => {
  console.log("Inside Send Data", jsonData);
  const getInputValue = (name, data) =>
    data.findIndex((itm) => itm.name === name) > -1
      ? data.filter((itm) => itm.name === name)[0].value
      : false;

  const data = JSON.parse(jsonData);
  const credentials = getInputValue("emP", data);
  const mCredentials = getInputValue("pmP", data);

  console.log({ data });
  // console.log('mCredentials', JSON.parse(mCredentials).productId);
  const toSend1 = fromPairs(map(data, (i) => [i.name, i.value]));
  const toSend = omit(toSend1, ["emP", "pmP", "nxP"]);

  const convertedData = {
    mCredentials,
    // email: getInputValue('email', data),
    ...toSend,
    previousOrderId: getInputValue("order_id", data),
    shippingId: getInputValue("shippingMethod", data) || 2, // TODO: What to do here?  Is 2 appropriate?
    billingModelId: getInputValue("billingModel", data) || 2, // TODO: What to do here?  Is 2 appropriate?
    campaignId: JSON.parse(credentials).campaignId,
    productId: JSON.parse(mCredentials).productId,
  };

  console.log({ convertedData });
  const { provider } = JSON.parse(
    get(convertedData, "mCredentials", "{}")
  );

  const url = `${settings.serverUrl}${settings.upsellUrl[provider]}`;

  console.log({ url });

  if (provider === "stripe") {
    
    try {
      const paymentIntentId = localStorage.getItem("paymentIntentId");
      const customerId = localStorage.getItem("customerId");

      // you'll need to call a backend endpoint to retrieve the payment method
      const response = await fetch(
        `${settings.serverUrl}/api/stripeRetrievePaymentMethod`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            mCredentials,
            paymentIntentId,
            customerId,
          }),
        }
      );

      const { success, error } = await response.json();

      if (success) {
        window.location.href = "https://www.google.com";
      } else {
        alert(error.message)
      }
      
    } catch (error) {
      console.error("Second charge failed", error);
    }
  } else {
    const jsonPromise = fetch(url, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(convertedData),
    }).then((r) => r.json());

    jsonPromise.then((data) => {
      console.log(data);
      // localStorage.setItem('orderResponse', JSON.stringify(data));
      if (data.error_message && data.resp_msg !== "Approved") {
        alert(data.error_message);
        callbackFn(false);
      } else callbackFn();
    }); // this works
  }
};
