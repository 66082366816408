import styled, { css } from "styled-components";
import { get } from "lodash";

const borderOffset = 1;
export default {
  editorObjectNormal: {
    border: "1px solid",
    boxSizing: "border-box",
    borderColor: "transparent",
    margin: -1 * borderOffset,
    padding: borderOffset
  },
  objectHandleNormal: {
    border: "1px solid",
    borderColor: "transparent",
    boxSizing: "border-box",
    padding: 5,
    color: "#3f51b5",
    position: "absolute",
    display: "flex",
    marginTop: -21,
    // marginLeft: -1 * borderOffset,
    fontSize: 10,
    height: 20,
    zIndex: 100,
  },
  dragHandleText: {
    display: "inline-block",
    top: -3,
    position: "relative"
  },
  gearIcon: {
    cursor: "pointer",
    color: "white",
    zoom: "50%",
    marginLeft: 10,
    position: "relative",
    top: -4
  },
  preview: { position: "absolute", height: 1, width: 1 },
};

export const Border = styled.div`
&:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-color: transparent;  
  min-height: 20px;
  ${({ borderSize = 1, settings, color, state }) => css`
    border: ${borderSize}px solid;
    cursor: ${get(settings, "canHaveChildren", false) ? "inherit" : "move"};
    ${(state === "hover" || state === "active") && `border-color: ${color};`}
  `}
}
`;