import { DocumentNode, gql } from "@apollo/client";

// getPages;
export const GET_PAGE: DocumentNode = gql`
  query getPage($pageId: ID!) {
    page(where: { id: $pageId }) {
      id
      name
      content
      publishedAt
      pageSettings
      slug
      type
      homePageForDomain {
        id
      }
      errorPageForDomain {
        id
      }
      object {
        id
        connectTo {
          id
          deleted
          page {
            id
            slug
          }
        }
      }
    }
  }
`;
