export enum PageTypes {
  BLANK = "blank",
  CONTENT = "content",
  HOME = "home",
  LAUNCH = "launch",
  PRODUCTS = "products",
  LEAD = "lead",
  LEGAL = "legal",
  EVENT = "event",
  CHECKOUT = "checkout",
  ORDER = "order",
  SALES = "sales",
  OTO = "oto",
  THANK = "thank",
  WEBINAR = "webinar",
  THANKYOU = "thank you"
}

export interface ILinks {
  name: string;
  slug: string;
  type: PageTypes;
}

export interface LinkSelectorProps {
  /**
   * the current link value
   */
  value?: string;

  /**
   * The label/placeholder to use for the dropdown field
   */
  label?: string;

  /**
   * The list of links
   */
  data?: ILinks[];

  /**
   * Triggers when the link content field changes
   */
  onChange?: (value: string) => void;

  /**
   * Triggers when the column count field loses focus
   */
  onBlur?: (value: string) => void;
}
