import { get } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { combineData } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.FIVE,
  layout: HeaderLayouts.FIVE,
  previewImage:
    "http://sandcastleassets.s3.amazonaws.com/blocks/headers/Header05.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckktsa7qd1v1u078062fyqewd",
    itemId: "r9l1c8r20g",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      // ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: false,
                properties: {
                  padding: 80,
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1551721434-8b94ddff0e6d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=701&q=80)",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "rgb(0,34,170,0.86)",
                  mobile: {
                    desktop: {
                      padding: 80,
                    },
                    smartphone: {
                      padding: 30,
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      ...get(content, "sub_headline.style", {}),
                    },
                    html: get(content, "sub_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: style.BUTTON.style,
                    caption1: "Get Started",
                    caption1style: style.BUTTON.caption1,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 50,
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [],
          },
        ],
      },
    ],
  },
};

export default settings;
