import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FieldNamePresets } from "@/Apps/CRM/types";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";

import { selectOptions } from "@/ui/CRUD/FormBuilder/EditFormField/fieldNames";

import {
  OrderSummaryTypes,
  OrderSummaryItemTypes,
  OrderSummaryDisplayOptions,
} from "@/Apps/Pages/WebComponents/v2/OrderSummary/types";

import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";

import {
  IconSource,
} from "@/Apps/Pages/WebComponents/v2/Icon/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.THIRTEEN,
  layout: FormLayouts.THIRTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form13.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkyvra3g2f8207806ga0wtnl",
    itemId: "iu17y74a0m",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  ...get(content, "main_headline.style", {}),
                  marginTop: 30,
                },
                html: "Billing Information",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                  marginTop: 10,
                },
                html:
                  "You're information is secure and will not be shared with a 3rd party",
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.FIRSTNAME,
                    placeholder: "First Name",
                    preset: FieldNamePresets.FIRSTNAME,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.LASTNAME,
                    placeholder: "Last Name",
                    preset: FieldNamePresets.LASTNAME,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.EMAIL,
                    placeholder: "Email",
                    preset: FieldNamePresets.EMAIL,
                  },
                ],
                properties: {
                  marginTop: 20,
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  marginTop: 30,
                },
                html: "Billing Address",
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.ADDRESS,
                    placeholder: "Street Address",
                    preset: FieldNamePresets.ADDRESS,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.CITY,
                    placeholder: "City",
                    preset: FieldNamePresets.CITY,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.STATE,
                    placeholder: "State",
                    preset: FieldNamePresets.STATE,
                    data: selectOptions.state,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.ZIP,
                    placeholder: "Zip/Postal Code",
                    preset: FieldNamePresets.ZIP,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.COUNTRY,
                    placeholder: "Country",
                    preset: FieldNamePresets.COUNTRY,
                    data: selectOptions.country,
                  },
                  {
                    id: Math.random().toString(36).slice(2),
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.PHONE,
                    placeholder: "Phone",
                    preset: FieldNamePresets.PHONE,
                  },
                ],
                properties: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: {
                  width: "100%",
                  marginTop: 30,
                },
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((8 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.SUBHEADLINE,
                        },
                        html: "Payment Information",
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: parseInt((4 * 100) / 12),
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.LIST,
                        canHaveChildren: false,
                        properties: {
                          display: "inline-block",
                          textAlign: "right",
                        },
                        data: [
                          {
                            icon: faCcVisa,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                          {
                            icon: faCcMastercard,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                          {
                            icon: faCcDiscover,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                          {
                            icon: faCcAmex,
                            iconSource: IconSource.FONTAWESOME,
                            iconStyle: {
                              fontSize: "20pt",
                              color: theme.backgroundColor,
                            },
                            style: {
                              display: "inline-block",
                              padding: "0 3px",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.FORM,
                data: [
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.CARDNUMBER,
                    placeholder: "•••• •••• •••• ••••",
                    preset: FieldNamePresets.CARDNUMBER,
                  },
                  {
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.EXPIRATIONMONTH,
                    placeholder: "Month",
                    preset: FieldNamePresets.EXPIRATIONMONTH,
                  },
                  {
                    type: FormFieldTypes.DROPDOWN,
                    name: FieldNamePresets.EXPIRATIONYEAR,
                    placeholder: "Year",
                    preset: FieldNamePresets.EXPIRATIONYEAR,
                  },
                  {
                    type: FormFieldTypes.TEXT,
                    name: FieldNamePresets.CVV,
                    placeholder: "CVV",
                    preset: FieldNamePresets.CVV,
                  },
                ],
                properties: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.FORMFIELD,
                canHaveChildren: false,
                attributes: {
                  placeholder: "Terms",
                  name: "Terms",
                  items: [
                    {
                      id: 0,
                      label: "I agree to the terms and conditions",
                      value: false,
                    },
                  ],
                },
                fieldType: FormFieldTypes.CHECKBOXES,
                styles: {
                  containerStyle: {
                    ...style.FORM_FIELD.style,
                    marginTop: 20,
                  },
                  labelStyle: style.FORM_FIELD.labelStyle,
                },
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: { ...style.BUTTON.style, marginTop: 30 },
                caption1: "Get Instant Access",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 20, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                style: {
                  height: "100%",
                },
              }),
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.SUBHEADLINE,
                  marginTop: 50,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Order Summary",
              },
              {
                type: ComponentTypes.ORDERSUMMARY,
                data: [
                  {
                    id: uniqueId(),
                    title: "Premium Account",
                    itemType: OrderSummaryItemTypes.PRODUCT,
                    value: "FREE",
                  },
                  {
                    id: uniqueId(),
                    title: "Shipping",
                    itemType: OrderSummaryItemTypes.PRODUCT,
                    value: 7.95,
                  },
                ],
                showHeader: true,
                showTotal: true,
                properties: { marginTop: 10 },
                designType: OrderSummaryTypes.STANDARD,
                displayOptions: {
                  [OrderSummaryDisplayOptions.HEADER]: true,
                  [OrderSummaryDisplayOptions.PRODUCTS]: true,
                  [OrderSummaryDisplayOptions.TOTAL]: true,
                  [OrderSummaryDisplayOptions.SUBTOTAL]: false,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
