import { Link } from "react-router-dom";

// import style from "@/Apps/Pages/Properties/style";

export const TriggerUpsell = ({ campaignId, nodeId }) => (
  <Link to={`/campaign/${campaignId}/${nodeId}/merchant/index`}>
    {/* <span className="underline cursor-pointer pt-4 text-center block">
      Open Payment Settings
    </span> */}
  </Link>
);
