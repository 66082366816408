import React, { useEffect, useRef } from "react";
import { API_DOMAIN, EDITOR_DOMAIN } from "@launchos/shared-utils/env";
import { DEFAULT_PAGE_ID, ISettings } from "./types";

export enum EditorTypes {
  STANDARD = "Standard",
  PURE = "Pure",
  FULL = "Full",
  SIMPLE = "Simple"
}

interface EditorCanvasProps {
  // Reference to the component instance
  // ref: React.RefObject<any>;

  // ID of the campaign
  campaignId?: string;

  // ID of the page
  pageId: string;

  // Optional property to show something
  toShow?: string;

  // Token to use for the iframe (required)
  token?: string;

  // Type of editor to use (Standard or Pure)
  variant?: EditorTypes;

  // The content (an array of ISettings objects) for a pure editor
  content?: ISettings[];

  // Whether the editor is stateful (manages its own state) or controlled (state managed by parent)
  // If true, the editor is controlled and relies on external state management
  // If false or undefined, the editor manages its own state internally
  controlled?: boolean;

  // Callback function to get drop-down items
  getDropDownItems?: (items: any, settings: any) => any;

  // Callback function when an item's state changes
  onItemChangeState?: (data: any) => void;

  // Callback function when an item is selected
  onItemSelect?: (data: any) => void;

  // Callback function when the component's state changes
  onChange?: (data: any) => void;

  // Callback function when the editor has loaded
  onLoad?: (data: any) => void;

  // Callback function when properties should be shown
  onShowProperties?: (data: any) => void;

  // Callback function when properties should be hidden
  onHideProperties?: () => void;
}

const updateContent = (content, pageId) => {
  // console.log("updateContent", content)
  const iframe = document.getElementById(`PageEditor-${pageId}`) as HTMLIFrameElement;
  if (iframe) {
    iframe.contentWindow?.postMessage(
      {
        type: "onEditorAction",
        action: "updateContent",
        payload: { content },
      },
      "*"
    );
  }
};

const updateComponentStyle = (id, newStyle, dbUpdate, wait = 500, pageId = DEFAULT_PAGE_ID) => {
  console.log("updateComponentStyle", id, newStyle, pageId);
  const iframe = document.getElementById(`PageEditor-${pageId}`) as HTMLIFrameElement;
  if (iframe) {
    iframe.contentWindow?.postMessage(
      {
        type: "onEditorAction",
        action: "updateComponentStyle",
        id,
        payload: { newStyle },
      },
      "*"
    );
  }
};

const updateComponent = (id, settings, pageId) => {
  // console.log("updateComponent", settings);
  const iframe = document.getElementById(`PageEditor-${pageId}`) as HTMLIFrameElement;
  if (iframe) {
    iframe.contentWindow?.postMessage(
      {
        type: "onEditorAction",
        action: "updateComponentSettings",
        id,
        payload: { settings },
      },
      "*"
    );
  }
};

const doUndoRedo = (type = "undo", pageId) => {
  const iframe = document.getElementById(`PageEditor-${pageId}`) as HTMLIFrameElement;
  if (iframe) {
    // alert("doUndoRedo");
    iframe.contentWindow?.postMessage(
      {
        type: "onEditorAction",
        action: "doUndoRedo",
        payload: { type },
      },
      "*"
    );
  }
};

const setMobileState = () => {};

export const useEditorActions = () => ({
  updateContent,
  updateComponent,
  updateComponentStyle,
  doUndoRedo,
  setMobileState,
});

const onNewEvent = (clbk) => {

    window.addEventListener(
      "message",
      async ({ origin, data }) => {
        // const { type, request, payload } = data;

        // Check the origin of the message for security
        if (origin !== EDITOR_DOMAIN) return;

        clbk(data)
      },
      false
    );

}

const sendEvent = (type, request, payload) => {
  window.parent.postMessage({ type, request, payload }, "*");
}

export const EditorCanvas: React.FC<EditorCanvasProps> = ({
  campaignId,
  pageId = DEFAULT_PAGE_ID,
  toShow = "builder",
  token,
  variant = EditorTypes.STANDARD,
  content = [],
  controlled = false,
  getDropDownItems = (items: any, settings) => items,
  onItemChangeState = () => null,
  onItemSelect = () => null,
  onChange = () => null,
  onLoad = () => null,
  onShowProperties = () => null,
  onHideProperties = () => null,
}) => {
  const pageEditorIframeRef = useRef<HTMLIFrameElement>(null);

  const canvasUrl =
    variant === EditorTypes.STANDARD
      ? `${API_DOMAIN}/iframes/${token}/campaign/${campaignId}/${pageId}/${toShow}`
      : `${EDITOR_DOMAIN}/pure-editor/${pageId}`;
      // : `${EDITOR_DOMAIN}/pure-editor${controlled ? '/controlled' : ''}`;

  if (!token && variant === EditorTypes.STANDARD) return <div>Token is required</div>;
  if (!pageId && variant === EditorTypes.STANDARD) return <div>Page Id is required</div>;
  if (!campaignId && variant === EditorTypes.STANDARD) return <div>Campaign Id is required</div>;

  const processDropDownItems = (items: any, settings: any) => {
    const dropDownItems = getDropDownItems(items, settings);
    // filter out anything other than what is needed (e.g. if you send functions it will break)
    return dropDownItems.map(({ id, icon, text }) => ({ id, icon, text }));
  };

  useEffect(() => {
    onNewEvent(msg => {
      const { type, request, payload, id } = msg;

      if (type === "EditorCanvasEvent" && id === pageId) {
        // if (request === "onChange") { console.log("onChange Event", payload, id) }
        if (request === "onSave") { /* console.log("onSave Event", payload) */ }
        if (request === "onItemSelect") { onItemSelect(payload) }
        if (request === "onShowProperties") onShowProperties(payload) //console.log("onShowProperties", payload)
        if (request === "onHideProperties") onHideProperties(payload) //console.log("onHideProperties", payload)
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener(
      "message",
      async ({ origin, data }) => {        
        const { type, request, payload } = data;

        // Check the origin of the message for security
        if (origin !== EDITOR_DOMAIN) return;

        const iframe = document.getElementById(`PageEditor-${pageId}`) as HTMLIFrameElement;

        // Check the message type
        // create a new object that doesn't include the type key from the data object
        const newData = Object.keys(data).reduce(
          (acc, key) => (key !== "type" ? { ...acc, [key]: data[key] } : acc),
          {}
        );

        if (type === "onEditorItemChangeState") onItemChangeState(newData);
        // if (type === "onEditorItemSelect") onItemSelect(newData);
        if (type === "onEditorChange") onChange(newData);
        if (type === "onEditorHasLoaded") {
          // update the editor with the content
          // console.log("The content to load", content);
          
          setTimeout(() => {
            if (variant === EditorTypes.PURE) updateContent(content, pageId);
            onLoad();
          }, 1000);
        }

        if (type === "editorObjectRequest") {
          if (request === "getDropDownItems") {
            const { items, settings } = payload;

            // console.log("editorObjectRequest", data);
            if (iframe)
              iframe.contentWindow?.postMessage(
                {
                  type: "editorObjectResponse",
                  dropDownItems: processDropDownItems(items, settings),
                },
                "*"
              );
          }

          if (request === "handleDropDownAction") {
            const { action, items, settings, content = [] } = payload;

            // find the item in the drop down items
            const dropDownItems = getDropDownItems(items, settings); // unfiltered (should have onClicks)
            const item = dropDownItems.find((itm) => itm.id === action);

            // if the item has an onClick handler, trigger it
            // console.log({ item, action, items, settings });
            if (item && item.onClick) item.onClick(payload);
          }
        }

        // if (type === "EditorCanvasEvent") {
        //   if (request === "onChange") { /* console.log("onChange Event", payload) */ }
        //   if (request === "onSave") { /* console.log("onSave Event", payload) */ }
        //   if (request === "onItemSelect") { /* console.log("Item Selected", payload) */ }
        //   if (request === "onShowProperties") onShowProperties(payload) //console.log("onShowProperties", payload)
        //   if (request === "onHideProperties") onHideProperties(payload) //console.log("onHideProperties", payload)
        // }
      },
      false
    );

    // Clean up
    return () => {
      window.removeEventListener("message", () => null);
    };
  }, []);

  useEffect(() => {
    console.log("Content Changed -- Updating iframe");
    if (variant === EditorTypes.PURE) updateContent(content, pageId);
  }, [content])

  return (
    <iframe
      ref={pageEditorIframeRef}
      id={`PageEditor-${pageId}`}
      className="w-full h-full page-editor"
      src={canvasUrl}
    />
  );
};