import { Page } from "@/ui/Layout";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "@/AppContext";

export const Copilot = ({ name = "Copilot" }) => {
  const [campaignName, setCampaignName] = useState();
  const { id } = useParams();
  const [campaignId, setCampaignId] = useState(id);
  const [topBarButtons, setTopBarButtons] = useState(null);
  const { setCopilotOpen } = useContext(AppContext);

  useEffect(() => {
    setCopilotOpen(true);
  }, []);

  useEffect(() => {
    // if (id) {
    console.log("Copilot.tsx", "setting campaign id", id);
    setCampaignId(id);
    // }
  }, [id]);

  return (
    <Page
      topBar={{
        showLeftSidebarToggle: true,
        page: [
          { label: `Launch Copilot`, href: "/copilot" },
          ...(campaignId
            ? [{ label: campaignName, href: `/copilot/${campaignId}` }]
            : []),
        ],
        items: topBarButtons,
        hideCopilotButton: true,
      }}
      copilotSettings={{ id: campaignId, setCampaignId, setCampaignName, setTopBarButtons }}
    />
  );
};
