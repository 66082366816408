import React from "react";
import { get } from "lodash";
import ReactPlayer from "react-player";

import reducers from "@/Apps/Pages/WebComponents/v2/Button/reducers";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import Icon from "@/Apps/Pages/WebComponents/v2/Icon/live";

export class VideoContent extends React.Component {
  constructor(props) {
    super(props);
    this.handleProgress = this.handleProgress.bind(this);
    this.state = {
      triggeredYet: false,
    };
  }

  handleProgress(e) {
    const {
      settings,
      getComponentSettings,
      updateComponentSettings,
    } = this.props;
    const { actions, triggerAfter = 0 } = settings;
    // console.log(e.playedSeconds);
    if (actions && e.playedSeconds > triggerAfter && !this.state.triggeredYet) {
      const doTheseActions = actions.filter(
        (itm) => itm.behavior === "playProgress"
      );

      this.setState({ triggerYet: true }, () => {
        doTheseActions.forEach((action) =>
          reducers([], {
            ...action,
            settings,
            updateComponentSettings,
            getComponentSettings,
          })
        );
      });
    }
  }

  render() {
    const { settings, muted, controls } = this.props;

    const videoStyle = {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 0,
    };

    const padding = settings.properties ? settings.properties.padding : 0;

    const properties = settings.hasOwnProperty("properties")
      ? settings.properties
      : false;

    const playIconStyle = {
      color: "white",
      fontSize: "72pt",
      position: "absolute",
      transform: `translate(-50%, -50%)`,
    };

    const width = get(properties, "width", "100%");

    return (
      <div style={{ padding }}>
        <div
          style={{
            ...properties,
            // maxWidth,
            width,
            marginTop: 0,
            padding: 0,
            position: "relative",
            paddingTop: `${100 / (1280 / 720)}%`,
            // backgroundColor: "white",
          }}
        >
          <ReactPlayer
            url={settings.src.mp4}
            style={videoStyle}
            light={get(settings, "properties.backgroundImage", "")
              .replace("url(", "")
              .replace(")", "")}
            width="100%"
            height="100%"
            muted={muted}
            controls={controls}
            ref={(rp) => (this.rp = rp)}
            onProgress={this.handleProgress}
            playIcon={
              <div>
                <Icon
                  type={IconTypes.PlayCircleOutline}
                  style={{
                    ...playIconStyle,
                    ...get(settings, "playIconStyle", {}),
                    color: `rgb(0,0,0,0.5)`,
                    margin: "1px 0 0 1px",
                  }}
                />
                <Icon
                  type={IconTypes.PlayCircleOutline}
                  style={{
                    ...playIconStyle,
                    ...get(settings, "playIconStyle", {}),
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

// VideoContent.defaultProps = {
//   muted: false,
//   controls: false,
// };

export const Video = (props) => {
  const { settings } = props;

  const displayStyle = {
    display: get(settings, "properties.display"),
    position: get(settings, "properties.position", "relative"),
    width: get(settings, "properties.width"),
    // height: get(settings, "properties.height"),
  };

  const marginTop = get(settings, "properties.marginTop", 0);
  const textAlign = get(settings, "properties.textAlign", "inherit");
  const height = "100%";

  // const width = get(settings, "properties.width", "100%");
  // const display = width === "100%" ? "block" : "inline-block"; // video is invisible if width:100% & display: 'inline-block', so made it conditional

  return (
    <div data-testid="WC-VIDEO-LIVE" style={{ ...displayStyle, marginTop, textAlign, height }}>
      <VideoContent {...props} muted={false} controls />
    </div>
  );
};

export default Video;
