import { Loading } from "@/ui/Layout/Loading";
import {
  Browsers,
  NewspaperClipping,
  TreeStructure,
} from "@phosphor-icons/react";
import { useEffect } from "react";
import { ArtifactOptions } from "../types";

export const Artifact = ({
  id,
  identifier,
  type,
  prompt,
  title,
  pageType,
  isLoading = false,
  shouldOpenArtifactWithThisOnFinish = {},
  setArtifactOptions = () => {},
  setShouldOpenArtifactWithThisOnFinish = () => {},
}) => {
  const options: ArtifactOptions = {
    id,
    type,
    prompt,
    title,
    pageType,
  };

  const openArtifact = () => {
    // if (type === "page builder") {
      setArtifactOptions({
        ...options,
        isShowing: true,
      });
    // }
  };

  return (
    <div className="inline-block">
      <div
        className="min-w-64 mb-6 border rounded dark:border-white/5 border-black/10 flex divide-x dark:divide-white/5 divide-black/10 cursor-pointer dark:bg-white/5 bg-black/5 hover:dark:bg-transparent hover:bg-transparent"
        onClick={() => {
          if (!isLoading) {
            openArtifact();
          }
        }}
      >
        {/* {JSON.stringify(shouldOpenArtifactWithThisOnFinish)} */}
        <div className="p-4 flex items-center justify-center">
          {isLoading && <Loading type="tiny" />}
          {!isLoading && (type === "page builder" || type.startsWith("page")) && (
            <Browsers className="text-2xl" />
          )}
          {!isLoading && (type === "funnel builder" || type.startsWith("campaign")) && (
            <TreeStructure className="text-2xl" />
          )}
          {!isLoading && (type === "content builder" || type.startsWith("content/")) && (
            <NewspaperClipping className="text-2xl" />
          )}
        </div>
        <div className="p-3">
          <p className="font-bold">{title}</p>
          <p className="text-xs">{isLoading ? 'Creating launch plan...' : 'Click to open'}</p>
        </div>
      </div>
    </div>
  );
};
