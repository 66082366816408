// import React from "react";
// import { FontIcon } from "@launchos/components/ui";
import FontIcon from "@/ui/_old/FontIcon";

// import { useDimensions } from "@launchos/plugins/utilities";
import { useDimensions } from "@/Apps/Pages/utils";

import style from "./style";

// TODO: When the window resizes, obj coord should re-populate, otherwise, this can look ugly
export const VideoPreviewWhileDragging = ({ width, height }) => {
  const [ref, coords] = useDimensions()
  return (
    <div
      style={{
        width: "100%",
        // height,
        // padding: height ? "inherit" : "18%",
        padding: "20%",
        // height: coords.width * (720 / 1080),
        // height: height ? height - 25 : width * (720 / 1080),
        ...style.previewContainer
      }}
    >
      <div ref={ref}>
        <FontIcon style={style.previewContainerIcon}>videocam_off</FontIcon>
      </div>
    </div>
  );
};
