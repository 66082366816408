import {
  ReactFlow,
  Controls,
  PanOnScrollMode,
  ReactFlowProvider,
  applyNodeChanges,
  ViewportPortal,
} from "@xyflow/react";
import { useState, useCallback, useEffect, useContext } from "react";
import { useEditorActions } from "@/Apps/Pages/Editor/EditorCanvas";
import {
  sampleContentData,
  sidebarData,
} from "@/Apps/Pages/Editor/Editor.stories";
import { IPropertyBuilderSettings } from "@/Apps/Pages/Properties/components/generator/types";
import Generator from "@/Apps/Pages/Properties/components/generator";

import { CampaignBuilderProps } from "@/ui/NodeCanvas/types";

import "reactflow/dist/style.css"; // Import the styles
import PageNode from "./PageNode";

import { triggerHook } from "@/plugins/client";
import {
  ComponentRenderHooks,
  HookTriggers,
  ListItemsHooks,
} from "@/plugins/types";
import { head } from "lodash";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import AddNewDrawer from "@/Apps/Pages/AddNewDrawer";
import * as v2editorActions from "@/Apps/Pages/V2Editor/actions";
import { EditorContext } from "./EditorContext";

const nodeTypes = {
  page: PageNode,
};

export const VisualBuilder: React.FC<CampaignBuilderProps> = ({
  id,
  campaignData = {},
  isWidgetSidebarShowing = false,
  addNewDrawerType = "OBJECTS",
}) => {
  const [nodes, setNodes] = useState([]);

  const [isPropertiesShowing, setIsPropertiesShowing] = useState(false);
  const [propertySections, setPropertySections] =
    useState<IPropertyBuilderSettings>(sidebarData);

  const [activeSettings, setActiveSettings] = useState({});

  const onNodesChange = useCallback(
    (changes: any) =>
      setNodes((nds) => applyNodeChanges(changes, nds) as typeof nds),
    []
  );

  const updateEditorContent = (pageId, newContent) => { 
    // console.log("updateEditorContent", pageId, newContent);
    setNodes((oldNodes) =>
      oldNodes.map((node) =>
        node.id === pageId
          ? { ...node, data: { ...node.data, content: newContent } }
          : node
      )
    );
  };

  const updateEditorComponent = (componentId, newComponent) => {
    // console.log("updateEditorComponent", componentId, newComponent); 
    nodes.forEach((node) => {
      const pageId = node.id;
      const content = node?.data?.content;
      const changedContent = v2editorActions.updateComponentSettings(content, componentId, newComponent);
      updateEditorContent(pageId, changedContent);
    });
  };

  const updateEditorComponentStyle = (componentId, newStyle) => {
    nodes.forEach((node) => {
      // const pageId = node.id;
      const content = node?.data?.content;
      const newComponent = v2editorActions.updateComponentStyle(content, componentId, newStyle);
      // console.log("Updated Editor Component Style", newComponent);
      updateEditorComponent(componentId, newComponent)
    });
  };

  const addThisAfterThat = (settings, id) => {
    console.log("addThisAfterThat", nodes);
    nodes.forEach((node) => {
      const pageId = node?.data?.pageId;
      const content = node?.data?.content;
      const newContent = v2editorActions?.addThisAfterThat(content, settings, id); // applies to every item in the canvas with that id.
      // console.log("Adding This After That", settings, id, content, newContent);
      updateEditorContent(pageId, newContent);
    });
  };

  const handleEditorChange = useCallback((pageId, changedContent) => {
    // console.log("handleEditorChange", pageId, changedContent.length);
    updateEditorContent(pageId, changedContent);
  }, []);

  const handleEditorItemSelect = (pageId, settings) => { 
    console.log("handleEditorItemSelect", pageId, settings);
    setActiveSettings(settings);
    setIsPropertiesShowing(true);
  };

  const handleEditorSave = () => { console.log("handleEditorSave") };

  // Set Nodes from incoming campaign data
  // Run this when it loads or there is a content change, 
  useEffect(() => {
    
    // load the nodes
    const { objects } = campaignData;
    setNodes(
      objects
        ?.filter(({ deleted, type }) => !deleted && type === "PageComponent")
        .map(({ id, page, settings }, key) => ({
          id,
          type: "page",
          data: {
            pageId: id,
            label: page?.name,
            ...page,
            settings,
            activeSettings,
            setActiveSettings,
            handleEditorChange,
            handleEditorItemSelect,
            handleEditorSave,
          },
          position: { x: key * 1450, y: 50 },
        }))
    );

    // show properties
    const properties = activeSettings?.type
      ? triggerHook(HookTriggers.onListItems, {
          id: ListItemsHooks.PROPERTIES,
          type: activeSettings.type,
        })
      : [{}];
    
    setPropertySections(head(properties));
    
  }, []); // Add activeSettingsHandler to the dependency array
  // }, [campaignData, activeSettings, setActiveSettings]); // Add activeSettingsHandler to the dependency array

  const onNodeClick = useCallback((event, node) => {
    // Handle node selection here if needed
  }, []);


  return (
    <DndProvider backend={HTML5Backend}>
      <ReactFlowProvider>
        <EditorContext.Provider value={{
          nodes, setNodes, 
          activeSettings,
          updateEditorContent,
          updateEditorComponent,
          updateEditorComponentStyle,
          addThisAfterThat,
        }}>
          <div className="w-full h-screen flex relative">
            {isWidgetSidebarShowing && (
              <div className="divide-y right-0 divide-black/10 dark:divide-white/10 border-r border-black/10 dark:border-white/10 h-full mr-2 overflow-y-auto w-[600px] bg-white dark:bg-black/80 z-10">
                <AddNewDrawer
                  activeTab={addNewDrawerType}
                  // addThisAfterThat={(...args) => console.log("Adding This After That", args)}
                  // addThisAfterThat={(settings, id) => addThisAfterThat(content, settings, id)}
                  addThisAfterThat={addThisAfterThat}
                />
              </div>
            )}

            <div className="pt-5 w-full z-0">
              <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                nodeTypes={nodeTypes}
                maxZoom={7}
                minZoom={0.1}
                fitView
                // preventScrolling
                // zoomOnScroll={false}
                // panOnScroll={false}
                panOnDrag={false}
                // panOnScrollMode={PanOnScrollMode.Free}
                nodesDraggable={false} // Prevent nodes from being dragged
                // nodesConnectable={false} // Prevent nodes from being connected
                // selectNodesOnDrag={false} // Prevent node selection when interacting with inner content
                onNodeClick={onNodeClick} // Handle node selection manually if needed
                proOptions={{ hideAttribution: true }}
              >
                <Controls />
              </ReactFlow>
            </div>

            {isPropertiesShowing && (
              <div className="divide-y right-0 divide-black/10 dark:divide-white/10 border-l border-black/10 dark:border-white/10 h-full ml-2 overflow-y-auto w-[475px] bg-white dark:bg-black/80 z-10">
                {/* <div className="text-xl">{JSON.stringify(activeSettings)}</div> */}
                {/* <div className="text-xl">{JSON.stringify(nodes[0]?.data?.content?.length)}</div> */}

                {propertySections?.main?.sections?.length ? (
                  <Generator
                    data={propertySections}
                    settings={activeSettings}
                    updateComponentStyle={updateEditorComponentStyle}
                    updateContent={updateEditorContent}
                    updateComponentSettings={updateEditorComponent}
                  />
                ) : (
                  triggerHook(
                    HookTriggers.onComponentRender,
                    {
                      id: ComponentRenderHooks.PROPERTIES,
                      type: activeSettings?.type,
                    },
                    {
                      settings: activeSettings,
                      updateComponentStyle: updateEditorComponentStyle,
                      updateContent: updateEditorContent,
                      updateComponentSettings: updateEditorComponent,
                    }
                  )
                )}
              </div>
            )}
          </div>
        </EditorContext.Provider>
      </ReactFlowProvider>
    </DndProvider>
  );
};
