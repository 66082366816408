import React from "react";
import HiddenFields from "./HiddenFields";
import { ComponentRenderHooks } from "@/plugins/types";

export const onComponentRender = (
  hook,
  { nextPage, emailPayload, merchantPayload, pageId, campaignId }
) => {
  if (
    hook.id === ComponentRenderHooks.PAGE_LOAD &&
    (nextPage.length || emailPayload.length || merchantPayload.length)
  )
    return [
      <HiddenFields
        key="hidden_fields"
        nextPage={nextPage}
        pageId={pageId}
        campaignId={campaignId}
        emailPayload={emailPayload}
        merchantPayload={merchantPayload}
      />,
    ];
};

export default HiddenFields;
