import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { BenefitLayouts } from "../types";

import theme from "../../theme";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";
import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: BenefitLayouts.FOURTEEN,
  layout: BenefitLayouts.FOURTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit14.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx5he5q231g07808cui36sy",
    itemId: "e9wobosfm8w",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 500,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.ICON,
            canHaveChildren: false,
            properties: style.ICON,
            iconType: IconTypes.Email,
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: get(content, "sub_headline.html", "..."),
          },
        ],
      },
    ],
  },
};

export default settings;
