import { CountDownTypes } from "./types";

import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
// import theme from "@/ui/theme";
const theme = {
  backgroundColor: "#0022AA",
  foregroundColor: "#FFF",
  fontFamily: "Open Sans"
}

export const style = {
  COUNTDOWN: {
    style: {
      margin: "0 auto",
      display: "inline-block",
    },
    dimensionStyle: {
      minWidth: 80,
    },
    numberStyle: {
      textAlign: "center",
      fontSize: "26pt",
      fontFamily: theme.fontFamily,
      fontWeight: "bold",
      padding: 10,
      backgroundColor: "#333",
      // backgroundColor: theme.backgroundColor,
      color: theme.foregroundColor,
      margin: 5,
    },
    labelStyle: {
      fontWeight: "bold",
      fontFamily: theme.fontFamily,
      fontSize: "0.85rem",
    },
    dividerStyle: {
      padding: 10,
      fontWeight: "bold",
      fontFamily: theme.fontFamily,
    },
  },
};

const settings: IComponentSettings = {
  id: ComponentTypes.COUNTDOWN,
  name: "Countdown",
  description: "",
  thumbnail: "comp_countdowns.png",
  default: {
    type: ComponentTypes.COUNTDOWN,
    countType: CountDownTypes.EVENT,
    eventDate: Date.now() + 365 * 24 * 60 * 60 * 1000,
    // properties: style.COUNTDOWN.style,
    properties: {
      textAlign: "center",
      display: "inline-block",
    },
    dimensionStyle: style.COUNTDOWN.dimensionStyle,
    labelStyle: style.COUNTDOWN.labelStyle,
    numberStyle: style.COUNTDOWN.numberStyle,
    dividerStyle: style.COUNTDOWN.dividerStyle,
    showDivider: false,
  },
};

export default settings;
