// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
// import { themeSettings } from "@launchos/plugins/webcomponents/v2/theme";

// import { IComponentSettings, ComponentTypes } from "../../types";
import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { DividerTypes } from "./types";

const settings: IComponentSettings = {
  id: ComponentTypes.DIVIDER,
  name: "Divider",
  description: "",
  thumbnail: "comp_dividers.png",
  default: {
    type: ComponentTypes.DIVIDER,
    properties: {
      // margin: "10px auto",
      width: "100%",
      height: 5,
      display: "inline-block",
      // display: "block",
      textAlign: "center",
    },
    dividerType: DividerTypes.HORIZONTAL,
    // color: themeSettings.default.highlightColor,
    color: "#f05a1f",
  },
  properties: {
    main: {
      // tabs: ["Basic"],
      sections: [
        // {
        //   type: SectionTypes.CUSTOM,
        //   id: "custom_type",
        //   sectionWrap: true,
        //   settings: {
        //     label: "Divider Style",
        //     isExpanded: true,
        //     icon: "format_paint",
        //   },
        // },
        {
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Divider Width",
            text: "Width",
            icon: "swap_horiz",
            showAutoCheck: false,
            isExpanded: true,
          },
        },
        {
          type: SectionTypes.HEIGHT,
          settings: {
            label: "Divider Size",
            text: "Size",
            icon: "height",
            isExpanded: true,
            showAutoCheck: false,
            minValue: 1,
            maxValue: 50,
          },
          sectionWrap: true,
        },
        {
          type: SectionTypes.COLORPICKER,
          sectionWrap: true,
          settings: {
            label: "Divider Color",
            icon: "colorize",
          },
        },
        {
          type: SectionTypes.POSITION,
          sectionWrap: true,
          settings: {
            label: "Divider Position",
            icon: "swap_vert",
          },
        },
        {
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
      ],
    },
  },
};

export default settings;
