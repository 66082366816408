import { ComponentRenderHooks } from "@/plugins/types";
import { ListItemComponent, Properties } from "./component";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "ListItem"
  ) {
    return [ListItemComponent];
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "ListItem") {
    return Properties(payload);
  }
};
