// import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import { ComponentRenderHooks } from "@/plugins/types";
import EditorColumn from "./column";
import WebComponentWrapper from "./WebComponentWrapper";
import { Properties } from "./Properties";

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === "Column"
  ) {
    return [EditorColumn];
  }

  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT_WRAPPER &&
    payload.type === "Column"
  ) {
    return [WebComponentWrapper];
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Column") {
    return Properties(payload);
  }
};