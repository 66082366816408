import { useContext, useEffect, useState } from "react";
import CodeMirror from "react-codemirror";
import { Modal, Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { getCampaign, updateCampaign } from "@/api/campaigns";
import { FileHtml, FileJs } from "@phosphor-icons/react";
import { TabsComponent } from "@/ui/TabsComponent";
import { AppContext } from "@/AppContext";
import { getSessionToken } from "@/api/auth";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import "codemirror/addon/display/autorefresh";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import { Loading } from "@/ui/Layout/Loading";
import { getPage, updatePage } from "@/api/pages";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

const contentWithHTML = [
  {
    type: "HTML",
    html: "<div></div>",
    style: {
      display: "inline-block",
    },
    properties: {},
    parent: "dr3ykbhmp9t",
    state: "normal",
    oldId: "cerigip261m",
    id: "cerigip261m",
  },
  {
    type: "Section",
    properties: {
      display: "block",
      width: "100%",
      padding: 0,
      mobile: {
        fullscreen: {
          padding: "0rem",
        },
      },
    },
    canHaveChildren: true,
    parent: 2540,
    state: "normal",
    oldId: "dr3ykbhmp9t",
    id: "dr3ykbhmp9t",
  },
  {
    parent: false,
    id: 2540,
    properties: {
      height: "100%"
    },
    type: "Body",
    canHaveChildren: true
  },
];

export const CustomHTML = ({ setIsOpen, pageId }) => {
  const { darkMode } = useContext(AppContext);
  const [html, setHTML] = useState("<div>\n\t\n</div>");
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomHTMLCodeSave = async () => {
    // call the api to save the campaign tracking codes
    const token = getSessionToken();
    
    const content = [
      { ...contentWithHTML[0], html },
      ...contentWithHTML.slice(1)
    ];

    const response = await updatePage(pageId, {
      token,
      settings: { content },
    });
    
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Campaign Tracking Codes Saved");
        setIsOpen(false);
        console.log(response);
      }
    }
  };

  // load in the campaign tracking codes from the api if there are any
  const loadCustomHTMLCode = async () => {
    setIsLoading(true);

    const token = getSessionToken();
    // const response = await getCampaign({ token, id: campaignId });
    const response = await getPage(pageId, { token })
    // console.log({ response })
    const content = response?.data?.content || [];

    const htmlObj = content.find(itm => itm.type===ComponentTypes.HTML);

    if (htmlObj?.html) setHTML(htmlObj?.html);
    
    setIsLoading(false);
  };

  useEffect(() => {
    loadCustomHTMLCode();

    // console.log("Active Tab", getActiveTabFromLocalStorage());
  }, []);

  return (
    <Modal
      title="Enter Custom HTML Code"
      onClose={() => setIsOpen(false)}
      onSuccess={handleCustomHTMLCodeSave}
      icon={<FileHtml />}
      size={PopupSizes.LARGE}
    >     

      {isLoading ? (
        <Loading />
      ) : (
        <div className="grid border border-black/10 dark:border-white/5">
          <CodeMirror
            className="cdmirror"
            options={{
              mode: "htmlmixed",
              lineNumbers: true,
              theme: darkMode ? "material-darker" : "default",
            }}
            value={html}
            onChange={(value) => {
              // setVariables({ ...variables, body: editor });
              setHTML(value)
            }}
            autoFocus
          />
        </div>
      )}
    </Modal>
  );
};
