// import * as React from "react";
import { get } from "lodash";
import { Grid } from "@material-ui/core";
import { LiveFormField } from "@/ui/CRUD/FormBuilder";

// import LiveFormField from "./LiveFormField";
// const LiveFormField = ({styles}) => <div style={styles}>Placeholder</div>
// LiveFormField

/**
 * A module for displaying, validating, and handling groups of form fields
 *
 * - Displays in either live view or edit view
 * - In "edit view", a drag-and-drop ux is provided to help users build forms
 * - Shows a list sortable object with a custom item container (&lt;EditFormField /&gt;)
 * - In "live view", the form is generated based on the incoming data it receives
 */
export function LiveFormBuilder(props) {
  // return <div data-testid="WC-FORMBUILDER-LIVE"></div>

  return (
    <div data-testid="WC-FORMBUILDER-LIVE">
      <Grid
        container
        justifyContent="space-between"
        spacing={Number(get(props.style, 'containerStyle.spacing', 0)) || 0}
        style={get(props.style, 'containerStyle')}
      >
        {/* {JSON.stringify(props)} */}
        {props.data && props.data.map((itm, key) => {
          // <div key={key}>{JSON.stringify(itm)}</div>
          return <LiveFormField key={key} {...itm} styles={props.style} />
        })}
      </Grid>
    </div>
  );
}

export default LiveFormBuilder