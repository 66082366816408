import * as React from "react";
import _ from "lodash";
// import { CURSOR_ID } from "@launchos/modules/editor/Builder/WebComponent/types";
import { CURSOR_ID, EditorMode } from "@/Apps/Pages/Editor/types";

// import html2canvas from "html2canvas";

import { Section } from "./live";

// import theme from "@launchos/components/ui/theme";
import theme from "@/ui/theme";

// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import EditorObject, { EmptyState } from "@/Apps/Pages/EditorObject";

// import { EditorMode } from "@launchos/modules/v2/Editor/types";

// import { EmptyState } from "@launchos/modules/editor/Builder/EditorObject";

// import { useDimensions } from "@launchos/plugins/utilities";
import { useDimensions } from "@/Apps/Pages/utils";

// import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";

import sectionSettings from "./settings";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
// import { relative } from "path";

// import LazyLoad from "react-lazyload";


export const Properties = (props) => {
  const { updateComponentStyle, settings } = props;
  const { properties } = sectionSettings;
  const { sections } = properties.main;

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections: sections },
      }}
      title="Page Section"
    />
  );
};

enum movePositions {
  MOVE_UP,
  MOVE_DOWN,
  DIVIDER = "divider",
}

export const EditorSection: React.FC<PluginProps> = (props) => {
  const {
    settings,
    mode,
    id,
    children,
    pageContent,
    moveThisByThat,
    toggleAddNewDrawer,
    setActiveAddNewTab,
  } = props;
  const [ref, hoverBoundingRect] = useDimensions();

  const properties = convertProperties(settings.properties);
  const sections = pageContent.filter((itm) => itm?.type === "Section");
  const key = sections.findIndex((itm) => itm.id === settings.id);

  let isEmpty = true;
  const childContent = pageContent?.filter(
    (itm) => itm?.parent === settings.id
  );
  if (childContent.length) {
    isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
  }

  const handleSectionMenuChange = (id) => {
    // if (id === movePositions.MOVE_UP) {
    //   moveThisByThat(sections[key].id, sections[key - 1].id);
    // }
    // if (id === movePositions.MOVE_DOWN) {
    //   moveThisByThat(sections[key].id, sections[key + 1].id);
    // }

    if (id === "PASTE") {
      const clipboard = JSON.parse(localStorage.getItem("clipboard"));
      if (_.get(clipboard, "type") !== "Section") {
        // only allow pasting another section
        alert(
          "You can only paste SECTION widgets below another SECTION widget"
        );
        return false;
      } else return true;
    }

    if (id === "ADD") {
      setActiveAddNewTab("SECTIONS");
      toggleAddNewDrawer();
    }

    // if (id === "SNAPSHOT") {
    //   const headerSection = document.getElementById(`section_${settings.id}`);
    //   html2canvas(headerSection).then((canvas) => {
    //     const base64image = canvas.toDataURL("image/png");
    //     console.log({ base64image });
    //     window.open(base64image, "_blank");
    //   });
    // }

    return true;
  };

  return (
    <V1ObjectWrapper
      settings={{
        ...settings,
        properties: { ...settings.properties, maxWidth: "100%", width: "100%" },
      }}
    >
      {/* <input onDoubleClick={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()} style={{ position: "absolute", zIndex: 100, padding: 10, color: 'red', backgroundColor: "#FFCC00" }} type="text" value={settings.id} /> */}
      <EditorObject
        id={id}
        {...props}
        PropertiesView={Properties}
        borderSize={5}
        color={
          _.get(settings, "state") === "hover"
            ? theme.altColors[7]
            : theme.primaryColor
        }
        objectHandleStyle={{
          hover: {
            backgroundColor:
              _.get(settings, "state") === "hover"
                ? theme.altColors[7]
                : theme.primaryColor,
            color: "white",
          },
        }}
        forceOffset
        dropDownItems={(items) => {
          // Move Up/Down
          // const k = items.findIndex(({ id }) => id === "divider");
          // const moveItems = [
          //   ...items.slice(0, k),
          //   ...(_.has(sections, key - 1) || _.has(sections, key + 1)
          //     ? [{ id: movePositions.DIVIDER }]
          //     : []),
          //   ...(_.has(sections, key - 1)
          //     ? [
          //       {
          //         id: movePositions.MOVE_UP,
          //         text: "Move Up",
          //         icon: "expand_less",
          //       },
          //     ]
          //     : []),
          //   ...(_.has(sections, key + 1)
          //     ? [
          //       {
          //         id: movePositions.MOVE_DOWN,
          //         text: "Move Down",
          //         icon: "expand_more",
          //       },
          //     ]
          //     : []),
          //   ...(_.has(sections, key - 1) || _.has(sections, key + 1)
          //     ? [{ id: movePositions.DIVIDER }]
          //     : []),
          //   ...items.slice(k + 1),
          // ];
          const moveItems = items;

          // Add New Section
          const p = moveItems.findIndex(({ id }) => id === "PROPERTIES");
          const addNewItem = [
            ...moveItems.slice(0, p),
            { id: "ADD", text: "Add Another Section", icon: "add_circle" },
            // { id: "SNAPSHOT", text: "Take Snapshot", icon: "camera" },
            ...moveItems.slice(p),
          ];
          return addNewItem;
        }}
        // onMouseMove={(event) =>
        //   console.log(
        //     "eChBR",
        //     event.clientX,
        //     event.clientY,
        //     hoverBoundingRect.left,
        //     hoverBoundingRect.top
        //   )
        // }
        onDropDownChange={handleSectionMenuChange}
        style={{ width: "100%", maxWidth: "100%" }}
      // debug
      >
        <div id={`section_${settings.id}`} ref={ref}>
          <Section
            {...settings}
            properties={properties}
            mode={mode}
            testId="WC-SECTION-EDIT"
          >
            {isEmpty && mode === EditorMode.EDITOR ? (
              <EmptyState {...props} />
            ) : (
              children
            )}
          </Section>
        </div>
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorSection;
