import { useState } from "react";
import { Button } from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Panel } from "@/ui/Panel";
import { Calout } from "@/ui/Calout";
import { DeleteFunnelModal } from "@/Apps/Funnels/Popups/DeleteFunnelModal";
import { classNames } from "@/Apps/Planning/data";

export const DeleteFunnel = ({
  unitName = "Funnel",
  campaignData = {},
  onDelete = () => null,
}) => {
  const [isDeleteCheckboxChecked, setIsDeleteCheckboxChecked] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const handleDelete = () => {
    // delete only if the checkbox is checked.  Warn the user if it's not checked
    if (!isDeleteCheckboxChecked) {
      alert(
        `Please check the checkbox to confirm that you want to delete this ${unitName.toLowerCase()} `
      );
      return;
    }

    // onDelete();
    setIsDeletePopupOpen(true);
  };

  return (
    <>
      {Boolean(isDeletePopupOpen) && (
        <DeleteFunnelModal
          unitName={unitName}
          campaignData={campaignData}
          setIsOpen={setIsDeletePopupOpen}
        />
      )}

      <Panel
        title={<span className="text-red-500">Delete this {unitName.toLowerCase()}</span>}
        className="border border-red-500"
        actionButton={
          <Button
            type={ButtonTypes.SOFT}
            label={`Delete ${unitName}`}
            // onClick={() => setIsDeletePopupOpen(true)}
            onClick={handleDelete}
          />
        }
      >
        <div className="flex items-start w-full mb-5">
          <Calout
            title={`You are about to delete this ${unitName.toLowerCase()}`}
            caption={`This action is irreversible. Are you sure you want to delete this ${unitName.toLowerCase()}?`}
          />
        </div>

        <div className="flex items-start pl-1">
          <input
            id="deleteBox"
            type="checkbox"
            checked={isDeleteCheckboxChecked}
            onChange={() =>
              setIsDeleteCheckboxChecked(!isDeleteCheckboxChecked)
            }
            className="w-[18px] h-[18px] text-black bg-white border-black/20 rounded dark:bg-transparent dark:border-white/20 dark:text-white/20 focus:ring-0 focus:outline-0 focus:outline-offset-0 focus:ring-offset-0"
          />
          <label htmlFor="deleteBox" className="ml-3 cursor-pointer">
            Yes, delete this {unitName.toLowerCase()}
          </label>
        </div>
      </Panel>
    </>
  );
};
