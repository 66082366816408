import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";
import { FieldNamePresets } from "@/Apps/CRM/types";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.NINETEEN,
  layout: FormLayouts.NINETEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form19.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzjrul12h5h0780m1q9wbx3",
    itemId: "tr6bk5wsqha",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          border: `1px solid ${theme.backgroundColor}`,
          mobile: {
            desktop: {
              width: 450,
            },
            smartphone: {
              width: "100%",
            },
          },

          display: "inline-block",
          textAlign: "right",
        },
        children: [
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              display: "inline-block",
              textAlign: "center",
              backgroundColor: "white",
            },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  backgroundColor: theme.backgroundColor,
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      padding: 40,
                      fontSize: "26pt",
                      lineHeight: "32pt",
                      color: theme.foregroundColor,
                    },
                    html: "Pay With Credit or Debit Card",
                  },
                ],
              },
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 30,
                },
                children: [
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: startCase(lorem.generateWords(4)),
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.SUBHEADLINE,
                      ...get(content, "main_headline.style", {}),
                    },
                    html: lorem.generateSentences(1),
                  },
                  {
                    type: ComponentTypes.FORM,
                    data: [
                      {
                        type: FormFieldTypes.TEXT,
                        name: FieldNamePresets.FIRSTNAME,
                        placeholder: "First Name",
                        preset: FieldNamePresets.FIRSTNAME,
                      },
                      {
                        type: FormFieldTypes.TEXT,
                        name: FieldNamePresets.EMAIL,
                        placeholder: "Email",
                        preset: FieldNamePresets.EMAIL,
                      },
                      {
                        type: FormFieldTypes.TEXT,
                        name: FieldNamePresets.PHONE,
                        placeholder: "Phone",
                        preset: FieldNamePresets.PHONE,
                      },
                    ],
                    properties: {
                      containerStyle: style.FORM_FIELD.style,
                      inputStyle: style.FORM_FIELD.inputStyle,
                      labelStyle: style.FORM_FIELD.labelStyle,
                    },
                  },
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: style.BUTTON.style,
                    caption1: "Yes! I Want This Product",
                    caption1style: style.BUTTON.caption1,
                    caption2: "Please add this to my shopping cart",
                    caption2style: style.BUTTON.caption1,
                    icon: IconTypes.Email,
                    iconStyle: {
                      marginLeft: 10,
                      marginTop: 2,
                      fontSize: "26pt",
                    },
                  },
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                      fontSize: "9pt",
                    },
                    html: lorem.generateSentences(2),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
