import { TabsComponent } from "@/ui/TabsComponent";
import { TabTypes } from "@/ui/TabsComponent/types";
import { ArrowUUpLeft, ArrowUUpRight } from "@phosphor-icons/react";

export const UndoRedoButtons = ({
  onUndo = () => {},
  onRedo = () => {},
  canUndo = () => false,
  canRedo = () => false,
}) => {
  return (
    <div className="flex px-3">
      {/* {JSON.stringify({ canUndo: canUndo(), canRedo: canRedo() })} */}
      <TabsComponent
        tabs={[
          {
            label: (
              <div className={`px-3`} onClick={onUndo}>
                <ArrowUUpLeft />
              </div>
            ),
            isActive: canUndo(),
            value: "undo",
          },
          {
            label: (
              <div className={`px-3`} onClick={onRedo}>
                <ArrowUUpRight />
              </div>
            ),
            isActive: canRedo(),
            value: "redo",
          },
        ]}
        type={TabTypes.BUTTON}
        actionSection={false}
      />
    </div>
  );
};
