import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "@/AppContext";
import Button from "@/ui/Button";
import ListDisplayGroup from "@/ui/ListDisplayGroup";
import Panel from "@/ui/Panel";
import { ButtonTypes, Variants } from "@/ui/types";
import { ListDropdownItem } from "@/ui/ListDropdown/types";
import { DeletePageModal } from "@/Apps/Funnels/Popups/DeletePageModal";
import { AddPageModal } from "@/Apps/Funnels/Popups/AddPageModal";
import { getImageFromType } from "@/Apps/Funnels/utils";
import {
  // makeSlug,
  // publishPage,
  openPublishedPage,
  makeThisMyHomePage,
  makeThisMy404Page,
  getPageDataFromObjId,
  openPageInEditor,
} from "@/api/pages";
import { getSessionToken } from "@/api/auth";

const getLaunchPadLink = (unitName: string) => {
  switch (unitName) {
    case "page":
      return "pages";
    case "step":
      return "forms";
    case "site":
      return "sites";
    case "site page":
      return "sites";
    default:
      return unitName;
  }
};

// darkMode; unitName; navigate
export const getPagesInCampaign = (
  campaignData: {
    id: string;
    objects?: Array<{
      deleted: boolean;
      type: string;
      id: string;
      name: string;
      x?: number;
      page?: { slug: string; type: string; id: string };
    }>;
  }
) => {
  return campaignData?.objects
    ?.filter(({ deleted, type }) => !deleted && type === "PageComponent")
    .sort((a: { x: any }, b: { x: any }) => (a.x || 0) - (b.x || 0)) // order by [x]    
};

export const PageList = ({
  panelTitle,
  unitName,
  getDropDownItems = (items: ListDropdownItem[]) => items,
  campaignData,
}) => {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  // const [isAddPagePopupOpen, setIsAddPagePopupOpen] = useState(false);
  const [activePageId, setActivePageId] = useState(null);

  const { darkMode } = useContext(AppContext);
  const navigate = useNavigate();

  const handleMakeThisMyHomePage = async (objId: string) => {
    const token = getSessionToken();
    const pageId = getPageDataFromObjId(objId, campaignData)?.id;
    const response = await makeThisMyHomePage(pageId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        alert(
          "This page is now your home page.  Please re-publish the page for it to take effect for your website visitors."
        );
        console.log(response);
      }
    }
  };

  const handleMakeThisMy404Page = async (objId: string) => {
    const token = getSessionToken();
    const pageId = getPageDataFromObjId(objId, campaignData)?.id;
    const response = await makeThisMy404Page(pageId, { token });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        alert(
          "This page is now your 404 (error) page.  Please re-publish the page for it to take effect for your website visitors."
        );
        console.log(response);
      }
    }
  };

  const handleDeletePage = (id: string) => {
    const pageId = getPageDataFromObjId(id, campaignData)?.id;
    setActivePageId(pageId);
    setIsDeletePopupOpen(true);
  };

  // const onAddPageClick = () => setIsAddPagePopupOpen(true);

  const dropdownItems: ListDropdownItem[] = [
    {
      id: "edit",
      label: "Edit Page",
      href: "#",
      onClick: (objId: string) => {
        navigate(`/funnels/${campaignId}/${objId}`);
      },
    },
    {
      id: "publish",
      label: "View Published Page",
      href: "#",
      onClick: (objId: string) =>
        openPublishedPage({
          campaignData,
          pageData: getPageDataFromObjId(objId, campaignData),
        }),
    },
    // { label: "Rename Page", href: "#" }, // Easy with API (but object may be a challenge)
    { type: "divider" },
    // { label: "Move Page Up", href: "#" }, // Object API needed (and to port code over from 2.0 app)
    // { label: "Move Page Down", href: "#" }, // Object API needed (and to port code over from 2.0 app)
    // { type: "divider" },
    // { label: "Make a Copy", href: "#" }, // Do this later
    // { label: "Change the Page URL (Slug)", href: "#" }, // Easy with API
    // { type: "divider" },
    {
      id: "homepage",
      label: "Make this my Homepage",
      href: "#",
      onClick: handleMakeThisMyHomePage,
    },
    {
      id: "404",
      label: "Make this my 404 (Error) Page",
      href: "#",
      onClick: handleMakeThisMy404Page,
    },
    { type: "divider" },
    {
      id: "delete",
      label: "Delete Page",
      href: "#",
      onClick: handleDeletePage,
    }, // Easy with API
  ];

  const campaignId = campaignData?.id;

  // Just Non Deleted Pages, shown in order
  const pagesInCampaign = getPagesInCampaign(campaignData || { id: '', objects: [] }).map(
    ({ id, name, type, page }) => ({
      id: id,
      title: name,
      caption: `/${page?.slug}`,
      image:
        getImageFromType({ type, subType: page?.type }) ||
        "https://flowbite.com/docs/images/logo.svg",
      imageStyle: getImageFromType({ type, subType: page?.type })
        ? {}
        : {
            padding: "5px 15px",
            backgroundColor: darkMode ? "#323232" : "#f5f5f5",
          },
      // status: { caption: "In Progress", type: Variants.PRIMARY },
      onClick: async () => {
        // navigate(`/${unitName !== 'page' ? getLaunchPadLink(unitName) : 'funnels'}/${campaignId}/${id}`);
        await openPageInEditor({
          campaignId,
          objectId: id,
          pageId: page?.id,
          navigate,
          unitName:
            unitName !== "page" ? getLaunchPadLink(unitName) : "funnels",
        });
      },
    })
  );

  return (
    <Panel
      title={panelTitle}
      actionButton={
        <Link to={`/launchpad/${getLaunchPadLink(unitName)}/${campaignId}`}>
          <Button
            // onClick={onAddPageClick}
            type={ButtonTypes.SOFT}
            label={`+ Add ${unitName}`}
          />
        </Link>
      }
    >
      {/* Render the delete page modal if it's set to open */}
      {Boolean(isDeletePopupOpen) && (
        <DeletePageModal
          campaignId={campaignId}
          pageId={activePageId}
          setIsOpen={setIsDeletePopupOpen}
          unitName={unitName}
        />
      )}

      {/* Render the add page modal if it's set to open */}
      {/* {Boolean(isAddPagePopupOpen) && (
        <AddPageModal
          campaignId={campaignId}
          setIsOpen={setIsAddPagePopupOpen}
          unitName={unitName}
        />
      )} */}

      <ListDisplayGroup // label="Pages in this funnel"
        // caption="Funnel Settings"
        items={pagesInCampaign}
        footer={{
          caption: `Click to add a new ${unitName}`,
          actionComponent: (
            <Link to={`/launchpad/${getLaunchPadLink(unitName)}/${campaignId}`}>
              <Button
                // onClick={onAddPageClick}
                type={ButtonTypes.SOFT}
                variant={Variants.PRIMARY}
                label={`+ Add ${unitName}`}
              />
            </Link>
          ),
        }}
        menuItems={getDropDownItems(dropdownItems)}
      />
    </Panel>
  );
};
