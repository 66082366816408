import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";
import { Fire } from "@phosphor-icons/react";

export const Wildfire = ({ name = "" }) => {
  const [wildfireCampaigns, setWildfireCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // get the wildfireCampaigns from the server
  const loadWildfireCampaigns = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "wildfire" });
    // const response = await getCampaigns({ token, type: "wildfireCampaign" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((wildfireCampaign: any) => {
          return {
            id: wildfireCampaign.id,
            label: wildfireCampaign.name,
            caption: `Last Updated: ${moment(
              wildfireCampaign.updatedAt
            ).fromNow()}`,
            img: (
              // <Fire className="text-lg" width="32" height="32" />
              <p className="text-2xl">🔥</p>              
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setWildfireCampaigns(seqs);
    }
    setIsLoading(false);
  };

  // load the wildfireCampaigns when the page loads
  useEffect(() => {
    loadWildfireCampaigns();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Link to="/launchpad/wildfire" className="justify-end">
            <Button
              label="+ Create a Wildfire Campaign"
              type={ButtonTypes.OUTLINED}
            />
          </Link>
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(wildfireCampaigns.length) && (
          <EmptyState
            title="No Wildfire Campaigns Yet"
            description="Get started by creating a new wildfire."
            showImage={false}
          >
            <Link to="/launchpad/wildfire">
              <Button
                label="Create a Wildfire Campaign"
                type={ButtonTypes.DEFAULT}
              />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(wildfireCampaigns.length) && (
           <Grid className="pb-6">
            {/* Can we change to Gallery View - let's me toggle views */}
            {wildfireCampaigns.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/wildfire/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
