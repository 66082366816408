import Modal from "@/ui/Modal";
import { Code, Copy } from "@phosphor-icons/react";
import { PopupSizes } from "@/ui/Modal/types";
import { PanelItem } from "@/ui/Panel";

export const EmbedCode = ({ slug = "", hostname = "" }) => {
  const theScript = `<style>#content { width: 100%; visibility: hidden; } #wrapper { width: 100%; } #funnel { width: 100%; position: absolute; top: 0 }</style><script type="text/javascript" src="https://${hostname}/widget.js?cid=funnel&r=${slug}"></script><div id="funnel" />`;
  return (
    <PanelItem
      title={
        <p>
          Copy This Embeddable Script
          <br />
          <div className="border border-black/10 dark:border-white/10 rounded-lg p-3 my-2 font-normal flex justify-between items-center bg-white/5">
            <span className="flex-1">{theScript}</span>
            <span
              className="cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(theScript);
                alert("The script has been added to your clipboard");
              }}
            >
              <Copy />
            </span>
          </div>
        </p>
      }
      description="Copy this script and paste it into your Keap order form settings."
    />
  );
};

export const GetCodePopup = ({ campaignData = {}, onClose = () => {} }) => {
  // const hostname = campaignData?.domain?.name || "";
  const hostname = "forms.freedomsoft.com";
  const slug = campaignData?.objects?.[0]?.page?.slug || "";

  return (
    <Modal
      icon={<Code />}
      title="Copy & Paste this Code into Keap Order Form Settings"
      size={PopupSizes.LARGE}
      onClose={onClose}
      onSuccess={onClose}
    >
      <div className="p-6">
        <EmbedCode hostname={hostname} slug={slug} />
      </div>
    </Modal>
  );
};
