import Height from "../BasicProperties/Height";
import Width from "../BasicProperties/Width";
import Section from "../Section";

const WidthHeight = ({ showWidth = true, showHeight = true, ...props }) => {
  return (
    <Section label="Size" icon="photo_size_select_large" {...props}>
      {showWidth && <Width {...props} />}
      {showHeight && <Height {...props} />}
    </Section>
  );
};

export default WidthHeight;
