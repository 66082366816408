import { ComponentRenderHooks } from "@/plugins/types";
import Countdown from "./component";
import settings from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import Properties from "./Properties";
import { SimpleCountdownProperties } from "./Properties/Properties";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.COUNTDOWN
  ) {
    return [Countdown];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.COUNTDOWN
  ) {
    return Properties(payload);
  }

  if (
    hook.id === ComponentRenderHooks.SIMPLE_PROPERTIES &&
    hook.type === ComponentTypes.COUNTDOWN
  ) {
    return SimpleCountdownProperties(payload);
  }
};