import { ComponentRenderHooks, ListItemsHooks } from "@/plugins/types";
import Spacer, { Properties } from "./component";
import settings from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }

  // if (hook.id === ListItemsHooks.PROPERTIES && hook.type === ComponentTypes.SPACER) {
  //   return settings.properties;
  // }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.SPACER
  ) {
    return [Spacer];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.SPACER
  ) {
    return Properties(payload);
  }
};
