/**
 * An object representing a tab.
 */
type TabItem = {
  /**
   * The label to display for the tab.
   */
  label: string;
  /**
   * The value associated with the tab.
   */
  value: string;
};

export enum TabTypes {
  TAB,
  BUTTON,
}

/**
 * Props for the TabsComponent.
 */
export type TabsComponentProps = {
  /**
   * Whether or not to show the action section.
   */
  actionSection?: boolean;
  /**
   * The currently active tab.
   */
  activeTab?: string;
  /**
   * Whether or not to store the active tab in local storage.
   */
  storeActiveTab?: boolean;
  /**
   * The class name to apply to the component.
   */
  className?: string;
  /**
   * Function to set the active tab.
   * @param tab - The tab to set as active.
   */
  setActiveTab?: (tab: string) => void;
  /**
   * An array of objects representing each tab.
   */
  tabs: TabItem[];

  /**
   * The type of tabs to render. Defaults to TabTypes.TAB if not specified.
   */
  type?: TabTypes;
};