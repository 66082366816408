import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import { MobileResponsiveToggleProps, MobileState } from "./types";
import { style, getDefaultFontSize } from "./style";
import FontIcon from "@/ui/_old/FontIcon";
import TabsComponent from "@/ui/TabsComponent";
import { TabTypes } from "@/ui/TabsComponent/types";
// import Button from "@/ui/_old/Button";

/**
 * For Mobile Responsiveness - set the root size that page elements derive their relative sizes from
 */
const SetRootFontSize: React.FC<{ mobileState: MobileState }> = ({
  mobileState,
}) => {
  const fontSize = getDefaultFontSize(mobileState);
  return (
    <Helmet>
      {/* <style type="text/css">{`html { font-size: ${fontSize}px; }`}</style> */}
      <style type="text/css">{`.wc-Body { font-size: ${fontSize}px; }`}</style>
    </Helmet>
  );
};

const MobileResponsiveToggle: React.FC<MobileResponsiveToggleProps> = ({
  onChange = () => {},
  mobileState = { type: MobileState.FULLSCREEN },
  setMobileState,
}: MobileResponsiveToggleProps) => {
  
  useEffect(() => {
    onChange(mobileState);
  }, [mobileState]);

  return (
    <div className="flex">
      <SetRootFontSize mobileState={mobileState} />

      <TabsComponent
        tabs={[
          {
            label: (
              <div className="px-3 flex justify-center">
                <FontIcon style={{ fontSize: "14pt" }}>zoom_out_map</FontIcon>
              </div>
            ),
            value: MobileState.FULLSCREEN,
          },
          {
            label: (
              <div className="px-3 flex justify-center">
                <FontIcon style={{ fontSize: "14pt" }}>tv</FontIcon>
              </div>
            ),
            value: MobileState.DESKTOP,
          },
          {
            label: (
              <div className="px-3 flex justify-center">
                <FontIcon style={{ fontSize: "14pt" }}>tablet_android</FontIcon>
              </div>
            ),
            value: MobileState.TABLET,
          },
          {
            label: (
              <div className="px-3 flex justify-center">
                <FontIcon style={{ fontSize: "14pt" }}>smartphone</FontIcon>
              </div>
            ),
            value: MobileState.SMARTPHONE,
          },
        ]}
        activeTab={mobileState?.type}
        // setActiveTab={(tab: MobileState) => setMobileState(tab)}
        setActiveTab={(ms) => setMobileState({ type: ms })}
        actionSection={false}
        className="bg-black/70 flex"
        type={TabTypes.BUTTON}
      />
    </div>
  );
};

export default MobileResponsiveToggle;
