import { Barcode, Receipt, Repeat, ShoppingCartSimple } from '@phosphor-icons/react';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { OrderForms } from "./OrderForms";
import { Dashboard } from './OrderForms/Dashboard';
import { ComponentTypes } from '../Pages/Editor/types';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("f$-internal")) return [];

    return [
      {
        icon: <Barcode className="text-lg" />,
        label: "Order Forms",
        href: "/order-forms",
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("f$-internal")) return [];
    // localStorage.setItem("sidebarOpen", "false");

    return [
      {
        label: "Order Forms",
        path: "/order-forms",
        element: <OrderForms name="Order Forms" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Order Forms" />,
          },
        ],
      },
    ];
  }

  if (hook.id === ListItemsHooks.EDITOR_OBJECT_MENU) {
    if (hook.type === ComponentTypes.CONTAINER) {
      return [
        { id: "divider" },
        { id: "repeat", iconComponent: <Repeat />, text: "Repeat as List" }
      ]
    }
  }
};

export const onMenuSelect = async ({ id, type }, payload) => {
  if (id === ListItemsHooks.EDITOR_OBJECT_MENU && payload.id === "repeat") {
    const { settings, updateComponentSettings } = payload;

    const dataName = window.prompt(
      `What is the name of the list or collection you want to repeat (e.g. “products”)`,
      settings?.data?.name || ""
    );

    if (!dataName) return;

    updateComponentSettings(settings?.id, {...settings, data: {...settings?.data, name: dataName }});

  }
}