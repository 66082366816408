import * as React from "react";
import { get } from 'lodash';

import Tabs, { Tab } from "@/ui/_old/Tabs";

// import Border from "@launchos/modules/editor/Builder/Properties/Sections/BorderShadow/Border";
import Border from "@/Apps/Pages/Properties/Sections/BorderShadow/Border";

export const CustomBorderSection: React.FC<{
    updateComponentStyle: unknown;
    updateComponentSettings: unknown;
    settings: unknown;
}> = (props) => {

    const [value, switchTab] = React.useState<number>(0);
    const { updateComponentStyle = () => null, updateComponentSettings = () => null, settings = {} } = props;

    const handleBorderChange = (payload) => {
        if (value === 0) updateComponentStyle(settings.id, payload, true, false);
        if (value === 1) updateComponentSettings(settings.id, { ...settings, tabHoverStyle: { ...get(settings, 'tabHoverStyle', {}), ...payload } });
        if (value === 2) updateComponentSettings(settings.id, { ...settings, tabActiveStyle: { ...get(settings, 'tabActiveStyle', {}), ...payload } });
    }

    return (
        <div>
            <Tabs transparent>
                <Tab active={value === 0} onClick={() => switchTab(0)}>Normal</Tab>
                <Tab active={value === 1} onClick={() => switchTab(1)}>Hover</Tab>
                <Tab active={value === 2} onClick={() => switchTab(2)}>Active</Tab>
            </Tabs>
            <div style={{ padding: 10 }}>
                {value === 0 && (<Border onChange={handleBorderChange} settings={settings} />)}
                {value === 1 && (<Border onChange={handleBorderChange} settings={{ ...settings, properties: { ...settings.properties, ...get(settings, 'tabHoverStyle', {}) } }} />)}
                {value === 2 && (<Border onChange={handleBorderChange} settings={{ ...settings, properties: { ...settings.properties, ...get(settings, 'tabActiveStyle', {}) } }} />)}
            </div>
        </div>
    );
};
