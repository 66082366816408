import { GET_CATALOG_ITEMS, GET_CATALOG_ITEM } from "./gql/catalog";
import { client } from "@/client";
import { groupBy } from "lodash";


interface CatalogOptions {
  type?: string;
  category?: string;
}

// export const getCatalogItems = async (type = "designs", category) => {
export const getCatalogItems = async ({
  type = "designs",
  category,
}: CatalogOptions = {}): Promise<any[]> => {

  const { data } = await client.query({
    query: GET_CATALOG_ITEMS,
  });

  const { catalogItems } = data;

  // group array of catalog items by category (type key) - use lodash to the grouping
  const groupedCatalogItems = groupBy(catalogItems, "type");
  let { designs, funnels, app, wireframes } = groupedCatalogItems;

  // filter response by category
  if (type === "designs")
    return category
      ? designs.filter((itm) => itm.categories === category)
      : designs;
  if (type === "wireframes")
    return category
      ? wireframes.filter((itm) => itm.categories === category)
      : wireframes;
  if (type === "funnels")
    return category
      ? funnels.filter((itm) => itm.categories === category)
      : funnels;
  if (type === "apps")
    return category
      ? app.filter((itm) => itm.categories === category)
      : app;
  return designs;

  // console.log("GET_CATALOG_ITEMS", { designs });
};

export const getCatalogItem = async (id: string) => {
  const { data } = await client.query({
    query: GET_CATALOG_ITEM,
    variables: { id },
  });

  const { catalogItem } = data;

  return catalogItem;
}

export const getFunnelsInCatalog = async () => {
  const { data } = await client.query({ query: GET_CATALOG_ITEMS });
  const { catalogItems } = data;

  // group array of catalog items by category (type key) - use lodash to the grouping
  const groupedCatalogItems = groupBy(catalogItems, "type");
  const { funnels } = groupedCatalogItems;

  return funnels;
  // console.log("GET_CATALOG_ITEMS", { funnels });
};