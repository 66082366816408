import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { IconSource } from "../Icon/types";

const settings: IComponentSettings = {
  id: ComponentTypes.FACEBOOKLOGIN,
  name: "FB Optin",
  description: "",
  thumbnail: {
    style: {
      background: "#4267B2",
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    iconSource: IconSource.FONTAWESOME,
    icon: faFacebookF,
    iconStyle: { fontSize: "20pt" },
  },
  category: "social",
  default: {
    type: ComponentTypes.FACEBOOKLOGIN,
    properties: {
      backgroundColor: "#2761FF",
      // width: "100%",
      textAlign: "center",
      display: "inline-block",
      fontSize: "14pt",
      color: "white",
      padding: 5,
      borderRadius: 4,
      border: "none",
    },
    buttonSettings: {
      caption1: "Continue with Facebook",
      icon: faFacebookF,
      iconSource: IconSource.FONTAWESOME,
      iconStyle: {
        padding: "0 5px",
      },
    },
  },
  properties: {
    main: {
      tabs: ["Basic"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CAPTION,
          id: "appId",
          sectionWrap: true,
          settings: {
            label: "Facebook App Id",
            icon: "build",
            text: "App Id",
            isExpanded: true,
            help: {
              text: "How to create a facebook app id",
              url: "https://support.launchos.com/hc/launch-os-help-center/articles/1717602875-how-to-create-a-facebook-app-id ",
            },
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.CAPTION,
          id: "caption",
          sectionWrap: true,
          settings: {
            label: "Button Caption",
            icon: "format_size",
            text: "Caption",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.COLORPICKER,
          sectionWrap: true,
          settings: {
            label: "Button Color",
            icon: "colorize",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Button Width",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
        // {
        //   tab: "Advanced",
        //   type: SectionTypes.BORDERSSHADOW,
        //   settings: {
        //     isExpanded: true
        //   },
        // },
        {
          tab: "Basic",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Actions (Optin Succeeded)",
            onlyTheseActions: ["DO_NOTHING", "TRIGGER_NEXT_NODE", "GO_TO_URL"],
            children: null,
          },
        },
        // {
        //   tab: "Advanced",
        //   type: SectionTypes.ACTIONS,
        //   settings: {
        //     label: "Actions (Optin Canceled)",
        //     onlyTheseActions: ["TRIGGER_NEXT_NODE", "GO_TO_URL"],
        //   },
        // },
      ],
    },
  },
};

export default settings;
