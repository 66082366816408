import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@/Apps/Pages/WebComponents/v2/Navigation/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconSource } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.FOUR,
  layout: FooterLayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzoubo42irl0780q5gtpfk3",
    itemId: "ept13cr6hnv",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((10 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  width: 700,
                  maxWidth: "100%",
                  mobile: {
                    desktop: {
                      display: "block",
                    },
                    smartphone: {
                      display: "none",
                    },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: false,
                    properties: {
                      width: "100%",
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        md: parseInt((3 * 100) / 12),
                        properties: {
                          height: "100%",
                          padding: 0,
                          minHeight: 20,
                        },
                        children: [
                          {
                            type: ComponentTypes.CONTAINER,
                            canHaveChildren: true,
                            properties: {
                              padding: 0,
                            },
                            children: [
                              {
                                type: ComponentTypes.NAVIGATION,
                                canHaveChildren: false,
                                linkStyle: style.NAVIGATION.linkStyle,
                                layoutStyle: LinkLayoutStyles.VERTICAL,
                                properties: {
                                  padding: 20,
                                },
                                data: [
                                  {
                                    id: uniqueId(),
                                    caption: "About Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Contact Us",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "Pricing",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                  {
                                    id: uniqueId(),
                                    caption: "FAQs",
                                    linkDisplayType: LinkDisplayTypes.TEXT,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((2 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Follow us",
              },
              {
                type: ComponentTypes.NAVIGATION,
                canHaveChildren: false,
                linkStyle: style.NAVIGATION.linkStyle,
                layoutStyle: LinkLayoutStyles.HORIZONTAL,
                properties: {
                  padding: 20,
                },
                data: [
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Twitter",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Facebook",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Instagram",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
