// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
// // import theme from "@launchos/components/ui/theme";
// import { IComponentSettings, ComponentTypes } from "../../types";

import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";

const settings: IComponentSettings = {
  id: ComponentTypes.IMAGE,
  name: "Image",
  description: "",
  thumbnail: "comp_picture.png",
  default: {
    type: ComponentTypes.IMAGE,
    src: "https://assets-pages.s3.amazonaws.com/drop_img-02.jpg",
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
    },
  },
  properties: {
    main: {
      tabs: ["Basic", "Advanced"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.UPLOADGROUP,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.ACTIONS,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTHHEIGHT,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {},
        },
        {
          tab: "Advanced",
          type: SectionTypes.BORDERSSHADOW,
          settings: {},
        },
      ],
    },
  },
};

export default settings;
