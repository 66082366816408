import { getFilterObject } from "@/Apps/CRM/scripts";
import { ContactsGallery } from "@/Apps/CRM/ContactsGallery";
import { Settings } from "@/Apps/Dashboards/Settings";
import TabsComponent from "@/ui/TabsComponent";
import { useState } from "react";

export const SettingsDashboard = ({ campaignData, setCampaignData, objectData = {} }) => {
  const [activeTab, setActiveTab] = useState("general");
  return (
    <div>
      <TabsComponent
        tabs={[
          { label: "General", value: "general" },
          { label: "SEO & Scripts", value: "seo" },
          { label: "Contacts", value: "contacts" },
          { label: "Connections", value: "connections" },
          { label: "AI Settings", value: "ai" },
        ]}
        activeTab={activeTab}
        setActiveTab={(tab) => {
          setActiveTab(tab);
          // const element = document.getElementById(tab);
          // if (element) {
          //   element.scrollIntoView({ behavior: 'smooth' });
          // }
        }}
        actionSection={false}
        storeActiveTab={false}
      />

      {activeTab === "general" && (
        <div id="general">
          <Settings
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            objectData={objectData}
            unitName="Page"
            widgets={["name", "share", "template", "delete"]}
          />
        </div>
      )}

      {activeTab === "seo" && (
        <div id="general">
          <Settings
            campaignData={campaignData}
            objectData={objectData}
            setCampaignData={setCampaignData}
            unitName="Page"
            widgets={["seo", "scripts"]}
          />
        </div>
      )}

      {activeTab === "contacts" && (
        <ContactsGallery
          onCreate={() => {}}
          defaultFilter={getFilterObject({
            field: "hiddenTags",
            operator: "contains",
            value: campaignData?.id,
          })}
        />
      )}

      {activeTab === "ai" && (
        <div id="ai">
          <Settings
            campaignData={campaignData}
            objectData={objectData}
            setCampaignData={setCampaignData}
            unitName="Page"
            widgets={["planner"]}
          />
        </div>
      )}

      {activeTab === "connections" && (
        <div id="connections">
          <Settings
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            objectData={objectData}
            unitName="Page"
            widgets={["connections", "domain"]}
          />
        </div>
      )}
    </div>
  );
};
