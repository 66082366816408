import * as React from "react";
import { get } from 'lodash';

// import style from "@launchos/modules/editor/Builder/Properties/style";
import style from "@/Apps/Pages/Properties/style";

// import {
//     FancySelector,
//     Icon,
// } from "@launchos/modules/editor/Builder/Properties/";
// import { updateComponentSettings } from "@launchos/modules/v2/Editor/actions";

import { TabPositions } from "../types";
import { FancySelector, Icon } from "@/Apps/Pages/Properties";

export const CustomDisplaySection: React.FC<{
    updateComponentStyle: unknown;
    updateComponentSettings: unknown;
    settings: unknown;
}> = ({
    updateComponentSettings = () => null,
    settings = { tabStyle: {}, tabPosition: TabPositions.TOP, tabVisibility: true },
}) => {

        const { tabStyle = {}, tabPosition = TabPositions.TOP, tabVisibility = true } = settings;

        const visibilityItems = [
            { id: true, component: (props) => <Icon {...props} icon="visibility" highlighted={tabVisibility} caption="Show Tabs" /> },
            { id: false, component: (props) => <Icon {...props} icon="visibility_off" highlighted={!tabVisibility} caption="Hide Tabs" /> },
        ]

        const positionItems = [
            { id: TabPositions.TOP, component: (props) => <Icon {...props} icon="vertical_align_top" highlighted={tabPosition === TabPositions.TOP} caption="Top" /> },
            { id: TabPositions.BOTTOM, component: (props) => <Icon {...props} icon="vertical_align_bottom" highlighted={tabPosition === TabPositions.BOTTOM} caption="Bottom" /> },
            { id: TabPositions.LEFT, component: (props) => <Icon {...props} iconStyle={{ transform: `rotate(90deg)` }} icon="vertical_align_bottom" highlighted={tabPosition === TabPositions.LEFT} caption="Left" /> },
            { id: TabPositions.RIGHT, component: (props) => <Icon {...props} iconStyle={{ transform: `rotate(90deg)` }} icon="vertical_align_top" highlighted={tabPosition === TabPositions.RIGHT} caption="Right" /> },
        ]

        return (
            <div>
                <div style={{ padding: 10 }}>
                    <fieldset style={style.fieldset}>
                        <legend>Tab Visibility</legend>
                        <FancySelector items={visibilityItems} onChange={(tabVisibility) => {
                            updateComponentSettings(settings.id, { ...settings, tabVisibility })
                        }} />
                    </fieldset>
                </div>

                {get(tabStyle, 'display') !== "none" && (<div style={{ padding: 10 }}>
                    <fieldset style={style.fieldset}>
                        <legend>Tab Position</legend>
                        <FancySelector style={{ zoom: 0.85, width: "inherit" }} items={positionItems} onChange={(tabPosition) => {
                            updateComponentSettings(settings.id, { ...settings, tabPosition })
                        }} />
                    </fieldset>
                </div>)}
            </div>
        );
    };
