import React, { useState, useEffect, useContext } from "react";

import { Logo } from "../Logo";

import { AvatarDropdown } from "../../Avatar";
import { LeftSidebarProps, OpenNavGroups } from "./types";
import { AppSectionNavGroups } from "./AppSectionNavGroups";
import { SupportSectionNavGroups } from "./SupportSectionNavGroups";
import { AppContext } from "@/AppContext";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Link } from "react-router-dom";

export const LeftSidebar: React.FC<LeftSidebarProps> = ({
  menuItems = [],
  showLogo = true,
  showAvatarDropdown = true,
  showAppSection = true,
  showSupportSection = true,
  avatarSettings = {},
  showCopilotButton = false,
}) => {
  // Define state for the open navigation groups
  const [openNavGroups, setOpenNavGroups] = useState<OpenNavGroups>({
    dashboard: true,
    apps: false,
    components: false,
    elements: false,
    charts: false,
    widgets: false,
    fonticons: false,
    drag: false,
    table: false,
    forms: false,
    users: false,
    pages: false,
    authentication: false,
    docs: false,
    funnels: false,
    kitchensink: false,
    ui: false,
  });

  // Define state for the avatar dropdown
  const [open, setOpen] = useState(false);

  const { sidebarOpen, setSidebarOpen } = useContext(AppContext);

  // Function to toggle the open navigation groups
  const doToggle: (str: any) => void = (str) => {
    setOpenNavGroups((prevOpenNavGroups) => {
      const newOpenNavGroups = { ...prevOpenNavGroups };
      Object.keys(newOpenNavGroups).forEach((key) => {
        newOpenNavGroups[key] = key === str ? !prevOpenNavGroups[str] : false;
      });
      return newOpenNavGroups;
    });
  };

  // Function to load in all menu items
  const loadInAllMenuItems = async () => {
    // Add to possible toggle states
    menuItems.forEach(({ label }) => {
      setOpenNavGroups((prevOpenNavGroups) => {
        return {
          ...prevOpenNavGroups,
          [label]: false,
        };
      });
    });
  };

  // Toggle the left sidebar
  const toggleLeftSidebar = () => {
    localStorage.setItem("sidebarOpen", !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  // Load in all menu items on component mount
  useEffect(() => {
    loadInAllMenuItems();
  }, []);

  // Render the component
  return (
    <>
      <nav
        data-testid="LeftSidebar"
        className="sidebar fixed top-0 bottom-0 z-40 flex-none w-[212px] border-r border-black/10 dark:border-white/10 transition-all duration-300 bg-white dark:bg-black/30 backdrop-blur text-sm"
      >
        <div className="h-full">
          {/* Avatar Dropdown */}
          {showAvatarDropdown && (
            <div className="flex items-center justify-between px-7 py-5">
              <AvatarDropdown
                open={open}
                setOpen={setOpen}
                settings={avatarSettings}
              />
              {/* <button
                type="button"
                className="text-black dark:text-white"
                onClick={toggleLeftSidebar}
                data-testid="Topbar-LeftSidebarToggle"
              >
                <SidebarToggleIcon />
              </button> */}
            </div>
          )}

          {showCopilotButton && (
            <div className="flex justify-center py-3">
              <a href="/copilot">
                <Button
                  label="+ Start a New Chat"
                  type={ButtonTypes.OUTLINED}
                  className="w-full"
                />
              </a>
            </div>
          )}

          {/* Menu Items */}
          <ul className="relative h-[calc(100vh-150px)] flex flex-col gap-2 overflow-y-auto overflow-x-hidden py-0">
            {/* App Section */}
            {showAppSection && (
              <AppSectionNavGroups
                doToggle={doToggle}
                openNavGroups={openNavGroups}
                menuItems={menuItems}
              />
            )}

            {/* Support Section */}
            {showSupportSection && (
              <SupportSectionNavGroups
                doToggle={doToggle}
                openNavGroups={openNavGroups}
              />
            )}
          </ul>

          {/* App Logo */}
          {showLogo && (
            <div style={{ marginTop: -50 }}>
              <Logo className="p-8 py-0" />
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
