import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { DashboardPage } from "@/Apps/Dashboards/DashboardPage";
import { getCampaign } from "@/api/campaigns";
import { Settings } from "@/Apps/Dashboards/Settings/Settings";
import { getSessionToken } from "@/api/auth";
import { CampaignBuilder } from "../../Funnels";
import Panel from "@/ui/Panel";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(getActiveTabFromLocalStorage() || "overview");
  const [broadcastData, setBroadcastData] = useState<{ name?: string }>({
    name: "",
  });
  const [emailData, setEmailData] = useState();
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  // Fetch the campaign data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();

    (async () => {
      const data = await getCampaign({ token, id });
      setBroadcastData(data);

      // set email data
      setEmailData(data?.objects[0]);

    })();
  }, []);

  // Use this to configure what shows in the top bar of the page
  const topBarSettings = {
    page: [
      { label: name, href: "/crm/broadcasts" },
      { label: broadcastData.name || "" },
    ],
  };

  if (!emailData) return null;

  return (
    <Page topBar={topBarSettings}>
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              { label: "Overview", value: "overview" },
              { label: "Contacts", value: "contacts" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />

          {activeTab === "overview" && (
            <>
            <DashboardPage
              campaignData={broadcastData}
              unitName="broadcast"
              widgets={[
                {
                  type: "kpi",
                  settings: {
                    items: [
                      { label: "Clicks", number: 30 },
                      { label: "Sales", number: 5 },
                      { label: "Commissions", number: "$110.00" },
                    ],
                  },
                }
              ]}
            />
            {/* {JSON.stringify(broadcastData?.objects[0])} */}
            <Panel className="mt-4" title="Broadcast Email" actionButton={<Button type={ButtonTypes.SOFT} label="Edit Email" onClick={() => navigate(`/crm/broadcasts/${id}/${broadcastData?.objects[0].id}`)} />}>
              <div className="inset p-6 border border-black/10 dark:border-white/10 dark:bg-white/5 bg-lightwhite rounded">
                <h3 className="text-lg">{broadcastData?.objects[0].name}</h3>
                <div className="py-3" dangerouslySetInnerHTML={{__html: broadcastData?.objects[0].settings?.content }} />
              </div>
            </Panel>
            </>
          )}
          {activeTab === "contacts" && (
            <DashboardPage
              campaignData={broadcastData}
              unitName="broadcast"
              widgets={["contactList"]}
            />
          )}
          {activeTab === "settings" && (
            <Settings
              campaignData={broadcastData}
              setCampaignData={setBroadcastData}
              unitName="Broadcast"
              widgets={["name", "delete"]}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
