import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import FormBuilder from "./component";
import settings from "./settings";
import { ComponentRenderHooks } from "@/plugins/types";
import { Properties } from "./Properties/Properties";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORM
  ) {
    return [FormBuilder];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.FORM
  ) {
    return Properties(payload);
  }
};
