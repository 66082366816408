// import { Link } from "react-router-dom";
import { LogoProps } from "./types";

/**
 * The Logo component displays the application logo and provides a link to the home page.
 */
export const Logo: React.FC<LogoProps> = ({ href = "/", className = "" }) => {
  // The Logo component accepts two optional props: href and className.
  // href is the URL that the logo should link to.
  // className is the CSS class name to apply to the logo element.

  return (
    <div data-testid="Logo" className={`flex p-4 ${className}`}>
      {/* The logo is wrapped in a div with a flex layout and padding. */}
      {/* <Link to={href} className="main-logo flex-1 w-full"> */}
      {/* The logo is displayed as an image wrapped in a Link component. */}
      {/* There are three different images that can be displayed depending on the theme: */}
      {/* - A light logo for the default theme */}
      {/* - A dark logo for the dark theme */}
      {/* - A footer logo for the dark theme */}
      <img
        alt="Launch OS"
        className="mr-3 h-9 sm:h-9"
        src="https://flowbite.com/docs/images/logo.svg"
      />
      <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white/80 text-black/80 tracking-tight">
        Launch OS
      </span>
      {/* </Link> */}
    </div>
  );
};
