import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { SocialProofLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: SocialProofLayouts.ELEVEN,
  layout: SocialProofLayouts.ELEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/socialproof/SocialProof11.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkysae0n2d0l0780y3gavfbw",
    itemId: "g6cvpwe4z2h",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.SOCIALPROOF,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: `Say Hello &amp; Share Your Insights`,
      },
      {
        type: ComponentTypes.DIVIDER,
        canHaveChildren: false,
        properties: {
          marginTop: 40,
          width: 100,
          textAlign: "center",
          display: "inline-block",
        },
        color: theme.backgroundColor,
        size: 3,
      },
      {
        type: ComponentTypes.FACEBOOKCOMMENTS,
        canHaveChildren: false,
        properties: {
          marginTop: 60,
        },
        href: "http://facebook.com",
      },
    ],
  },
};

export default settings;
