import * as React from "react";
import { get } from 'lodash';
// import { ColorPicker } from "@launchos/modules/editor/Builder/Properties";
import { ColorPicker } from "@/Apps/Pages/Properties";

// import Tabs, { Tab } from "@/ui/_old/Tabs";
import Tabs, { Tab } from "@/ui/_old/Tabs";

export interface ColorPickerSectionProps {
    updateComponentSettings: (
        id: string,
        properties: any,
        dbUpdate?: boolean,
        forceUpdate?: number
    ) => any;
    updateComponentStyle: (
        id: string,
        properties: any,
        dbUpdate?: boolean,
        forceUpdate?: number
    ) => any;
    settings: any;
}

export const CustomColorSection: React.FC<ColorPickerSectionProps> = ({
    updateComponentSettings,
    updateComponentStyle,
    settings,
}) => {
    const [value, switchTab] = React.useState<string>(0);

    return (
        <div onClick={e => e.stopPropagation()}>
            <Tabs transparent>
                <Tab active={value === 0} onClick={() => switchTab(0)}>Normal</Tab>
                <Tab active={value === 1} onClick={() => switchTab(1)}>Hover</Tab>
                <Tab active={value === 2} onClick={() => switchTab(2)}>Active</Tab>
            </Tabs>

            <div style={{ paddingTop: 20 }}>
                {value === 0 && <ColorPicker color={get(settings, 'properties.backgroundColor', '')} onChange={(e) => updateComponentStyle(settings.id, { backgroundColor: e.hex }, true, 0)} />}
                {value === 1 && <ColorPicker color={get(settings, 'tabHoverStyle.backgroundColor', '')} onChange={(e) => updateComponentSettings(settings.id, { ...settings, tabHoverStyle: { ...settings.tabHoverStyle, backgroundColor: e.hex, }, }, true, 0)} />}
                {value === 2 && <ColorPicker color={get(settings, 'tabActiveStyle.backgroundColor', '')} onChange={(e) => updateComponentSettings(settings.id, { ...settings, tabActiveStyle: { ...settings.tabActiveStyle, backgroundColor: e.hex, }, }, true, 0)} />}
            </div>
        </div>
    );
};
