import * as React from "react";

import { Menu, MenuItem } from "@material-ui/core";

import { DragHandleProps, EditorObjectState, ActionItems } from "./types";
import { EditorContext } from "../../Editor";
import { EditorActions } from "../../types";

import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import Icon from "@/Apps/Pages/WebComponents/v2/Icon/live";

const DragHandleMenu = ({ actions, id, dispatch }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (action) => {
    switch (action.type) {
      case ActionItems.DELETE:
        dispatch({ type: EditorActions.REMOVE_ITEM, payload: { id } });
        break;
      case ActionItems.DUPLICATE:
        dispatch({ type: EditorActions.DUPLICATE_ITEM, payload: { id } });
        break;
    }

    setAnchorEl(null);
  };

  return (
    <>
      <span
        aria-controls={`simple-menu-${id}`}
        data-testid="Editor-EditorObject-Dropdown"
        // aria-haspopup="true"
        onClick={handleClick}
        style={{
          display: "inline-block",
          position: "absolute",
          cursor: "pointer",
          marginTop: 1,
          fontSize: "16pt",
          marginLeft: 5,
          color: "black",
          textShadow: "0px 0px 2px rgba(255, 0, 0, 1)",
          width: 50,
        }}
      >
        <Icon type={IconTypes.ArrowDropDown} />
      </span>

      <Menu
        id={`simple-menu-${id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, key) => (
          <MenuItem
            key={key}
            data-testid="Editor-EditorObject-Dropdown-Items"
            onClick={() => handleChange(action)}
          // onClick={handleClose}
          >
            {action.caption}
          </MenuItem>
        ))}
        <MenuItem
          data-testid="Editor-EditorObject-Dropdown-Items"
          onClick={() => handleChange({ type: ActionItems.DUPLICATE })}
        // onClick={handleClose}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          data-testid="Editor-EditorObject-Dropdown-Items"
          onClick={() => handleChange({ type: ActionItems.DELETE })}
        // onClick={handleClose}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export const DragHandle: React.FC<DragHandleProps> = ({
  label,
  icon,
  state,
  color,
  actions = [],
  id,
}) => {
  const editor = React.useContext(EditorContext);
  // const { removeItem, duplicateItem } = editor;
  const { content, dispatch } = editor;
  return (
    <div
      data-testid={`Editor-EditorObject-State-${state}`}
      style={{
        background: color,
        position: "absolute",
        marginTop: -34,
        marginLeft: -4,
        height: 30,
        zoom: 0.9,
        cursor: "grab",
        color: "white",
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      }}
    >
      {state === EditorObjectState.ACTIVE && (
        <>
          <span style={{ display: "inline-block", padding: 3 }}>
            <Icon type={icon} />
          </span>
          <span
            style={{
              display: "inline-block",
              fontFamily: "Roboto",
              fontSize: "10pt",
              padding: "10px 10px 0 0",
              position: "relative",
              top: -8,
            }}
          >
            {label.toUpperCase()}
          </span>
          <span
            style={{
              display: "inline-block",
              position: "relative",
              top: -4,
              paddingRight: 8,
            }}
          >
            <Icon
              type={IconTypes.Settings}
              style={{
                cursor: "pointer",
                fontSize: "13pt",
                display: "inline-block",
              }}
            />
          </span>
          <DragHandleMenu id={id} actions={actions} dispatch={dispatch} />
          {/* <SimpleMenu /> */}
        </>
      )}
    </div>
  );
};
