import { useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { getCampaigns } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";

export const SmartForms = ({ name = "" }) => {
  const [sequences, setSequences] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // get the sequences from the server
  const loadSequences = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "form" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((sequence: any) => {
          return {
            id: sequence.id,
            label: sequence.name,
            caption: `Last Updated: ${moment(sequence.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setSequences(seqs);
    }
    setIsLoading(false);
  };

  // load the sequences when the page loads
  useEffect(() => {
    loadSequences();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Link to="/launchpad/forms" className="justify-end">
            <Button label="+ Create a Smart Form" type={ButtonTypes.OUTLINED} />
          </Link>
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(sequences.length) && (
          <EmptyState
            title="No Smart Forms Yet"
            description="Get started by creating a new form."
            showImage={false}
          >
            <Link to="/launchpad/forms">
              <Button label="Create a Smart Form" type={ButtonTypes.DEFAULT} />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(sequences.length) && (
           <Grid className="pb-6">
            {sequences.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                console.log(
                  "sequence",
                  id,
                  label,
                  caption,
                  img,
                  status,
                  taskDetails
                );

                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/forms/${id}`}
                    status={status}
                    // sharedWith={sharedWith}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
