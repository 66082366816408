import { Navigate } from 'react-router-dom';
import { Users } from '@phosphor-icons/react';

import { HookObject, ListItemsHooks } from "@/plugins/types";

import { CRM } from './CRM';
import { ContactsDashboard } from "./ContactsDashboard";
import { Broadcasts } from './Broadcasts/Broadcasts';
import { Dashboard as BroadcastDashboard } from './Broadcasts/Dashboard';
import { Dashboard as CampaignDashboard } from './Campaigns/Dashboard';
import { Campaigns } from './Campaigns';
import { ContentEditor } from '../Content/ContentEditor';
import { BroadcastEditor } from './Broadcasts/BroadcastEditor';
import { BroadcastConfirmation } from './Broadcasts/BroadcastConfirmation';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("crm")) return [];
    return [
      {
        label: "People",
        icon: <Users className="text-lg" />,
        href: "/crm/contacts",
        order: 5,
        children: [
          {
            label: "Contacts",
            href: "/crm/contacts",
          },
          {
            label: "Campaigns",
            href: "/workflows",
          },
          // {
          //   label: "Broadcasts",
          //   href: "/crm/broadcasts",
          // },
          // {
          //   label: "Templates",
          //   href: "/crm/templates",
          // },
          // {
          //   label: "Customers",
          //   href: "/crm/customers",
          // },
          // {
          //   label: "Affiliates",
          //   href: "/crm/affiliates",
          // },
        ]
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("crm")) return [];
    return [
      {
        path: "/crm",
        element: <Navigate to="/crm/contacts" />,
        children: [
          {
            path: "/crm/contacts",
            element: <CRM name="People" />,
          },
          {
            path: "/crm/campaigns",
            element: <Campaigns name="Campaigns" />,
          },
          {
            path: "/crm/campaigns/:id",
            element: <CampaignDashboard name="Campaign" />,
          },
          {
            path: "/crm/broadcasts",
            element: <Broadcasts name="Broadcasts" />,
          },
          {
            path: "/crm/broadcasts/new/edit",
            element: <BroadcastEditor />,
          },
          {
            path: "/crm/broadcasts/:id/:objectId",
            element: <BroadcastEditor />,
          },
          {
            path: "/crm/broadcasts/:id/:objectId/confirmation",
            element: <BroadcastConfirmation />,
          },
          {
            path: "/crm/broadcasts/:id",
            element: <BroadcastDashboard name="Broadcast" />,
          },
          {
            path: "/crm/templates",
            element: <CRM name="Templates" />,
          },
          // {
          //   path: "/crm/customers",
          //   element: <CRM name="Customers" />,
          // },
          // {
          //   path: "/crm/affiliates",
          //   element: <CRM name="Affiliates" />,
          // },
        ],
      },
      {
        path: "/crm/contacts/:id",
        element: <ContactsDashboard name="People" />,
      },
      // {
      //   path: "/crm/customers/:id",
      //   element: <CustomersDashboard name="Customers" />,
      // },
      // {
      //   path: "/crm/affiliates/:id",
      //   element: <AffiliatesDashboard name="Affiliates" />,
      // },
    ];
  }
};


export { CRM };