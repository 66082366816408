import { CreateContentPopup } from "@/Apps/Content/ContentDashboardPage";
import { handleContentSave } from "@/Apps/Content/ContentEditor";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { useState } from "react";

export const Email = ({ campaignId, objectData, setEditDrawerOpen }) => {
  const [content, setContent] = useState(objectData?.data?.settings?.content);
  const [subject, setSubject] = useState(objectData?.data?.settings?.subject);
  const [name, setName] = useState(objectData?.data?.name);

  const handleSave = async () => {
    const data = {
      ...objectData?.data,
      name,
      settings: {
        ...objectData?.data?.settings,
        content,
        subject,
      }
    };
    await handleContentSave(data, campaignId);
    setEditDrawerOpen(false);
  };

  return (
    <div>
      {/* {JSON.stringify(objectData?.data)} */}
      {/* {JSON.stringify({ name, content })} */}
      <CreateContentPopup
        activeObject={objectData?.data}
        campaignId={campaignId}
        setIsCreateContentPopupOpen={() => {
          setEditDrawerOpen;
        }}
        unitName="Email"        
        withModal={false}
        // onChange={({ data, name, settings = { content: "" } }) => {
        // onChange={console.log}
        onChange={({ id, campaignId, name, settings = { content: "", subject: "" } }) => {
          // const { id, campaignId } = data;
          const { content, subject } = settings;
          console.log({ name, content, subject, id, campaignId });
          setName(name);
          setContent(content);
          setSubject(subject);
        }} />
      <div className="flex justify-end items-center mt-8 gap-4">
        <Button
          onClick={() => setEditDrawerOpen(false)}
          label="Cancel"
          type={ButtonTypes.SOFT} />
        <Button
          onClick={handleSave}
          label="Save"
          // variant={Variants.INFO}
          type={ButtonTypes.DEFAULT} />
      </div>
    </div>
  );
};
