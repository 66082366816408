import { forEach, has } from "lodash";

// Components
import webcomponents_v1 from "@/Apps/Pages/WebComponents/v1/live";
import webcomponents_v2 from "@/Apps/Pages/WebComponents/v2/live";
// import addons from "./addons/live";
import addons from "@launchos/client-site-vite/src/plugins/live";
// import misc_v1 from "./misc/v1/live";

export const plugins = [
  ...webcomponents_v1,
  ...webcomponents_v2,
  ...addons,
  // ...misc_v1,
];

export const triggerHook: any = (name, hook, payload, actions = []) => {
  let toRet = [];
  forEach(plugins, async (itm) => {
    if (has(itm, name)) {
      const res = itm[name](hook, payload, actions); // trigger the function on every plugin that has it
      const response = res;
      forEach(response, (itm) => toRet.push(itm));
    }
  });

  return toRet;
};
