import { Browsers } from '@phosphor-icons/react';
import { Pages } from './Pages';

import { CopilotHooks, HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import { Preview } from './Page/Preview';
import get_pages from './prompts/get_pages';
import get_page from './prompts/get_page';

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("pages")) return [];
    return [
      {
        label: "Pages",
        icon: <Browsers className="text-lg" />,
        href: "/pages",
        order: 3,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    // if (!permissions.includes("pages")) return [];
    return [
      {
        path: "/pages",
        element: <Pages name="Pages" />,
        children: [
          {
            path: ":campaignId",
            element: <Dashboard name="Pages" />,
          },
        ],
      },
      {
        path: "/pages/preview/:campaignId/:objectId/:pageId",
        element: <Preview />
      },
      {
        path: "/pages/preview/:campaignId/:objectId",
        element: <Preview />
      },
    ];
  }
};

export const onChatPrompt = (
  hook: HookObject,
  { context },
  { copilotContext }
) => {
  if (
    hook.id === CopilotHooks.GET_SYSTEM_PROMPT &&
    context[0]?.label === "Pages" &&
    copilotContext
  ) {

    console.log("(Pages) About to System Prompt", context, copilotContext);

    // retrieve the page content
    let systemPrompt = "";

    if (context[0]?.label === "Pages") systemPrompt += get_pages(copilotContext['Pages']);      
    if (copilotContext['Page Content']) systemPrompt += get_page(copilotContext["Page Content"]);

    return systemPrompt;
  }
};

export { Pages };