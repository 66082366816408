import { useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";

import { API_DOMAIN } from "@launchos/shared-utils/env";
import { IDropdownMenuItem } from "@/ui/Menu";
import { changeObjectName, deleteObject } from "@/api/campaigns";
import { getAddNodeMenuItems } from "../Apps/Funnels/utils";
import { getSessionToken } from "./auth";
import { has, keys, uniq } from "lodash";

// The API Calls

/**
 * Publishes a page.
 * @param pageId - The ID of the page to publish.
 * @param token - The authorization token.
 * @returns A promise that resolves to the published page data.
 */
export const publishPage = async (pageId, { token }) => {
  // alert("Publish - Page Id: " + pageId);
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(`${API_DOMAIN}/pages/${pageId}/publish`, {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Makes the specified page the home page.
 * @param pageId - The page ID.
 * @param token - The authorization token.
 * @returns The response data.
 */
export const makeThisMyHomePage = async (pageId, { token }) => {
  // alert("Home - Page Id: " + pageId);
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ pageId });

    try {
      const response = await fetch(
        `${API_DOMAIN}/pages/${pageId}/makeHomePage`,
        {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        }
      );
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Makes the specified page the 404 page.
 * @param pageId - The page ID.
 * @param token - The authorization token.
 * @returns The response data.
 */
export const makeThisMy404Page = async (pageId, { token }) => {
  // alert("404 - Page Id: " + pageId);
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ pageId });

    try {
      const response = await fetch(
        `${API_DOMAIN}/pages/${pageId}/make404Page`,
        {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        }
      );
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Deletes the specified page.
 * @param pageId - The page ID.
 * @param token - The authorization token.
 * @returns The response data.
 */
export const deletePage = async (pageId, { token }) => {
  // alert("Delete - Page Id: " + pageId);
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(`${API_DOMAIN}/pages/${pageId}`, {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Creates a new page.
 * @param {Object} options - The options for creating the page.
 * @param {string} options.token - The authorization token.
 * @param {Object} options.settings - The settings for the new page.
 * @returns {Promise<Object>} - A promise that resolves to the created page data.
 */
export const createPage = async ({ token, settings }) => {
  // const { objectId = null, campaignId, name, theme, type, content = [], slug } = settings;
  // alert("Create - Page Name: " + pageName);

  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(settings);

    try {
      const response = await fetch(`${API_DOMAIN}/pages`, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Retrieves a page by its ID.
 * @param {string} pageId - The ID of the page to retrieve.
 * @param {Object} options - The options for retrieving the page.
 * @param {string} options.token - The authorization token.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved page data.
 */
export const getPage = async (pageId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    // const raw = JSON.stringify(settings);

    try {
      const response = await fetch(`${API_DOMAIN}/pages/${pageId}`, {
        method: "GET",
        headers: myHeaders,
        // body: raw,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Updates a page by its ID.
 * @param {string} pageId - The ID of the page to update.
 * @param {Object} options - The options for updating the page.
 * @param {string} options.token - The authorization token.
 * @param {Object} options.settings - The updated settings for the page.
 * @returns {Promise<Object>} - A promise that resolves to the updated page data.
 */
export const updatePage = async (pageId, { token, settings }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(settings);

    try {
      const response = await fetch(`${API_DOMAIN}/pages/${pageId}`, {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

// End of API Calls

interface CampaignData {
  domain?: {
    name?: string;
  };
}

interface PageData {
  slug?: string;
}

/**
 * Converts a name into a slug.
 * @param name - The name to convert into a slug.
 * @returns The slugified version of the name.
 */
export const makeSlug = (name) => {
  const slug = slugify(name.toString().toLowerCase(), {
    remove: /[*+~.()'"!:@]/g,
  });
  return slug;
};

/**
 * Gets the live URL of a page.
 * @param campaignData - The campaign data.
 * @param pageData - The page data.
 * @returns The live URL of the page.
 */
export const getLiveURL = ({
  campaignData,
  pageData,
}: {
  campaignData: CampaignData;
  pageData: PageData;
}): string => {
  const domainName = campaignData?.domain?.name;
  const pageSlug = pageData?.slug;
  const url = `https://${domainName}/${pageSlug}`; // To Do: check for ssl
  return url;
};

/**
 * Opens the published page in a new tab.
 * @param campaignData - The campaign data.
 * @param pageData - The page data.
 */
export const openPublishedPage = ({
  campaignData,
  pageData,
}: {
  campaignData: CampaignData;
  pageData: PageData;
}) => {
  const url = getLiveURL({ campaignData, pageData });
  window.open(url, "_blank");
};

/**
 * Retrieves the page data from the object ID.
 * @param objId - The object ID.
 * @param campaignData - The campaign data.
 * @returns The page data.
 */
export const getPageDataFromObjId = (objId: string, campaignData = {}) => {
  console.log('getPageDataFromObjId', { objId, campaignData });
  
  const pageData = campaignData?.objects?.find(({ id }) => id === objId)?.page;
  return pageData;
};

export const openPageInEditor = async ({
  campaignId,
  objectId,
  pageId,
  navigate,
  unitName = "funnels",
}) => {
  // check if it's a blank page without a chosen template
  const page = await getPage(pageId, { token: getSessionToken() });
  const { content, type } = page?.data;

  if (content?.length === 1) {
    // alert("This page has no content.  Please choose a template first.")
    navigate(
      `/${unitName}/${campaignId}/${objectId}/templates/${type || "all"}`
    );
  } else {
    navigate(`/${unitName}/${campaignId}/${objectId}`);
  }
};

/**
 * Retrieves the dropdown menu items for a node in the campaign flow.
 * @param data - The data object containing the necessary information.
 * @param callbackFtn - The callback function to be executed.
 * @returns An array of dropdown menu items.
 */
export const getNodeDropdownItems = (
  data,
  callbackFtn = () => null
): IDropdownMenuItem[] => {
  // console.log({ campaignData, nodeId });
  const navigate = useNavigate();
  const params = useParams();

  const {
    campaignData,
    nodeId,
    nodes,
    setNodes,
    // setEdges,
    // event,
    // reactFlowWrapper,
  } = data;

  const node = nodes.find((nd) => nd.id === nodeId);
  const campaignId = params.id;
  const objectId = node?.data?.id;

  const MENU_EDIT_PAGE: IDropdownMenuItem = {
    label: "Edit Page",
    onClick: async () => {
      // open the editor
      if (node.data.type === "PageComponent") {
        await openPageInEditor({
          campaignId,
          objectId,
          pageId: node.data.page.id,
          navigate,
        });
      }
    },
  };

  const MENU_RENAME_PAGE: IDropdownMenuItem = {
    label: "Rename Page",
    onClick: () => {
      callbackFtn();
      const newName = prompt("Enter a new name for this");
      if (newName) {
        changeObjectName(nodeId, newName);
        // now update the node inside the nodes array with the new name
        const newNodes = nodes.map((nd) => {
          if (nd.id === nodeId) {
            return {
              ...nd,
              data: {
                ...nd.data,
                name: newName,
              },
            };
          } else {
            return nd;
          }
        });
        setNodes(newNodes);
      }
    },
  };

  const MENU_SEPARATOR: IDropdownMenuItem = {
    type: "separator",
  };

  const MENU_VIEW_PUBLISHED_PAGE: IDropdownMenuItem = {
    label: "View Published Page",
    onClick: () => {
      console.log({ pageData: node.data });
      openPublishedPage({ campaignData, pageData: node?.data?.page });
      callbackFtn();
    },
  };

  const MENU_COPY_LINK_TO_CLIPBOARD: IDropdownMenuItem = {
    label: "Copy link to clipboard",
    onClick: () => {
      const link = getLiveURL({ campaignData, pageData: node?.data?.page });
      // console.log({ link });
      navigator.clipboard.writeText(link);
      callbackFtn();
    },
  };

  const MENU_MAKE_THIS_MY_HOMEPAGE: IDropdownMenuItem = {
    label: "Make this my Homepage",
    onClick: async () => {
      const token = getSessionToken();
      const pageId = getPageDataFromObjId(nodeId, campaignData)?.id;
      const response = await makeThisMyHomePage(pageId, { token });
      callbackFtn();

      if (response) {
        if (response.error) {
          alert(response.error);
        } else {
          alert(
            `This page is now your home page.  
            Please re-publish the campaign for it to take effect for your website visitors.`
          );
          console.log(response);
        }
      }
    },
  };

  const MENU_MAKE_THIS_MY_404_PAGE: IDropdownMenuItem = {
    label: "Make this my 404 (Error) Page",
    onClick: async () => {
      const token = getSessionToken();

      const pageId = getPageDataFromObjId(nodeId, campaignData)?.id;
      const response = await makeThisMy404Page(pageId, { token });

      callbackFtn();
      if (response) {
        if (response.error) {
          alert(response.error);
        } else {
          alert(
            `This page is now your 404 (error) page.  
            Please re-publish the campaign for it to take effect for your website visitors.`
          );
          console.log(response);
        }
      }
    },
  };

  const MENU_DELETE_ITEM: IDropdownMenuItem = {
    label: "Delete Item",
    onClick: async () => {
      // we need to remove the node from the nodes array
      const newNodes = nodes.filter((nd) => nd.id !== nodeId);
      setNodes(newNodes);

      // and then we need to remove the object from the database
      // and the page if there is one associated with it
      const pageId = getPageDataFromObjId(nodeId, campaignData)?.id;
      if (pageId) {
        const token = getSessionToken();
        await deletePage(pageId, { token });
      } else {
        await deleteObject(nodeId);
      }
    },
  };

  if (node) {
    const { type } = node.data;

    if (type === "PageComponent") {
      return [
        MENU_EDIT_PAGE,
        MENU_RENAME_PAGE,
        MENU_SEPARATOR,
        MENU_VIEW_PUBLISHED_PAGE,
        MENU_COPY_LINK_TO_CLIPBOARD,
        MENU_SEPARATOR,
        MENU_MAKE_THIS_MY_HOMEPAGE,
        MENU_MAKE_THIS_MY_404_PAGE,
        MENU_SEPARATOR,
        MENU_DELETE_ITEM,
      ];
    } else if (
      type === "RedirectComponent" ||
      type === "SmartRouteComponent" ||
      type === "TrackingSource" ||
      type === "ABSplitComponent"
    ) {
      return [
        MENU_RENAME_PAGE,
        MENU_SEPARATOR,
        MENU_VIEW_PUBLISHED_PAGE,
        MENU_COPY_LINK_TO_CLIPBOARD,
        MENU_SEPARATOR,
        MENU_MAKE_THIS_MY_HOMEPAGE,
        MENU_SEPARATOR,
        MENU_DELETE_ITEM,
      ];
    } else if (type === "AnotherFunnelComponent") {
      return [
        MENU_RENAME_PAGE,
        MENU_SEPARATOR,
        MENU_VIEW_PUBLISHED_PAGE,
        MENU_COPY_LINK_TO_CLIPBOARD,
        MENU_SEPARATOR,
        MENU_DELETE_ITEM,
      ];
    } else {
      return [MENU_DELETE_ITEM];
    }
  } else {
    // add menu items for adding different nodes
    return getAddNodeMenuItems(data);
  }
};

/**
 * Generates the content for a new page based on the specified type and page type.
 * @param type - The type of the page component.
 * @param pageType - The type of the page.
 * @returns The generated page content.
 */
export const getNewPageContent = () => {
  const content = [
    {
      parent: false,
      state: "normal",
      id: 2540,
      properties: {
        height: "100%",
      },
      type: "Body",
      canHaveChildren: true,
    },
  ];

  return content;
};

/**
 * Generates the settings for a new page based on the specified type and page type.
 * @param type - The type of the page component.
 * @param pageType - The type of the page.
 * @returns The generated page settings.
 */
export const getNewPageSettings = (type, pageType) => {
  if (
    type === "RedirectComponent" ||
    type === "SmartRouteComponent" ||
    type === "TrackingSource" ||
    type === "ABSplitComponent" ||
    type === "AnotherFunnelComponent"
  )
    return {
      tracking: {
        head: `
        <script type="text/javascript">
          window.location.href = "/<%=nextPage%>";
        </script>
      `,
      },
    };

  return null;
};

export const getFontsFromPage = (content) => {
  let fontList = [];
  
  content.forEach((itm) => {
    const k = keys(itm).filter(
      (i) => has(itm[i], "fontFamily") || has(itm, "fontsUsed")
    );

    k.forEach((i) => {
      let fontFamily;
      if (has(itm[i], "fontFamily")) fontFamily = itm[i].fontFamily;
      else if (has(itm[i], "[0].fontFamily")) fontFamily = itm[i][0].fontFamily;

      if (fontFamily && fontList.findIndex((ff) => ff === fontFamily))
        fontList.push(fontFamily);
    });
  });

  return uniq(fontList);
}