// import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import FormField from "./component";
import Properties from "./Properties";
import settings from "./settings";
// import { ComponentTypes } from "../../types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ComponentRenderHooks } from "@/plugins/types";


export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.FORMFIELD
  ) {
    return [FormField];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.FORMFIELD
  ) {
    return Properties(payload);
  }
};
