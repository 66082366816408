import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { UrgencyLayouts } from "../types";
import { CountDownTypes } from "@/Apps/Pages/WebComponents/v2/Countdown/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: UrgencyLayouts.ONE,
  layout: UrgencyLayouts.ONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/urgency/Urgency01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkztsx5q2llj0780vbppfmr4",
    itemId: "u5j1r64m5rf",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.URGENCY,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: `Wait! Your Order Is Not Yet Complete! ${startCase(
          lorem.generateWords(4)
        )}...FREE!`,
      },
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUBHEADLINE,
          color: theme.highlightColor,
          ...get(content, "main_headline.style", {}),
        },
        html: `Time Left to Complete Your Checkout`,
      },
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          mobile: {
            desktop: {
              padding: 50,
            },
            smartphone: {
              padding: 10,
            },
          },
          display: "inline-block",
          textAlign: "center",
        },
        children: [
          {
            type: ComponentTypes.COUNTDOWN,
            countType: CountDownTypes.EVENT,
            eventDate: Date.now() + 2 * 24 * 60 * 60 * 1000,
            properties: {},
            dimensionStyle: style.COUNTDOWN.dimensionStyle,
            labelStyle: style.COUNTDOWN.labelStyle,
            numberStyle: style.COUNTDOWN.numberStyle,
            dividerStyle: style.COUNTDOWN.dividerStyle,
            showDivider: false,
            actions: [],
          },
        ],
      },
      getImagePlaceholder({
        type: ImagePlaceholderTypes.MOBILE05,
        style: {
          backgroundColor: theme.backgroundColor,
          display: "inline-block",
          mobile: {
            desktop: {
              width: 300,
              textAlign: "center",
            },
            smartphone: {
              width: "100%",
            },
          },
          margin: "0 auto",
        },
      }),
      {
        type: ComponentTypes.BUTTON,
        canHaveChildren: false,
        properties: style.BUTTON.style,
        caption1: "Click Here to Close this Window",
        caption1style: style.BUTTON.caption1,
        caption2: "...and complete your order!",
        caption2style: style.BUTTON.caption2,
      },
    ],
  },
};

export default settings;
