import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CTALayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.SIX,
  layout: CTALayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx8ufdx24fg0780ybqe4j4m",
    itemId: "phwas9xce2",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          width: 600,
          maxWidth: "100%",
          margin: "0 auto",
          display: "inline-block",
          textAlign: "center",
          mobile: {
            desktop: {
              width: 600,
              maxWidth: "100%",
            },
            smartphone: {
              width: "100%",
              maxWidth: "100%",
            },
          },
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: get(content, "main_headline.html", "..."),
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: false,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: get(content, "sub_headline.html", "..."),
          },
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: true,
            properties: {
              textAlign: "center",
            },
            children: [
              {
                type: ComponentTypes.COLUMNS,
                canHaveChildren: false,
                properties: style.BUTTON_CONTAINER,
                children: [
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: 50,
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.BUTTON,
                        canHaveChildren: false,
                        properties: {
                          ...style.BUTTON.style,
                          backgroundColor: "transparent",
                          border: `1px solid ${theme.backgroundColor}`,
                        },
                        caption1: "Get Started",
                        caption1style: {
                          ...style.BUTTON.caption1,
                          color: theme.backgroundColor,
                        },
                      },
                    ],
                  },
                  {
                    type: ComponentTypes.COLUMN,
                    canHaveChildren: true,
                    preventDelete: true,
                    md: 50,
                    properties: { height: "100%", padding: 0, minHeight: 20 },
                    children: [
                      {
                        type: ComponentTypes.BUTTON,
                        canHaveChildren: false,
                        properties: style.BUTTON.style,
                        caption1: "Get Started",
                        caption1style: style.BUTTON.caption1,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
