import React from "react";
import MobileResponsiveness from "./MobileResponsiveness";
import { ComponentRenderHooks } from "@/plugins/types";

export const onComponentRender = (hook, payload, { setMobileState }) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && setMobileState)
    return [<MobileResponsiveness key="mobile" onChange={setMobileState} />];
};

export default MobileResponsiveness;
