// import { ComponentRenderHooks } from "@launchos/plugins/types";
// import * as React from "react";
// import { has } from "lodash";
// import { ComponentTypes } from "../../types";
import { Helmet } from "react-helmet";
// import { generateInlineStyle } from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";
// import { EditorMode } from "@/Apps/Pages/Editor/types";

import { OrderBumpTypes, OrderBumpProps, PluginProps } from "./types";
// import reducers from "@launchos/plugins/webcomponents/v2/Button/reducers";
// import { ActionBehaviors } from "@launchos/modules/editor/Builder/Properties/BuildingBlocks/Interactions/types";

import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import { generateInlineStyle } from "@/Apps/Pages/Properties/RichEditor/utilities";
import reducers from "../Button/reducers";
import { ActionBehaviors } from "@/Apps/Pages/Properties/BuildingBlocks/Interactions/types";
import React from "react";
import { LiquidRenderer } from "@/Apps/Pages/Page/LiquidRenderer";

export const OrderBump: React.FC<OrderBumpProps> = ({
  style = {},
  properties,
  children,
  type,
  html,
  id,
  actions,
  mode,
}) => {
  const [checked, setChecked] = React.useState<boolean>(false)

  React.useEffect(() => {
    // set the checkbox to be checked if the cart already has the item
    // let currentCart = JSON.parse(localStorage.getItem('cart')) || []
    // setChecked(currentCart.findIndex(itm => itm.id === id) > -1)

    // clear the shopping cart
    localStorage.removeItem('cart')

  }, [])

  const handleCheckBumpClick = () => {
    if (actions) {
      const doTheseActions = actions.filter((itm) => itm.behavior === ActionBehaviors.CLICK);

      doTheseActions.forEach((action) =>
        reducers([], {
          ...action,
          returnCallBack: setChecked
        })
      );
    }
  }

  return (
    <li
      data-testid="WC-ORDERBUMP-LIVE"
      style={{
        display: type === OrderBumpTypes.BLOCK ? "block" : "flex",
        ...style,
        ...properties,
        ...(mode === EditorMode.LIVE ? { cursor: "pointer" } : {}),
      }}
      onClick={handleCheckBumpClick}
    >
      <Helmet>
        <style type="text/css">
          {generateInlineStyle({ properties, id }, EditorMode.LIVE)}
        </style>
      </Helmet>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
            paddingRight: 5,
          }}
        >
          <input
            name={`orderBump${id}`}
            type="checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
          }}
          // {...(!children ? { dangerouslySetInnerHTML: { __html: html } } : {})}
        >
          {!children && <LiquidRenderer html={html} />}
          {children && children}
        </div>
      </div>
    </li>
  );
};

const OrderBumpPluginComponent: React.FC<PluginProps> = ({ settings }) => {
  return <OrderBump {...settings} mode={EditorMode.LIVE} />;
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ORDERBUMP
  ) {
    return [OrderBumpPluginComponent];
  }
};

export default OrderBump;
