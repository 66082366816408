import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";

const settings: IComponentSettings = {
  id: "text",
  name: "text",
  thumbnail: "comp_text.png",
  default: {
    type: ComponentTypes.TEXT,
    html: "Replace this paragraph text with your own",
    properties: {
      display: "inline-block",
      padding: 5,
    },
  },
};

export default settings;
