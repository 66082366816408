import { EditorComponentProps } from "@/Apps/Pages/Editor/types";
import { LiveFormFieldProps } from "@/ui/CRUD/FormBuilder/LiveFormField/types";

export interface PluginProps extends EditorComponentProps {
  settings: LiveFormFieldProps;
}

export interface PropertiesProps extends EditorComponentProps {
  settings: LiveFormFieldProps;
}

// From `v2/CRUD/FormBuilder/types`
export enum FormFieldTypes {
  TEXT = "Text",
  PASSWORD = "Password",
  CHECKBOXES = "Checkboxes",
  MULTIPLECHOICE = "Multiple Choice",
  TEXTAREA = "Textarea",
  SELECT = "Select",
  DATE = "Date",
  CONTENT = "Content",
  NUMBER = "Number",
  CURRENCY = "Currency",
  DROPDOWN = "Drop Down",
  // TYPOGRAPHY = "Typography",
  // FILEUPLOAD = "FileUpload",
  // LINEARSCALE = "LinearScale",
}

export interface validationSchema { }

export interface FormBuilderProps {
  /**
   * Establishes which version of the FormBuilder components should show
   */
  mode?: EditorMode;

  /**
   * The list of form fields that the form builder shows
   */
  data?: LiveFormFieldProps[];

  /**
   * the props to use for the SortableList component
   */
  sortableSettingsOverride?: SortableListProps;

  /**
   * The style to use on all form field
   */
  globalFieldStyle?: {
    labelStyle: React.CSSProperties;
    inputStyle: React.CSSProperties;
    containerStyle: React.CSSProperties;
    validationStyle: React.CSSProperties;
  };

  /**
   * A Yup schema or a function that returns a Yup schema.
   */
  // validationSchema?: yup.InferType<typeof validationSchema>;
  validationSchema?: any;

  /**
   * Triggers when one of the fields change
   * - returns the item that changed, the complete mutated data array, and the validation status
   */
  onChange?: (data: LiveFormFieldProps[]) => void;

  /**
   * Triggers when one of the fields triggers a blur event
   * - returns the item that changed, the complete mutated data array, and the validation status
   */
  onBlur?: (data: LiveFormFieldProps[]) => void;
}
