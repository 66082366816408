import { ContextValueType } from "@/AppContext";
import moment from "moment";

/**
 * Interface for the first parameter of setContextForView function
 */
export interface SetContextParams {
  /** The prompt to be added to the context */
  prompt?: string;
  /** The data to be formatted and added to the context */
  data: any[];
  /** The view object to match against currentlyViewing items */
  // view: Record<string, unknown>;
  view: string;
}

export const setContextForView = (
  { prompt = '', data, view }: SetContextParams,
  { copilotContext, setCopilotContext }: ContextValueType
) => {
  console.log(
    "setContextForView",
    { prompt, data, view },
    { copilotContext, setCopilotContext }
  );

  let newCopilotContext = copilotContext;
  newCopilotContext[view] = `${prompt}

  ${(() => {

    const formatValue = (value) => {
      if (Array.isArray(value)) {
        return `[${value.map(formatValue).join(", ")}]`;
      } else if (typeof value === "object" && value !== null) {
        return `{\n${Object.entries(value)
          .map(([k, v]) => `    ${k}: ${formatValue(v)}`)
          .join(",\n")}\n  }`;
      } else {
        return JSON.stringify(value);
      }
    };

    const formatEntry = ([key, value]) => `${key}: ${formatValue(value)}`;

    if (Array.isArray(data)) {
      return data
        .map((item) => Object.entries(item).map(formatEntry).join("\n"))
        .join("\n\n---\n\n");
    } else if (typeof data === "object" && data !== null) {
      return Object.entries(data).map(formatEntry).join("\n");
    } else {
      return String(data);
    }

  })()}
  
  `;

  console.log("setContextForView", "newCopilotContext", newCopilotContext);

  setCopilotContext(newCopilotContext);
};
