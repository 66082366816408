import * as React from "react";
import _ from "lodash";

// import { EditorMode } from "@launchos/modules/v2/Editor/types";
import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import { PluginProps } from "./types";
// import { Progress } from "./live";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { paymentElementSettings, addressElementSettings } from "./settings";
// import { updateSection } from "@/Apps/Pages/Properties/components/generator";
// import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

export const PaymentElementProperties = (props) => {
  // const { updateComponentSettings, settings } = props;
  const { properties } = paymentElementSettings;
  const { sections } = properties.main;

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections },
      }}
      title="Payment Element Settings"
    />
  );
};

export const PaymentElement = () => {
  return (
    <img src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/64f22f2c7d6db41d7a8bfdaf/images/65af5bcec231e622d44b3abc/file-sfltpNZWG8.png" width="100%" />
  )
}
export const EditorPaymentElement: React.FC<PluginProps> = (props) => {
  const { settings, mode, id } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Payment Form"
        id={id}
        {...props}
        PropertiesView={PaymentElementProperties}
      >
        <PaymentElement
          {...settings}
          properties={properties}
          testId="WC-PAYMENT_ELEMENT-EDIT"
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export const AddressElementProperties = (props) => {
  // const { updateComponentSettings, settings } = props;
  const { properties } = addressElementSettings;
  const { sections } = properties.main;

  return (
    <PropertiesBuilder
      {...props}
      data={{
        ...properties,
        main: { ...properties.main, sections },
      }}
      title="Address Element Settings"
    />
  );
};

export const AddressElement = () => {
  return (
    <img
      src="https://sandcastleassets.s3.us-east-1.amazonaws.com/StripeAddressElement.png"
      width="100%"
    />
  );
}


export const EditorAddressElement: React.FC<PluginProps> = (props) => {
  const { settings, mode, id } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings}>
      <EditorObject
        title="Address Form"
        id={id}
        {...props}
        PropertiesView={AddressElementProperties}
      >
        <AddressElement
          {...settings}
          properties={properties}
          testId="WC-ADDRESS_ELEMENT-EDIT"
        />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorPaymentElement;
