import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { FormLayouts } from "../types";
import { FormFieldTypes } from "@/ui/CRUD/FormBuilder/types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FormLayouts.TWENTY,
  layout: FormLayouts.TWENTY,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/forms/Form20.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzjrul12h5h0780m1q9wbx3",
    itemId: "vcai8nnwav",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FORM,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
          ...get(content, "main_headline.style", {}),
        },
        html: "Get Free, Instant Access",
      },
      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
          ...get(content, "main_headline.style", {}),
        },
        html: `${startCase(lorem.generateWords(15))}.`,
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 20,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.FORMFIELD,
                canHaveChildren: false,
                attributes: {
                  placeholder: "Name",
                  name: "name",
                },
                fieldType: FormFieldTypes.TEXT,
                styles: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.FORMFIELD,
                canHaveChildren: false,
                attributes: {
                  placeholder: "Email",
                  name: "email",
                },
                fieldType: FormFieldTypes.TEXT,
                styles: {
                  containerStyle: style.FORM_FIELD.style,
                  inputStyle: style.FORM_FIELD.inputStyle,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: style.BUTTON.style,
                caption1: `Yes! Please Send Access`,
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
