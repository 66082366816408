import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container, Page } from "@/ui/Layout/Page";
import { getCampaign, updateObject } from "@/api/campaigns";
import Button from "@/ui/Button";
import { ButtonTypes, Variants } from "@/ui/types";

import { getSessionToken } from "@/api/auth";
import { PublishCampaignModal } from "../Funnels/Popups/PublishCampaignModal";

import { ContactsGallery } from "@/Apps/CRM/ContactsGallery";
import { getFilterObject } from "@/Apps/CRM/scripts";
import Modal, { Title } from "@/ui/Modal";
import { PopupPosition, PopupSizes } from "@/ui/Modal/types";
import Stepper from "@/ui/Stepper";
import { Loading } from "@/ui/Layout/Loading";
import { AppContext } from "@/AppContext";
import { SITE_DOMAIN } from "@launchos/shared-utils/env";
import { Gear, MagicWand, PaintBrush, Play, Plus } from "@phosphor-icons/react";
import { SettingsDashboard } from "./Settings/SettingsDashboard";
import { FullEditor } from "./Editor/FullEditor";
import { DndProvider } from "react-dnd";
import { TopbarButton } from "@/ui/Layout/Topbar/Topbar";
import MobileResponsiveToggle from "./MobileResponsiveToggle";
import { MobileState } from "./MobileResponsiveToggle/types";
import { ComponentTypes, ISettings } from "./Editor/types";
import { getPromptForAlternativeWidgetCopy } from "../AITools/ChatBot/prompts";
import UndoRedoButtons from "./UndoRedoButtons";
import {
  ChoosePageTemplateModal,
  performTemplateChange,
} from "./widgets/Templates";
import { complete, completeComponent } from "@/api/ai";
import { listAncestors, recursivelyRetrieveItem } from "./V2Editor/actions";
import SimpleBuilder from "../Funnels/SimpleBuilder";
import { EditorTypes } from "./Editor/EditorCanvas";

const UpgradePopup = ({ setIsOpen }) => {
  const token = getSessionToken();

  return (
    <Modal
      title="Please Upgrade to Use This Feature"
      footer={false}
      position={PopupPosition.CENTERED}
      size={PopupSizes.FULLSCREEN}
      onClose={() => setIsOpen(false)}
      childrenStyle={{ padding: 0, margin: 0 }}
    >
      <iframe
        className="w-full h-full"
        src={`${SITE_DOMAIN}/upgrade?token=${token}`}
      />
    </Modal>
  );
};

const CongratsPopup = ({ setIsOpen }) => {
  const [step, setStep] = useState(1);
  const steps = [
    {
      title: "Your Page is Being Generated",
      media: "",
      caption:
        "Wait a moment while our AI generates your initial landing page based on your preferences. This custom starting point will help you create a unique page faster.",
    },
    {
      title: "Choose from Options",
      media: "",
      caption:
        "Browse through AI-generated variations of your page. Click on the option you prefer to select it as your starting point.",
    },
    {
      title: "Double-click to edit",
      media: "",
      caption:
        "To modify any element on your page, simply double-click it. This will open the editing interface where you can change text, images, or styles.",
    },
    {
      title: "Right-click to regenerate",
      media: "",
      caption:
        "If you're not satisfied with an element, right-click it and select 'Regenerate'. Our AI will create new variations for you to choose from.",
    },
    {
      title: "Publish to launch",
      media: "",
      caption:
        "Once you're happy with your landing page, click the 'Publish' button to make it live. You can always come back to edit or update your page later.",
    },
  ];
  return (
    <Modal
      title="Welcome to Launch OS"
      footer={false}
      position={PopupPosition.CENTERED}
      onClose={() => setIsOpen(false)}
    >
      <div className="p-3">
        <div className="mx-auto w-4/5 mt-3">
          <Stepper activeStep={step} numberOfSteps={steps.length} />
        </div>

        <Title>{steps[step - 1].title}</Title>

        {/* <Loading type="gallery"  /> */}
        <div>{steps[step - 1].caption}</div>

        <div className="text-center mb-4">
          {step < steps.length && (
            <Button
              label="Next ->"
              type={ButtonTypes.OUTLINED}
              onClick={() => setStep(step + 1)}
            />
          )}
          {step === steps.length && (
            <Button
              label="Finish"
              type={ButtonTypes.OUTLINED}
              onClick={() => setIsOpen(false)}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export const handleAskAIComponentRequest = async ({
  prompt = "",
  payload = {
    settings: {},
    pageContent: [],
    addThisAfterThat: function (newContent: ISettings, id: string): void {
      throw new Error("Function not implemented.");
    },
  },
}: {
  prompt?: string;
  payload?: {
    settings: ISettings;
    pageContent: ISettings[];
    addThisAfterThat: (newContent: ISettings, id: string) => void;
    removeItem?: (id: string) => void;
    moveThisByThat?: (sourceId: string, targetId: string) => void;
    updateComponentSettings?: (
      id: string,
      newSettings: Partial<ISettings>
    ) => void;
  };
}) => {
  const {
    settings,
    pageContent,
    addThisAfterThat,
    removeItem,
    moveThisByThat,
    updateComponentSettings,
  } = payload;

  const ancestors = listAncestors(pageContent, settings.id);
  const section = ancestors?.find(
    ({ type }) => type === ComponentTypes.SECTION
  );
  const sectionWithChildren = recursivelyRetrieveItem(pageContent, section.id);

  //   const systemPrompt = `You are tasked with analyzing and modifying part of a web page based on a user's request. Follow these instructions carefully:

  // 1. You will be provided with:
  //    - HTML content for the section
  //    - The corresponding JSON objects that represent that HTML
  //    - A user's request for changes

  // 2. Your task is to analyze the content and determine what changes to make based on the user's request. The changes could involve:
  //    - Adding new components (intention: ADD_NEW)
  //    - Removing components (intention: DELETE)
  //    - Moving components (intention: MOVE)
  //    - Modifying existing components (intention: MODIFY)

  // 3. Your response should be a JSON object with:
  //    - An "intention" field specifying the type of change (ADD_NEW, DELETE, MOVE, or MODIFY)
  //    - An "id" field referencing the target component
  //    - For ADD_NEW/MODIFY: a "components" array containing the new/modified component objects
  //    - For MOVE: "sourceId" and "targetId" fields specifying the components to move

  // 4. When making changes:
  //    - Maintain the existing style and design patterns
  //    - Ensure changes align with the page's purpose
  //    - Keep the overall structure intact
  //    - Make only changes that directly address the user's request

  // Here is the HTML for the section:
  // <section_html>
  // ${convertPagetoHTML(sectionWithChildren)}
  // </section_html>

  // Here are the corresponding objects:
  // <section_objects>
  // ${JSON.stringify(sectionWithChildren)}
  // </section_objects>

  // Analyze the content and respond with appropriate changes based on the user's request.`

  const result = await completeComponent(
    prompt,
    "",
    settings,
    sectionWithChildren,
    pageContent
  );
  console.log("handleAskAIComponentRequest", result);

  if (result.intention === "ADD_NEW") {
    result?.components.forEach((c) => {
      addThisAfterThat(c, result.id);
    });
  } else if (result.intention === "DELETE") {
    // Handle delete by passing the id to remove
    if (result.id) {
      removeItem(result.id);
    }
  } else if (result.intention === "MOVE") {
    // Move source element relative to target element
    if (result.sourceId && result.targetId) {
      moveThisByThat(result.sourceId, result.targetId);
    }
  } else if (result.intention === "MODIFY") {
    // Update the component with the modified settings
    if (result.components && result.id) {
      result?.components.forEach((c) => {
        updateComponentSettings(result.id, c);
      });
    }
  }

  return null;
};

export const handleGetAlternateCopyRequest = async ({
  settings,
  updateComponent = () => {},
  flavor = "improve",
  prompt = "Please create alternative copy for this element",
}: {
  settings: ISettings;
  updateComponent?: (id: string, newSettings: Partial<ISettings>) => void;
  flavor?: "improve" | "create";
  prompt?: string;
}) => {
  // get the new content from the AI API
  const system = getPromptForAlternativeWidgetCopy(
    settings.type,
    settings.html,
    flavor
  );

  try {
    const response = await complete(prompt, system);
    if (response) {
      const obj = { ...settings, html: response };
      updateComponent(settings.id, obj);
      return obj;
    }
  } catch (error) {
    console.error("Error getting alternative copy:", error);
  }
};

export const Dashboard = ({ name = "Pages", homeHref = "/pages" }) => {
  const [activeTab, setActiveTab] = useState("editor");
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [isCongratsPopupOpen, setIsCongratsPopupOpen] = useState(false);
  const [isUpgradePopupOpen, setIsUpgradePopupOpen] = useState(false);
  const [campaignData, setCampaignData] = useState<{ name?: string }>({});
  const [objectData, setObjectData] = useState<{ name?: string }>({});
  const [pageId, setPageId] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [aiIsLoading, setAIIsLoading] = useState<boolean>(false);
  const [mobileState, setMobileState] = useState<{ type: MobileState }>({
    type: MobileState.FULLSCREEN,
  });
  const [isWidgetSidebarShowing, setIsWidgetSidebarShowing] =
    useState<boolean>(false);
  const [isPropertiesSidebarShowing, setIsPropertiesSidebarShowing] =
    useState<boolean>(true);
  const [chooseTemplatePopupIsShowing, setChooseTemplatePopupIsShowing] =
    useState<boolean>(false);

  const [editorType, setEditorType] = useState(EditorTypes.SIMPLE);

  // const pageEditorIframeRef = useRef<HTMLIFrameElement>(null);
  const currentEditorRef = useRef();

  const { campaignId, objectId, type } = useParams();
  const { permissions = [] } = useContext(AppContext);

  const navigate = useNavigate();

  const id = campaignId;

  const handleRegeneratePageCopy = async () => {
    if (currentEditorRef.current) {
      const { content = [], updateEditorComponent } =
        currentEditorRef.current as any;
      // console.log("Editor Content", content);
      const textObjects = content.filter(
        (settings: ISettings) =>
          settings?.type === ComponentTypes.TEXT ||
          settings?.type === ComponentTypes.PARAGRAPH ||
          settings?.type === ComponentTypes.HEADLINE ||
          settings?.type === ComponentTypes.V1BUTTON
      );

      // loop through them (async loop)
      setAIIsLoading(true);
      for (const itm of textObjects) {
        // use AI to generate alternative content for it
        await handleGetAlternateCopyRequest({
          settings: itm,
          flavor: "create",
          updateComponent: updateEditorComponent,
        });
      }
      setAIIsLoading(false);
    }
  };

  // Fetch the funnel data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();
    console.log("From Pages", { permissions });

    (async () => {
      const data = await getCampaign({ token, id });
      console.log("campaign data", data);
      setCampaignData(data);

      if (Boolean(campaignData?.settings?.simple))
        setEditorType(EditorTypes.SIMPLE);
      else setEditorType(EditorTypes.FULL);

      if (!objectId) {
        setPageId(data?.objects[0]?.page?.id); // if no objectId, then use the first object found in the campaign
        setObjectData(data?.objects[0]);
      } else {
        // if an objectId was found, get the page id from it.
        const { objects } = data;
        console.log("The objects", objects);
        const object = objects.find(({ id }) => id === objectId);
        console.log("The object", object);
        setObjectData(object);
        setPageId(object?.page?.id);
      }

      setIsLoading(false);
    })();
  }, []);

  const handleEditorHasLoaded = async () => {
    if (objectData?.page?.content?.length < 2) {
      setChooseTemplatePopupIsShowing(true);
      return;
    }

    if (objectData?.settings?.withAI && currentEditorRef) {
      console.log("Trigger Page Copy Regeneration!!");
      await handleRegeneratePageCopy();

      const r = await updateObject(objectId, {
        settings: {
          ...objectData?.settings,
          withAI: false,
        },
      });
    }
  };

  // Use this to configure what shows in the top bar of the page
  const page = !objectId
    ? [{ label: name, href: homeHref }, { label: campaignData.name || "•••" }]
    : [
        // { label: name, href: "/funnels" },
        {
          label: campaignData?.name || "•••",
          href: `${homeHref}/${campaignId}`,
        },
        {
          label: objectData?.name || "•••",
          canRename: true,
          onChange: console.log,
        }, // To Do: Get Page name & make this a dropdown showing all funnel pages
      ];

  const topBarSettings = {
    page,
    leftItems:
      activeTab === "editor" && editorType === EditorTypes.FULL ? (
        <>
          <div className="p-3" />
          <TopbarButton
            onClick={() => setIsWidgetSidebarShowing(!isWidgetSidebarShowing)}
            isActive={isWidgetSidebarShowing}
          >
            <Plus />
          </TopbarButton>

          <div className="mt-5">
            {/* {JSON.stringify(currentEditorRef?.current.doUndo)} */}
            <UndoRedoButtons
              canUndo={currentEditorRef.current?.canUndo}
              canRedo={currentEditorRef.current?.canRedo}
              onUndo={() => currentEditorRef.current?.doUndo()}
              onRedo={() => currentEditorRef.current?.doRedo()}
            />
          </div>
        </>
      ) : (
        <></>
      ),
    items: (
      <>
        {aiIsLoading && <Loading type="tiny" />}
        {/* {activeTab === "editor" && !aiIsLoading && (
          <TopbarButton onClick={handleRegeneratePageCopy}>
            <div title="Regenerate Copy">
              <Sparkle />
            </div>
          </TopbarButton>
        )} */}
        {activeTab === "editor" && editorType === EditorTypes.FULL && (
          <TopbarButton
            isActive={isPropertiesSidebarShowing}
            onClick={() =>
              setIsPropertiesSidebarShowing(!isPropertiesSidebarShowing)
            }
          >
            <PaintBrush />
          </TopbarButton>
        )}
        <TopbarButton
          isActive={activeTab === "settings"}
          onClick={() => {
            if (activeTab === "settings") setActiveTab("editor");
            else setActiveTab("settings");
          }}
        >
          <Gear />
        </TopbarButton>
        {activeTab !== "settings" && (
          <TopbarButton
            onClick={() => {
              if (editorType === EditorTypes.FULL)
                setEditorType(EditorTypes.SIMPLE);
              if (editorType === EditorTypes.SIMPLE)
                setEditorType(EditorTypes.FULL);
            }}
          >
            <MagicWand />
          </TopbarButton>
        )}
        <TopbarButton
          onClick={() =>
            window.open(`/pages/preview/${campaignId}/${objectData?.id}`)
          }
        >
          <Play />
        </TopbarButton>
        <Button
          label={<span>Publish</span>}
          onClick={() => {
            if (permissions.includes("freemium")) setIsUpgradePopupOpen(true);
            else setIsPublishPopupOpen(true);
          }}
          type={ButtonTypes.OUTLINED}
          variant={Variants.INFO}
        />
      </>
    ),
    centerItems:
      activeTab === "editor" && editorType === EditorTypes.FULL ? (
        <MobileResponsiveToggle
          onChange={setMobileState}
          mobileState={mobileState}
          setMobileState={setMobileState}
        />
      ) : (
        <></>
      ),
  };

  if (isLoading)
    return (
      <div className="p-24">
        <Loading type="gallery" />
      </div>
    );

  return (
    <Page topBar={topBarSettings}>
      {/* Render the publish campaign modal if it's set to open */}
      {isPublishPopupOpen && (
        <PublishCampaignModal
          unitName="Page"
          setIsOpen={setIsPublishPopupOpen}
          campaignId={id}
        />
      )}

      {isCongratsPopupOpen && (
        <CongratsPopup setIsOpen={setIsCongratsPopupOpen} />
      )}

      {isUpgradePopupOpen && <UpgradePopup setIsOpen={setIsUpgradePopupOpen} />}

      {chooseTemplatePopupIsShowing && (
        <ChoosePageTemplateModal
          defaultType={type || "all"}
          setIsOpen={setChooseTemplatePopupIsShowing}
          onSelect={async (template) => {
            const pageId = objectData?.page?.id;
            await performTemplateChange(template, pageId);

            setChooseTemplatePopupIsShowing(false);

            // Reload the page
            if (homeHref && campaignId && objectData?.id && window)
              window.location.href = `${homeHref}/${campaignId}/${objectData?.id}`;
          }}
        />
      )}

      {activeTab === "editor" &&
        id &&
        pageId !== "" &&
        editorType === EditorTypes.SIMPLE &&
        campaignData?.name && (
          <SimpleBuilder
            title="Page Builder"
            pageId={campaignData?.objects?.[0]?.page?.id}
            campaignData={campaignData}
            setCampaignData={(data) => setCampaignData(data)}
            // onClose={() => setIsFunnelWizardPopupOpen(false)}
            showAsModal={false}
          />
        )}

      {activeTab === "editor" &&
        id &&
        pageId !== "" &&
        editorType === EditorTypes.FULL &&
        campaignData?.name && (
          <FullEditor
            ref={currentEditorRef}
            campaignId={id}
            objectId={objectData?.id}
            pageId={String(pageId)}
            mobileState={mobileState}
            isWidgetSidebarShowing={isWidgetSidebarShowing}
            isPropertiesSidebarShowing={isPropertiesSidebarShowing}
            onLoad={handleEditorHasLoaded}
            setMobileState={setMobileState}
          />
        )}

      {activeTab === "contacts" && (
        <ContactsGallery
          onCreate={() => {}}
          defaultFilter={getFilterObject({
            field: "hiddenTags",
            operator: "contains",
            value: id,
          })}
        />
      )}

      {activeTab === "settings" && (
        <Container>
          <Button
            label="<- Back to Editor"
            type={ButtonTypes.SOFT}
            onClick={() => setActiveTab("editor")}
            className="mb-4"
          />
          <SettingsDashboard
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            objectData={objectData}
          />
        </Container>
      )}
    </Page>
  );
};
