import { getInputVals } from "@/Apps/Pages/Page/scripts";

export default {
  getShippingMethods: () => {
    const isWin = typeof window !== "undefined";
    if (isWin) {
      const inputVals = getInputVals();
      const field = inputVals.filter(itm => itm.name === "pmP");
      let shipping = false;

      try {
        shipping = JSON.parse(JSON.parse(field[0].value).campaign).shipping;
      } catch (e) {
        shipping = false;
      }

      if (shipping) {
        return shipping.map(itm => ({
          name: itm.shipping_id,
          value: itm.shipping_name
        }));
      }
    }

    return [{ name: "0", value: "Shipping..." }];
  },

  getBillingModels: () => {
    const isWin = typeof window !== "undefined";
    if (isWin) {
      const inputVals = getInputVals();
      const field = inputVals.filter(itm => itm.name === "pmP");
      let billing = false;

      try {
        billing = JSON.parse(JSON.parse(field[0].value).billingModel);
      } catch (e) {
        billing = false;
      }

      if (billing) {
        return billing.map(itm => ({ name: itm.id, value: itm.name }));
      }
    }

    return [{ name: "0", value: "Billing Model..." }];
  },

  formContentUpdate: action => {
    const { payload } = action;
    if (
      typeof window === "object" &&
      payload.name !== "cardNumber" &&
      payload.name !== "cvv" &&
      payload.name !== "password"
    ) {
      let p = [];
      p[payload["name"]] = payload.value;
      const formData = JSON.parse(sessionStorage.getItem("formData"));

      sessionStorage.setItem(
        "formData",
        JSON.stringify({
          ...formData,
          ...p
        })
      );
    }
  }
};
