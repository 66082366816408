import _, { get, has, cloneDeep } from "lodash";
import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { MenuItemLabel } from "@/ui/_old/ListItem";
import { listChildren, recursivelyDuplicate, recursivelyRemoveItem, recursivelyRetrieveItem } from "../V2Editor/actions";
import { triggerAsyncHook, triggerHook } from "@/plugins/client";
import { HookTriggers, ListItemsHooks } from "@/plugins/types";
import { useCmdK } from "@/ui/CommunicationView/CmdK/CmdK";
import { useCompletion } from "ai/react";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { isJsonString } from "@/utils";

export const renameObject = ({ updateComponentSettings, settings }) => {
  const label = prompt(
    "What would you like to name this element?",
    _.get(settings, "label", "")
  );
  if (label) {
    console.log({ label }, { settings });
    updateComponentSettings(
      settings.id,
      {
        ...settings,
        label,
      },
      true,
      false
    );
  }
};

const Divider = () => {
  return (
    <>
      <div className="h-px border-t border-b border-white/10" />
      <div className="h-px border-t border-b border-black/10" />
    </>
  );
}

export const DropDownMenu = (props) => {
  const {
    anchorEl,
    // label,
    type,
    handleDropDownClose,
    onDropDownChange = () => true,
    rSettings,
    settings,
    removeItem,
    duplicateItem,
    addThisAfterThat,
    moveThisByThat,
    pageContent,
    updateComponentSettings,
    showProperties,
    getMobileState,
    changeState,
  } = props;

  const { open: openCmdK } = useCmdK();

  const { complete, isLoading } = useCompletion({
    api: `${AI_DOMAIN}/api/completion`,
    // body: {
    //   // type: "chat",
    //   systemPrompt: "",
    // },
  });

  const siblings = pageContent.filter((itm) => itm.parent === settings.parent);
  const key = siblings.findIndex((itm) => itm.id === settings.id);

  const defaultMenuItems = [
    { id: "CUT", icon: "content_cut", text: "Cut" },
    { id: "COPY", icon: "content_copy", text: "Copy" },
    ...(window.localStorage.hasOwnProperty("clipboard")
      ? [{ id: "PASTE", icon: "content_paste", text: "Paste Below" }]
      : []),
    { id: "divider" },
    ...(_.has(siblings, key - 1)
      ? [{ id: "MOVE_UP", icon: "expand_less", text: "Move Up" }]
      : []),
    ...(_.has(siblings, key + 1)
      ? [{ id: "MOVE_DOWN", icon: "expand_more", text: "Move Down" }]
      : []),
    ...(_.has(siblings, key - 1) || _.has(siblings, key + 1)
      ? [{ id: "divider" }]
      : []),
    { id: "rename", icon: "input", text: "Edit Component Settings" },
    { id: "duplicate", icon: "file_copy", text: "Duplicate" },
    { id: "delete", icon: "delete_forever", text: "Delete" },
  ];

  const items =
    typeof props.dropDownItems === "function"
      ? props.dropDownItems(defaultMenuItems)
      : defaultMenuItems;
  const [useTheseDropDownItems, setUseTheseDropDownItems] =
    React.useState(items);

  const handleChange = (action, e) => {
    e.stopPropagation();
    if (onDropDownChange(action)) {
      switch (action) {
        case "duplicate":
          duplicateItem(settings.id);
          break;
        case "delete":
          removeItem(settings.id);
          break;
        case "save":
          break;
        case "rename":
          // renameObject({ updateComponentSettings, settings });
          const newSettings = window.prompt("Update Settings...[esc] to close", JSON.stringify(settings));
          if (!isJsonString(newSettings)) { alert("Invalid Settings, try again!"); return; }
          if (newSettings) updateComponentSettings(settings.id, JSON.parse(newSettings));
          break;
        case "CUT": {
          // generate JSON
          const cnt = listChildren(pageContent, settings.id);
          const clipboard = JSON.stringify(cnt);

          // add to local storage
          window.localStorage.setItem("clipboard", clipboard);

          // now remove item
          removeItem(settings.id);
          break;
        }
        case "COPY": {
          // generate JSON
          const cnt = listChildren(pageContent, settings.id);
          const clipboard = JSON.stringify(cnt);

          // add to local storage
          if (typeof window === "object") {
            window.localStorage.setItem("clipboard", clipboard);
            // alert("The Element Has Been Copied");
          }

          break;
        }
        case "PASTE": {
          // grab from local storage
          const clipboard = JSON.parse(localStorage.getItem("clipboard"));
          // add to canvas below this section
          changeState(2540, "active", () => {
            // don't add sections below objects
            if (clipboard.type === "Section" && settings.type !== "Section")
              alert(
                "You can only paste SECTION widgets below another SECTION widget"
              );
            // it should probably remap the id's like it does in recursively duplicate (cloning ids is bad)
            // you could modify the duplicateItem function to return the new object (instead of updating the state)
            else addThisAfterThat(clipboard, settings.id);
          });

          break;
        }
        case "MOVE_UP": {
          if (_.has(siblings[key], "id") || _.has(siblings[key - 1], "id")) {
            moveThisByThat(
              _.get(siblings[key], "id"),
              _.get(siblings[key - 1], "id")
            );
          }

          break;
        }
        case "MOVE_DOWN": {
          if (_.has(siblings[key], "id") || _.has(siblings[key + 1], "id")) {
            moveThisByThat(
              _.get(siblings[key], "id"),
              _.get(siblings[key + 1], "id")
            );
          }

          break;
        }
        case "PROPERTIES": {
          showProperties();
          break;
        }
        case "reset":
          const { type } = getMobileState();
          const { rSettings } = props;
          if (has(rSettings, "properties.mobile")) {
            const properties = cloneDeep(rSettings.properties);
            delete properties.mobile[type];

            console.log(rSettings.properties, properties);
            updateComponentSettings(settings.id, {
              ...settings,
              properties,
            });
          }
          // console.log(type)
          break;
        default:
          break;
      }
    }

    triggerAsyncHook(
      HookTriggers.onMenuSelect,
      { id: ListItemsHooks.EDITOR_OBJECT_MENU, type },
      { id: action, ...props },
      { openCmdK, complete }
    );

    // if this is from an iframe, send the action back to the parent
    const isFromIframe = window.location !== window.parent.location;

    if (isFromIframe) {
      console.log({ action, useTheseDropDownItems });
      // post a message to the parent iframe so that it can handle the action
      const message = {
        type: "editorObjectRequest",
        request: "handleDropDownAction",
        payload: {
          action,
          settings,
          items: useTheseDropDownItems,
          content: pageContent,
        },
      };

      window.parent.postMessage(message, "*");
    }

    handleDropDownClose();
  };

  const pluginDropDownItems = (items) => {
    const newItems = triggerHook(
      HookTriggers.onListItems,
      { id: ListItemsHooks.EDITOR_OBJECT_MENU, type },
      items,
    );
    // console.log("Calling for dropdown", newItems);
    if (newItems.length) return newItems;
    return items;
  };

  // const iframeDropDownItems = async (items) => {
  //   const isFromIframe = window.location !== window.parent.location;

  //   if (isFromIframe) {
  //     // Create a promise that will resolve when the parent iframe sends a message back
  //     const messagePromise = new Promise((resolve) => {
  //       window.addEventListener("message", (e) => {
  //         if (e.data.type === "editorObjectResponse") {
  //           const { dropDownItems } = e.data;
  //           resolve(dropDownItems);
  //         }
  //       });
  //     });

  //     // Post a message to the parent iframe requesting the drop down items
  //     const message = {
  //       type: "editorObjectRequest",
  //       request: "getDropDownItems",
  //       payload: { settings, items },
  //     };
  //     window.parent.postMessage(message, "*");

  //     const dropDownItems = await messagePromise;
  //     return dropDownItems;
  //   }

  //   return items;
  // };

  const getDropDownItems = async () => {
    const newItems = await pluginDropDownItems(items);
    // const newItems = await iframeDropDownItems(pluginDropDownItems(items));
    setUseTheseDropDownItems(newItems);
  };

  React.useEffect(() => {
    getDropDownItems();
  }, [type]);

  return (
    <Menu
      id="editor-object-menu"
      anchorEl={anchorEl}
      open={get(anchorEl, "mouseY", null) !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        get(anchorEl, "mouseY", null) !== null &&
        get(anchorEl, "mouseX", null) !== null
          ? {
              top: get(anchorEl, "mouseY", null),
              left: get(anchorEl, "mouseX", null),
            }
          : undefined
      }
      onClose={handleDropDownClose}
    >
      {useTheseDropDownItems.map(({ id = false, icon = false, iconComponent, text }, key) =>
        id === "divider" ? (
          <Divider />
        ) : (
          <MenuItem key={key} onClick={(e) => handleChange(id, e)}>
            <MenuItemLabel
              style={{ fontSize: "12pt", marginTop: 4 }}
              icon={icon}
              iconComponent={iconComponent}
            >
              {text}
            </MenuItemLabel>
          </MenuItem>
        )
      )}

      {location.hostname === "localhost" && (
        <div>
          <Divider />
          {/* <MenuItem
            key="debugMobile"
            onClick={(e) => {
              handleChange("reset", e);
            }}
          >
            <MenuItemLabel icon="format_color_reset">
              Clear Mobile Style
            </MenuItemLabel>
          </MenuItem> */}
          <MenuItem
            key="debug2"
            onClick={(e) => {
              e.stopPropagation();
              console.log("Settings", settings);
              console.log("Properties", rSettings.properties);
              const items = recursivelyRetrieveItem(pageContent, settings.id);
              console.log("The Item (with children)", items);
              handleDropDownClose();
            }}
          >
            <MenuItemLabel icon="bug_report">Debug</MenuItemLabel>
          </MenuItem>
        </div>
      )}
    </Menu>
  );
};
