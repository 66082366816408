import { useEffect, useState } from "react";
import { getCatalogItem, getCatalogItems } from "@/api/catalog";
// import { useEditorActions } from "@/Apps/Pages/Editor/EditorCanvas";
import SectionWrapper from "../SectionWrapper";
import DropdownGroup from "../../groups/DropdownGroup";
import { getPage } from "@/api/pages";
import { getSessionToken } from "@/api/auth";
import { get } from "lodash";
import { client } from "@/client";
import { GET_PAGE } from "@/api/gql/pages";
// import { DEFAULT_PAGE_ID } from "@/Apps/Pages/Editor/types";

export const defaultColorPalettes = [
  {
    id: "ocean-breeze",
    label: "Ocean Breeze",
    values: {
      primary: "#3B82F6",
      secondary: "#2DD4BF",
      accent: "#67E8F9", 
      background: "#BAE6FD"
    }
  },
  {
    id: "sunset-glow",
    label: "Sunset Glow", 
    values: {
      primary: "#F97316",
      secondary: "#FBBF24", 
      accent: "#FDE047",
      background: "#F87171"
    }
  },
  {
    id: "forest-mist",
    label: "Forest Mist",
    values: {
      primary: "#059669",
      secondary: "#22C55E",
      accent: "#A3E635",
      background: "#5EEAD4"
    }
  },
  {
    id: "lavender-dreams", 
    label: "Lavender Dreams",
    values: {
      primary: "#9333EA",
      secondary: "#8B5CF6",
      accent: "#818CF8",
      background: "#F0ABFC"
    }
  },
  {
    id: "autumn-harvest",
    label: "Autumn Harvest",
    values: {
      primary: "#D97706",
      secondary: "#F97316",
      accent: "#FACC15", 
      background: "#EF4444"
    }
  },
  {
    id: "arctic-frost",
    label: "Arctic Frost",
    values: {
      primary: "#2563EB",
      secondary: "#22D3EE",
      accent: "#CBD5E1",
      background: "#E5E7EB"
    }
  },
];

export const Appearance = ({
  filter = {},
  type = "designs",
  colorPalettes = defaultColorPalettes,
  payload = {},
  onThemeChange = () => {},
  onColorChange = () => {},
}) => {
  const [templates, setTemplates] = useState([]);
  // const { updateContent } = useEditorActions();

  const fetchTemplates = async () => {
    const items = await getCatalogItems({ type, ...filter });
    console.log({ items });
    setTemplates(
      items.map(({ name, id, image }) => ({
        label: name,
        id,
        icon: (
          <div className="w-12 h-12 mr-2 overflow-hidden rounded">
            <img src={image} className="w-full" />
          </div>
        ),
      })) || []
    );
  };

  const handleTemplateChange = async (key) => {
    const template = templates[key];
    // console.log({ template })
    const id = template.id;

    // get the page content
    const templateData = await getCatalogItem(id);
    console.log({ template, templateData });

    // const { page } = templateData;
    // const { content } = page;
    // const token = getSessionToken();
    // const { content } = await getPage(templateData?.page?.id, { token })

    const pageDetails = await client.query({
      query: GET_PAGE,
      variables: { pageId: templateData?.page?.id },
    });
    const content = get(pageDetails, "data.page.content", false);

    // update the page with the content
    // if (updateContent) updateContent(content, DEFAULT_PAGE_ID);
    // console.log("Changing Content", content);
    onThemeChange(templateData?.id, content);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const colorItems = colorPalettes.map((palette) => ({
    label: palette.label,
    id: palette.id,
    icon: (
      <div className="flex mr-1">
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.primary }}
        />
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.secondary }}
        />
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.accent }}
        />
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.background }}
        />
      </div>
    ),
  }));

  return (
    <SectionWrapper label="Appearance">
      <DropdownGroup
        label="Theme"
        onChange={handleTemplateChange}
        value={payload?.find((v) => v.id === "themeId")?.value}
        items={templates}
      />
      <DropdownGroup
        label="Colors"
        onChange={(key) => onColorChange(colorPalettes[key]?.id)}
        // value={payload?.find((v) => v.id === "colorPalette")?.value}
        value={payload?.find(v => v.id === "palette")?.value}
        items={colorItems}
      />
    </SectionWrapper>
  );
};
