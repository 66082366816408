import * as React from "react";
import { selectOptions } from "../../EditFormField/fieldNames"
import _ from 'lodash';

/**
 * A component that shows a LIVE list of radio buttons or tools for configuring that radio button's look, feel, and behavior
 */
export function LiveMultipleChoice(props) {
  const {
    labelStyle,
    descriptionStyle,
    inputStyle, containerStyle
  } = props.styles;
  const { data = selectOptions.defaultOptions } = props;

  return (<div data-testid="FormBuilder-FormFields-MultipleChoice-LIVE">
    {props.label && <div style={labelStyle}>{props.label}</div>}
    {props.description && <div style={descriptionStyle}>{props.description}</div>}
    {data && data.map(({
      id, label, value
    }, index) => <div key={id} data-testid="FormBuilder-FormFields-MultipleChoice-RadioButton" style={{ ...inputStyle, marginBottom: index < (data.length - 1) ? _.get(containerStyle, 'marginBottom', 'inherit') : 'inherit' }}>
        <input id={id} type="radio" onChange={e => props.handleChange({
          checked: e.checked,
          id
        })} style={{
          marginRight: 10
        }} />
        {/* <span>{label}</span> */}
        <label for={id}>{label || value}</label>
      </div>)}
  </div>);
}

export default LiveMultipleChoice