import theme from "@/ui/theme";
import styled from "styled-components";

export default {
  UL: {
    maxHeight: 350,
    overflowY: "scroll",
    width: "100%",
    marginLeft: 0,
  },
  LI: {
    // display: "inline-block",
    // width: 100,
    // height: 110,
    // padding: 10,
    // border: "2px solid transparent",
    // margin: 0,
    // textAlign: "center" as "center",
    // overflowY: "hidden" as "hidden",
    // color: "#666",
    // cursor: "pointer",
  },
  activeLI: {
    border: `2px solid ${theme.primaryColor}`,
    background: "#EEE",
  },
  input: {
    width: "100%",
    padding: 10,
    marginBottom: 20,
    fontSize: "13pt",
    // boxSizing: "border-box",
    border: "1px solidl #EEE",
  },
  iconStyle: { fontSize: "28pt" },
};

export const LI = styled.li`
  &:hover {
    background-color: #eee;
    border: 2px solid #0000cc;
  }
`;
