import { Robot } from '@phosphor-icons/react';
import { Agents } from './Agents';

import { HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import { SystemPrompt } from './widgets/SystemPrompt';
import { DeploymentSettings } from './widgets/DeploymentSettings';

export const onListItems = (
  hook: HookObject,
  payload: any,
  _,
  { permissions = [] }
) => {
  
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    
    if (!permissions.includes("agents")) return [];

    return [
      {
        label: "Agents",
        icon: <Robot className="text-lg" />,
        href: "/agents",
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    return [
      {
        path: "/agents",
        element: <Agents name="Smart Agents" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Smart Agents" />,
          },
        ],
      },
    ];
  }

  if (hook.id === ListItemsHooks.WIDGET) {
    console.log("WIDGETS!!", hook, payload, permissions);
    // if (!permissions.includes("bot")) return [];
    if (hook.type === "prompt") {
      return <SystemPrompt 
        campaignData={payload?.settings?.campaignData} 
        setCampaignData={payload?.settings?.setCampaignData} 
        unitName="Agent"
      />
    }

    if (hook.type === "deploySettings") {
      return (
        <DeploymentSettings
          campaignData={payload?.settings?.campaignData}
          setCampaignData={payload?.settings?.setCampaignData}
          unitName="Agent"
        />
      );
    }
  }
};


export { Agents };