
import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";
import { FormFieldTypes } from "../FormBuilder/types";

const settings: IComponentSettings = {
  id: ComponentTypes.FORMFIELD,
  name: "Form Field",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "input",
  },
  default: {
    type: ComponentTypes.FORMFIELD,
    fieldType: FormFieldTypes.TEXT,
    properties: {
      display: "inline-block",
      borderWidth: 1,
      borderRadius: 5,
      borderStyle: "solid",
      borderColor: "#BBBBBB",
      width: "100%",
      padding: 10,
    },
    attributes: {},
  },
  properties: {
    main: {
      tabs: ["Basic", "Advanced"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "field_settings",
          sectionWrap: true,
          settings: {
            label: "Field Settings",
            icon: "settings",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.TYPOGRAPHY,
          settings: {
            label: "Field Font",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTHHEIGHT,
          settings: {
            label: "Field Size",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            label: "Alignment",
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.BACKGROUND,
          settings: {
            isExpanded: false,
            showAlpha: true,
            label: "Background Color",
            hideTabs: true,
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.BORDERSSHADOW,
          settings: {
            label: "Borders & Shadow",
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.ACTIONS,
          settings: {
            label: "Field Actions",
            isExpanded: true,
            children: null,
          },
        },
      ],
    },
  },
};

export default settings;
