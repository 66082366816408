import _ from "lodash";
import React, { useContext } from "react";

import style from "@/Apps/Pages/Properties/style";
import { ComponentSelector } from "../Helpers/ComponentSelector";
import ListItem from "@/ui/_old/ListItem";
import Popup from "@/Apps/Pages/WebComponents/v1/popup/settings";
import { addThisAfterThat } from "@/Apps/Pages/V2Editor/actions";
import { EditorContext } from "@/Apps/Pages/Editor/EditorContext";

export const createPopupWindow = async (
  { pageContent, updateContent, pageId },
  hidden = false
) => {
  const bodyObj = _.head(_.filter(pageContent, (itm) => itm.type === "Body"));
  const rootObjs = _.filter(pageContent, (itm) => itm.parent === bodyObj.id);

  const id = _.get(rootObjs, `${rootObjs.length - 1}.id`, false);

  if (id) {
    const name = window.prompt(
      "Please give your Popup Window a name...",
      "Default Popup"
    );
    if (name) {
      const popupWindow = {
        ...Popup.default,
        properties: {
          ...Popup.default.properties,
          display: hidden ? "none" : "block",
          mobile: {
            ...Popup.default.properties.mobile,
            desktop: {
              ...Popup.default.properties.mobile.desktop,
              display: hidden ? "none" : "block",
            },
          },
        },
        children: Popup.default.children.reverse(),
        name,
      };

      console.log({ popupWindow });
        const content = addThisAfterThat(pageContent, popupWindow, id);
        updateContent(pageId, content);
    }
  }
};

export const setNewPopup = (props, newProps, callbackFn) => {
  const oldPageContent = props.pageContent;
  const newPageContent = newProps.pageContent;

  callbackFn({ pageContent: newPageContent });

  console.log("length", props.pageContent.length, newProps.pageContent.length);

  // find the new Popup
  const oldPopups = _.map(
    _.filter(oldPageContent, (itm) => itm.type === "Popup"),
    (itm) => itm.id
  );
  const newPopups = _.map(
    _.filter(newPageContent, (itm) => itm.type === "Popup"),
    (itm) => itm.id
  );

  const difference = _.difference(newPopups, oldPopups);

  if (difference.length) {
    const newPopupId = _.head(difference);
    const newPopupContent = _.head(
      _.filter(newPageContent, (itm) => itm.id === newPopupId)
    );
    console.log({ oldPopups, newPopups, difference, newPopupContent });

    return {
      settings: {
        ...newPopupContent,
        id: newPopupId,
      },
    };
  }
};

export class PopupOptions extends React.Component {
  constructor(props) {
    super(props);

    // this.createPopupWindow = this.createPopupWindow.bind(this);
    this.setPopup = this.setPopup.bind(this);
    this.showSelectedPopup = this.showSelectedPopup.bind(this);
  }
  static contextType = EditorContext;

  UNSAFE_componentWillUpdate(newProps) {
    if (this.props.pageContent.length !== newProps.pageContent.length) {
      const newPopup = setNewPopup(this.props, newProps, (payload) =>
        this.setState(payload)
      );
      if (newPopup) this.setPopup(newPopup);
    }
  }

  setPopup(itm) {
    const { action, updateAction } = this.props;
    updateAction(action, {
      ..._.get(action, "payload", false),
      showId: itm.settings.id,
      showLabel: itm.settings.name,
    });
  }

  showSelectedPopup() {
    const { action, updateComponentSettings, pageContent } = this.props;
    const { showId } = action.payload;
    const component = _.head(_.filter(pageContent, (itm) => itm.id === showId));
    updateComponentSettings(
      showId,
      {
        ...component,
        state: "active",
        properties: {
          ...component.properties,
          display: "block",
        },
      },
      true,
      false
    );
    window.scrollTo(0, 0);
  }

  render() {
    const { action, pageContent } = this.props;
    const { payload } = action;
    const { pageId } = this.context;

    const content = _.has(payload, "showId")
      ? _.filter(pageContent, (itm) => itm.id === payload.showId)
      : [];

    return (
      <React.Fragment>
        <ComponentSelector
          {...this.props}
          filterOnly={[["type", "Popup"]]}
          label={
            _.has(payload, "showLabel") && content.length
              ? `Show: ${payload.showLabel}`
              : "Select a Popup to Show..."
          }
          onChange={(itm) => this.setPopup(itm)}
          children={<div />}
        >
          <ListItem
            leftIcon="open_in_new"
            onClick={() => createPopupWindow({...this.props, pageId })}
          >
            Create a new Popup Window
          </ListItem>
        </ComponentSelector>
        {_.has(payload, "showLabel") && content.length ? (
          <span
            className="underline cursor-pointer pt-4 text-center block"
            onClick={this.showSelectedPopup}
          >
            Show Popup Window Now
          </span>
        ) : null}
      </React.Fragment>
    );
  }
}
