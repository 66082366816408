import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { FormFieldTypes } from "./types";
import { FieldNamePresets } from "@/ui/CRUD/FormBuilder/EditFormField/types";

const style = {
  FORM_FIELD: {
    style: {
      // padding: "5px 0",
      // maxWidth: "100%",
      margin: "0.3125rem",
      padding: 10,
    },
    inputStyle: {
      // width: "100%",
      // padding: 15,
      // border: "1px solid #DDD",
      // borderRadius: 4,
      borderWidth: 1,
      borderRadius: 5,
      borderStyle: "solid",
      borderColor: "#BBBBBB",
      width: "100%",
      padding: 10,
    },
    labelStyle: {
      // fontFamily: "Roboto",
      // fontSize: "10pt",
      // textAlign: "left",
      // padding: 5,
      padding: 10,
    },
  },
};

const settings: IComponentSettings = {
  id: ComponentTypes.FORM,
  name: "Form Builder",
  description: "",
  thumbnail: "comp_form.png",
  default: {
    type: ComponentTypes.FORM,
    data: [
      {
        id: Math.random().toString(36).slice(2),
        type: FormFieldTypes.TEXT,
        name: "firstName",
        placeholder: "First Name",
        preset: FieldNamePresets.FIRSTNAME,
      },
      {
        id: Math.random().toString(36).slice(2),
        type: FormFieldTypes.TEXT,
        name: "lastName",
        placeholder: "Last Name",
        preset: FieldNamePresets.LASTNAME,
      },
      {
        id: Math.random().toString(36).slice(2),
        type: FormFieldTypes.TEXT,
        name: "email",
        placeholder: "Email",
        preset: FieldNamePresets.EMAIL,
      },
    ],
    properties: {
      display: "inline-block",
      ...style.FORM_FIELD.style,
      ...style.FORM_FIELD.inputStyle,
      ...style.FORM_FIELD.labelStyle,
    },
  },
  properties: {
    main: {
      tabs: ["Basic", "Advanced"],
      sections: [
        {
          tab: "Basic",
          type: SectionTypes.CUSTOM,
          id: "formBuilder_settings",
          sectionWrap: false,
          settings: {
            label: "Form Builder",
            icon: "construction",
            isExpanded: true,
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.TYPOGRAPHY,
          settings: {
            label: "Field Font",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.POSITIONPADDING,
          settings: {},
        },
        {
          tab: "Basic",
          type: SectionTypes.SPACING,
          sectionWrap: true,
          settings: {
            label: "Field Spacing",
            icon: "height",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.WIDTH,
          sectionWrap: true,
          settings: {
            label: "Field Size",
            text: "Width",
            icon: "swap_horiz",
          },
        },
        {
          tab: "Basic",
          type: SectionTypes.ALIGNMENT,
          settings: {
            verticalAlign: false,
            marginAlign: false,
            textAlign: true,
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.BACKGROUND,
          settings: {
            isExpanded: false,
            showAlpha: true,
            label: "Background Color",
            hideTabs: true,
          },
        },
        {
          tab: "Advanced",
          type: SectionTypes.BORDERSSHADOW,
          settings: {
            isExpanded: true,
          },
        },
      ],
    },
  },
};

export default settings;
