import * as React from "react";

import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import Icon, { IconTypes } from "../../WebComponents/v2/Icon";
import Image from "../../WebComponents/v2/Image";


export enum VideoPlaceholderTypes {
  NONE,
  POPUP,
}

interface vProps {
  style?: any;
  playIconStyle?: any;
  type?: VideoPlaceholderTypes;
}

const thumbnail = `https://images.unsplash.com/photo-1503978581482-e06dc278d5c2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80`;

const vidStyle = {
  margin: "0 auto",
  padding: "110px 20px",
  // background: theme.backgroundColor,
  backgroundImage: `url(${thumbnail})`,
  backgroundPosition: "center",
  backgroundSize: "100%",
  borderRadius: 3,
  position: "relative",
};

export const VideoPlaceholder: React.FC<vProps> = ({
  style,
  playIconStyle,
  type,
}) => {
  const videoStyle = {
    ...vidStyle,
    ...(type === VideoPlaceholderTypes.POPUP
      ? {
        background: "green",
        textAlign: "center",
        padding: 0,
        marginTop: 70,
        marginBottom: 120,
      }
      : {}),
    ...style,
  };

  return (
    <div title="Video" id="hero_video" style={videoStyle}>
      <Icon
        type={IconTypes.PlayCircleOutline}
        style={{
          fontSize: "72pt",
          // maxWidth: 80,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          cursor: "pointer",
          ...playIconStyle,
        }}
      />
    </div>
  );
};

// export const getVideoPlaceholder = () => ({
//   type: ComponentTypes.VIDEO,
//   canHaveChildren: false,
//   src: {
//     mp4:
//       "https://file-examples.com/wp-content/uploads/2017/04/file_example_MP4_480_1_5MG.mp4",
//     ogg:
//       "https://file-examples.com/wp-content/uploads/2018/04/file_example_OGG_480_1_7mg.ogg",
//   },
//   playIconStyle: {},
//   properties: {
//     textAlign: "center",
//     display: "inline-block",
//     width: "100%",
//     maxWidth: "100%",
//     backgroundImage: `url(${thumbnail})`,
//   },
// });

export const getVideoPlaceholder = ({
  type = VideoPlaceholderTypes.NONE,
  style = {},
  thumbnail = `https://images.unsplash.com/photo-1503978581482-e06dc278d5c2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80`,
  playIconStyle = {
    // textShadow: `5px 5px rgb(255,255,255,1)`,
    color: "white",
  },
}: {
  type: VideoPlaceholderTypes;
  style?: React.CSSProperties;
  playIconStyle?: React.CSSProperties;
  thumbnail?: string;
}) => {
  if (type === VideoPlaceholderTypes.POPUP) {
    return {
      type: ComponentTypes.ICON,
      canHaveChildren: false,
      iconType: IconTypes.PlayCircleOutline,
      properties: {
        fontSize: "72pt",
        textAlign: "center",
        display: "inline-block",
        cursor: "pointer",
        ...playIconStyle,
      },
    };
  }
  return {
    type: ComponentTypes.VIDEO,
    canHaveChildren: false,
    src: {
      mp4:
        "https://file-examples.com/wp-content/uploads/2017/04/file_example_MP4_480_1_5MG.mp4",
      ogg:
        "https://file-examples.com/wp-content/uploads/2018/04/file_example_OGG_480_1_7mg.ogg",
    },
    playIconStyle,
    properties: {
      textAlign: "center",
      display: "inline-block",
      width: "100%",
      maxWidth: "100%",
      backgroundImage: `url(${thumbnail})`,
      ...style,
    },
  };
};

export enum ImagePlaceholderTypes {
  MOBILE01,
  MOBILE02,
  MOBILE03,
  MOBILE04,
  MOBILE05,
  DESKTOP01,
  DESKTOP02,
  NONE,
}
interface iProps {
  style?: any;
  type: ImagePlaceholderTypes;
}

const imgStyle = {
  width: "100%",
  margin: "0 auto",
  // height: "100%",
};

export const ImagePlaceholder: React.FC<iProps> = ({ style, type }) => {
  return (
    <div
      style={{
        borderRadius: 5,
        ...(type === ImagePlaceholderTypes.NONE ? { height: "100%" } : {}),
        ...style,
        ...(type === ImagePlaceholderTypes.MOBILE02
          ? {
            maxHeight: 450,
            // maxWidth: 1450,
            margin: "0 auto",
            overflowY: "hidden",
          }
          : {}),
      }}
    >
      <>
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.DESKTOP02 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/DESKTOP02.png"
            />
          )}
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.DESKTOP01 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/DESKTOP01.png"
            />
          )}
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.MOBILE01 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE01.webp"
            />
          )}
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.MOBILE02 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE02.png"
            />
          )}
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.MOBILE03 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE03.png"
            />
          )}
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.MOBILE04 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE04.png"
            />
          )}
        {type !== ImagePlaceholderTypes.NONE &&
          type === ImagePlaceholderTypes.MOBILE05 && (
            <Image
              style={imgStyle}
              src="http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE05.png"
            />
          )}
      </>
    </div>
  );
};

interface ImagePlaceholderStyle extends React.CSSProperties {
  mobile?: {
    desktop: React.CSSProperties;
    smartphone: React.CSSProperties;
  };
}

export const getImagePlaceholder = ({
  type = ImagePlaceholderTypes.NONE,
  src,
  style = {},
}: {
  type: ImagePlaceholderTypes;
  src?: string;
  style: ImagePlaceholderStyle;
}) => {
  return {
    type: ComponentTypes.PICTURE,
    ...(type === ImagePlaceholderTypes.NONE
      ? {
        src:
          src ||
          "https://images.unsplash.com/photo-1541990333441-32db7f1571af?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.DESKTOP02
      ? {
        src:
          "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/DESKTOP02.png",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.DESKTOP01
      ? {
        src:
          "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/DESKTOP01.png",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.MOBILE01
      ? {
        src:
          // "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE01.webp",
          "https://cdn.pixabay.com/photo/2018/06/02/00/22/imac-3447560_960_720.jpg",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.MOBILE02
      ? {
        src:
          "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE02.png",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.MOBILE03
      ? {
        src:
          "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE03.png",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.MOBILE04
      ? {
        src:
          "https://www.att.com/catalog/en/idse/Apple/Apple%20iPhone%2011/Purple-hero-zoom.png",
        // src: "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE04.png",
        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
    ...(type === ImagePlaceholderTypes.MOBILE05
      ? {
        // src:
        // "http://ambid-backups.s3.amazonaws.com/screenshots/sandcastle/placeholders/MOBILE05.png",
        src:
          "https://www.androidcentral.com/sites/androidcentral.com/files/topic_images/2014/Cell-Phone-Plans-topic-page-graphic.png",

        properties: { ...imgStyle, ...style, backgroundColor: "transparent" },
      }
      : {}),
  };
};
