// import {
  //   ISettings,
  //   IStates,
  // } from "@launchos/modules/editor/Builder/WebComponent/types";
  
import { IStates, ISettings } from "../Editor/types";
  
  // import { IconList } from "@launchos/components/ui/FontIcon/FontIcon.d";
export type IconList = ""; // References `components/ui/FontIcon/FontIcon.d.ts` (replace this)

export enum EditorMode {
  LIVE = "live",
  EDITOR = "editor",
}

export interface EditorComponentProps {
  hidden: boolean;
  content: "edit";
  pageContent: ISettings[];
  setCanUndo: () => void;
  id: string;
  mode: EditorMode;
  addThisAfterThat: (item, id, callbackFn?: () => void) => void;
  removeItem: (id: string, shoudlDbUpdate?: boolean) => void;
  duplicateItem: (
    id: string,
    doRecursive: boolean,
    shoudlDbUpdate?: boolean,
    callbackFn?: () => void
  ) => void;
  moveThisByThat: (id1: string, id2: string, shoudlDbUpdate?: boolean) => void;
  addHoverCursor: any;
  changeState: (id: string, state: IStates, callbackFn?: () => void) => void;
  state: IStates;
  updateContent: (
    content,
    shoudlDbUpdate?: boolean,
    debounceTimeout?: number,
    callbackFn?: () => void
  ) => void;
  getComponentSettings?: (id?: string) => any;
  updateComponentSettings?: (
    id: string,
    settings: any,
    shoudlDbUpdate?: boolean,
    debounceTimeout?: boolean | number
  ) => void;
  updateComponentStyle?: (
    id: string,
    settings: any,
    shoudlDbUpdate?: boolean,
    debounceTimeout?: boolean | number
  ) => void;
  listAncestors: (content: any, id: any) => false | any[];
  listComponents: (
    content: ISettings[],
    filterOut: any[],
    filterOnly: any[]
  ) => { id: string; icon: IconList; html: string; settings: ISettings };
  setActiveObject: any;
  doUndoRedo: (type: "undo" | "redo") => void;
  undoStackSize: number;
  undoPosition: number;
  setMobileState: (type, dimensions, backgroundImage: boolean) => void;
  getMobileState: () => void;
  setActivePropertyWindows: () => void;
  getActivePropertyWindows: any;
  /**
   * Establishes whether or not dragging is enabled
   */
  setCanDrag: () => any;
  /**
   * Returns whether or not dragging is enabled
   */
  getCanDrag: () => boolean;
  getCampaignQuery?: any;
}

export enum EditorActions {
  CHANGE_STATE,
  CLEAR_ALL_STATES,
  MOVE_THIS_BY_THAT,
  ADD_HOVER_CURSOR,
  REMOVE_ITEM,
  DUPLICATE_ITEM,
  UPDATE_CONTENT,
  UNDO,
  REDO,
}

export enum DragTypes {
  EDITOROBJECT = "Editor",
}

export enum EditorDragMode {
  PIXEL,
  OBJECTS,
  BLOCKS,
}

export interface EditorProps {
  /**
   * Temporary
   */
  children?: any;

  /**
   * The name to use for the Editor component.  Useful for plugins that want to hook into your specific editor vs others
   */
  name: string;

  /**
   * enum(LIVE,EDITOR)
   */
  mode?: EditorMode;

  /**
   * Whether or not the user is able to add new items to the editor
   */
  canAddItems?: Boolean;

  /**
   * Whether or not dragging is enabled
   */
  canDrag?: Boolean;

  /**
   * The type of drag to perform (pixel-by-pixel, object snap, or blocks)
   */
  dragMode?: EditorDragMode;

  /**
   * Whether or not snap guides are enabled while in pixel-by-pixel mode
   */
  snapGuidesAreEnabled?: Boolean;

  /**
   * The initial content to use to populate the editor with objects
   */
  initialContent: any[];

  /**
   * The components that are available for rendering
   */
  components?: any;

  /**
   * The custom component to use for the cursor that shows while dragging
   */
  cursorComponent?: React.ReactNode;

  /**
   * A custom component that will wrap around each editor component
   */
  componentWrapperComponent?: React.ReactNode;

  /**
   * The custom component to use instead of the default editor object component
   */
  editorObjectComponent?: React.ReactNode;

  /**
   * For customizing the default editor object (e.g. colors, etc.)
   */
  editorObjectSettings?: any;

  /**
   * The custom component to use instead of the default drag handle component
   */
  dragHandleComponent?: React.ReactNode;

  /**
   * For customizing the default drag handle (e.g. colors, etc.)
   */
  dragHandleSettings?: any;

  /**
   * The custom component to use for resize handles
   */
  resizeHandleComponent?: React.ReactNode;

  /**
   * For customizing the default resize handle (e.g. colors, etc.)
   */
  resizeHandleSettings?: any;

  /**
   * The props to use for the <ItemLegend /> component
   */
  itemLegendSettings?: any;

  /**
   * Whether or not the <ItemLegend /> component should render
   */
  itemLegendIsVisible?: Boolean;

  /**
   * Replaces the default <ItemLegend /> component
   */
  itemLegendComponent?: React.ReactNode;

  /**
   * The current undo stack
   */
  undoStack?: any[];

  /**
   * The current position in the undo stack
   */
  undoPosition?: number;

  /**
   * Whether or not the editor is in read only mode
   */
  readOnly?: Boolean;

  /**
   * The current zoom of the editor
   */
  zoom?: number;

  /**
   * Triggers when a new item is added to the editor
   */
  onAdd?: () => void;

  /**
   * Triggers when an item is removed from the editor
   */
  onRemove?: () => void;

  /**
   * Triggers when an item is duplicated in the editor
   */
  onDuplicate?: () => void;

  /**
   * Triggers when an item is moved in the editor
   */
  onMove?: () => void;

  /**
   * Triggers when the state of an item changes in the editor
   */
  onStateChange?: () => void;

  /**
   * Triggers when an item in the editor changes in some way
   */
  onChange?: () => void;

  /**
   * Triggers when a key is pressed in the editor ~ to handle delete requests, pixel nudging, etc.
   */
  onKeyUp?: () => void;

  /**
   * Triggers when an item is selected in the editor
   */
  onSelect?: () => void;

  /**
   * Triggers when an undo action is supposed to happen
   */
  onUndo?: () => void;

  /**
   * Triggers when redo action is supposed to happen
   */
  onRedo?: () => void;
}

export const CURSOR_ID: string | number = 1111;
export const BODY: string | number = 2540;
