import { ComponentRenderHooks } from "@/plugins/types";
import EditorPopup, { Properties } from "./Popup";

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Popup") {
    return [EditorPopup];
  }
  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Popup") {
    return Properties(payload);
  }
};
