import { ComponentRenderHooks } from "@/plugins/types";
import LiveColumn from "./component";
import WebComponentWrapper from "./WebComponentWrapper";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.COLUMN
  ) {
    return [LiveColumn];
  }
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT_WRAPPER &&
    payload.type === ComponentTypes.COLUMN
  ) {
    return [WebComponentWrapper];
  }
};
