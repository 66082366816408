import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.SIX,
  layout: ContentLayouts.SIX,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content06.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckky7r33x28f507806a3vy0ss",
    itemId: "nyk95olmbtc",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.ITEM_HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Step 1",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: get(content, "sub_headline.html", "..."),
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: {
                  ...style.BUTTON.style,
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.backgroundColor}`,
                },
                caption1: "Get Started",
                caption1style: {
                  ...style.BUTTON.caption1,
                  color: theme.backgroundColor,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.ITEM_HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Step 2",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: get(content, "sub_headline.html", "..."),
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: {
                  ...style.BUTTON.style,
                },
                caption1: "Get Started",
                caption1style: {
                  ...style.BUTTON.caption1,
                },
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((4 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.HEADLINE,
                canHaveChildren: false,
                properties: {
                  ...style.ITEM_HEADLINE,
                  ...get(content, "main_headline.style", {}),
                },
                html: "Step 3",
              },
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  ...get(content, "sub_headline.style", {}),
                },
                html: get(content, "sub_headline.html", "..."),
              },
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: {
                  ...style.BUTTON.style,
                  backgroundColor: "transparent",
                  border: `1px solid ${theme.backgroundColor}`,
                },
                caption1: "Get Started",
                caption1style: {
                  ...style.BUTTON.caption1,
                  color: theme.backgroundColor,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
