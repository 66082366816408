import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { NavigationLayouts } from "../types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@/Apps/Pages/WebComponents/v2/Navigation/types";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: NavigationLayouts.THREE,
  layout: NavigationLayouts.THREE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/navigation/Navigation03.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzm1kez2ie007803ieamizj",
    itemId: "owm8mvlnr1",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((3 * 100) / 12),
            properties: {
              height: "100%",
              mobile: {
                desktop: {
                  padding: 20,
                },
                smartphone: {
                  padding: 5,
                },
              },
              minHeight: 20,
            },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src: theme.logoIcon,
                style: {
                  width: 75,
                  display: "inline-block",
                  mobile: {
                    desktop: {
                      textAlign: "left",
                    },
                    smartphone: {
                      textAlign: "center",
                    },
                  },
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: {
              height: "100%",
              mobile: {
                desktop: {
                  padding: 20,
                },
                smartphone: {
                  padding: 5,
                },
              },
              minHeight: 20,
            },
            children: [
              {
                type: ComponentTypes.NAVIGATION,
                canHaveChildren: false,
                linkStyle: style.NAVIGATION.linkStyle,
                layoutStyle: LinkLayoutStyles.HORIZONTAL,
                properties: {
                  padding: 20,
                  textAlign: "center",
                  display: "inline-block",
                },
                data: [
                  {
                    id: uniqueId(),
                    caption: "Home",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                  {
                    id: uniqueId(),
                    caption: "Features",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                  {
                    id: uniqueId(),
                    caption: "Blog",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                  {
                    id: uniqueId(),
                    caption: "About Us",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                  {
                    id: uniqueId(),
                    caption: "Contact Us",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((3 * 100) / 12),
            properties: {
              height: "100%",
              mobile: {
                desktop: {
                  padding: 20,
                },
                smartphone: {
                  padding: 5,
                },
              },
              minHeight: 20,
            },
            children: [
              {
                type: ComponentTypes.LISTITEM,
                canHaveChildren: false,
                properties: style.LI.style,
                html: `My Account`,
                icon: IconTypes.AccountCircle,
                iconStyle: style.LI.iconStyle,
                contentStyle: { padding: "15px 0" },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
