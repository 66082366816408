import { agent, collection } from "../objects";
import { getNodeMenuLabelComponent } from "../CampaignBuilder/Node/Node";
import { getNodeIconComponent } from "../CampaignBuilder/Node/NodeIcon";
import { createObject, handleCreateNewPageFromTemplate } from "@/api/campaigns";

// import { workflowNodes } from "@/Apps/Workflows/nodes/workflowNodes";

export const agentNodes = ({ position, campaignData, nodes, setNodes }) => [

  ...agent.map((itm) => ({
    label: getNodeMenuLabelComponent(itm.name),
    icon: getNodeIconComponent({
      Icon: itm?.icon,
      color: itm?.iconColor,
      iconStyle: { fontSize: "18pt" },
      containerStyle: { padding: "5px 10px" },
    }),
    onClick: async () => {
      const { data } = await createObject({
        name: itm.name,
        x: parseInt(position.x),
        y: parseInt(position.y),
        campaignId: campaignData.id,
        screenshot: "",
        type: itm.type,
        settings: { type: itm.id },
      });

      const newObjectId = data?.createObject?.id;

      const newNode = {
        id: newObjectId,
        data: {
          id: newObjectId,
          x: parseInt(position.x),
          y: parseInt(position.y),
          label: itm.name,
          // page: newPageData,
          page: {}
        },
        position,
        width: 130,
        height: 90,
        sourcePosition: "right",
        targetPosition: "left",
        type: "defaultNode",
      };

      setNodes([...nodes, newNode]);
    }
  })),
  // {
  //   label: "Internal Sources",
  //   items: collection
  //     .filter(({ group }) => group !== "filter")
  //     .map((page) => {
  //       return {
  //         label: getNodeMenuLabelComponent(page.name),
  //         icon: getNodeIconComponent({
  //           Icon: page?.icon,
  //           color: page?.iconColor,
  //           iconStyle: { fontSize: "18pt" },
  //           containerStyle: { padding: "5px 10px" },
  //         }),
  //         onClick: () => {
  //           handleCreateNewPageFromTemplate({
  //             position,
  //             campaignData,
  //             page: {
  //               ...page,
  //               settings: { type: page?.name },
  //             },
  //             setNodes,
  //             nodes,
  //           });
  //         },
  //       };
  //     }),
  // },
  // {
  //   label: "Join & Filter",
  //   items: collection
  //     .filter(({ group }) => group === "filter")
  //     .map((page) => {
  //       return {
  //         label: getNodeMenuLabelComponent(page.name),
  //         icon: getNodeIconComponent({
  //           Icon: page?.icon,
  //           color: page?.iconColor,
  //           iconStyle: { fontSize: "18pt" },
  //           containerStyle: { padding: "5px 10px" },
  //         }),
  //         onClick: () => {
  //           handleCreateNewPageFromTemplate({
  //             position,
  //             campaignData,
  //             page: {
  //               ...page,
  //               settings: { type: page?.name },
  //             },
  //             setNodes,
  //             nodes,
  //           });
  //         },
  //       };
  //     }),
  // },
  // ...workflowNodes({ position, campaignData, nodes, setNodes }),
];
