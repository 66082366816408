import * as React from "react";
import { get, uniq } from "lodash";

// import { RichEditor } from "@launchos/modules/editor/Builder/Properties";
// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
// import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
// import { updateSection } from "@launchos/modules/v2/Properties/components/generator";
// import { SectionTypes } from "@launchos/modules/v2/Properties/types";

// import {
//   generateInlineStyle,
//   generateGoogleFontList,
// } from "@launchos/modules/editor/Builder/Properties/RichEditor/utilities";

// import { IconTypes } from "../Icon";

import { OrderBump } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import listItemSettings from "./settings";
import { RichEditor } from "@/Apps/Pages/Properties";
import EditorObject from "@/Apps/Pages/EditorObject";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { generateGoogleFontList, generateInlineStyle } from "@/Apps/Pages/Properties/RichEditor/utilities";
import { parseLiquidString } from "@/Apps/Pages/Page/LiquidRenderer";

export const Properties = (props) => {
  const { updateComponentSettings, updateComponentStyle, settings } = props;
  const { properties } = listItemSettings;
  const { sections } = properties.main;

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections },
  };

  return (
    <PropertiesBuilder
      {...props}
      title="Order Bump Settings"
      data={updatedProperties}
    />
  );
};

export const OrderBumpComponent: React.FC<PluginProps> = (props) => {
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  const { id, mode, settings, match, updateComponentSettings, setIsEditing, setCanDrag, setCanUndo, changeState, pageContent } = props;
  const properties = convertProperties(settings.properties);
  // const buttonList = ["bold", "italic", "underline"];

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["lineheight"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link", "selectall"],
  ];
  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  const handleChange = (html, fontsList) => {
    const currentFontList = get(
      settings,
      "fontsUsed",
      []
    ).map((arr) => get(arr, "fontFamily"));
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({
      fontFamily: font,
    }));
    updateComponentSettings(settings.id, {
      ...settings,
      html,
      fontsUsed,
    });
  }


  return (
    <>
      <V1ObjectWrapper settings={settings}>
        <EditorObject
          {...props}
          PropertiesView={Properties}
          dragHandle={dragHandleIsShowing}
          label="Order Bump"
        >
          <OrderBump {...settings} properties={properties} mode={mode}>
            <RichEditor
              id={`ed_${settings.id}`}
              content={parseLiquidString(settings.html)}
              style={generateInlineStyle(settings)}
              buttonList={buttonList}
              menuList={menuList}
              googleFontList={googleFontList}
              onChange={handleChange}
              onInstantChange={() => setDragHandleIsShowing(false)}
              onClick={() => {
                setDragHandleIsShowing(true);
              }}
              onMouseEnter={() => {
                setDragHandleIsShowing(true);
              }}
              onFocus={(e) => {
                setIsEditing(true);
                setCanDrag(false);
                changeState(settings.id, "active");
                setCanUndo(false);
              }}
              onBlur={(e) => {
                setDragHandleIsShowing(true);
                setCanUndo(true);
                setTimeout(() => {
                  setIsEditing(false);
                  setCanDrag(true);
                }, 200);
              }}
            />
          </OrderBump>
        </EditorObject>
      </V1ObjectWrapper>
    </>
  );
};
