// import React from "react";
import {
  Background,
  Section,
  Padding,
  BorderShadow, // @Launch OS -- confirm this works because I changed the spelling
  PropertiesWindow,
} from "@/Apps/Pages/Properties";

const BasicPropertiesTab = (props) => (
  <>  
    <Background
      {...props}
      accept="image/jpeg, image/png, video/mp4, video/ogg"
    />
    <Section label="Padding" icon="import_export" isExpanded>
      <Padding {...props} />
    </Section>
    <BorderShadow {...props} hideBorderRadius hideShadow />
  </>
);

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Column", component: BasicPropertiesTab }]}
  />
);
