import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";


import { get, startCase, uniqueId } from "lodash";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

import { BenefitLayouts } from "../types";
import theme from "../../theme";

import { combineData, lorem } from "../../utils";
import { style } from "./style";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon";


import { default as defaultData } from "./data.json";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
const content = combineData(defaultData);

// console.log(
//   "imagePlaceholder",
//   getImagePlaceholder({
//     type: ImagePlaceholderTypes.NONE,
//     style: {
//       height: "100%",
//       backgroundColor: theme.backgroundColor,
//     },
//   })
// );

const settings: ILayoutSettings = {
  label: BenefitLayouts.ONE,
  layout: BenefitLayouts.ONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit01.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkwzmhha20mu0780yzhnmh2r",
    itemId: "u5j1r64m5rf",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: true,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            properties: {
              height: "100%",
              padding: 0,
              minHeight: 20,
            },
            md: 50,
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 50,
                  mobile: {
                    desktop: { padding: 50 },
                    smartphone: { padding: 20 },
                  },
                },
                children: [
                  getImagePlaceholder({
                    type: ImagePlaceholderTypes.NONE,
                    src:
                      "https://images.unsplash.com/photo-1547037579-f0fc020ac3be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
                    style: {
                      height: "100%",
                      backgroundColor: theme.backgroundColor,
                    },
                  }),
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            properties: {
              height: "100%",
              padding: 0,
              minHeight: 20,
            },
            md: 50,
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: true,
                properties: {
                  padding: 50,
                  mobile: {
                    desktop: { padding: 50 },
                    smartphone: { padding: 20 },
                  },
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: {
                      ...style.TEXT,
                    },
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: {
                      ...style.HEADLINE,
                    },
                    html: get(content, "main_headline.html", "..."),
                  },
                  {
                    type: ComponentTypes.COLUMNS,
                    canHaveChildren: true,
                    properties: {},
                    children: [
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        properties: {},
                        md: 50,
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              backgroundColor: "transparent",
                            },
                            caption1: "Learn More",
                            caption1style: {
                              ...style.BUTTON.caption1,
                              color: theme.backgroundColor,
                            },
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMN,
                        canHaveChildren: true,
                        preventDelete: true,
                        properties: {},
                        md: 50,
                        children: [
                          {
                            type: ComponentTypes.BUTTON,
                            canHaveChildren: false,
                            properties: {
                              ...style.BUTTON.style,
                              border: `1px solid ${theme.backgroundColor}`,
                            },
                            caption1: "Get Started",
                            caption1style: style.BUTTON.caption1,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
