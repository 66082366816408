import { ComponentRenderHooks } from "@/plugins/types";
import EditorHeadline, { Properties, SimpleHeadlineProperties } from "./Headline";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === "Headline") {
    return [EditorHeadline];
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Headline") {
    return Properties(payload);
  }

  if (hook.id === ComponentRenderHooks.SIMPLE_PROPERTIES && hook.type === "Headline") {
    return SimpleHeadlineProperties(payload);
  }
};
