import { createTheme } from "@material-ui/core/styles";

export const muiThemeDark = createTheme({
  palette: {
    type: "dark",
    background: {
      paper: "#111", // Darker background for menus
    },
    text: {
      primary: "#e0e0e0", // Softer text color
      secondary: "#b0b0b0", // Even softer for secondary text
    },
  },
  overrides: {
    MuiMenu: {
      paper: {
        boxShadow: "0 2px 2px rgba(255, 255, 255, 0.11)",
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: "normal", // Set font weight to normal
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.08)", // Softer hover effect
        },
      },
    },
  },
});

export const muiThemeLight = createTheme({
  palette: { type: "light" },
  overrides: {
    MuiMenu: {
      paper: {
        boxShadow: "0 2px 2px rgba(0, 0, 0, 0.11)",
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: "normal", // Set font weight to normal
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)", // Softer hover effect
        },
      },
    },
  },
});

export const theme: {
  primaryColor: string;
  secondaryColor: string;
  disabledColor: string;
  tertiaryColor: string;
  darkColor: string;
  lightColor: string;
  altColors: string[];
  logoIcon: string;
} = {
  primaryColor: "#0082d2",
  secondaryColor: "#f14b59",
  disabledColor: "#5b5b5b",
  tertiaryColor: "#169e7d",
  darkColor: "#222",
  lightColor: "#ddd",
  altColors: [
    "#f26e81",
    "#ff5a4e",
    "#bfcd6b",
    "#80638e",
    "#77c7e5",
    "#ffaa9c",
    "#f8d46d",
    "#666666",
    "#6be1c3",
    "#f87759",
    "#9d79b4",
  ],
  logoIcon: "https://s3.amazonaws.com/sandcastleassets/images/logo_icon.png",
};

export const fieldset = {
  padding: "12px 0",
};

export default theme;
