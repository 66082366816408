import * as React from "react";
import styled from "styled-components";
import { get } from "lodash";

import { FileUploadProps } from "../types";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { AttributeContainer } from "@/ui/CRUD/AttributeBuilder";
import { AttributeNames } from "@/ui/CRUD/AttributeBuilder/types";
import { LiveFormFieldProps } from "../../LiveFormField/types";
import { LiveFileUpload } from "./LiveFileUpload";

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const {
    mode = EditorMode.LIVE,
    onChange = () => null,
    styles = {
      labelStyle: {},
      descriptionStyle: {},
      inputStyle: {},
      containerStyle: {},
      validationStyle: {},
      iconStyle: {},
    },
    name,
    label,
    description,
    defaultValue,
    disabled,
    icon,
    columns = 12,
    attributeContainerDataSettings = {},
  } = props;

  /**
   * Deals with any changes that comes from the various attributes that are loaded
   *
   * - Converts to LiveFormFieldProps shape
   * - Triggers (and passes along the mutated LiveFormFieldProps payload to) the onChange event
   */
  const handleChange = (data: LiveFormFieldProps): void => {
    onChange(data);
  };

  const {
    iconStyle = {},
    descriptionStyle = {},
    containerStyle = {},
    inputStyle = {},
    validationStyle = {},
    labelStyle = {},
  } = styles;
  return (
    <div data-testid="FormBuilder-FormFields-FileUpload" style={containerStyle}>
      {mode === EditorMode.LIVE && (
        <LiveFileUpload label={label} styles={styles} columns={columns} description={description}></LiveFileUpload>
      )}
      {mode === EditorMode.EDITOR && (
        <div data-testid="FormBuilder-FormFields-FileUpload-EDITOR">
          <AttributeContainer
            onChange={handleChange}
            attributes={props}
            data={[
              {
                id: "label",
                attribute: AttributeNames.LABEL,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "description",
                attribute: AttributeNames.DESCRIPTION,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "name",
                attribute: AttributeNames.FORMITEMNAME,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "columns",
                attribute: AttributeNames.COLUMNS,
                settings: {
                  hide: true,
                  ...attributeContainerDataSettings,
                },
              },
              {
                id: "required",
                attribute: AttributeNames.REQUIRED,
                settings: {},
              },
            ]}
          ></AttributeContainer>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
