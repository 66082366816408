import { ComponentRenderHooks, ListItemsHooks } from "@/plugins/types";
// import { ComponentTypes } from "../../types";
// import { OrderBumpComponent } from "./component";
import settings from "./settings";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { OrderBumpComponent, Properties } from "./component";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }

  if (hook.id === ListItemsHooks.PROPERTIES && hook.type === ComponentTypes.ORDERBUMP) {
    return settings.properties;
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ORDERBUMP
  ) {
    return [OrderBumpComponent];
  }

  if (
    hook.id === ComponentRenderHooks.PROPERTIES &&
    hook.type === ComponentTypes.ORDERBUMP
  ) {
    return Properties(payload);
  }
};
