import theme from "@/ui/theme";
import {
  ArrowsSplit,
  Article,
  At,
  Basket,
  Broadcast,
  Calendar,
  ChartLineUp,
  CreditCard,
  CurrencyCircleDollar,
  Envelope,
  EnvelopeSimple,
  FacebookLogo,
  FileCsv,
  Funnel,
  Gavel,
  HandsPraying,
  HourglassMedium,
  House,
  InstagramLogo,
  Intersect,
  LinkedinLogo,
  Megaphone,
  NewspaperClipping,
  NoteBlank,
  PenNib,
  PersonSimple,
  Play,
  Robot,
  RocketLaunch,
  ShoppingBagOpen,
  ShoppingCart,
  Sigma,
  Stop,
  Tag,
  TwitterLogo,
  UserList,
  UserPlus,
  VideoCamera,
  WebhooksLogo,
} from "@phosphor-icons/react";

export const pages = [
  {
    type: "PageComponent",
    id: "blank_page",
    name: "Blank Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_blank.jpg`,
    pageType: "blank",
    icon: NoteBlank,
    iconColor: "#760344",
  },
  {
    type: "PageComponent",
    id: "content_page",
    name: "Content Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_article.jpg`,
    pageType: "content",
    icon: Article,
    iconColor: "#0099cb",
  },
  {
    type: "PageComponent",
    id: "home_page",
    name: "Home Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_about.jpg`,
    pageType: "home",
    icon: House,
    iconColor: "#760344",
  },
  {
    type: "PageComponent",
    id: "content_page",
    name: "Launch Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_product.jpg`,
    pageType: "launch",
    icon: RocketLaunch,
    iconColor: "#0099cb",
  },
  // {
  //   type: "PageComponent",
  //   id: "content_page",
  //   name: "Product View Page",
  //   screenshot: `${
  //     settings.app.assetsLocation
  //   }/images/icons/pages/NT_text.png`,
  //   pageType: "products"
  // },
  {
    type: "PageComponent",
    id: "lead_page",
    name: "Lead Capture Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_squeeze_email.jpg`,
    pageType: "lead",
    icon: At,
    iconColor: "#ec8622",
    // iconColor: "rgb(255, 0, 0)",
  },
  {
    type: "PageComponent",
    id: "legal_page",
    name: "Legal Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_misc_terms.jpg`,
    pageType: "legal",
    icon: Gavel,
    iconColor: "#760344",
  },
  {
    type: "PageComponent",
    id: "live_event_page",
    name: "Live Event Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_content_video.jpg`,
    pageType: "event",
    icon: VideoCamera,
    iconColor: "#0099cb",
  },
  {
    type: "PageComponent",
    id: "precart_checkout",
    name: "Pre-cart Checkout Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_precart.jpg`,
    pageType: "checkout",
    icon: Basket,
    iconColor: "#c0b937",
  },
  {
    type: "PageComponent",
    id: "order_page",
    name: "Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_longform.jpg`,
    pageType: "order",
    icon: CreditCard,
    iconColor: "#c0b937",
  },
  {
    type: "PageComponent",
    id: "sales_page",
    name: "Sales Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales.jpg`,
    pageType: "sales",
    icon: CurrencyCircleDollar,
    iconColor: "#c0b937",
  },
  {
    type: "PageComponent",
    id: "sales_page",
    name: "Special Offer (Upsell)",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_sales_bonus.jpg`,
    pageType: "oto",
    icon: ShoppingBagOpen,
    iconColor: "#c0b937",
  },
  {
    type: "PageComponent",
    id: "thankyou_page",
    name: "Thank You Page",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_ty_simple.jpg`,
    pageType: "thank you",
    icon: HandsPraying,
    iconColor: "#febf34",
  },
  {
    type: "PageComponent",
    id: "webinar_page",
    name: "Event (Webinar) Registration",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_squeeze_webinar.jpg`,
    pageType: "webinar",
    icon: Broadcast,
    iconColor: "#ec8622",
  },
  {
    type: "addon",
    id: "ad_optin_popup",
    name: "Opt-in Popup",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_popup_optin.png`,
  },
  {
    type: "cart",
    id: "shopping_cart_1shoppingcart",
    name: "1ShoppingCart Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_1shoppingcart.png`,
    iconColor: "#427abb",
  },
  {
    type: "cart",
    id: "shopping_cart_amazon",
    name: "Amazon Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_amazon.png`,
    iconColor: "#f4971e",
  },
  {
    type: "cart",
    id: "shopping_cart_clickbank",
    name: "ClickBank Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_clickbank.png`,
    iconColor: "#b22e2e",
  },
  {
    type: "cart",
    id: "shopping_cart_infusionsoft",
    name: "Keap Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_infusionsoft.png`,
    iconColor: "#87b850",
  },
  {
    type: "cart",
    id: "shopping_cart_jvzoo",
    name: "JVZoo Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_jvzoo.png`,
    iconColor: "#e56c2b",
  },
  {
    type: "cart",
    id: "shopping_cart_nanacast",
    name: "Nanacast Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_nanacast.png`,
    iconColor: "#33afd9",
  },
  {
    type: "cart",
    id: "shopping_cart_paypal",
    name: "PayPal Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_paypal.png`,
    iconColor: "#204882",
  },
  {
    type: "cart",
    id: "shopping_cart_samcart",
    name: "SamCart Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_samcart.png`,
    iconColor: "#2397cf",
  },
  {
    type: "cart",
    id: "shopping_cart_shopify",
    name: "Shopify Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_shopify.png`,
    iconColor: "#a2ca58",
  },
  {
    type: "cart",
    id: "shopping_cart_ultracart",
    name: "Ultracart Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_ultracart.png`,
    iconColor: "#333333",
  },
  {
    type: "cart",
    id: "shopping_cart_zaxaa",
    name: "Zaxaa Order Form",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_zaxaa.png`,
    iconColor: "#50b8a1",
  },
  {
    type: "cart",
    id: "shopping_cart_another",
    name: '"Another" Shoping Cart',
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/PT_cart_another.png`,
    icon: ShoppingCart,
    iconColor: "#aaaaaa",
  },
];

export const traffic = [
  {
    type: "TrackingSource",
    id: "traffic_source_facebook",
    name: "Facebook Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_facebook.png`,
    // icon: FacebookLogo,
    iconColor: "#547bbcff",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_adwords",
    name: "Google Adwords",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_adwords.png`,
    iconColor: "#4c7bbe",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_email",
    name: "Email Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_email.png`,
    iconColor: "#fac766",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_blog",
    name: "Blog Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_blog.png`,
    iconColor: "#8c589f",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_twitter",
    name: "Twitter Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_twitter.png`,
    iconColor: "#78cbef",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_youtube",
    name: "YouTube Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_youtube.png`,
    iconColor: "#ce4646",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_webinar",
    name: "Webinar Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_webinar.png`,
    iconColor: "#adce74",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_affiliate",
    name: "Affiliate Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_affiliate.png`,
    iconColor: "#8ccfdd",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_other",
    name: '"Other" Traffic',
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_other.png`,
    iconColor: "#d87da5",
    width: 150,
    height: 100,
  },
  {
    type: "TrackingSource",
    id: "traffic_source_organic",
    name: "Organic Traffic",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/TS_organic.png`,
    iconColor: "#ef8a52",
    width: 150,
    height: 100,
  },
  {
    type: "RedirectComponent",
    id: "traffic_source_redirect",
    description:
      "Creates a link that will automatically redirect its visitors to a destination url that you specify",
    name: "Route to URL",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_pageredirect.png`,
    iconColor: "#b08abe",
    width: 150,
    height: 100,
  },
  {
    type: "SmartRouteComponent",
    id: "traffic_source_facebook", // ??
    name: "Smart Route",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_conditionalredirect.png`,
    iconColor: "#b08abe",
    width: 150,
    height: 100,
  },
  {
    type: "ABSplitComponent",
    name: "AB Split Testing",
    description:
      "Creates a link that randomly redirects its visitors between two or more destinations",
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_splittesting.png`,
    iconColor: "#b08abe",
    width: 150,
    height: 100,
  },
  {
    type: "AnotherFunnelComponent",
    name: '"Another" Funnel',
    screenshot: `https://s3.amazonaws.com/sandcastleassets/images/icons/pages/AC_anotherfunnel.png`,
    description:
      "Creates a link that automatically routes its visitors to a page in another funnel",
    iconColor: "#8ccfdd",
    width: 150,
    height: 100,
  },
];

export const content = [
  { type: "ContentComponent", id: "blog_post", name: "Blog Post", iconColor: theme.altColors[7], icon: Article },
  { type: "ContentComponent", id: "facebook_post", name: "Facebook Post", iconColor: "#316FF6", icon: FacebookLogo },
  { type: "ContentComponent", id: "twitterX_post", name: "Twitter Post", iconColor: "#1DA1F2", icon: TwitterLogo },
  { type: "ContentComponent", id: "instagram_post", name: "Instagram Post", iconColor: "#C13584", icon: InstagramLogo },
  { type: "ContentComponent", id: "linkedIn_post", name: "LinkedIn Post", iconColor: "#0077b5", icon: LinkedinLogo },
  { type: "ContentComponent", id: "advertisement", name: "Advertisement", iconColor: theme.altColors[4], icon: Megaphone },
  { type: "ContentComponent", id: "email_content", name: "Email", iconColor: theme.altColors[1], icon: Envelope },
  { type: "ContentComponent", id: "content_post", name: "Content Post", iconColor: theme.altColors[7], icon: NewspaperClipping },
];

export const workflow = [
  {
    icon: Play,
    iconColor: theme.altColors[0],
    type: "Trigger",
    name: "External Event",
    id: "external_event",
    direction: "vertical",
  },
  {
    icon: EnvelopeSimple,
    iconColor: theme.altColors[0],
    type: "Task",
    name: "Send Email",
    id: "send_email",
    direction: "vertical",
  },
  {
    icon: Tag,
    iconColor: theme.altColors[1],
    type: "Trigger",
    name: "Tag",
    id: "tag",
    direction: "vertical",
  },
  {
    icon: UserPlus,
    iconColor: theme.altColors[2],
    type: "Trigger",
    name: "New Workflow Contact",
    id: "new_contact",
    direction: "vertical",
  },
  {
    icon: WebhooksLogo,
    iconColor: theme.altColors[3],
    type: "Task",
    name: "Trigger Webhook",
    id: "trigger_webhook",
    direction: "vertical",
  },
  {
    icon: Calendar,
    iconColor: "#8ccfdd",
    type: "Trigger",
    name: "Date",
    id: "date",
    direction: "vertical",
  },
  {
    icon: HourglassMedium,
    iconColor: theme.altColors[4],
    type: "Task",
    name: "Wait",
    id: "wait",
    direction: "vertical",
  },
];

export const collection = [
  { type: "CollectionComponent", id: "crm", group: "source", name: "My Contacts", iconColor: theme.altColors[2], icon: UserList },
  { type: "CollectionComponent", id: "analytics", group: "source", name: "Analytics", iconColor: theme.altColors[6], icon: ChartLineUp },
  { type: "CollectionComponent", id: "content", group: "source", name: "My Content", iconColor: theme.altColors[3], icon: PenNib },
  { type: "CollectionComponent", id: "csv", group: "source", name: "Imported Data", iconColor: theme.altColors[7], icon: FileCsv },
  { type: "CollectionComponent", id: "join", group: "filter", name: "Join Data", iconColor: theme.altColors[5], icon: Intersect },
  { type: "CollectionComponent", id: "split", group: "filter", name: "Split Data", iconColor: theme.altColors[3], icon: ArrowsSplit },
  { type: "CollectionComponent", id: "filter", group: "filter", name: "Filter Data", iconColor: theme.altColors[4], icon: Funnel },
  { type: "CollectionComponent", id: "aggregate", group: "filter", name: "Aggregate", iconColor: theme.altColors[1], icon: Sigma },
];

export const agent = [
  { type: "ChatComponentStart", id: "start", name: "Start", iconColor: theme.altColors[7], icon: Play },
  { type: "ChatComponentText", id: "text", name: "Generate Text", iconColor: theme.altColors[3], icon: Robot },
  { type: "ChatComponentEnd", id: "end", name: "End", iconColor: theme.altColors[7], icon: Stop },
]

export default {
  pages, traffic, content, workflow, collection, agent
}