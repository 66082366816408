import { PaymentElement, AddressElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useEffect,
  useState,
  createContext,
  useContext,
  ReactNode,
  FC,
} from "react";
import {
  getInputVals,
  getInputValue,
} from "@/Apps/Pages/Page/scripts/getInputValues";
import settings from "@/Apps/Pages/Page/config";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ComponentRenderHooks } from "@/plugins/types";

const StripeContext = createContext(false);
export const useStripeContext = () => useContext(StripeContext);

const usePaymentInputs = () => {
  const [mCredentials, setMCredentials] = useState<string | null>(null);

  useEffect(() => {
    const checkInputs = () => {
      const inputVals = getInputVals() || {};
      const credentials = getInputValue("pmP", inputVals);
      if (credentials && credentials!== "{}") {
        setMCredentials(credentials);
        return true;
      }
      return false;
    };

    // Initial check
    if (checkInputs()) return;

    // Watch for DOM changes
    const observer = new MutationObserver(() => {
      checkInputs();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["value"],
    });

    return () => observer.disconnect();
  }, []);

  return mCredentials;
};

export const StripeElementWrapper: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [stripePromise, setStripePromise] = useState<ReturnType<
    typeof loadStripe
  > | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const mCredentials = usePaymentInputs();
  const { serverUrl } = settings;

  useEffect(() => {
    if (!mCredentials) return;

    console.log("StripePaymentWrapper", { mCredentials })

    // Fetch publish key
    const fetchPublishKey = async () => {
      try {
        const response = await fetch(`${serverUrl}/api/stripeConfig`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ mCredentials }),
        });

        if (!response.ok) throw new Error("Failed to fetch publish key");
        const { publishKey } = await response.json();
        setStripePromise(await loadStripe(publishKey));
      } catch (error) {
        console.error("Stripe publish key error:", error);
      }
    };

    // Create payment intent
    const createPaymentIntent = async () => {
      try {
        const response = await fetch(`${serverUrl}/api/stripePaymentIntent`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ mCredentials }),
        });

        if (!response.ok) throw new Error("Failed to create payment intent");
        const { clientSecret, paymentIntentId, customerId } = await response.json();
        setClientSecret(clientSecret);

        // Store important identifiers in localStorage for next page

        const isUpsell = document.querySelector('button[type="TRIGGER_UPSELL"]') !== null;
        console.log({ isUpsell });
        if (!isUpsell) { // don't update the session if this is an upsell page
          localStorage.setItem("paymentIntentId", paymentIntentId);
          localStorage.setItem("customerId", customerId);
        }

      } catch (error) {
        console.error("Payment intent error:", error);
      }
    };

    fetchPublishKey();
    createPaymentIntent();
  }, [mCredentials]);

  const isReady = Boolean(clientSecret && stripePromise && mCredentials);

  return (
    <StripeContext.Provider value={isReady}>
      {isReady ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          {children}
        </Elements>
      ) : (
        children
      )}
    </StripeContext.Provider>
  );
};

export const StripePaymentElement = () => {
  const isInStripeContext = useStripeContext();

  // const stripe = isInStripeContext ? useStripe() : {};
  // const elements = isInStripeContext ? useElements() : {};

  return isInStripeContext 
  ? <PaymentElement id="payment-element" />
  : <div>Loading...</div>;
};

export const StripeAddressElement = () => {
  const isInStripeContext = useStripeContext();

  // const stripe = isInStripeContext ? useStripe() : {};
  // const elements = isInStripeContext ? useElements() : {};

  return isInStripeContext 
  ? <AddressElement options={{ mode: "billing" }} />
  : <div>Loading...</div>;
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.PAYMENT
  ) {
    return [StripePaymentElement];
  }

  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ADDRESS
  ) {
    return [StripeAddressElement];
  }
};

export default StripePaymentElement;
