// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
// import theme from "@launchos/components/ui/theme";
// import { IComponentSettings, ComponentTypes } from "../../types";

import { ComponentTypes, IComponentSettings } from "@/Apps/Pages/Editor/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import theme from "@/ui/theme";

const settings: IComponentSettings = {
  id: ComponentTypes.SPACER,
  name: "Spacer",
  description: "",
  thumbnail: {
    style: {
      background:
        theme.altColors[Math.floor(Math.random() * theme.altColors.length)],
      color: `rgb(255,255,255,0.85)`,
      fontFamily: "Roboto",
      fontSize: "8pt",
      textAlign: "center",
      paddingTop: 15,
    },
    icon: "space_bar",
  },
  default: {
    type: ComponentTypes.SPACER,
    properties: {
      height: 40,
    },
  },
  properties: {
    main: {
      sections: [
        {
          type: SectionTypes.HEIGHT,
          settings: {
            label: "Spacer Size",
            text: "Size",
            icon: "height",
            isExpanded: true,
            showAutoCheck: false,
          },
          sectionWrap: true,
        },
      ],
    },
  },
};

export default settings;
