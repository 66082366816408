// Components
import * as Section from "../v2/Section/plugin";
// // import * as banner from "./banner";
import * as body from "./body";
import * as button from "./button";
// // import * as carousel from "./carousel";
import * as columns from "./columns";
import * as column from "./column";
import * as container from "./container";
// // import * as countdown from "./countdown";
import * as cursor from "./cursor";
// // import * as divider from "./divider";
import * as formField from "./formField";
import * as headline from "./headline";
// // import * as list from "./list";
// // import * as navigation from "./navigation";
import * as picture from "./picture";
import * as popup from "./popup";
// // import * as progress from "./progress";
// import * as social from "./social";
import * as text from "./text";
import * as video from "./video";

export default [
  Section,
  cursor,
  body,
  text,
  headline,
  button,
  container,
  columns,
  column,
  picture,
  video,
  formField,
  popup,
//   social,
];
