import { useContext, useEffect, useState } from "react";
import moment from "moment";

import { Page, Container } from "@/ui/Layout/Page";
import { Grid } from "@/ui/Grid";
import { Card } from "@/ui/Card";
import { cloneCampaign, getCampaigns, updateCampaign } from "@/api/campaigns";
import { ButtonTypes, Status } from "@/ui/types";
import { getSessionToken } from "@/api/auth";
import { Link, useNavigate } from "react-router-dom";
import Button from "@/ui/Button";
import { EmptyState } from "@/ui/Layout";
import { Loading } from "@/ui/Layout/Loading";
import { AppContext } from "@/AppContext";
import { setContextForView } from "../AITools/utils";

export const Workflows = ({ name = "" }) => {
  const [workflowList, setWorkflowList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const contextObj = useContext(AppContext);
  const navigate = useNavigate();

  // get the workflowList from the server
  const loadWorkflows = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "workflow" });
    if (response) {
      // convert the response to the format that the Card components expect
      const seqs = response
        ?.filter(({ deleted }) => !deleted)
        .map((workflow: any) => {
          return {
            id: workflow.id,
            label: workflow.name,
            caption: `Last Updated: ${moment(workflow.updatedAt).fromNow()}`,
            img: (
              <img
                className="w-[42px] h-[42px] flex-none rounded-full overflow-hidden object-cover"
                src="https://flowbite.com/docs/images/logo.svg"
                alt=""
              />
            ), // funnel.screensnhot
            status: Status.INPROGRESS,
          };
        });

      setWorkflowList(seqs);

      setContextForView({
        data: seqs.map(({ id, label, caption }) => ({ id, name: label, caption })),
        view: name,
      }, contextObj);
      
    }
    setIsLoading(false);
  };

  const handleCreateWorkflow = async () => {
    const name = prompt("Enter a name for your workflow");
    const campaignId = "cjz0ij1qz0ems0792n1kpu5ac"; // The ID of the collection campaign template (blank)
    if (name) {
      setIsLoading(true);
      const token = getSessionToken();
      const response = await cloneCampaign(campaignId, { token, name });
      const newCampaignId = response?.data?.cloneCampaign?.response?.id;

      // update the type of the campaign to be a collections campaign
      const updateResponse = await updateCampaign(newCampaignId, {
        token,
        settings: {
          type: "workflow",
        },
      });

      // console.log("newCampaignId", newCampaignId, updateResponse);

      if (newCampaignId) {
        // navigate to the new funnel
        navigate(`/workflows/${newCampaignId}`);
      }
    }
  };

  // load the workflowList when the page loads
  useEffect(() => {
    loadWorkflows();
  }, []);

  return (
    <Page
      topBar={{
        page: [{ label: name }],
        items: (
          <Button label="+ Create a Workflow" type={ButtonTypes.OUTLINED} onClick={handleCreateWorkflow} />
        ),
      }}
    >
      <Container>
        {isLoading && <Loading type="gallery" />}
        {!isLoading && !Boolean(workflowList.length) && (
          <EmptyState
            title="No Workflows Yet"
            description="Get started by creating a new workflow."
            showImage={false}
          >
            <Link to="/launchpad/workflows">
              <Button label="Create a Workflow" type={ButtonTypes.DEFAULT} />
            </Link>
          </EmptyState>
        )}
        {!isLoading && Boolean(workflowList.length) && (
           <Grid className="pb-6">
            {/* Can we change to Gallery View - let's me toggle views */}
            {workflowList.map(
              (
                {
                  id,
                  label,
                  caption,
                  img = "[logo]",
                  status,
                  // sharedWith,
                  taskDetails,
                },
                key
              ) => {
                return (
                  <Card
                    key={key}
                    label={label}
                    caption={caption}
                    image={img}
                    href={`/workflows/${id}`}
                    status={status}
                    taskDetails={taskDetails}
                  />
                );
              }
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};
