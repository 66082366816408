import { AGENTS_DOMAIN, AI_DOMAIN, API_DOMAIN, AUTOMATION_DOMAIN } from "@launchos/shared-utils/env";
import { client } from "@/client";
import {
  ADD_CONNECTION,
  ADD_OBJECT,
  DELETE_OBJECT,
  GET_OBJECT,
  GET_OBJECTS,
  REMOVE_CONNECTION,
  UPDATE_OBJECT,
} from "./gql/campaigns";
import { createPage, getNewPageContent, getNewPageSettings } from "@/api/pages";
import { getSessionToken } from "./auth";
import { CampaignPromise } from "./gql/types";

interface CreateObjectParams {
  name: string;
  x: number;
  y: number;
  campaignId: string;
  screenshot: string;
  type: string;
  settings: any;
}

// START OF AJAX CALLS

/**
 * Creates a new campaign.
 */
export const createCampaign = async ({
  token,
  name,
  type = "funnel",
}: {
  token: string;
  name: string;
  type?: string;
}): Promise<CampaignPromise> => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({ name, type });

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(`${API_DOMAIN}/campaigns`, requestOptions);
  const data = await response.json();
  console.log("createCampaign", data);
  return data;
};

/**
 * Retrieves the funnels from the API.
 * @param options - The options for the API request.
 * @returns A promise that resolves to the campaign data.
 */
export const getCampaigns = async (params) => {
  const { token, ...filter } = params;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  // const raw = JSON.stringify({ type });
  const queryString = new URLSearchParams(params).toString();
  // console.log("queryString", queryString);

  const requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    // body: raw,
    redirect: "follow",
  };

  // convert the params to a filter object (remove the token variable)
  // console.log("filter", filter);

  const response = await fetch(
    `${API_DOMAIN}/campaigns/?filter=${JSON.stringify(filter)}`,
    requestOptions
  );

  const { data } = await response.json();
  // console.log("funnels", response);
  return data;

  // else {
  // 	window.location.href = '/login';
  // }
};

/**
 * Fetches the funnel data for a specific campaign.
 * @param token - The authorization token.
 * @param id - The ID of the campaign.
 * @returns The funnel data for the campaign.
 */
export const getCampaign = async ({ token, id }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(`${API_DOMAIN}/campaigns/${id}`, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
      const { data } = await response.json();
      // console.log("funnel data", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Creates a new campaign and an associated object.
 * @param {Object} options - The options for creating the campaign and object.
 * @param {string} options.token - The authorization token.
 * @param {string} options.name - The name of the campaign and object.
 * @param {string} options.type - The type of the campaign.
 * @param {string} options.nodeType - The type of the object.
 * @param {Object} options.settings - The settings for the object.
 * @returns {Promise<Object>} An object containing the created campaign and object.
 */

export const createCampaignAndObject = async ({ token, name, type, nodeType, settings }) => {

  // create a campaign
  const campaign = await createCampaign({
    token,
    name,
    type,
  });

  // Create an object
  const object = await createObject({
    name,
    x: 0,
    y: 0,
    type: nodeType,
    settings,
    campaignId: campaign?.id,
  });

  return {
    campaign,
    object
  }
}

/**
 * Updates the funnel for a campaign.
 * @param campaignId - The ID of the campaign.
 * @param options - The options for updating the funnel.
 * @param options.token - The authorization token.
 * @param options.settings - The settings for the funnel update.
 * @returns The updated funnel data.
 */
export const updateCampaign = async (campaignId, { token, settings }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const payload = {
        method: "PATCH",
        headers: myHeaders,
        // body: settings,
        body: JSON.stringify(settings),
        redirect: "follow",
      };
      // console.log("payload", payload);
      const response = await fetch(
        `${API_DOMAIN}/campaigns/${campaignId}`,
        payload
      );
      const data = await response.json();
      // console.log("Update Campaign Response", data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Publishes a campaign.
 * @param campaignId - The ID of the campaign to publish.
 * @param options - The options object containing the token.
 * @returns A Promise that resolves to the response data or rejects with an error.
 */
export const publishCampaign = async (campaignId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(
        `${API_DOMAIN}/campaigns/${campaignId}/publish`,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        }
      );
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Deletes a funnel for a given campaign.
 * @param campaignId - The ID of the campaign.
 * @param options - The options object containing the token.
 * @returns A Promise that resolves to the deleted funnel data.
 */
export const deleteCampaign = async (campaignId, { token }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(`${API_DOMAIN}/campaigns/${campaignId}`, {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Clones a funnel for a given campaign.
 * @param campaignIdToClone - The ID of the campaign to clone.
 * @param params - The parameters for cloning the funnel.
 * @returns A Promise that resolves to the cloned funnel data.
 */
export const cloneCampaign = async (campaignIdToClone, { token, name }) => {
  if (token) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(
        `${API_DOMAIN}/campaigns/${campaignIdToClone}/clone`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({ name }),
          redirect: "follow",
        }
      );
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
};

/**
 * Retrieves objects for a given campaign.
 * @param campaignId - The ID of the campaign.
 * @returns A Promise that resolves to the objects for the campaign.
 */
export const getObjects = async (campaignId) => {
  return await client.query({
    query: GET_OBJECTS,
    variables: { id: campaignId },
    fetchPolicy: "network-only"
  });
};

/**
 * Updates the name of an object in the database.
 * @param id - The ID of the object.
 * @param name - The new name for the object.
 */
export const changeObjectName = async (id, name: string) => {
  await client.mutate({
    mutation: UPDATE_OBJECT,
    variables: { id, name },
  });
};

/**
 * Deletes an object with the specified ID.
 * @param {string} id - The ID of the object to delete.
 */
export const deleteObject = async (id) => {
  return await client.mutate({
    mutation: DELETE_OBJECT,
    variables: { id },
  });
};

/**
 * Deletes an object with the specified ID.
 * @param {string} id - The ID of the object to delete.
 */
export const getObject = async (id) => {
  // client.mutate({
  //   mutation: DELETE_OBJECT,
  //   variables: { id },
  // });
  return await client.query({
    query: GET_OBJECT,
    variables: { id },
  });
};

/**
 * Creates a new object with the specified parameters.
 * @param {CreateObjectParams} params - The parameters for creating the object.
 * @returns {Promise<any>} - A promise that resolves to the result of the mutation.
 */
export const createObject = async ({
  name,
  x,
  y,
  campaignId,
  screenshot,
  type,
  settings,
}: CreateObjectParams) => {
  return client.mutate({
    mutation: ADD_OBJECT,
    variables: {
      name,
      x,
      y,
      campaignId,
      screenshot,
      type,
      settings,
    },
  });
};

/**
 * Updates an object with the specified ID.
 * @param id - The ID of the object to be updated.
 * @param token - The authentication token.
 * @param settings - The settings object containing the name, x, y, and optional settings properties.
 * @returns A Promise that resolves to the result of the mutation.
 */
export const updateObject = async (id, object) => {
  // const { name, type, x, y } = settings;
  // let variables = { id, name, type, x, y };
  // if (settings?.settings)
  //   variables = { ...variables, settings: settings.settings };

  const variables = { id, ...object };

  return client.mutate({
    mutation: UPDATE_OBJECT,
    variables,
  });
};

/**
 * Gets the list of connected nodes for a given nodeId
 * @param nodeId The id of the node you want to get the connected nodes for
 * @param param1 The token and campaignId to pass to the getCampaign function
 * @returns The array of connected nodes for the given nodeId
 */
export const getConnectedNodes = async (nodeId, { token, campaignId }) => {
  const data = await getCampaign({ token, id: campaignId });
  const { objects } = data || {};
  const { connectTo = [] } = objects.find((obj) => obj.id === nodeId);

  console.log("getConnectedNodes", { nodeId, connectTo, objects });

  const outgoing = connectTo.map(({ id }) => ({
    id,
    ...objects.find((obj) => obj.id === id),
  }));

  const incoming = objects.filter(({ connectTo }) => {
    if (!connectTo) return false;
    return connectTo.find(({ id }) => id === nodeId);
  });

  return { outgoing, incoming };
};

/**
 * Deletes a connection between two entities.
 * @param connection - The connection object containing the source and target IDs.
 */
export const deleteConnection = async ({ source, target }) => {
  client.mutate({
    mutation: REMOVE_CONNECTION,
    variables: { id: source, connectTo: target },
  });
};

/**
 * Creates a connection between two entities.
 * @param connection - The connection object containing source and target IDs.
 */
export const createConnection = async ({ source, target }) => {
  await client.mutate({
    mutation: ADD_CONNECTION,
    variables: { id: source, connectTo: target },
  });
};

/**
 * Handles the creation of a new page from a template.
 * @param properties - The properties required for creating a new page.
 */
export const handleCreateNewPageFromTemplate = async ({
  position,
  campaignData,
  page,
  setNodes = (x) => {},
  nodes = [],
}, customNodeSettings = {}) => {
  // Add the new node to the database
  const { data } = await createObject({
    x: parseInt(position.x),
    y: parseInt(position.y),
    campaignId: campaignData.id,
    ...page,
  });

  const newObjectId = data?.createObject?.id;

  // now create a page for the new node
  // and connect it to that object Id
  const token = getSessionToken();
  const newPageData = await createPage({
    token,
    settings: {
      content: page?.content || getNewPageContent(page?.type, page?.pageType),
      name: page.name,
      objectId: newObjectId,
      pageSettings: getNewPageSettings(page?.type, page?.pageType),
      slug:
        page?.slug ||
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "")
          .substr(0, 6),
      theme: page?.id || page?.id || "",
      type: page?.pageType || page?.type,
    },
  });

  // add the node to the nodes array
  const newNode = {
    id: newObjectId,
    data: {
      ...page,
      ...customNodeSettings,
      id: newObjectId,
      x: parseInt(position.x),
      y: parseInt(position.y),
      label: page.name,
      page: newPageData,
    },
    position,
    width: 130,
    height: 90,
    sourcePosition: "right",
    targetPosition: "left",
    type: "defaultNode",
  };

  setNodes([...nodes, newNode]);

  console.log("data", data, { newNode }, newObjectId);

  return { object: data?.createObject, page: newPageData };
  
};

export const initializeBroadcast = async (campaignId, broadcastOptions) => {
  console.log(broadcastOptions);

  const myHeaders = new Headers();
  const token = getSessionToken()
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  const raw = JSON.stringify({ campaignId, ...broadcastOptions });
  
  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  
  const response = await fetch(
    `${AUTOMATION_DOMAIN}/broadcasts`,
    requestOptions
  );
  const data = await response.text();

  console.log("initialize broadcast data", data);
  return data;
};

export const initializeWorkflow = async (campaignId) => {

  const token = getSessionToken();
  
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  
  const raw = JSON.stringify({ id: campaignId });
  
  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  
  const response = await fetch(`${AUTOMATION_DOMAIN}/workflows`, requestOptions);
  const data = await response.text();

  console.log("initialize workflow data", data);

  alert("Your Workflow Has Been Published");

  return data;
};

export const generateMarketingPlan = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      prompt: "Please generate a marketing plan",
    });

    try {
      const response = await fetch(`${AGENTS_DOMAIN}/plan`, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      const result = await response.text();
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
    }
}