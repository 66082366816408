import { useEffect, useState } from "react";
import { Calout } from "@/ui/Calout";
import { AppsIcon, AuthenticaDashboardIcon, tionIcon, ComponentsIcon, SettingsIcon, DashboardIcon } from "@/ui/Icons";

import { BigOptionGroup } from "@/ui/BigOptionGroup";
import { InputGroup } from "@/ui/InputGroup";
import { Modal } from "@/ui/Modal";
import { TextInput } from "@/ui/TextInput";
import { Button } from "@/ui/Button";
import { Pagination } from "@/ui/Pagination/Pagination";
import { getSessionToken } from "@/api/auth";

import registrars from "./registrars.json";

import { ButtonTypes, DropDownTypes, Variants } from "@/ui/types";

import { PopupSizes } from "@/ui/Modal/types";

import { TextInputFlavors } from "@/ui/TextInput/types";

import { Stepper } from "@/ui/Stepper";
import { Dropdown } from "@/ui/Dropdown";
import {
  createDomain,
  createSSL,
  deleteDomain,
  getDomain,
  getDomainData,
} from "../../api/domains";
import { FooterButtons } from "@/ui/Modal/Modal";

export enum DomainSteps {
  CHOOSE_ONE,
  ADD,
  CONTENT,
  SUBDOMAIN,
  SELECTION,
  TUTORIAL,
  SUCCESS,
}

export const Title = ({ children }) => (
  <div className="p-5 mb-5 text-2xl text-center">{children}</div>
);

type DomainStepProps = {
  domainName?: string;
  setDomainName?: (domainName: string) => void;
  subdomainName?: string;
  setSubdomainName?: (subdomainName: string) => void;
  registrar?: string;
  setRegistrar?: (registrar: string) => void;
  onNextStep?: (step: DomainSteps) => void;
  setIsOpen?: (isOpen: boolean) => void;
};

const DomainChooseOne: React.FC<DomainStepProps> = ({
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar = "",
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  const [domainKeyChoice, setDomainKeyChoice] = useState(0);

  const domainDropdown = [
    { label: "www.DomainName1.com", href: "#" },
    { label: "www.DomainName2.com", href: "#" },
    { label: "www.DomainName3.com", href: "#" },
    { label: "www.DomainName4.com", href: "#" },
  ];
  return (
    <div>
      <Title>Choose the Domain Name for this Campaign.</Title>
      <div className="mx-auto w-10/12">
        <Dropdown
          label="https://sjfksld.tenminutefunnels.com"
          type={DropDownTypes.BASIC}
          items={domainDropdown}
          onClick={(k) => setDomainKeyChoice(k)}
        />
      </div>
      <FooterButtons
        showBack={false}
        onCancel={() => setIsOpen(false)}
        onNext={() => {
          setDomainName(domainDropdown[domainKeyChoice].label);
          onNextStep(DomainSteps.SUCCESS);
        }}
      />
    </div>
  );
};

const DomainAddOne: React.FC<DomainStepProps> = ({
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar = "",
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  return (
    <div>
      <Title>Type in the Domain Name you want to add to your account</Title>

      <div className="grid grid-flow-row gap-7 mb-7">
        <TextInput
          value={domainName}
          onChange={(e) => setDomainName(e.target.value)}
          label="Enter your domain name (e.g. YourName.com)"
          flavor={TextInputFlavors.MODERN}
        />
      </div>

      <Calout title="Please use a domain you own. You can buy one from any registrar." />
      <FooterButtons
        showBack={false}
        onCancel={() => setIsOpen(false)}
        onNext={() => onNextStep(DomainSteps.CONTENT)}
      />
    </div>
  );
};

const DomainHasContent: React.FC<DomainStepProps> = ({
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar = "",
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  return (
    <div>
      <Title>
        Is there any content on
        <span className="text-red-600 px-2">{domainName}</span>
        that should be preserved?
      </Title>
      <div className="flex items-center justify-center gap-5">
        <Button
          style={{ padding: "0.75rem" }}
          className="font-semibold"
          type={ButtonTypes.SOFT}
          label="No content, I'm starting from scratch"
          // onClick={() => onNextStep(DomainSteps.SELECTION)}
          onClick={() => onNextStep(DomainSteps.SUCCESS)}
        />
        <Button
          style={{ padding: "0.75rem" }}
          className="font-semibold"
          type={ButtonTypes.SOFT}
          label="It has content, preserve what's there"
          onClick={() => onNextStep(DomainSteps.SUBDOMAIN)}
        />
      </div>
      <FooterButtons
        showNext={false}
        onCancel={() => setIsOpen(false)}
        onBack={() => onNextStep(DomainSteps.ADD)}
      />
    </div>
  );
};

const DomainSubdomain: React.FC<DomainStepProps> = ({
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar = "",
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  return (
    <div>
      <Title>
        No problem. To preserve{" "}
        <span className="text-red-600 px-2">www.{domainName}</span>, let's use a
        subdomain like
        <span className="text-red-600 px-2">example.{domainName}</span> instead.
      </Title>
      <div className="gap-5">
        <div className="mx-auto w-3/5">
          <InputGroup
            elements={[
              {
                type: "input",
                leftRounded: true,
                placeholder: "subdomain",
                inputType: "text",
                value: subdomainName,
                onChange: (e) => setSubdomainName(e.target.value),
              },
              { type: "button", text: `.${domainName}`, rightRounded: true },
            ]}
          />
        </div>
        {/* <Calout
					title="Enter a subdomain prefix to use with your domain name"
				/> */}
      </div>
      <FooterButtons
        onCancel={() => setIsOpen(false)}
        onBack={() => onNextStep(DomainSteps.CONTENT)}
        // onNext={() => onNextStep(DomainSteps.SELECTION)}
        onNext={() => onNextStep(DomainSteps.SUCCESS)}
      />
    </div>
  );
};

const DomainProviderSelection: React.FC<DomainStepProps> = ({
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar,
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  return (
    <div>
      <Title>
        Which provider did you choose for your domain registration (or hosting)?
      </Title>

      <div className="p-5" style={{ marginTop: -25 }}>
        
        {/* <BigOptionGroup
          options={Object.keys(registrars).map((i) => ({
            id: i,
            icon: (
              <img
                src={registrars[i]["logo"]}
                style={{ width: "80%", maxHeight: 30 }}
              />
            ),
          }))}
          className="grid-cols-3"
          selectedOption={registrar}
          onChange={setRegistrar}
        /> */}
      </div>

      {/* <Calout
        title={`Not using ${registrar}? Click here for instructions for other providers.`}
      /> */}
      <FooterButtons
        onCancel={() => setIsOpen(false)}
        onBack={() => onNextStep(DomainSteps.CONTENT)}
        onNext={() => onNextStep(DomainSteps.SUCCESS)}
        // onNext={() => onNextStep(DomainSteps.TUTORIAL)}
      />
    </div>
  );
};

const DomainTutorial: React.FC<DomainStepProps> = ({
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar = "",
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  const [step, setStep] = useState(0);
  return (
    <div>
      <Title>
        Awesome! You got this domain name from {registrar}. To connect it,
        simply...
      </Title>
      <div className="grid grid-cols-1 gap-3" style={{ marginTop: -10 }}>
        {/* Image */}
        <div>
          <div className="flex items-center justify-between gap-5">
            {/* Title */}
            <p className="font-semibold mb-3">
              {step + 1}. {registrars[registrar].steps[step].title}
            </p>

            <Pagination
              // showFirstLastButtons
              showPrevNextButtons
              // showPageNumbers={false}
              currentPage={step + 1}
              totalPages={registrars[registrar].steps.length}
              showIcons
              circles
              fill="solid"
              onPageChange={(s) => {
                if (s > 0 && s <= registrars[registrar].steps.length)
                  setStep(s - 1);
              }}
            />
          </div>

          <img
            className="w-full border-8 dark:border-white/20 rounded"
            alt=""
            src={registrars[registrar].steps[step].image}
          />
        </div>
        {/* Instruction */}
        <div className="py-8 px-2">
          {/* Steps */}
          <img
            className="max-w-[150px] mb-5"
            alt=""
            src={registrars[registrar].logo}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: registrars[registrar].steps[step].description,
            }}
          />
        </div>
      </div>

      <Calout
        title={`Not using ${registrar}? Click here for instructions for other providers.`}
      />

      <FooterButtons
        onCancel={() => setIsOpen(false)}
        onBack={() => onNextStep(DomainSteps.SELECTION)}
        onNext={() => onNextStep(DomainSteps.SUCCESS)}
      />
    </div>
  );
};

const DomainSuccess: React.FC<DomainStepProps> = ({
  campaignId,
  domainName = "",
  setDomainName = () => null,
  subdomainName = "",
  setSubdomainName = () => null,
  registrar = "",
  setRegistrar = () => null,
  onNextStep = () => null,
  setIsOpen = () => null,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const addDomain = async () => {
    const token = getSessionToken();
    const name = subdomainName ? `${subdomainName}.${domainName}` : domainName;

    // check if it already exists?
    const allDomains = await getDomainData({ token });
    const domainExists = allDomains.some(({ name: n }) => n === name);

    if (!domainExists) {
      let body = { token };
      if (campaignId) body = { ...body, campaignId };
      const response = await createDomain(name, body);
      console.log(response);

      if (response) {
        setIsLoading(false);
      } else {
        alert("Error adding domain");
      }
    } else {
      alert("You've already added this domain.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Add Domain To Account
    addDomain();
  }, []);

  if (isLoading)
    return (
      <div>
        <Title>
          One moment please...
          <p className="text-sm p-3 text-white/40 text-center">
            while we add {domainName} to your account
          </p>
        </Title>
      </div>
    );

  return (
    <div>
      <Title>Success! Your domain ({domainName}) is now connected.</Title>
      <Calout
        title="In most cases, it only takes about 20 minutes before your domain is ready."
        caption="In rare cases it can take up to 48 hours."
      />

      <div className="flex items-center justify-center gap-5 mt-7">
        <Button
          style={{ padding: "0.5rem" }}
          type={ButtonTypes.OUTLINED}
          variant={Variants.PRIMARY}
          label="Add Another Domain"
          onClick={() => onNextStep(DomainSteps.ADD)}
        />
        <Button
          style={{ padding: "0.5rem" }}
          type={ButtonTypes.OUTLINED}
          variant={Variants.SUCCESS}
          label="Go to Dashboard"
          onClick={() => {
            setIsOpen(false);
            // reload
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};

export const AddDomainModal = ({ campaignId, setIsOpen }) => {
  const [step, setStep] = useState(DomainSteps.ADD);
  const [domainName, setDomainName] = useState("");
  const [subdomainName, setSubdomainName] = useState("");
  const [registrar, setRegistrar] = useState("GoDaddy");

  let activeStep = 0;
  let numberOfSteps = 5;

  switch (step) {
    case DomainSteps.CHOOSE_ONE:
      activeStep = 1;
      numberOfSteps = 2;
      break;
    case DomainSteps.ADD:
      activeStep = 1;
      break;
    case DomainSteps.CONTENT:
      activeStep = 2;
      break;
    case DomainSteps.SUBDOMAIN:
      activeStep = 3;
      break;
    case DomainSteps.SELECTION:
      activeStep = 4;
      break;
    case DomainSteps.TUTORIAL:
      activeStep = 5;
      break;
  }

  return (
    <Modal
      title="Add a Domain Name"
      size={PopupSizes.LARGE}
      onClose={() => setIsOpen(false)}
      onSuccess={() => alert("Success")}
      footer={false}
      icon={<SettingsIcon />}
    >
      <div className="items-center mb-7">
        {step !== DomainSteps.SUCCESS && (
          <div className="mx-auto w-4/5 mt-3">
            <Stepper activeStep={activeStep} numberOfSteps={numberOfSteps} />
          </div>
        )}

        {step === DomainSteps.CHOOSE_ONE && (
          <DomainChooseOne
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
        {step === DomainSteps.ADD && (
          <DomainAddOne
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
        {step === DomainSteps.CONTENT && (
          <DomainHasContent
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
        {step === DomainSteps.SUBDOMAIN && (
          <DomainSubdomain
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
        {step === DomainSteps.SELECTION && (
          <DomainProviderSelection
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
        {step === DomainSteps.TUTORIAL && (
          <DomainTutorial
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
        {step === DomainSteps.SUCCESS && (
          <DomainSuccess
            campaignId={campaignId}
            setIsOpen={setIsOpen}
            domainName={domainName}
            setDomainName={setDomainName}
            subdomainName={subdomainName}
            setSubdomainName={setSubdomainName}
            onNextStep={setStep}
            registrar={registrar}
            setRegistrar={setRegistrar}
          />
        )}
      </div>
    </Modal>
  );
};

export const SSLModal = ({ activeDomainId, setIsOpen }) => {
  const handleDomainCreateSSL = async () => {
    const token = getSessionToken();
    const response = await createSSL(activeDomainId, { token });
    console.log(response);

    if (response) {
      setIsOpen(false);
      alert(
        "SSL creation complete. Please wait 20 minutes for the SSL to be activated."
      );
      window.location.reload();
    } else {
      alert("Error creating SSL");
    }
  };

  return (
    <Modal
      title="Secure this domain (SSL)"
      icon={<AuthenticationIcon />}
      onClose={() => setIsOpen(false)}
      onSuccess={handleDomainCreateSSL}
    >
      <Title>
        Would you like to create a secure connection for this domain?
      </Title>
      <Calout title="When you create an SSL Certificiate, the padlock and https protocol will be activated for your website." />
      <div className="flex items-center p-5"></div>
    </Modal>
  );
};

export const DeleteDomainModal = ({ activeDomainId, setIsOpen }) => {
  const handleDomainDisconnect = async () => {
    const token = getSessionToken();
    const response = await deleteDomain(activeDomainId, { token });
    if (response) {
      setIsOpen(false);
      window.location.reload();
    } else {
      alert("Error disconnecting domain");
    }
  };

  return (
    <Modal
      title="Disconnect this domain?"
      onClose={() => setIsOpen(false)}
      onSuccess={handleDomainDisconnect}
    >
      <Title>Are you sure you want to disconnect this domain?</Title>

      <Calout title="Note: Any sites you have connected to this domain will be disconnected." />
    </Modal>
  );
};
