/** TODO: Make the live page and the editor page call the same function -- the code is the same */
export default (objSettings, currentMobileState) => {
  const properties = objSettings?.properties || false;

  let newSettings = objSettings;

  if (properties) {
    if (properties.hasOwnProperty("mobile")) {
      const fullscreen = properties.mobile.hasOwnProperty("fullscreen")
        ? properties.mobile.fullscreen
        : {};
      const desktop = properties.mobile.hasOwnProperty("desktop")
        ? properties.mobile.desktop
        : {};
      const tablet = properties.mobile.hasOwnProperty("tablet")
        ? properties.mobile.tablet
        : {};
      const smartphone = properties.mobile.hasOwnProperty("smartphone")
        ? properties.mobile.smartphone
        : {};

      if (currentMobileState.type === "smartphone") {
        newSettings = {
          ...objSettings,
          properties: {
            ...properties,
            ...fullscreen,
            ...desktop,
            ...tablet,
            ...smartphone
          }
        };
      } else if (currentMobileState.type === "tablet") {
        newSettings = {
          ...objSettings,
          properties: {
            ...properties,
            ...smartphone,
            ...fullscreen,
            ...desktop,
            ...tablet
          }
        };
      } else if (currentMobileState.type === "desktop") {
        newSettings = {
          ...objSettings,
          properties: {
            ...properties,
            ...smartphone,
            ...tablet,
            ...fullscreen,
            ...desktop
          }
        };
      } else if (currentMobileState.type === "fullscreen") {
        newSettings = {
          ...objSettings,
          properties: {
            ...properties,
            ...smartphone,
            ...tablet,
            ...desktop,
            ...fullscreen
          }
        };
      }
    }
  }

  return newSettings;
};
