import { EditorComponentProps, EditorMode } from "@/Apps/Pages/Editor/types";

export enum CountDownTypes {
  EVENT = "event",
  VISIT = "visit",
  TOP_OF_HOUR = "hour",
}
export interface CountdownProps {
  mode?: EditorMode;
  firstVisitExpiration?: any;
  countType?: any;
  eventDate?: any;
  style?: any;
  labelStyle?: any;
  dimensionStyle?: any;
  dividerStyle?: any;
  numberStyle?: any;
  actions?: any;
  showDays?: Boolean;
  showHours?: Boolean;
  showMinutes?: Boolean;
  showSeconds?: Boolean;
  showDivider?: Boolean;
  showLabels?: Boolean;
}
export interface PluginProps extends EditorComponentProps {
  settings: CountdownProps;
  match?: any;
}
