// import { ComponentRenderHooks, ListItemsHooks } from "@launchos/plugins/types";
import * as React from "react";
import { get } from "lodash";
// import * as MaterialIcon from "@material-ui/icons";
import MaterialIcon from '@material-ui/core/Icon';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ComponentTypes } from "../../types";
import reducers from "../Button/reducers";
// import { EditorMode } from "@/Apps/Pages/Editor/types";

import { IconTypes, IconProps, IconSource, PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import { ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import { ComponentRenderHooks } from "@/plugins/types";

const convertToSnakeCase = str => {

  switch (str) {
    case "3d_rotation":
      return "ThreeDRotation";
    case "4k":
      return "FourK";
    case "360":
      return "ThreeSixty";
  }

  return str.replace(/\.?([A-Z])/g, function (x, y) { return "_" + y.toLowerCase() }).replace(/^_/, "");
}

export const Icon: React.FC<IconProps> = (props) => {
  const {
    style = {},
    className = "",
    properties = {},
    type = IconTypes.Block,
    source = IconSource.MATERIAL,
    actions = [],
    updateComponentSettings = () => null,
    getComponentSettings = () => null,
    mode = EditorMode.LIVE,
    onClick = () => null,
  } = props;
  // const IconComponent = MaterialIcon[type];

  // if you follow this path, make sure to add the following to the index.html
  // <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
  const IconComponent = ({ style, onClick }) => {
    if (type === "Twitter") return <TwitterIcon className={className} style={style} onClick={onClick} />;
    if (type === "Instagram") return <InstagramIcon className={className} style={style} onClick={onClick} />;
    if (type === "YouTube") return <YouTubeIcon className={className} style={style} onClick={onClick} />;
    if (type === "WhatsApp") return <WhatsAppIcon className={className} style={style} onClick={onClick} />;
    return <MaterialIcon className={className} style={style} onClick={onClick}>{convertToSnakeCase(type)}</MaterialIcon>
  }

  const handleIconClick = () => {
    if (actions) {
      const doTheseActions = actions.filter((itm) => itm.behavior === "click");
      doTheseActions.forEach((action) => {
        reducers([], {
          ...action,
          settings: props,
          updateComponentSettings,
          getComponentSettings,
        })
      }
      );
    }
  };

  return (
    <span
      data-testid="WC-ICON-LIVE"
      onClick={handleIconClick}
      style={{
        padding: 0,
        margin: 0,
        border: "none",
        boxShadow: "none",
        background: "transparent",
        cursor: mode === EditorMode.LIVE ? "pointer" : "grab",
        // display: "flex",
        // alignItems: "center",
        textAlign: "center",
        ...style,
        ...properties,
      }}
    >
      {source === IconSource.MATERIAL && (
        <IconComponent
          className={className}
          style={{
            ...style,
            ...properties,
            padding: 0,
            border: "none",
            marginTop: 0,
            marginBottom: 0,
            backgroundColor: "transparent",
          }}
          onClick={onClick}
        />
      )}
      {source === IconSource.FONTAWESOME && (
        <FontAwesomeIcon
          className={className}
          style={{
            ...style,
            ...properties,
            padding: 0,
            border: "none",
            marginTop: 0,
            marginBottom: 0,
            backgroundColor: "transparent",
          }}
          icon={type}
          onClick={onClick}
        />
      )}
    </span>
  );
};

const IconPluginComponent: React.FC<PluginProps> = ({
  settings,
  updateComponentSettings = () => null,
  getComponentSettings = () => null,
  mode = EditorMode.LIVE,
  className = ""
}) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Icon
        className={className}
        {...settings}
        updateComponentSettings={updateComponentSettings}
        getComponentSettings={getComponentSettings}
        mode={mode}
        type={get(settings, "iconType", false)}
        properties={properties}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.ICON
  ) {
    return [IconPluginComponent];
  }
};

export { IconTypes, IconSource };

export default Icon;
