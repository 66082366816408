import { useEffect, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { has, keys } from "lodash";

import { Topbar, LeftSidebar, RightSidebar } from "..";

import { getBasicUserInfo, getSessionToken, getTokenInfo, isLoggedIn } from "@/api/auth";
import { AppContext } from "@/AppContext";
import { ContainerProps, PageProps } from "./types";
import { triggerAsyncHook } from "@/plugins/client";
import { HookTriggers, ListItemsHooks } from "@/plugins/types";
import { NavGroupItem } from "../LeftSidebar/types";

// import { getSessionToken } from "@/api/auth";
import { CopilotWrapper } from "@/Apps/AITools/CopilotWrapper";

export const Container: React.FC<ContainerProps> = ({
  children = <></>,
  className = "",
  fullWidth = false,
}) => {
  return (
    // Start Container
    <div
      className={`p-7 h-full ${className}`}
    >
      {/* Start Max Width Container */}
      {!fullWidth ? (
        <div className="mx-auto max-w-6xl h-full">{children}</div>
      ) : (
        children
      )}
      {/* End Max Width Container */}
    </div>
    // End Container
  );
};

export const Page: React.FC<PageProps> = ({
  topBar = {},
  copilotSettings = {},
  children,
}) => {
  // Define state for the menu items
  const [menuItems, setMenuItems] = useState<NavGroupItem[]>([]);
  const [userInfo, setUserInfo] = useState<any>({});
  const [loggedIn, setIsLoggedIn] = useState<boolean>(false);
  

  // Get the app context
  const contextObj = useContext(AppContext);
  const { disableRightSidebar, copilotOpen, darkMode } = contextObj;
  const [copilotCampaignName, setCopilotCampaignName] = useState();
  const [copilotCampaignId, setCopilotCampaignId] = useState(copilotSettings?.id);

  // Check if logged in (check for a token)
  // const navigate = useNavigate();

  // Function to check if the token is valid
  // const checkToken = async () => {
  //   // Get the token from local storage
  //   const token = getSessionToken();
  //   // Call the API to check if the token is valid
  //   const response = await getTokenInfo(token);
  //   // Set the user info state
  //   setUserInfo(response);

  //   // If the response has an error property, redirect to login page with error message
  //   if (has(response, "error")) {
  //     navigate("/login", { state: { error: response.error } });
  //   }
  // };
  const checkToken = () => {
    const token = getSessionToken();
    const isUserloggedIn = Boolean(token);
    setIsLoggedIn(isUserloggedIn);

    if (isUserloggedIn) {
      const response = getBasicUserInfo();
      console.log("Basic User Info", response);
      setUserInfo(response);
    }
  }

  // Function to load in all menu items
  const loadInAllMenuItems = async () => {
    // Trigger the onListItems hook to get the menu items
    const menuItemsFromPlugin = await triggerAsyncHook(
      HookTriggers.onListItems,
      {
        id: ListItemsHooks.APP_DRAWER,
        position: "top",
      },
      null,
      null,
      contextObj
    );

    // Set the menu items state
    setMenuItems(menuItemsFromPlugin);
  };

  // Call checkToken function on component mount
  useEffect(() => {
    checkToken();
    loadInAllMenuItems();
  }, [contextObj]);

  useEffect(() => {
    contextObj?.setCopilotOpen(false); // start with a closed copilot
  }, [])

  useEffect(() => {
    if (copilotSettings.id) setCopilotCampaignId(copilotSettings.id);
  }, [copilotSettings.id])

  if (!loggedIn) return (
    <div className="main-content h-[100vh] flex-1">
      <CopilotWrapper
        id={copilotCampaignId}
        setCampaignId={setCopilotCampaignId}
        setCopilotCampaignname={setCopilotCampaignName}
        {...copilotSettings}
      >
        {children}
      </CopilotWrapper>
    </div>
  );

  return (
    <>
      {/* Render the Sidebar */}      
      <LeftSidebar
        menuItems={menuItems}
        avatarSettings={userInfo}
        showLogo={false}
        // showSupportSection={userInfo?.permissions?.includes("launchOS")}
        showSupportSection={false}
        showCopilotButton={userInfo?.permissions?.includes("copilot")}
      />
    

      {/* Render the Page Content */}
      <div
        data-testid="Page"
        className="main-content flex-1"
        // className="flex-1"
        style={disableRightSidebar ? { marginRight: 0 } : {}}
      >
        {/* Render the top bar component */}
        <Topbar {...topBar} />

        {/* Start Content */}
        <div
          data-testid="Page-Content"
          className="h-[calc(100vh-65px)] overflow-y-auto overflow-x-hidden"
        >
          {/* Render the child components */}
          {copilotOpen ? (
            <CopilotWrapper 
              id={copilotCampaignId} 
              setCampaignId={setCopilotCampaignId}
              setCopilotCampaignname={setCopilotCampaignName}
              {...copilotSettings}
            >
              {children}
            </CopilotWrapper>
          ) : children}
        </div>
        {/* End Content */}
      </div>

      {/* Render the Right Sidebar if not disabled */}
      {!disableRightSidebar && (
        <RightSidebar
          notifications={false}
          activities={false}
          contacts={false}
          chat={true}
          pageSettings={topBar?.page}
        />
      )}
    </>
  );
};
