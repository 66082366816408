import React, { useEffect } from "react";
import { PromptMessageContainerProps, MessageType } from "../types";
import { ThumbsDown, ThumbsUp } from "@phosphor-icons/react";
import Markdown from "react-markdown";
import { Artifact } from "@/Apps/AITools/Artifacts/Artifact";
import rehypeRaw from "rehype-raw";
import { Loading } from "@/ui/Layout/Loading";
import { isJsonString } from "@/utils";
import { keys } from "lodash";
import { ArtifactOptions } from "@/Apps/AITools/types";
import remarkBreaks from "remark-breaks";
import { markdownComponents } from "./markdown";

const ArtifactComponent: React.FC<{
  node: { children: React.ReactNode };
  identifier: string;
  type: string;
  title: string;
  id: string;
  // children: React.ReactNode;
  isLoading: boolean;
  shouldOpenArtifactWithThisOnFinish: ArtifactOptions;
  setArtifactOptions: () => void;
  setShouldOpenArtifactWithThisOnFinish: () => void;
}> = ({
  node,
  identifier,
  type,
  title,
  id,
  isLoading,
  shouldOpenArtifactWithThisOnFinish,
  setArtifactOptions,
  setShouldOpenArtifactWithThisOnFinish,
}) => {
  const getMarkdownContent = (element: React.ReactNode): string => {
    if (typeof element === "string") {
      return element;
    }
    if (Array.isArray(element)) {
      return element.map(getMarkdownContent).join("");
    }
    if (element && typeof element === "object") {
      if (element.type === "text") {
        return element.value || "";
      }
      if (element.children) {
        return getMarkdownContent(element.children);
      }
    }
    return "";
  };

  useEffect(() => {
    
    const options: ArtifactOptions = {
      id,
      type,
      prompt: String(getMarkdownContent(node.children)),
      title,
      pageType: "lead",
    };
    
    if (isLoading) setShouldOpenArtifactWithThisOnFinish(options);
    // if (isLoading) {
    //   if (shouldOpenArtifactWithThisOnFinish?.id !== id) {
    //     console.log("setting artifact options", options, shouldOpenArtifactWithThisOnFinish?.id, id);
    //     setShouldOpenArtifactWithThisOnFinish(options);
    //   }
    // }

  }, []);

  return (
    <div className="block">
      {/* {JSON.stringify({ isLastMessage })} */}
      <Artifact
        id={id}
        identifier={identifier}
        type={type}
        prompt={String(getMarkdownContent(node.children))}
        title={title}
        pageType="lead"
        isLoading={isLoading}
        shouldOpenArtifactWithThisOnFinish={shouldOpenArtifactWithThisOnFinish}
        setArtifactOptions={setArtifactOptions}
        setShouldOpenArtifactWithThisOnFinish={setShouldOpenArtifactWithThisOnFinish}
      />
    </div>
  );
  // return <span className="bg-yellow-200 p-1 rounded text-black">{children}</span>;
};

export const PromptMessageContainer: React.FC<PromptMessageContainerProps> = ({
  type = "Minimal",
  messages = [],
  withFeedbackButtons = false,
  feedbackButtons = [
    {
      // label: "Yes",
      onClick: () => {},
      icon: <ThumbsUp size={24} className="p-1" />,
      // tooltip: "Yes",
    },
    {
      // label: "No",
      onClick: () => {},
      icon: <ThumbsDown size={24} className="p-1" />,
      // tooltip: "No",
    },
  ],
  withIcon = true,
  setTopBarButtons = () => [],
  shouldOpenArtifactWithThisOnFinish = {},
  setArtifactOptions = () => {},
  setShouldOpenArtifactWithThisOnFinish = () => {},
  isLoading = false,
}) => {
  useEffect(() => {
    const wn = document.getElementById("chat-conversation-container");
    wn?.scrollTo({ top: wn.scrollHeight });
  }, []);

  // Render a single message bubble
  const renderMessageCaption = (message: MessageType, index: number) => {
    const { caption, from, type, id, icon, toolInvocations = [] } = message;
    const messageCaptionTestId = "message-caption";
    const messageIconTestId = "message-icon";

    const isFromUser = from === "User";
    const isLastMessage = message.id === messages[messages.length - 1].id;
    return (
      <div
        key={index}
        className={`
          rounded-md
          text-md
        ${
          isFromUser
            ? "flex flex-row my-1 px-4 py-8 sm:px-6 bg-lightwhite dark:bg-[#161616]/80"
            : "flex my-1 px-4 py-8 sm:px-6 border dark:border-white/5 border-black/5"
        }
      `}
        // style={{ backgroundColor: isFromUser ? "rgb(22 22 22 / 0.8)" : 'inherit' }}
      >
        {/* Render the message icon */}
        {withIcon && icon && (
          <div data-testid={messageIconTestId}>
            {typeof icon === "string" ? (
              <img
                src={icon}
                className="mr-2 flex h-8 w-8 rounded-full sm:mt-4"
              />
            ) : (
              icon
            )}
          </div>
        )}

        {/* Render the message caption */}

        <div>
          {caption && (
            <div
              data-testid={messageCaptionTestId}
              className="flex max-w-3xl items-center"
            >
              <div
                className="space-y-3 dark:text-white/80 text-black/80"
                style={{
                  fontFamily:
                    "ui-sans-serif, -apple-system, system-ui, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol",
                }}
              >
                {/* Render the message text */}
                <Markdown
                  children={caption}
                  remarkPlugins={[remarkBreaks]}
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    ...markdownComponents,
                    antthinking: () => <div className="hidden" />,
                    antartifact: ({ node, ...props }) => (
                      <ArtifactComponent
                        node={node}
                        {...props}
                        id={id}
                        shouldOpenArtifactWithThisOnFinish={
                          shouldOpenArtifactWithThisOnFinish
                        }
                        setArtifactOptions={setArtifactOptions}
                        setShouldOpenArtifactWithThisOnFinish={
                          setShouldOpenArtifactWithThisOnFinish
                        }
                        isLoading={isLastMessage && isLoading}
                      />
                    ),
                  }}
                />
              </div>
            </div>
          )}
          {Boolean(toolInvocations.length) && (
            <>
              <p
                className="space-y-3 text-[1.0rem] dark:text-white/80 text-black/80"
                style={{
                  fontFamily:
                    "ui-sans-serif, -apple-system, system-ui, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol",
                }}
              >
                {toolInvocations[0]?.args?.commentary}
              </p>
              <div className="py-3">
                <Artifact
                  id={toolInvocations[0]?.toolCallId}
                  type={toolInvocations[0]?.args?.type}
                  title={toolInvocations[0]?.args?.title}
                  prompt={toolInvocations[0]?.args?.prompt}
                  commentary={toolInvocations[0]?.args?.commentary}
                  pageType={toolInvocations[0]?.args?.pageType}
                  setArtifactOptions={setArtifactOptions}
                />
              </div>
            </>
          )}
          {withFeedbackButtons && !isFromUser && (
            <div className="mt-4 gap-3 flex">
              {renderFeedbackButtons(caption)}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Render a single message caption
  const renderMessageBubble = (message: MessageType, index: number) => {
    const { caption, from } = message;
    const messageBubbleTestId = "message-bubble";
    // const messageIconTestId = "message-icon";

    const isFromUser = from === "User";

    return (
      <div
        key={index}
        data-testid={messageBubbleTestId}
        className={`${
          isFromUser
            ? `place-self-end text-right`
            : `place-self-start text-left`
        }`}
      >
        {/* Render the message icon */}
        {/* {withIcon && message.icon && (
          <div data-testid={messageIconTestId}>{message.icon}</div>
        )} */}

        {caption && (
          <div
            className={`${
              isFromUser
                ? "bg-lightpurple-300 text-white p-5 rounded-2xl rounded-br-none"
                : "bg-lightwhite dark:bg-white/5 p-5 rounded-2xl rounded-bl-none"
            }`}
          >
            {caption}
          </div>
        )}

        {withFeedbackButtons && !isFromUser && (
          <div className="mt-4 gap-3 flex">
            {renderFeedbackButtons(caption)}
          </div>
        )}
      </div>
    );
  };

  // Render the feedback buttons
  const renderFeedbackButtons = (caption = "") => {
    return feedbackButtons.map((button, index) => {
      const { label, onClick, className = "", icon, tooltip } = button;
      const feedbackButtonTestId = "feedback-button";

      return (
        <button
          key={index}
          data-testid={feedbackButtonTestId}
          onClick={() => onClick(caption)}
          title={tooltip}
          className={className}
        >
          {icon && icon(caption)}
          {label}
        </button>
      );
    });
  };

  // Render the prompt message container
  return (
    <div data-testid="PromptMessageContainer">
      {/* Render the message bubbles or captions based on the type prop. */}
      {type === "Minimal" ? (
        <div className="flex-1 gap-2 flex flex-col overflow-y-auto text-sm leading-6 text-slate-900 dark:text-lightwhite sm:text-base sm:leading-7">
          {/* don't send any json messages (that represents page content and we don't want to render those) */}
          {messages
            .filter(({ caption }) => !isJsonString(caption))
            .map(renderMessageCaption)}
        </div>
      ) : (
        // Bubbles
        <div className="max-w-4xl mx-auto space-y-7 grid grid-cols-1">
          {messages.map(renderMessageBubble)}
        </div>
      )}
      {/* Render the feedback buttons if withFeedbackButtons prop is true */}
      {/* {withFeedbackButtons && renderFeedbackButtons()} */}
    </div>
  );
};

export default PromptMessageContainer;
