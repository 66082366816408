import * as React from "react";

import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import { PluginProps } from "./types";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
// import { updateSection } from "@/Apps/Pages/Properties/components/generator";
import { Spacer } from "./live";
import _ from "lodash";
import spacerSettings from "./settings";
// import { SectionTypes } from "@/Apps/Pages/Properties/types";

export const Properties = (props) => {
  const { updateComponentStyle, settings } = props;
  const { properties } = spacerSettings;
  // const sections = updateSection(
  //   properties.main.sections,
  //   SectionTypes.HEIGHT,
  //   {
  //     onChange: (resp, dbUpdate = true, wait = 500) => {
  //       switch (resp) {
  //         case "GROW": {
  //           const height = _.get(settings, "properties.height", 0) + 10;
  //           updateComponentStyle(settings.id, { height }, true, false);
  //           break;
  //         }
  //         case "SHRINK": {
  //           const height = _.get(settings, "properties.height", 6) - 10;
  //           updateComponentStyle(settings.id, { height }, true, false);
  //           break;
  //         }
  //         default: {
  //           const height = resp;
  //           updateComponentStyle(settings.id, { height }, dbUpdate, wait);
  //         }
  //       }
  //     },
  //   }
  // );

  return (
    <PropertiesBuilder
      title="Spacer Settings"
      data={{
        ...properties,
        main: { ...properties.main, sections: properties.main.sections },
      }}
      {...props}
    />
  );
};

/**
 * A Spacer
 */
export const EditorSpacer: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;

  return (
    <EditorObject id={id} {...props} PropertiesView={Properties}>
      <Spacer {...settings} />
    </EditorObject>
  );
};

export default EditorSpacer;
