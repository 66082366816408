import { EditorComponentProps, EditorMode } from "@/Apps/Pages/Editor/types";
import * as React from "react";
// import {
//   EditorMode,
//   EditorComponentProps,
// } from "@launchos/modules/v2/Editor/types";

export enum DividerTypes {
  HORIZONTAL = "Horizontal",
  VERTICAL = "Vertical",
}

export interface DividerProps {
  mode?: EditorMode;
  style?: React.CSSProperties;
  properties?: React.CSSProperties;
  color?: string;
  size?: number;
  type?: DividerTypes;
  dividerType?: DividerTypes;
  testId?: string;
}

export interface PluginProps extends EditorComponentProps {
  settings: DividerProps;
}
