import { Link } from "react-router-dom";
import { SignedIn } from "@clerk/clerk-react";
import { SignInButton } from "@clerk/clerk-react";
import { UserButton } from "@clerk/clerk-react";
import { SignedOut } from "@clerk/clerk-react";

import { useNavigate } from "react-router-dom";

import { Avatar } from "./Avatar";
import {
  DownCarrotIcon,
  SignoutIcon,
  SupportIcon,
  SettingsIcon,
  ProfileIcon,
  FlashIcon,
  LightToggleIcon,
  DarkToggleIcon,
} from "../Icons";
import { useContext } from "react";
import { AppContext } from "@/AppContext";
import { DotIcon } from "lucide-react";

export const DropdownMenu = ({ open, user = {} }) => {
  const { permissions = [] } = useContext(AppContext);

  const navItems = [
    { name: "Profile", icon: <ProfileIcon />, href: "/profile" },
    { name: "Domain Names", icon: <SettingsIcon />, href: "/domains" },
    ...(permissions.includes("launchOS")
      ? [
          {
            name: "Integrations",
            icon: <FlashIcon className="mr-1" />,
            href: "/integrations",
          },
          {
            name: "Support",
            icon: <SupportIcon />,
            href: "https://support.launchos.com/hc/launch-os-help-center/en/categories/launch-os",
          },
        ]
      : []),
  ];

  // const { darkMode, setDarkMode } = useContext(AppContext);

  return (
    <ul
      style={{ left: 0 }}
      className={`dropdown-menu border dark:border-white/5 ${
        open ? "block" : "hidden"
      }`}
      transition-enter-active-class="transition duration-200 transform ease-out"
      transition-enter-class="opacity-0 translate-y-1"
      transition-enter-to-class="opacity-100 translate-y-0"
      transition-leave-active-class="transition duration-100 transform ease-in"
      transition-leave-class="opacity-100 translate-y-0"
      transition-leave-to-class="opacity-0 translate-y-1"
    >
      <li>
        <div className="flex items-center !p-1">
          <div className="flex-none">
            {/* <img
              className="h-7 w-7 rounded-full object-cover"
              src="https://lh3.googleusercontent.com/a/AAcHTtceqZW2SpOFxjcGs9USJMaXRHmntj5Kpd4WXRjN6g=s192-c-mo"
              alt="image"
            /> */}
            <Avatar type="Rounded" email={user?.email} height="7" width="7" />
          </div>
          <div className="pl-2">
            <h4 className="text-sm text-black dark:text-white font-medium leading-none">
              {user?.firstName} {user?.lastName?.slice(0, 1)}.
            </h4>
            <Link
              className="text-black/40 dark:text-white/40 hover:text-black dark:hover:text-white text-xs"
              to="/profile"
            >
              {user?.email}
            </Link>
          </div>
        </div>
      </li>
      <li className="h-px bg-black/5 block my-1"></li>
      {navItems.map((item, index) => (
        <li key={index}>
          <Link to={item.href} className="flex items-center">
            {item.icon}
            {item.name}
          </Link>
        </li>
      ))}
      <li className="h-px bg-black/10 dark:bg-white/10 block my-1"></li>
      {/* Dark & Light mode toggle */}
      {/* <li>
        <Link
          to="#"
          className="flex items-center"
          onClick={() => setDarkMode(!darkMode)}
        >
          {darkMode ? (
            <LightToggleIcon className="mr-1" />
          ) : (
            <DarkToggleIcon className="mr-1" />
          )}
          {darkMode ? "Light mode" : "Dark mode"}
        </Link>
      </li>
      <li className="h-px bg-black/5 dark:bg-white/5 block my-1"></li> */}
      <li>
        <Link
          to="/login"
          className="text-black dark:text-white flex items-center"
        >
          <SignoutIcon />
          Sign Out
        </Link>
      </li>
    </ul>
  );
};

export const AvatarDropdown = ({ open, setOpen, settings = {} }) => {
  const navigation = useNavigate();
  return (
    <>
      <SignedIn>
        <UserButton
          showName
          appearance={{
            elements: {
              userButtonBox: "flex flex-row-reverse",
            },
          }}
        >
          <UserButton.MenuItems>
            <UserButton.Action
              label="Domain Names"
              labelIcon={<SettingsIcon />}
              onClick={() => navigation("/domains")}
            />
            <UserButton.Action
              label="Integrations"
              labelIcon={<FlashIcon className="mr-1" />}
              onClick={() => navigation("/integrations")}
            />
          </UserButton.MenuItems>
        </UserButton>
        <span className="text-black/40 dark:text-white/40">
          <DownCarrotIcon />
        </span>
      </SignedIn>
      <SignedOut>
        <div
          data-testid="AvatarDropdown"
          className="profile flex items-center justify-between cursor-pointer w-full"
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
        >
          <button
            type="button"
            className="flex items-center justify-between gap-1.5"
          >
            <Avatar
              type="Rounded"
              email={settings?.email}
              height="7"
              width="7"
            />
            <span className="fw-medium hidden xl:block hover:font-semibold">
              {settings?.firstName} {settings?.lastName}
            </span>
          </button>
          <span className="text-black/40 dark:text-white/40">
            <DownCarrotIcon />
          </span>
          <div>
            <DropdownMenu open={open} user={settings} />
          </div>
        </div>
      </SignedOut>
    </>
  );

};
