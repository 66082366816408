import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { CTALayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.TWENTYONE,
  layout: CTALayouts.TWENTYONE,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA21.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "e1i2e4i2fnh",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: { maxWidth: 800, margin: "0 auto" },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: `Yes! I'm Ready to Join Today!`,
          },
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((2 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.PICTURE,
                    canHaveChildren: false,
                    properties: {
                      mobile: {
                        desktop: { display: "inline-block" },
                        smartphone: { display: "none" },
                      },
                    },
                    src:
                      "https://www.onlygfx.com/wp-content/uploads/2016/04/hand-drawn-arrow-1-300x276.png",
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((10 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.BUTTON,
                    canHaveChildren: false,
                    properties: { ...style.BUTTON.style, marginTop: 30 },
                    caption1: "Get Your Membership Now!",
                    caption1style: style.BUTTON.caption1,
                    caption2:
                      "...and beginning building your first funnel in minutes",
                    caption2style: style.BUTTON.caption2,
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((2 * 100) / 12),
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.PICTURE,
                    canHaveChildren: false,
                    properties: {
                      transform: `scaleX(-1)`,
                      display: "inline-block",
                      mobile: {
                        desktop: { display: "inline-block" },
                        smartphone: { display: "none" },
                      },
                    },
                    src:
                      "https://www.onlygfx.com/wp-content/uploads/2016/04/hand-drawn-arrow-1-300x276.png",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
