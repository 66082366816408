import React from "react";
import PageScripts from "./PageScripts";
import { ComponentRenderHooks } from "@/plugins/types";

export const onComponentRender = (hook, { trackingPayload }) => {

  if (hook.id === ComponentRenderHooks.BODY_START && trackingPayload.length)
    return [<PageScripts key="page_scripts" payload={trackingPayload} type="body" />];

  if (hook.id === ComponentRenderHooks.PAGE_LOAD && trackingPayload.length)
    return [<PageScripts key="page_scripts" payload={trackingPayload} type="head" />];

  if (hook.id === ComponentRenderHooks.BODY_END && trackingPayload.length)
    return [<PageScripts key="page_scripts" payload={trackingPayload} type="bodyEnd" />];

  // else return null;
};

export default PageScripts;
