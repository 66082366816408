import { CopilotHooks, HookObject, ListItemsHooks } from "@/plugins/types";
import { ChatBot } from "./ChatBot";
import { ComponentTypes } from "../Pages/Editor/types";
import { Sparkle, Textbox } from "@phosphor-icons/react";
import { CopilotGallery } from "./CopilotGallery";
import { Copilot } from "./Copilot";
import { SettingsIcon, Sparkles } from "lucide-react";
import { handleAskAIComponentRequest, handleGetAlternateCopyRequest } from "../Pages/Dashboard";
import artifacts from "./prompts/artifacts";
import general from "./prompts/general";
import { listAncestors, listChildren, recursivelyRetrieveItem } from "../Pages/V2Editor/actions";
import { isJsonString } from "@/utils";
import { getBasicUserInfo } from "@/api/auth";
// import { useCmdK } from "@/ui/CommunicationView/CmdK/CmdK";

const updateComponentSettingsMenuItem = [
  { id: "divider" },
  // { id: "update-settings", iconComponent: <SettingsIcon />, text: "Edit Component Settings" },
];

export const onListItems = (
  { id, position, type },
  payload,
  actions,
  // { permissions = [] }
) => {
  const user = getBasicUserInfo()
  const { permissions } = user;
  
  // if (!permissions.includes("copilot")) return [];

  if (id === ListItemsHooks.EDITOR_OBJECT_MENU) {

    // alert(JSON.stringify(permissions))

    if (type === ComponentTypes.TEXT || type === ComponentTypes.PARAGRAPH || type === ComponentTypes.HEADLINE) {
      return [
        { id: "ai-ask", iconComponent: <Textbox />, text: "Ask Copilot" },
        { id: "ai-copy",  iconComponent: <Sparkles />, text: "Improve Copy" },
        { id: "prompt", iconComponent: <></>, text: "Set Prompt" },
        { id: "divider" },
        ...payload,
        ...(permissions.includes("admin") ? updateComponentSettingsMenuItem : []),
      ];
    }

    return [
      { id: "ai-ask", iconComponent: <Sparkles />, text: "Ask Copilot" },
      { id: "prompt", iconComponent: <></>, text: "Set Prompt" },
      { id: "divider" },
      ...payload,
      ...(permissions.includes("admin") ? updateComponentSettingsMenuItem : []),
    ];

  }

  if (id === ListItemsHooks.APP_DRAWER && position === "top") {
    if (!permissions.includes("copilot")) return [];
    return [
      {
        icon: <Sparkle className="text-lg" />,
        label: "Copilot",
        href: "/copilot",
      },
    ];
  }

  if (id === ListItemsHooks.ROUTES) {
    return [
      {
        label: "Copilot",
        path: "/copilot",
        element: <Copilot name="Launch Copilot" />,
        children: [
          {
            path: ":id",
            element: <Copilot name="Launch Copilot - Name" />,
          },
        ],
      },
      {
        label: "Copilot",
        path: "/copilot/all",
        element: <CopilotGallery name="Launch Copilot" />,
      },
      {
        label: "Copilot",
        path: "/copilot/test",
        element: <ChatBot />,
      },
    ];
  }
};

export const onChatPrompt = (
  hook: HookObject,
  { context },
  { copilotContext }
) => {
  if (hook.id === CopilotHooks.GET_SYSTEM_PROMPT && context && copilotContext) {
    // const { copilotContext } = actions;
    console.log("About to System Prompt", context, copilotContext);

    let systemPrompt = "";
    
    // grab general and artifact prompt
    systemPrompt += artifacts;
    systemPrompt += '\n\n';
    systemPrompt += general;

    // console.log("The System Prompt", systemPrompt);

    return systemPrompt;
  }
};

export const onMenuSelect = async ({ id, type }, payload, { openCmdK, complete }) => {
  // console.log("onMenuSelect", id, type, payload);
  if (id === ListItemsHooks.EDITOR_OBJECT_MENU && payload.id === "ai-ask") {
    const { settings, updateComponentSettings, pageContent } = payload;

    const ancestors = listAncestors(pageContent, settings.id);
    const section = ancestors?.find(({ type }) => type === ComponentTypes.SECTION)
    const sectionChildrenOne = listChildren(pageContent, section.id);
    const sectionChildrenTwo = recursivelyRetrieveItem(pageContent, section.id);    
 
    console.log("Current Payload Is...", payload, ancestors, { sectionChildrenOne, sectionChildrenTwo});

    const prompt = window.prompt("Page Instructions...[esc] to close");
    if (!prompt) return;
    // openCmdK();
    if (type === ComponentTypes.TEXT || type === ComponentTypes.PARAGRAPH || type === ComponentTypes.HEADLINE) {

      await handleGetAlternateCopyRequest({
        settings,
        prompt,
        updateComponent: updateComponentSettings,
      });

    }    
    else {
      await handleAskAIComponentRequest({ 
        prompt,
        payload,        
      });
    }
  } else if (id === ListItemsHooks.EDITOR_OBJECT_MENU && payload?.id === "ai-copy") {
    if (type === ComponentTypes.TEXT || type === ComponentTypes.PARAGRAPH || type === ComponentTypes.HEADLINE) {
      const { settings, updateComponentSettings } = payload;
      await handleGetAlternateCopyRequest({
        settings,
        updateComponent: updateComponentSettings,
      });      
    }
  } else if (id === ListItemsHooks.EDITOR_OBJECT_MENU && payload?.id === "update-settings") {
    const { updateComponentSettings, settings } = payload;
    const newSettings = window.prompt("Update Settings...[esc] to close", JSON.stringify(settings));
    if (!newSettings) return;
    if (!isJsonString(newSettings)) {
      alert("Invalid Settings, try again!"); 
      return;
    } else {
      updateComponentSettings(settings.id, JSON.parse(newSettings));
    }
  } else if (id === ListItemsHooks.EDITOR_OBJECT_MENU && payload?.id === "prompt") {
    const { updateComponentSettings, settings } = payload;
    const prompt = window.prompt("Update Prompt...[esc] to close", settings?.prompt);
    if (!prompt) return;
    updateComponentSettings(settings.id, {...settings, prompt});
  }
};

export { ChatBot };
