import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
// import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import DangerousHTML from 'react-dangerous-html';
import { PluginProps } from "./types";

import { LiquidRenderer } from "@/Apps/Pages/Page/LiquidRenderer";
export const HTMLPlugin: React.FC<PluginProps> = ({ settings = { html: ""} }) => {
  const { html = "" } = settings;
  return (
    <div
      data-testid="WC-HTML-LIVE"
      // dangerouslySetInnerHTML={{ __html: html }}
    >
      {/* <DangerousHTML html={html} /> */}
      <LiquidRenderer html={html} />
    </div>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.HTML
  ) {
    return [HTMLPlugin];
  }
};

export default HTMLPlugin;
