import * as React from "react";
import { PropertiesProps } from "../types";

import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

import formBuilderSettings from "../settings";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { FormBuilder } from "@/ui/CRUD/FormBuilder";

/**
 * The property window that shows for the FormBuilderEditorObject
 */
export const Properties: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);

  const { properties } = formBuilderSettings;
  const { sections } = properties.main;

  /**
   * Triggers when the <FormBuilder /> component showing in the property window changes
   * @params
   */
  const handleFormBuilderChange = (data, dbUpdate = false): void => {
    const { updateComponentSettings } = props;
    const newSettings = { ...props.settings, data };
    // console.log({ newSettings })
    setSettings(newSettings);
    updateComponentSettings(settings.id, newSettings, dbUpdate);
  };


  const withFormBuilder = updateSection(sections, "formBuilder_settings", {
    component: (
      <div style={{
        // width: 350,
        // marginLeft: -20,
      }}>
        <FormBuilder
          {...props}
          data={settings.data}
          mode={EditorMode.EDITOR}
          onBlur={(newData) => handleFormBuilderChange(newData, true)}
          onChange={(newData) => handleFormBuilderChange(newData, true)}
        />
      </div>
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withFormBuilder },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export default React.memo(Properties);
