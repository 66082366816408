import { createContext } from "react";

interface PageContextType {
  nextPage?: string;
  seoPayload?: Record<string, unknown>;
  merchantPayload?: Record<string, unknown>;
  trackingPayload?: Record<string, unknown>;
  emailPayload?: Record<string, unknown>;
  cssPayload?: string;
  exitPayload?: Record<string, unknown>;
  id?: string;
  objectId?: string;
  campaignId?: string;
  userId?: string;
  campaignSettings?: Record<string, unknown>;
}

export const PageContext = createContext<PageContextType>({
  nextPage: "",
  seoPayload: {},
  merchantPayload: {},
  trackingPayload: {},
  emailPayload: {},
  cssPayload: "",
  exitPayload: {},
  id: "",
  objectId: "",
  campaignId: "",
  campaignSettings: {},
  userId: "",
});