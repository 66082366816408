import { useEffect, useState } from "react";
import TableView from "@/ui/DataView/TableView";
import { DefaultDataProps } from "@/ui/DataView/types";
import { CalendarBlank } from "@phosphor-icons/react";

import Modal from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import TextInput from "@/ui/TextInput";
import { TextInputFlavors } from "@/ui/TextInput/types";
import { TextArea } from "@/Login/Onboarding/Onboarding";

import Panel from "@/ui/Panel";
import Select from "@/ui/Select";
// import FileInput from "@/ui/CommunicationView/ChatConversation/FileInput";
import { getSessionToken } from "@/api/auth";
import { deleteObject, getCampaign } from "@/api/campaigns";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { EmptyState } from "@/ui/Layout";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import { Container } from "@/ui/Layout/Page";
import { getIconData } from "../Funnels/utils";

import { useCompletion } from "ai/react";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { Loading } from "@/ui/Layout/Loading";

export const CreateContentPopup = ({
  setIsCreateContentPopupOpen,
  activeObject,
  unitName = "Post",
  campaignId,
  onChange = () => {},
  onSave = () => {},
  withModal = true,
}) => {

  const [item, setItem] = useState(activeObject);

  const { complete, isLoading } = useCompletion({
    api: `${AI_DOMAIN}/api/completion`,
    body: {
      type: "chat",
      systemPrompt: "",
    },
  });

  useEffect(() => {
    onChange(item);
  }, [item])


  useEffect(() => {
    const checkAI = async () => {
      const token = getSessionToken();
      const campaignData = await getCampaign({ token, id: campaignId });
      const withAI = campaignData?.settings?.withAI;
      console.log("withAI", withAI, activeObject);

      if (withAI) {
        const content = await complete("Please create content for this post.");
        const name = await complete("Please create a title for the you just created.");
        setItem({ ...item, name, settings: { ...item?.settings, content } });

        console.log("response", title, content);
      }
    }

    if (!activeObject) checkAI();
  }, []);

  // const { type, content } = item?.settings;
  
  // if (type === "Email") {
  //   // const markdown = marked(content);
  //   const html = marked.parse(content);
  //   // console.log("html", html);
  //   // console.log("markdown", markdown);
  // }

  const childContent = (
    <div className="flex gap-7 px-3 w-full">
      <div className="flex-1 flex flex-col gap-3">
        {isLoading ? (
          <Loading type="gallery" />
        ) : (
          <>
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label="Enter a title" // value={props.firstName}
              placeholder="Enter a title"
              name="title" // onChange={props.handleProfileChange}
              value={item?.name}
              onChange={(e) => setItem({ ...item, name: e.target.value })}
            />
            {unitName === "Email" && (
            <TextInput
              flavor={TextInputFlavors.MODERN}
              label="Subject Line"
              placeholder="Enter email subject"
              name="subject"
              value={item?.settings?.subject || ''}
              onChange={(e) =>
                setItem({
                  ...item,
                  settings: { ...item.settings, subject: e.target.value },
                })
              }
            />
            )}
            <TextArea
              flavor={TextInputFlavors.MODERN}
              placeholder="Enter content"
              defaultValue={item?.settings?.content}
              value={item?.settings?.content}
              onChange={(e) =>
                setItem({
                  ...item,
                  settings: { ...item.settings, content: e.target.value },
                })
              }
              className="h-96"
            />              
            {/* )} */}
          </>
        )}
      </div>
      {unitName !== "Email" && <div className="flex w-[280px] flex-col gap-3">
        <Panel title="Post Settings" className="flex flex-col gap-4">
          <p>Post Type</p>
          <Select
            items={[
              { label: "Blog Post" },
              { label: "Facebook Post" },
              { label: "Twitter Post" },
              { label: "Instagram Post" },
              { label: "LinkedIn Post" },
              { label: "Advertisement" },
              { label: "Email" },
              { label: "Content Post" },
            ]}
            value={item?.settings?.type}
            onChange={(e) => {
              console.log("e", e.target.value);
              setItem({
                ...item,
                settings: { ...item.settings, type: e.target.value },
              });
            }}
          />
          {/* <p>Categories</p>
            <Select
              items={[{ label: "Category 1" }, { label: "Category 2" }]}
              multiple
              value={item?.settings?.categories}
              onChange={(e) => {
                console.log("e", e.target.value);
                setItem({ ...item, settings: { ...item.settings, categories: e.target.value } });
              }}
            /> */}
        </Panel>
        {/* <Panel title="Media" className="flex flex-col gap-4"> */}
        {/* <TextInput
              flavor={TextInputFlavors.CLASSIC}
              label="Media (url)" // value={props.firstName}
              placeholder="Enter a url"
              name="url" // onChange={props.handleProfileChange}
            /> */}
        {/* <FileInput /> */}
        {/* </Panel> */}
      </div>}
    </div>
  );

  // return <BroadcastOptions />;

  if (!withModal) return childContent;

  return (
    <Modal
      title={`Create New ${unitName}`}
      onClose={() => setIsCreateContentPopupOpen(false)}
      onSuccess={onSave}
      size={PopupSizes.XXXLARGE}
    >
      {childContent}
    </Modal>
  );


};

export const ContentDashboardPage = ({
  campaignData = {},
  onOpenContentItem,
  onNewContentItem,
  unitName = "Post",
}) => {

  const [content, setContent] = useState<DefaultDataProps[]>([]);

  const navigate = useNavigate();

  const handleMenuItemClick = async (id, item) => {
    // console.log("id", id);
    // console.log("item", item);
    if (id === "delete") {
      // console.log("delete");
      if (confirm("Are you sure you want to delete this item?")) {
        const response = await deleteObject(item.id);
        console.log("response", response);
        window.location.href = `/content/${campaignData?.id}`;
      }
    }
  };

  useEffect(() => {
    console.log("campaignData", campaignData);

    const contentInPackage = campaignData?.objects
      ?.filter(
        ({ deleted, type }) =>
          !deleted && (type === "ContentComponent")
      )
      .sort((a: { x: any }, b: { x: any }) => (a.x || 0) - (b.x || 0)) // order by [x]
      .map((obj) => {
        const { id, name, updatedAt, type } = obj;
        
        let { Icon, color } = getIconData(obj, false);
        return {
          id: id,
          label: name,
          // caption: `/${page?.slug}`,
          icon: (
            <div 
              className="w-8 h-8 p-1 bg-lightpurple-100 flex rounded-lg text-black text-center justify-center items-center mr-2"
              style={{ backgroundColor: color }}
            >
              {Icon && <Icon size={18} className="text-white" />}
            </div>
          ),
          data: [
            // {
            //   id: "size",
            //   label: "Categories",
            //   fieldType: "string",
            //   value: "Standard Blog Post",
            // },
            // {
            //   id: "type",
            //   label: "Author",
            //   fieldType: "string",
            //   value: "Karina Clark",
            // },
            {
              id: "date",
              label: "Last Updated",
              fieldType: "date",
              icon: (
                <CalendarBlank
                  width="16"
                  height="16"
                  className="dark:text-white/40"
                />
              ),
              value: moment(updatedAt).fromNow(),
            },
          ],
        };});

    setContent(contentInPackage);
  }, []);

  if (!content?.length) return null;
  // if (isLoading) return <div>Loading...</div>;

  return (
    <div className="h-full">
      {!Boolean(content.length) && (
        <Container>
          <EmptyState
            title="No Posts Yet"
            description="Get started by creating a new post."
            showImage={false}
          >
            <Button
              onClick={onNewContentItem}
              label="+ Create Content item"
              type={ButtonTypes.DEFAULT}
            />
          </EmptyState>
        </Container>
      )}
      {Boolean(content.length) && (
         <TableView
           items={content}
           // showFooterPagination
           showMenuItems
           menuItems={[
             // { id: "download", label: "Open" },
             // { id: "duplicate", label: "Duplicate" },
             // { type: "divider" },
             { id: "delete", label: "Delete" },
           ]}
           onItemClick={(id) => navigate(`/content/${campaignData?.id}/${id}`)}
           onMenuItemClick={handleMenuItemClick}
           // onNewItemClick={() => console.log("New item clicked")}
         />
      )}
    </div>
  );
};
