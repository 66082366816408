// import React from "react";
// import { lazy } from "react";

// const body = lazy(() => import("./body/live"));

import * as body from "./body/live";
import * as button from "./button/live";
import * as columns from "./columns/live";
import * as column from "./column/live";
import * as container from "./container/live";
import * as formField from "./formField/live";
import * as headline from "./headline/live";
import * as picture from "./picture/live";
import * as popup from "./popup/live";
// import * as social from "./social/live";
import * as text from "./text/live";
import * as video from "./video/live";
// import * as countdown from "./countdown/live";

export default [
  body,
  text,
  headline,
  button,
  container,
  columns,
  column,
  picture,
  video,
  formField,
  popup,
  // social,
  // countdown,
];
