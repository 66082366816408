import { loginUser } from "@/api/auth";
import { useSession } from "@clerk/clerk-react";
import { useEffect } from "react";

export const ClerkVerify = () => {
  const { isSignedIn, session } = useSession();
  const loginClerkUser = async () => {

    const { id } = session?.user?.publicMetadata;
    const token = await session?.getToken();

    const data = await loginUser({ clerkToken: token, userId: id });

    if (data) {
      if (data.error) {
        alert(data.error);
      } else {
        console.log("the data", data);
        if (data?.token) {
          const { permissions } = data;
          if (permissions?.includes("f$-internal")) window.location.href = "/order-forms";
          else if (permissions?.includes("scroll")) window.location.href = "/crm/contacts";
          else if (permissions?.includes("copilot")) window.location.href = "/copilot"; // go to the copilot home screen after log in
          else window.location.href = "/funnels";
        } else {
          alert("Unable to login. Please try again.");
        }
      }
    }
  };

  useEffect(() => {
    if (isSignedIn && session) {
      loginClerkUser();
    }
  }, [isSignedIn, session]);

  return <div>Logging In...</div>;
};
