import { Link, useNavigate } from "react-router-dom";
import { Page } from "@/ui/Layout";
import { Container } from "@/ui/Layout/Page";
import { Panel } from "@/ui/Panel";
import { TabsComponent } from "@/ui/TabsComponent";
import { TextInput } from "@/ui/TextInput";

import { TextInputFlavors, TextInputTypes } from "@/ui/TextInput/types";
import { getTokenInfo } from "@/api/auth";
import { useContext, useEffect, useState } from "react";
import { updateUserData } from "@/api/users";
import { getSessionToken } from "@/api/auth";
import { AppContext } from "@/AppContext";

export const AboutYou = (props) => {
  return (
    <Panel title="About You">
      <div className="grid grid-flow-row gap-7">
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label="First Name"
          value={props.firstName}
          placeholder="First Name"
          name="firstName"
          onChange={props.handleProfileChange}
        />
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label="Last Name"
          value={props.lastName}
          placeholder="Last Name"
          name="lastName"
          onChange={props.handleProfileChange}
        />
        <TextInput
          flavor={TextInputFlavors.MODERN}
          label="Email Address"
          value={props.email}
          placeholder="Email Address"
          name="email"
          onChange={props.handleProfileChange}
        />
      </div>
    </Panel>
  );
};

export const Profile = () => {
  const [userData, setUserData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
  }>({ firstName: "", lastName: "", email: "" });
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const { permissions = [] } = useContext(AppContext);

  const { firstName, lastName, email } = userData;

  const navigation = useNavigate()

  const getUserData = async () => {
    const token = getSessionToken();
    const response = await getTokenInfo(token);
    if (response) {
      // console.log("user data", { response });
      setUserData(response);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleProfileChange = (e: any) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handlePasswordChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const handleSaveChanges = async () => {
    // console.log(userData);
    // check that passwords are the same
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    // check that passwords are at least 8 characters
    // and that they are not empty
    if (
      password.length < 8 &&
      password.length > 0 &&
      confirmPasswowrd.length > 0
    ) {
      alert("Password must be at least 8 characters");
      return;
    }

    // check that email is valid
    if (!email.includes("@")) {
      alert("Invalid email address");
      return;
    }

    // check that first name and last name are not empty
    if (firstName === "" || lastName === "") {
      alert("First name and last name cannot be empty");
      return;
    }

    // Save the changes
    // console.log(userData);
    // send the userData, but first sanitize it such that only strings are sent
    const token = getSessionToken();
    // const userId = localStorage.getItem("userId");
    const { id } = userData;
    let data = { firstName, lastName, email };
    if (password.length > 0) data = { ...data, password };
    console.log(data, id);
    const response = await updateUserData(id, { token, data });
    if (response) {
      if (response.error) {
        alert(response.error);
      } else {
        // alert("Changes Saved");
        window.location.href = "/login"; // redirect to login page because the token needs to be refreshed
        
        console.log(response);
      }
    }
  };

  return (
    <Page topBar={{ page: [{ label: "Profile" }] }}>
      <Container>
        <TabsComponent
          tabs={[
            { label: "Profile", value: "profile" },
            { label: "Domain Names", value: "domains" },
            ...(permissions.includes("launchOS") ? [{ label: "Integrations", value: "integrations" }] : [])
          ]}
          activeTab={"profile"}
          setActiveTab={(val) => {
            // window.location.href = `/${val}`;
            navigation(`/${val}`);
          }}
          actionSection={false}
        />

        <div className="grid grid-flow-row gap-7">
          <AboutYou
            firstName={firstName}
            lastName={lastName}
            email={email}
            handleProfileChange={handleProfileChange}
          />

          <Panel title="Password">
            <div className="grid grid-flow-row gap-7">
              <TextInput
                type={TextInputTypes.PASSWORD}
                flavor={TextInputFlavors.MODERN}
                label="New Password"
                value={password}
                name="password"
                placeholder="New Password"
                onChange={handlePasswordChange}
              />
              <TextInput
                type={TextInputTypes.PASSWORD}
                flavor={TextInputFlavors.MODERN}
                label="Confirm Password"
                value={confirmPassword}
                name="confirmPassword"
                placeholder="Confirm Password"
                onChange={handlePasswordChange}
              />
            </div>
          </Panel>

          <div className="-mx-7 -mb-7 border-black/10 dark:border-white/10 px-7 py-[18px] flex justify-end gap-4">
            <Link to="/" className="btn">
              Discard
            </Link>
            <Link to="#" onClick={handleSaveChanges} className="btn">
              Save Changes
            </Link>
          </div>
        </div>
      </Container>
    </Page>
  );
};
