import _ from "lodash";

import { Helmet } from "react-helmet";
import { generateInlineStyle } from "@/Apps/Pages/Properties/RichEditor/utilities";
import { EditorMode } from "@/Apps/Pages/Editor/types";
import { LiquidRenderer } from "@/Apps/Pages/Page/LiquidRenderer";

const Content = props => {
  const { settings, queryString, pageId } = props;
  const { actions } = settings;

  const Parent = x => (
    <div style={{ ...settings.properties, marginTop: 0 }}>{x.children}</div>
  );
  
  const content = (
    <>
      <Helmet>
        <style type="text/css">
          {generateInlineStyle(settings, EditorMode.LIVE)}
        </style>
      </Helmet>
      <div
        style={{ display: "inline" }}
        className="wc-ds-html"
        data-settings-id={settings.id}
      >
        {(typeof window === "function") === "undefined" ? (
          `<%= item['${pageId}']['${settings.id}']['html'] %>`
        ) : (
          <LiquidRenderer html={settings.html} />
        )}
      </div>
    </>
  );

  const haslink = _.get(
    _.filter(
      actions,
      itm =>
        itm.behavior === "click" &&
        (itm.type === "GO_TO_URL" || itm.type === "TRIGGER_NEXT_NODE")
    ),
    0,
    false
  );

  let href = haslink
    ? _.get(actions, "[0].payload.url", _.get(settings, "href", ""))
    : "";

  href = _.has(props, "queryString") ? `${href}?id=${queryString.id}` : href;

  return haslink ? (
    <Parent>
      <a href={href} style={{ cursor: "pointer", textDecoration: "underline" }}>
        {content}
      </a>
    </Parent>
  ) : (
    <Parent>{content}</Parent>
  );
};

export const Paragraph = props => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  const handleClick = () => {
    const {
      dispatch,
      settings,
      cKey,
      fieldValues,
      updateComponentSettings,
      getComponentSettings
    } = props;
    const { actions } = settings;

    if (actions) {
      const doTheseActions = actions.filter(itm => itm.behavior === "click");
      doTheseActions.forEach(action =>
        dispatch({
          ...action,
          settings: { ...settings, cKey, fieldValues },
          updateComponentSettings,
          getComponentSettings
        })
      );
    }
  };

  return (
    <div data-testid="WC-TEXT-LIVE" onClick={handleClick} style={{ marginTop, textAlign }}>
      <Content {...props} />
    </div>
  );
};

// export default connect()(Paragraph);
export default Paragraph;
