// import { Cell, FontIcon } from "@launchos/components/ui";
import _ from "lodash";

import { DragSource } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { IconSource } from "@launchos/plugins/webcomponents/v2/Icon/types";

import { styles, BasicStyle } from "./style";
import { Cell } from "@/ui/_old/Grid";
import FontIcon from "@/ui/_old/FontIcon";
import { IconSource } from "../WebComponents/v2/Icon/types";
import { CURSOR_ID } from "../Editor/types";

// const CURSOR_ID = 1111;

/**
 * This represents a draggable object in the Item Drawer
 * Users are able to drag these items onto the canvas
 * @param {object} props The props
 * @returns {function} The component
 */

const componentSource = {
  beginDrag(props) {
    return { ...props };
  },

  endDrag(props) {
    let { settings, addThisAfterThat, onClose } = props;

    const { properties } = settings;

    settings = {
      ...props.settings,
      properties: properties || false,
    };

    addThisAfterThat(settings, CURSOR_ID);
    // onClose();

    console.log("END DRAG!", settings);
  },
};

const ObjectThumbnailComponent = ({ item, connectDragSource }) => {
  return connectDragSource(
    <div>
      {typeof item.thumbnail === "object" ? (
        <Cell>
          <BasicStyle style={_.get(item, "thumbnail.style", {})}>
            {_.get(item, "thumbnail.iconSource") === IconSource.FONTAWESOME ? (
              <div style={{ position: "relative" }}>
                <span
                  style={{
                    ...styles.icon,
                    position: "absolute",
                    color: "transparent",
                    fontSize: "14pt",
                  }}
                >
                  ▢
                </span>
                <FontAwesomeIcon
                  style={{
                    ...styles.icon,
                    ..._.get(item, "thumbnail.iconStyle", {}),
                  }}
                  icon={_.get(item, "thumbnail.icon", <div />)}
                />
              </div>
            ) : (
              <FontIcon
                style={{
                  ...styles.icon,
                  ..._.get(item, "thumbnail.iconStyle", {}),
                }}
              >
                {_.get(item, "thumbnail.icon", "block")}
              </FontIcon>
            )}

            <div style={{ padding: 7, lineHeight: 'normal' }}>{_.get(item, "name")}</div>
          </BasicStyle>
        </Cell>
      ) : (
        <Cell>
          <BasicStyle
            style={{
              background: `url(https://s3.amazonaws.com/sandcastleassets/images/icons/${item.thumbnail})`,
            }}
          />
        </Cell>
      )}
    </div>
  );
};

export default DragSource("box", componentSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(ObjectThumbnailComponent);
