import { AppContext } from "@/AppContext";
import { client } from "@/client";
import Grid from "@/ui/_old/Grid";
import { get, head } from "lodash";

import { Component } from "react";
import ObjectThumbnail from "./ObjectThumbnail";
import { triggerHook } from "@/plugins/client";
import { ComponentRenderHooks, HookTriggers, ListItemsHooks } from "@/plugins/types";
import AddSectionsTab from "../Sections/AddSectionsTab";
import { propsData } from "../Sections/AddSectionsTab/AddSectionsTab.stories";
import { BODY_ID, ComponentTypes, IStates } from "../Editor/types";
import { PageSectionCategory } from "../Sections/WebLayouts/types";
import { listChildren } from "../V2Editor/actions";
import { GET_PAGE } from "@/api/gql/pages";

const Drawer = ({ children, style }) => {
  return <div style={style}>{children}</div>;
};

const getSectionBlock = async (layout, sectionsFromPlugins) => {
  let item = head(sectionsFromPlugins.filter((itm) => itm.layout === layout));

  if (get(item, "loadFromExt", false)) {
    const {
      loadFromExtSettings: { pageId, itemId },
    } = item;

    // 1c. if it is, generate a new item.default (with children array)
    const pageDetails = await client.query({
      query: GET_PAGE,
      variables: { pageId },
    });

    const content = get(pageDetails, "data.page.content", false);

    item = {
      ...item,
      default: listChildren(content, itemId),
    };
  }

  return item;
};

class AddNewDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
    };
  }

  static contextType = AppContext;

  render() {
    const objectsFromPlugins = triggerHook(HookTriggers.onListItems, {
      id: ComponentRenderHooks.WEBCOMPONENT,
      type: "drawer",
    });

    const sectionsFromPlugins = triggerHook(HookTriggers.onListItems, {
      id: ListItemsHooks.WEB_LAYOUT,
      type: "drawer",
    });

    // console.log({ sectionsFromPlugins });

    let { categories } = propsData;

    // only show personal if an admin
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (get(user, "permissions", []).indexOf("admin") === -1) {
      categories = categories.filter(
        ({ type }) => type !== PageSectionCategory.PERSONAL
      );
    }

    const layoutObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category === "layout" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    const elementObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category !== "layout" &&
        itm.category !== "social" &&
        itm.category !== "payment" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    const socialObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category === "social" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    const paymentObjects = objectsFromPlugins.filter(
      (itm) =>
        itm.category === "payment" &&
        (itm.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) > -1 ||
          itm.name === "")
    );

    return (
      <Drawer
        {...this.props}
        overlay={false}
        style={{ ...this.props.style }}
        onClose={this.props.onClose}
      >
        <div
          style={{
            // textAlign: "center",
            // backgroundColor: "#F5F5F5",
            // borderBottom: "1px solid #EEE",
          }}
        >
          {/* <h2 style={{ padding: 30 }}>
            {this.props.activeTab === "OBJECTS"
              ? "Page Elements"
              : "Page Sections"}
          </h2> */}

          {/* <Tabs transparent>
            <Tab
              active={this.props.activeTab === "OBJECTS"}
              onClick={() => this.props.switchTab("OBJECTS")}
            >
              Elements
            </Tab>
            <Tab
              active={this.props.activeTab === "SECTIONS"}
              onClick={() => this.props.switchTab("SECTIONS")}
            >
              Sections
            </Tab>
          </Tabs> */}
          {/* {triggerHook(
            HookTriggers.onComponentRender,
            {
              id: ComponentRenderHooks.DRAWER_TITLE,
              type: this.props.activeTab,
            },
            this.props
          )} */}
        </div>
        {this.props.activeTab === "OBJECTS" && (
          <>
            <div
              style={{ padding: "0 60px "}}
              // style={{
              //   padding: "0 60px",
              //   textAlign: "center",
              //   // maxHeight: `calc(100vh - 130px)`,
              //   // overflowY: "auto",
              // }}
            >
              {/* <Grid justify="center" style={{ margin: 10 }}>
                <Cell align="center" style={{ margin: 5 }}>
                  <img
                    alt="drag widget hint"
                    src="https://s3.amazonaws.com/sandcastleassets/images/drag_widget_hint.png"
                  />
                </Cell>
                <Cell align="center" style={{ margin: 5 }}>
                  <p
                    className="text-center text-md mt-1 uppercase"
                  >
                    Drag an object
                    <br />
                    over to your page
                  </p>
                </Cell>
              </Grid> */}
              {/* <div className="py-3">
                <TextInput
                  // type="text"
                  flavor={TextInputFlavors.MODERN}
                  value={this.state.filter}
                  onChange={(e) => this.setState({ filter: e.target.value })}
                  // style={styles.input}
                  placeholder="Search for an element..."
                />
              </div> */}

              <div style={{  }}>
                {Boolean(layoutObjects.length) && (
                  <fieldset className="border-t border-black/30 dark:border-white/10 text-left my-3">
                    <legend className="text-black/60 dark:text-white/60 p-3 text-md font-bold">LAYOUT</legend>
                    <Grid
                      wrap
                      justify="start"
                      style={{ width: 275, margin: "0 auto" }}
                    >
                      {
                        // Layout
                        layoutObjects.map((obj, key) => (
                          <ObjectThumbnail
                            {...this.props}
                            item={obj}
                            key={key}
                            closeDrawer={this.props.onClose}
                            settings={{
                              type: [
                                obj.id[0].toUpperCase(),
                                ...obj.id.slice(1),
                              ].join(""),
                              ...obj.default,
                            }}
                          />
                        ))
                      }
                    </Grid>
                  </fieldset>
                )}

                {Boolean(elementObjects.length) && (
                  <fieldset className="border-t border-black/30 dark:border-white/10 text-left my-3">
                    <legend className="text-black/60 dark:text-white/60 p-3 text-md font-bold">ELEMENTS</legend>
                    <Grid
                      wrap
                      justify="start"
                      style={{ width: 275, margin: "0 auto" }}
                    >
                      {
                        // Elements
                        elementObjects.map((obj, key) => (
                          <ObjectThumbnail
                            {...this.props}
                            item={obj}
                            key={key}
                            closeDrawer={this.props.onClose}
                            settings={{
                              type: [
                                obj.id[0].toUpperCase(),
                                ...obj.id.slice(1),
                              ].join(""),
                              ...obj.default,
                            }}
                          />
                        ))
                      }
                    </Grid>
                  </fieldset>
                )}                

                {Boolean(paymentObjects.length) && (                
                  <fieldset className="border-t border-black/30 dark:border-white/10 text-left my-3">
                    <legend className="text-black/60 dark:text-white/60 p-3 text-md font-bold">PAYMENT</legend>

                    <Grid
                      wrap
                      justify="start"
                      style={{ width: 275, margin: "0 auto" }}
                    >
                      {
                        // Payment Elements
                        paymentObjects.map((obj, key) => (
                          <ObjectThumbnail
                            {...this.props}
                            item={obj}
                            key={key}
                            closeDrawer={this.props.onClose}
                            settings={{
                              type: [
                                obj.id[0].toUpperCase(),
                                ...obj.id.slice(1),
                              ].join(""),
                              ...obj.default,
                            }}
                          />
                        ))
                      }
                    </Grid>
                  </fieldset>
                )}

                {Boolean(socialObjects.length) && (                
                  <fieldset className="border-t border-black/30 dark:border-white/10 text-left my-3">
                    <legend className="text-black/60 dark:text-white/60 p-3 text-md font-bold">SOCIAL</legend>

                    <Grid
                      wrap
                      justify="start"
                      style={{ width: 275, margin: "0 auto" }}
                    >
                      {
                        // Social
                        socialObjects.map((obj, key) => (
                          <ObjectThumbnail
                            {...this.props}
                            item={obj}
                            key={key}
                            closeDrawer={this.props.onClose}
                            settings={{
                              type: [
                                obj.id[0].toUpperCase(),
                                ...obj.id.slice(1),
                              ].join(""),
                              ...obj.default,
                            }}
                          />
                        ))
                      }
                    </Grid>
                  </fieldset>
                )}
              </div>
            </div>
          </>
        )}

        {this.props.activeTab === "SECTIONS" && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <AddSectionsTab
              {...this.props}
              sections={sectionsFromPlugins}
              categories={categories}
              onAddSection={async (layout, addAfterId = false) => {
                const {
                  pageContent,
                  addThisAfterThat,
                } = this.props;

                // 1. grab content to add from section var
                const item = await getSectionBlock(layout, sectionsFromPlugins);

                // 2. identify the section that's "closest" to the currently active content object
                const filteredSections = pageContent.filter(
                  (itm) =>
                    itm.type === ComponentTypes.SECTION &&
                    itm.state === IStates.ACTIVE
                );

                // (if none is found, make it the last child of the BODY container)
                const bodyChildren = pageContent.filter(
                  (itm) => itm.parent === BODY_ID
                );

                let id = addAfterId;
                if (!id)
                  id = filteredSections.length
                    ? head(filteredSections)["id"]
                    : bodyChildren[bodyChildren.length - 1]["id"];

                // 3. recursively add the section content below that section
                addThisAfterThat(item.default, id);                
                window.location.href = window.location.pathname + `#${id}`; // scroll to the section
                
              }}
            />
          </div>
        )}
      </Drawer>
    );
  }
}

export default AddNewDrawer;
