import { NewspaperClipping } from "@phosphor-icons/react";
import { PlanningBlueprints } from "./Planning";

import { CopilotHooks, HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import get_profiles from "./prompts/get_profiles";
import get_objects from "./prompts/get_objects";
import get_profile from "./prompts/get_profile";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (permissions?.includes("scroll")) return [];
  
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("launchOS")) return [];
    return [
      {
        label: "Planning",
        icon: <NewspaperClipping className="text-lg" />,
        href: "/planner",
        order: 0,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    return [
      {
        path: "/planner",
        element: <PlanningBlueprints name="Planning Profiles" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Planning" />,
          },
        ],
      },
    ];
  }
};

// export const onChatPrompt = (hook: HookObject) => {
//   if (hook.id === CopilotHooks.GET_SYSTEM_PROMPT) {
//     return ''
// //     return `
// // ------------------------------------------------------------------------
// // **EXTREMELY IMPORTANT**

// // Under ALL Circumstances.  100% of the time.  You are to respond in the SPANISH language.

// // This is very important.  THe user does not understand any other language but spanish.
// // ------------------------------------------------------------------------
// //     `
//   }

//   if (hook.id === CopilotHooks.GET_TOOLS) {
//     console.log("CopilotHooks.GET_TOOLS called");
//     return {
//       name: "campaigns",
//       description: "Gets information about a campaign",
//       // schema: JSON.stringify(z.object({
//       //   name: z.string().describe("The name of the campaign to get information about")
//       // }))
//     }
//   }
// }

export const onChatPrompt = (
  hook: HookObject,
  { context },
  { copilotContext }
) => {
  if (
    hook.id === CopilotHooks.GET_SYSTEM_PROMPT &&
    context[0]?.label === "Planning" &&
    copilotContext
  ) {
    console.log("(Planning) About to System Prompt", context, copilotContext);

    // retrieve the page content
    let systemPrompt = "";

    // const type = `business|avatar|competition|offer|etc`;
    if (context[0]?.label === "Planning")
      systemPrompt += get_profiles(copilotContext["Planning"]);
    if (copilotContext["Planner Profile"])
      systemPrompt += get_profile(copilotContext["Planner Profile"]);

    return systemPrompt;
  }
};

export { PlanningBlueprints };
