import { PenNib } from '@phosphor-icons/react';
import { Content } from './Content';

import { CopilotHooks, HookObject, ListItemsHooks } from "@/plugins/types";
import { Dashboard } from "./Dashboard";
import { ContentEditor } from './ContentEditor';
import get_packages from './prompts/get_packages';
import get_objects from "./prompts/get_objects";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("content")) return [];
    return [
      {
        label: "Content",
        icon: <PenNib className="text-lg" />,
        href: "/content",
        order: 8,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("content")) return [];
    return [
      {
        path: "/content",
        element: <Content name="Content Library" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Content Library" />,
          },
          {
            path: ":packageId/:objectId",
            element: <ContentEditor />,
          },
        ],
      },
    ];
  }
};


export const onChatPrompt = (
  hook: HookObject,
  { context },
  { copilotContext }
) => {
  if (
    hook.id === CopilotHooks.GET_SYSTEM_PROMPT &&
    context[0]?.label === "Content" &&
    copilotContext
  ) {
    console.log("(Content) About to System Prompt", context, copilotContext);

    // retrieve the page content
    let systemPrompt = "";

    if (context[0]?.label === "Content") systemPrompt += get_packages(copilotContext["Content"]);
    if (copilotContext['Content Dashboard']) systemPrompt += get_objects(copilotContext["Content Dashboard"]);
    

    return systemPrompt;
  }
};
export { Content };