import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: ContentLayouts.FOUR,
  layout: ContentLayouts.FOUR,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/content/Content04.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckky7r33x28f507806a3vy0ss",
    itemId: "0xhl8v31qjr",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CONTENT,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((5 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              getImagePlaceholder({
                type: ImagePlaceholderTypes.NONE,
                src:
                  "https://images.unsplash.com/photo-1539125530496-3ca408f9c2d9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
                style: {
                  height: "100%",
                  // backgroundColor: theme.backgroundColor,
                  margin: "0 auto",
                },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((7 * 100) / 12),
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.CONTAINER,
                canHaveChildren: false,
                properties: {
                  padding: "0 50px",
                },
                children: [
                  {
                    type: ComponentTypes.TEXT,
                    canHaveChildren: false,
                    properties: style.TEXT,
                    html: "Pre Headline Hook...",
                  },
                  {
                    type: ComponentTypes.HEADLINE,
                    canHaveChildren: false,
                    properties: style.HEADLINE,
                    html:
                      "Free report reveals how to get the most out of your ability to  include actual images.",
                  },
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: false,
                    properties: {
                      marginTop: 40,
                    },
                    children: [
                      {
                        type: ComponentTypes.COLUMNS,
                        canHaveChildren: false,
                        properties: {
                          width: "100%",
                        },
                        children: [
                          {
                            type: ComponentTypes.COLUMN,
                            canHaveChildren: true,
                            preventDelete: true,
                            md: parseInt((6 * 100) / 12),
                            properties: {
                              height: "100%",
                              padding: 10,
                              minHeight: 20,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  fontSize: "16pt",
                                },
                                html: "First Headline",
                              },
                              {
                                type: ComponentTypes.TEXT,
                                canHaveChildren: false,
                                properties: style.TEXT,
                                html:
                                  "It's true. Getting results is much easier than the relationship between the links it contains",
                              },
                            ],
                          },
                          {
                            type: ComponentTypes.COLUMN,
                            canHaveChildren: true,
                            preventDelete: true,
                            md: parseInt((6 * 100) / 12),
                            properties: {
                              height: "100%",
                              padding: 10,
                              minHeight: 20,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  fontSize: "16pt",
                                },
                                html: "Second Headline",
                              },
                              {
                                type: ComponentTypes.TEXT,
                                canHaveChildren: false,
                                properties: style.TEXT,
                                html:
                                  "It's true. Getting results is much easier than the relationship between the links it contains",
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: ComponentTypes.COLUMNS,
                        canHaveChildren: false,
                        properties: {
                          width: "100%",
                        },
                        children: [
                          {
                            type: ComponentTypes.COLUMN,
                            canHaveChildren: true,
                            preventDelete: true,
                            md: parseInt((6 * 100) / 12),
                            properties: {
                              height: "100%",
                              padding: 10,
                              minHeight: 20,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  fontSize: "16pt",
                                },
                                html: "Third Headline",
                              },
                              {
                                type: ComponentTypes.TEXT,
                                canHaveChildren: false,
                                properties: style.TEXT,
                                html:
                                  "It's true. Getting results is much easier than the relationship between the links it contains",
                              },
                            ],
                          },
                          {
                            type: ComponentTypes.COLUMN,
                            canHaveChildren: true,
                            preventDelete: true,
                            md: parseInt((6 * 100) / 12),
                            properties: {
                              height: "100%",
                              padding: 10,
                              minHeight: 20,
                            },
                            children: [
                              {
                                type: ComponentTypes.HEADLINE,
                                canHaveChildren: false,
                                properties: {
                                  ...style.HEADLINE,
                                  fontSize: "16pt",
                                },
                                html: "Fourth Headline",
                              },
                              {
                                type: ComponentTypes.TEXT,
                                canHaveChildren: false,
                                properties: style.TEXT,
                                html:
                                  "It's true. Getting results is much easier than the relationship between the links it contains",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
