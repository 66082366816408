import moment from "moment";
import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import {
  LinkDisplayTypes,
  LinkLayoutStyles,
} from "@/Apps/Pages/WebComponents/v2/Navigation/types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { IconSource } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import {
  faFacebookF,
  faGoogle,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: FooterLayouts.SEVEN,
  layout: FooterLayouts.SEVEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/footers/Footer07.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkzoubo42irl0780q5gtpfk3",
    itemId: "g7mtk0jmmd",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.FOOTER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          mobile: {
            desktop: {
              display: "block",
            },
            smartphone: {
              display: "none",
            },
          },
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: {
                  height: "100%",
                  padding: 0,
                  minHeight: 20,
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 0,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Information",
                      },
                      {
                        type: ComponentTypes.NAVIGATION,
                        canHaveChildren: false,
                        linkStyle: style.NAVIGATION.linkStyle,
                        layoutStyle: LinkLayoutStyles.VERTICAL,
                        properties: {
                          padding: 20,
                        },
                        data: [
                          {
                            id: uniqueId(),
                            caption: "About Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "Contact Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "FAQs",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: {
                  height: "100%",
                  padding: 0,
                  minHeight: 20,
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 0,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Support",
                      },
                      {
                        type: ComponentTypes.NAVIGATION,
                        canHaveChildren: false,
                        linkStyle: style.NAVIGATION.linkStyle,
                        layoutStyle: LinkLayoutStyles.VERTICAL,
                        properties: {
                          padding: 20,
                        },
                        data: [
                          {
                            id: uniqueId(),
                            caption: "About Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "Contact Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "FAQs",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: {
                  height: "100%",
                  padding: 0,
                  minHeight: 20,
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 0,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Terms",
                      },
                      {
                        type: ComponentTypes.NAVIGATION,
                        canHaveChildren: false,
                        linkStyle: style.NAVIGATION.linkStyle,
                        layoutStyle: LinkLayoutStyles.VERTICAL,
                        properties: {
                          padding: 20,
                        },
                        data: [
                          {
                            id: uniqueId(),
                            caption: "About Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "Contact Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "FAQs",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: {
                  height: "100%",
                  padding: 0,
                  minHeight: 20,
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 0,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Company",
                      },
                      {
                        type: ComponentTypes.NAVIGATION,
                        canHaveChildren: false,
                        linkStyle: style.NAVIGATION.linkStyle,
                        layoutStyle: LinkLayoutStyles.VERTICAL,
                        properties: {
                          padding: 20,
                        },
                        data: [
                          {
                            id: uniqueId(),
                            caption: "About Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "Contact Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "FAQs",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: parseInt((3 * 100) / 12),
                properties: {
                  height: "100%",
                  padding: 0,
                  minHeight: 20,
                },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 0,
                    },
                    children: [
                      {
                        type: ComponentTypes.HEADLINE,
                        canHaveChildren: false,
                        properties: {
                          ...style.HEADLINE,
                          ...get(content, "main_headline.style", {}),
                        },
                        html: "Features",
                      },
                      {
                        type: ComponentTypes.NAVIGATION,
                        canHaveChildren: false,
                        linkStyle: style.NAVIGATION.linkStyle,
                        layoutStyle: LinkLayoutStyles.VERTICAL,
                        properties: {
                          padding: 20,
                        },
                        data: [
                          {
                            id: uniqueId(),
                            caption: "About Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "Contact Us",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                          {
                            id: uniqueId(),
                            caption: "FAQs",
                            linkDisplayType: LinkDisplayTypes.TEXT,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          mobile: {
            desktop: {
              marginTop: 60,
            },
            smartphone: {
              marginTop: 0,
            },
          },
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.NAVIGATION,
                canHaveChildren: false,
                linkStyle: style.NAVIGATION.linkStyle,
                layoutStyle: LinkLayoutStyles.HORIZONTAL,
                properties: {
                  padding: 20,
                  display: "inline-block",
                  mobile: {
                    desktop: {
                      textAlign: "left",
                    },
                    smartphone: {
                      textAlign: "center",
                    },
                  },
                },
                data: [
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Twitter",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Facebook",
                  },
                  {
                    id: uniqueId(),
                    caption: " ",
                    linkDisplayType: LinkDisplayTypes.ICON,
                    label: " ",
                    icon: "Instagram",
                  },
                ],
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.NAVIGATION,
                canHaveChildren: false,
                linkStyle: style.NAVIGATION.linkStyle,
                layoutStyle: LinkLayoutStyles.HORIZONTAL,
                properties: {
                  display: "inline-block",
                  textAlign: "right",
                },
                data: [
                  {
                    id: uniqueId(),
                    caption: "Privacy Policy",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                  {
                    id: uniqueId(),
                    caption: "Terms & Conditions",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                  {
                    id: uniqueId(),
                    caption: "Site Map",
                    linkDisplayType: LinkDisplayTypes.TEXT,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
