import Dropdown from "@/ui/Dropdown";
import Panel, { PanelItem } from "@/ui/Panel";

import { useEffect, useState } from "react";
import { getCatalogItem, getCatalogItems } from "@/api/catalog";

// import { DEFAULT_PAGE_ID } from "@/Apps/Pages/Editor/types";
import { Loading } from "@/ui/Layout/Loading";
import { getCampaign, getCampaigns, updateCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { head, keys } from "lodash";
import { getPage, updatePage } from "@/api/pages";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import Modal from "@/ui/Modal";
import { CustomHTML } from "../../F$/Popups/CustomHTML";

import { themes } from "../themes";

export const Themes = ({ unitName = "Order Form", campaignData = {}, metaData = {} }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [value, setValue] = useState(campaignData?.settings?.themeId);
  const [isHTMLPopupOpen, setIsHTMLPopupOpen] = useState(false);

  // Find the first page id
  const objects = campaignData?.objects || [];
  const pageId = objects[0]?.page?.id;

  const fetchPages = async () => {
    const token = getSessionToken();
    const response = await getCampaigns({ token, type: "page" });
    const customHTML = { name: "Custom HTML", id: "custom_html" };
    if (response) {
      console.log("response", response);
      const pages = [customHTML, ...response]
        ?.filter(({ deleted }) => !deleted)
        .map((page: any) => ({
          label: page.name,
          id: page?.id,
          name: page.name,
          icon: (
            <img
              className="w-[42px] h-[42px] mr-4 flex-none rounded-full overflow-hidden object-cover"
              src="https://flowbite.com/docs/images/logo.svg"
              alt=""
            />
          ),
        }));
      setTemplates(pages);
      setIsLoading(false);
    }
  }

  const fetchThemes = () => {
    setTemplates(keys(themes).map(theme => ({ label: theme, id: theme, name: theme })) as { label: string, id: string, name: string }[]);
    setIsLoading(false);
  }
  
  // const fetchTemplates = async (filter = {}) => {
  //   const items = await getCatalogItems({ type: "designs", ...filter });
  //   const customHTML = { name: "Custom HTML", id: "custom_html", image: "" };
  //   const newTemplates =
  //     [customHTML, ...items].map(({ name, id, image }) => ({
  //       label: "",
  //       id,
  //       name,
  //       icon: (
  //         <div className="w-48">
  //           <div className="w-full rounded overflow-hidden h-36 bg-white">
  //             <img src={image} className="w-full rounded" />
  //           </div>
  //           <div className="text-center pt-2">{name}</div>
  //         </div>
  //       ),
  //     })) || [];

  //   setTemplates(newTemplates);
  //   setIsLoading(false);
  // };

  const handleThemeChange = async key => {
    const theme = templates[key];
    console.log("Theme Details", theme);

    const campaignId = campaignData?.id;
    const token = getSessionToken();

    const id = theme.id;
    setValue(id);

    // 1. update the campaign settings with the chosen template
    const settings = { 
      settings: { ...campaignData?.settings, themeId: id } 
    };
    const response = await updateCampaign(campaignId, { 
      token, 
      settings 
    });
    console.log("updateCampaign Response", response);

    if (id === "custom_html") return;

    // get the page content
    const { objects } = await getCampaign({ token, id });
    const { page } = head(objects);

    console.log("Page Details", { objects, page });

    // const { content } = await getPage(id, { token });
    // console.log("Page Details", { id, content });
    console.log("Updating the page", pageId, { token, settings: { content: page?.content }});

    const pgResp = await updatePage(pageId, { token, settings: { content: page?.content } });
    console.log("Save Page Response", pgResp);
  }

  const handleTemplateChange = async (key) => {
    const template = templates[key];

    const campaignId = campaignData?.id;
    const token = getSessionToken();

    const id = template.id;
    setValue(id);

    // 1. update the campaign settings with the chosen template
    const settings = { settings: { themeId: id } };
    const response = await updateCampaign(campaignId, { token, settings });
    console.log("updateCampaign Response", response);

    if (id === "custom_html") return;

    // get the page content
    const templateData = await getCatalogItem(id);
    // console.log({ template, templateData });

    const { page } = templateData;
    const { content } = page;

    // 2. update the order form node / page with the chosen template content

    // console.log("the order form object", head(objects))
    // update the content with `content`

    // 3. save the order form page
    const pgResp = await updatePage(pageId, { token, settings: { content } });
    console.log("Save Page Response", pgResp);
  };

  useEffect(() => {
    // fetchTemplates({ category: "order" });
    if (metaData?.showPages) fetchPages();
    else fetchThemes();

  }, []);

  return (
    <Panel title={`${unitName} Design`}>
      {isHTMLPopupOpen && (
        <CustomHTML setIsOpen={setIsHTMLPopupOpen} pageId={pageId} />
      )}

      <PanelItem
        title={`Choose a Design`}
        description={`Choose a design for your ${unitName.toLowerCase()}.`}
        button={
          loading ? (
            <Loading type="tiny" />
          ) : (
            <div className="flex gap-2">
              <Dropdown
                label={
                  templates.filter((itm) => itm.id === value)[0]?.name ||
                  "Choose..."
                }
                items={templates}
                onClick={handleThemeChange}
              />
              {value === "custom_html" && (
                <Button
                  className="p-1 text-md"
                  type={ButtonTypes.SOFT}
                  label="Edit HTML"
                  onClick={() => setIsHTMLPopupOpen(true)}
                />
              )}
            </div>
          )
        }
      />
    </Panel>
  );
};

export default Themes;