import React, { useState } from "react";
import { get, uniq } from "lodash";

// import { CopilotTextarea } from "@copilotkit/react-textarea";

// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";
import {
  // RichEditor,
  PositionPadding,
  Alignment,
  Interactions,
  PropertiesWindow,
} from "@/Apps/Pages/Properties";

import RichEditor, { RichEditorPosition } from "@/Apps/Pages/Properties/RichEditor";

import {
  generateInlineStyle,
  generateGoogleFontList,
} from "@/Apps/Pages/Properties/RichEditor/utilities";

// import { useDimensions } from "@launchos/plugins/utilities";
// import { checkForSmartCopyIntegration } from '@launchos/plugins/misc/v2/smartcopy/client/scripts'

import style from "./style";
import EditorObject from "@/Apps/Pages/EditorObject";
import { useDimensions } from "@/Apps/Pages/utils";
import {
  LiquidRenderer,
  parseLiquidString,
} from "@/Apps/Pages/Page/LiquidRenderer";

const TextContentEdit = (props) => {
  const [ref, hoverBoundingRect] = useDimensions();
  const [dragHandleIsShowing, setDragHandleIsShowing] = React.useState(true);
  const [usesSmartCopy, setUsesSmartCopy] = React.useState(false);

  const {
    settings,
    updateComponentSettings,
    pageContent,
    setIsEditing,
    setCanDrag,
    getCanDrag,
    changeState,
    setCanUndo,
  } = props;

  // const handleOnLoad = async () => {
  //   const usesIt = await checkForSmartCopyIntegration();
  //   // console.log({ usesIt })
  //   setUsesSmartCopy(usesIt)
  // }

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["bullist", "numlist"],
    ["outdent", "indent"],
    ["lineheight"],
    ["link"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    // usesSmartCopy ? ["smartcopy"] : [],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link"],
  ];

  // get list of fonts used
  const googleFontList = generateGoogleFontList(settings, pageContent);

  const handleChange = (html, fontsList) => {
    const currentFontList = get(settings, "fontsUsed", []).map((arr) =>
      get(arr, "fontFamily")
    );
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({
      fontFamily: font,
    }));
    updateComponentSettings(settings.id, {
      ...settings,
      html,
      fontsUsed,
    });
  };

  // React.useEffect(() => {
  //   handleOnLoad()
  // }, [])

  // React.useEffect(() => {
  //   console.log("Text Editor Props Changed", settings.html);
  // }, [settings.html])
  const isDragging = pageContent.filter(
    (content) => content.type === "Cursor"
  ).length;

  // console.log("Text Settings Rendered", settings.id);

  return (
    <EditorObject
      {...props}
      PropertiesView={Properties}
      dragHandle={dragHandleIsShowing}
      label="Text"
      noOffset={get(settings, "html", "").length < 20}
    >
      <div
        style={{ ...settings.properties, marginTop: 0 }}
        // ref={ref}
      >
        <span style={style.span}>
          <RichEditor
            id={`ed_${settings.id}`}
            content={parseLiquidString(settings.html)}
            style={generateInlineStyle(settings)}
            buttonList={buttonList}
            menuList={menuList}
            googleFontList={googleFontList}
            onChange={handleChange}
            onInstantChange={() => setDragHandleIsShowing(false)}
            onClick={() => {
              setDragHandleIsShowing(true);
            }}
            onMouseEnter={() => {
              setDragHandleIsShowing(true);
            }}
            onFocus={(e) => {
              e.stopPropagation();

              console.log("Text Editor Focus", getCanDrag());
              setIsEditing(true);
              setCanDrag(false);
              changeState(settings.id, "active");
              setCanUndo(false);
            }}
            onBlur={(e) => {
              console.log("Text Editor Blur", getCanDrag());
              setDragHandleIsShowing(true);
              setCanUndo(true);
              setTimeout(() => {
                setIsEditing(false);
                setCanDrag(true);
              }, 200);
            }}
            // disabled={props.show === "preview" || isDragging}
            // disabled={props.show === "preview"}
            // disabled={isDragging}
            // disabled
            // placeholder="Click here to begin entering content..."
          />
        </span>
      </div>
    </EditorObject>
  );
};

const BasicPropertiesTab = (props) => (
  <>
    {/* <Typography {...props} showLineHeight isExpanded /> */}
    <PositionPadding {...props} isExpanded />
    <Alignment
      {...props}
      label="Text Alignment"
      verticalAlign={false}
      textAlign
    />
    <Interactions
      {...props}
      label="Text Actions"
      onUpdate={(action) => console.log(action)}
    >
      <div />
    </Interactions>
  </>
);

export const SimpleTextProperties = (props) => {
  const {
    settings,
    updateComponentSettings,
  } = props;

  const buttonList = [
    ["bold", "italic", "underline", "strikethrough"],
    ["bullist", "numlist"],
    ["outdent", "indent"],
    ["lineheight"],
    ["link"],
    ["removeformat"],
  ];

  const menuList = [
    ["undo", "redo"],
    // usesSmartCopy ? ["smartcopy"] : [],
    ["fontfamily", "googlefonts", "fontsizes", "align", "lineheight"],
    ["forecolor", "backcolor"],
    ["link"],
  ];

  const handleChange = (html, fontsList) => {
    const currentFontList = get(settings, "fontsUsed", []).map((arr) => get(arr, "fontFamily"));
    const newFontList = uniq([...currentFontList, ...fontsList]);
    const fontsUsed = newFontList.map((font) => ({ fontFamily: font }));
    updateComponentSettings(settings.id, { ...settings, html, fontsUsed });
  };

  return (
    <div className="px-3">
      <h3 className="text-sm font-medium">{settings?.prompt}</h3>
      <div className="rounded-lg my-5" style={{ zoom: "75%" }}>
        <RichEditor
          id={`ed_${settings.id}`}
          content={settings.html}
          style={generateInlineStyle(settings)}
          buttonList={buttonList}
          menuList={menuList}
          position={RichEditorPosition.TOP}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[{ id: "basic", title: "Text", component: BasicPropertiesTab }]}
  />
);

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";

  return (
    <div style={{ marginTop, textAlign }}>
      <TextContentEdit {...props} />
    </div>
  );
};
