import React from "react";
import SEO from "./SEO";
import { ComponentRenderHooks } from "@/plugins/types";

export const onComponentRender = (hook, { seoPayload }) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && seoPayload.length)
    return [<SEO key="seo" payload={seoPayload} />];
};

export default SEO;
