import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { getSessionToken } from "./auth";
import { createCampaignAndObject, getObjects, updateObject } from "./campaigns";
import { head } from "lodash";
import { Message } from "ai/react/dist";

interface CreateOrUpdateChatParams {
  // An array of all previous messages in the chat
  messages?: Message[];

  // The most recent message to add to the chat (usually the output of an onFinish method from a useChat hook)
  lastMessage?: Message;

  // The user's input that triggered the last message
  input?: string;

  // The unique identifier for the chat session (in the case of a session that will be updated)
  sessionId?: string;

  // The name of the chat session (optional)
  name?: string;
}

export const complete = async (
  prompt = "Hi!",
  systemPrompt = "",
  type = null
) => {
  try {
    const r = await fetch(`${AI_DOMAIN}/api/completion`, {
      method: "post",
      body: JSON.stringify({ prompt, systemPrompt, type }),
    });
    const response = await r.json();
    return response.text;
  } catch (error) {
    console.error(`Error`, error);
    return false;
  }
};

export const completeComponent = async (
  prompt = "Give me an alternative version of this component",
  systemPrompt = "",
  settings = {},
  context = [],
  pageContent = [],
) => {
  try {
    const r = await fetch(`${AI_DOMAIN}/api/copilot/artifacts/page/update`, {
      method: "post",
      body: JSON.stringify({ prompt, systemPrompt, settings, context, pageContent }),
    });
    const response = await r.json();
    return response;
  } catch (error) {
    console.error(`Error`, error);
    return false;
  }
}

export const createOrUpdateChat = async ({
  lastMessage,
  messages = [],
  input = "",
  sessionId,
  name,
}: CreateOrUpdateChatParams) => {
  const allMessages = [
    ...messages,
    {
      role: "user",
      id: Math.random().toString(36).substr(2, 9),
      content: input,
      createdAt: new Date().toISOString(),
    },
    lastMessage,
  ];

  if (!Boolean(messages.length)) {
    console.log("Will Create New Chat around this", lastMessage);
    const token = getSessionToken();

    const settings = {
      messages: allMessages,
    };

    const useThisName = name || await complete(
      "Please generate a simple word or phrase (no more than 4 words) to title the attached thread of chatbot messages.  Just return the phrase only.  No before or after messages...just the word or phrase.",
      JSON.stringify(allMessages)
    );
  
    const { campaign, object } = await createCampaignAndObject({
      token,
      name: useThisName,
      type: "chat",
      nodeType: "chat_history",
      settings,
    });

    console.log("Chat Campaign Created", campaign, object);

    // route to schedule page
    // navigate(`/copilot/${campaign?.id}`);
    // setCampaignId(campaign?.id);
    // setCampaignName(campaign?.name);
    // window.location.href = `/copilot/${id}`;
    // clbk(campaign);

    return campaign;
  } else {
    console.log("Will Simply Update the current chat", lastMessage, messages);

    const response = await getObjects(sessionId);
    const object = head(response?.data?.objects);
    await updateObject(object?.id, {
      settings: { ...object?.settings, messages: allMessages },
    });
    return null;
  }
};