"use client";
import { LoremIpsum } from "lorem-ipsum";
import { ISettings } from "./Editor/types";
import { get, has, keys, List, uniq } from "lodash";

import React, { useRef, useState, useLayoutEffect } from "react";
import { PageContent } from "./Page/Page";
import ReactDOMServer from "react-dom/server";

export const lorem: any = new LoremIpsum({
  sentencesPerParagraph: {
    max: 4,
    min: 3,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

const objectDeepKeys = (obj: any): string[] => {
  if (obj == null || typeof obj !== "object") {
    return [];
  }
  return keys(obj)
    .filter((key) => obj[key] instanceof Object)
    .flatMap((key) => [
      key,
      ...objectDeepKeys(obj[key]).map((k) => `${key}.${k}`),
    ]);
};

// export const generateFontList = (content: ISettings[]): string[] => {
//   if (!Array.isArray(content) || content.length === 0) {
//     return [];
//   }
//   return uniq(
//     objectDeepKeys(content)
//       .filter((itm) => itm.includes(".fontFamily"))
//       .map((key) => get(content, key))
//       .filter((font): font is string => typeof font === "string")
//   );
// };

interface FontItem {
  fontFamily?: string;
  [key: string]: any;
}

export const generateFontList = (content) => {
  // const toProcess = pages.map((itm) => itm.content || false);

  // console.log({ pages: pages.map(itm => keys(itm)), toProcess: toProcess.map(({ slug }) => slug || false) })
  // console.log("****************\n\n", pages.filter(itm => has(itm.content)).map(itm => itm.slug), "\n\n*******************")
  // console.log("****************\n\n", pages.map(itm => ({ slug: itm.slug, keys: keys(itm), content: itm.content ? itm.content.length : 0 })), "\n\n*******************")

  let fontList: List<any> | null | undefined = [];
  // console.log("Fonts from content", content);
  // toProcess.forEach((itm) => {
    // if (itm) {
      // const itmContent = JSON.parse(itm);

      content.forEach((itm) => {
        const k = keys(itm).filter(
          (i) => has(itm[i], "fontFamily") || has(itm, "fontsUsed")
        );

        k.forEach((i) => {
          let fontFamily = "";

          // Type assertion to treat itm[i] as FontItem
          const item = itm[i] as FontItem;

          if (has(item, "fontFamily")) fontFamily = item.fontFamily;
          else if (has(item, "[0].fontFamily")) fontFamily = item[0].fontFamily;

          if (fontFamily && fontList.findIndex((ff) => ff === fontFamily))
            fontList.push(fontFamily);
        });
      });
    // }
  // });

  // console.log("\n\n", "Font List", uniq(fontList), "\n\n");

  return uniq(fontList);
};

export const useDimensions = () => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [dimensions, setDimensions] = useState({});

  const domRectToObject = (rect: DOMRect) => {
    return {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
    };
  };

  useLayoutEffect(() => {
    if (ref.current) {
      // setDimensions(ref.current.getBoundingClientRect().toJSON());
      setDimensions(domRectToObject(ref.current.getBoundingClientRect()));
    }
  }, [ref.current]);

  return [ref, dimensions];
};

export const cancelActions = (e) => {
  // e.preventDefault();
  e.stopPropagation();
  return false;
};

export const encode = (s, k) => btoa(btoa(btoa(s)));
export const decode = (s, k) => atob(atob(atob(s)));

export enum ClipPathTypes {
  TRIANGLE = "TRIANGLE",
  TRAPEZOID = "TRAPEZOID",
  PARALLELOGRAM = "PARALLELOGRAM",
  RHOMBUS = "RHOMBUS",
  PENTAGON = "PENTAGON",
  HEXAGON = "HEXAGON",
  HELPTAGON = "HELPTAGON",
  OCTAGON = "OCTAGON",
  NONAGON = "NONAGON",
  DECAGON = "DECAGON",
  BEVEL = "BEVEL",
  RABBET = "RABBET",
  LEFTARROW = "LEFTARROW",
  RIGHTARROW = "RIGHTARROW",
  LEFTPOINT = "LEFTPOINT",
  RIGHTPOINT = "RIGHTPOINT",
  LEFTCHEVRON = "LEFTCHEVRON",
  RIGHTCHEVRON = "RIGHTCHEVRON",
  STAR = "STAR",
  CROSS = "CROSS",
  MESSAGE = "MESSAGE",
  CLOSE = "CLOSE",
  FRAME = "FRAME",
  INSET = "INSET",
  CIRCLE = "CIRCLE",
  ELLIPSE = "ELLIPSE",
}

interface IClipPaths {
  type: ClipPathTypes;
}

export const getClipPath = ({ type }: IClipPaths) => {
  switch (type) {
    case ClipPathTypes.TRIANGLE:
      return `polygon(50% 0%, 0% 100%, 100% 100%);`;
    case ClipPathTypes.TRAPEZOID:
      return `polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);`;
    case ClipPathTypes.PARALLELOGRAM:
      return `polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)`;
    case ClipPathTypes.RHOMBUS:
      return `polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);`;
    case ClipPathTypes.PENTAGON:
      return `polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);`;
    case ClipPathTypes.HEXAGON:
      return `polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);`;
    case ClipPathTypes.HELPTAGON:
      return `polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);`;
    case ClipPathTypes.OCTAGON:
      return `polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);`;
    case ClipPathTypes.NONAGON:
      return `polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);`;
    case ClipPathTypes.DECAGON:
      return `polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);`;
    case ClipPathTypes.BEVEL:
      return `polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);`;
    case ClipPathTypes.RABBET:
      return `polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);`;
    case ClipPathTypes.LEFTARROW:
      return `polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)`;
    case ClipPathTypes.RIGHTARROW:
      return `polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)`;
    case ClipPathTypes.LEFTPOINT:
      return `polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%)`;
    case ClipPathTypes.RIGHTPOINT:
      return `polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)`;
    case ClipPathTypes.LEFTCHEVRON:
      return `polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)`;
    case ClipPathTypes.RIGHTCHEVRON:
      return `polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)`;
    case ClipPathTypes.STAR:
      return `polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)`;
    case ClipPathTypes.CROSS:
      return `polygon(10% 25%, 35% 25%, 35% 0%, 65% 0%, 65% 25%, 90% 25%, 90% 50%, 65% 50%, 65% 100%, 35% 100%, 35% 50%, 10% 50%)`;
    case ClipPathTypes.MESSAGE:
      return `polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)`;
    case ClipPathTypes.CLOSE:
      return `polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)`;
    case ClipPathTypes.FRAME:
      return `polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%)`;
    case ClipPathTypes.INSET:
      return `inset(5% 20% 15% 10%)`;
    case ClipPathTypes.CIRCLE:
      return `circle(50% at 50% 50%)`;
    case ClipPathTypes.ELLIPSE:
      return `ellipse(25% 40% at 50% 50%)`;
    default:
      return null;
  }
};

export const remapCampaignVariables = (arr) => {
  try {
    return arr
      .filter(item => item.value !== null)
      .reduce((obj, item) => {
        obj[item.id] = item.value;
        return obj;
      }, {});
  } catch (err) {
    return {};
  }
};  

export const convertPagetoHTML = obj => {
  return ReactDOMServer.renderToStaticMarkup(
    React.createElement(PageContent, {
      id: "undefined",
      content: JSON.stringify(obj),
      nextPage: "#",
      getPageData: () => {},
      trackingPayload: "",
      seoPayload: "",
      merchantPayload: "", 
      emailPayload: "",
      cssPayload: "",
      exitPayload: "",
      objectId: "cm4kr8rtd26ve08110cbkd5yh",
      campaignId: "cm4kr8r4f26uz0811dhydieos",
      userId: "cjyuon38x00fa0792wzw2v1lu"
    })
  );
}
