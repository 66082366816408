import React, { useState, useEffect, useRef } from "react";
import { DownCarrotIcon } from "../Icons";
import { Link } from "react-router-dom";
import { DropDownProps } from "./types";
import { Loading } from "../Layout/Loading";

// Define the Dropdown component
export const Dropdown: React.FC<DropDownProps> = ({
  label = "Dropdown Menu",
  withBorder = false,
  items = [], 
  isLoading = false,
  onClick,
  onOpen = () => {},
  className = "",
}) => {
  // Define the dropdown state and a ref to the dropdown element
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Define the toggle and close dropdown functions
  const toggleDropdown = () => {
    setDropdown(!dropdown);
    onOpen();
  };

  const closeDropdown = () => {
    setDropdown(false);
  };

  // Add an event listener to close the dropdown when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownRef]);

  // Render the dropdown button and menu
  return (
    <div data-testid="Dropdown" className={`dropdown`} ref={dropdownRef}>
      <button
        // Define the button classes based on the withBorder prop
        className={`btn flex ${withBorder && "bg-transparent"} items-center border border-black/${withBorder ? 20 : 5} dark:border-white/${withBorder ? 20 : 5} justify-between w-full ${className}`}
        // Add an onClick handler to toggle the dropdown
        onClick={toggleDropdown}
        // Add an onKeyDown handler to close the dropdown when the Escape key is pressed
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            closeDropdown();
          }
        }}
      >
        {label}
        <DownCarrotIcon className="ml-1" />
      </button>
      {/* Render the dropdown menu if the dropdown state is true */}
      {dropdown && (
        <ul
          className="right-0 whitespace-nowrap max-h-96 overflow-y-auto"
          onClick={closeDropdown}
          role="menu"
          aria-orientation="vertical"
          tabIndex={-1}
        >
          {isLoading && <Loading />}

          {/* Map over the items prop and render a Link for each item */}
          {items.map(({ label, href, icon, caption }, key) => {
            
            return (
              <li key={key}>
                {Boolean(onClick) ? (
                  <div
                    className="px-4 py-2 cursor-pointer flex"
                    onClick={() => onClick(key)}
                  >
                    {icon}
                    {label}
                    {caption && <span className="text-black/5 dark:text-white/30 text-sm px-3">{caption}</span>}
                  </div>
                ) : (
                  <Link to={href} role="menuitem">
                    <span>{label}</span>
                    {caption && <span className="text-black/5 dark:text-white/30 text-sm px-3">{caption}</span>}
                  </Link>
                )}
              </li>
            );})}
        </ul>
      )}
    </div>
  );
};