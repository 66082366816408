import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { get, startCase, uniqueId } from "lodash";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { BenefitLayouts } from "../types";
import theme from "../../theme";

import { style } from "./style";
import content from "./data.json";
import { getImagePlaceholder, ImagePlaceholderTypes } from "../../Placeholders";

const getFtrGroup = ({ name, data }) => {
  const { title, text } = data;
  return {
    type: ComponentTypes.CONTAINER,
    canHaveChildren: true,
    properties: {
      border: "1px solid #DDD",
      borderRadius: 3,
      backgroundColor: "#EEE",
      marginTop: 50,
      // height: "inherit",
    },
    children: [
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((3 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  textAlign: "left",
                  // fontWeight: "bold" as "bold",
                },
                html: title,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((9 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.TEXT,
                canHaveChildren: false,
                properties: {
                  ...style.TEXT,
                  // fontWeight: "bold" as "bold",
                },
                html: text,
              },
            ],
          },
        ],
      },
    ],
  };
};

const settings: ILayoutSettings = {
  label: BenefitLayouts.TEN,
  layout: BenefitLayouts.TEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/benefits/Benefit10.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkx36rg821t10780ogx3g72b",
    itemId: "fo113uwqi1w",
  },
  default: {
    label: PageSectionCategory.BENEFIT,
    type: ComponentTypes.SECTION,
    bodyOnly: true,
    canHaveChildren: true,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          maxWidth: 650,
          margin: "0 auto",
        },
        children: [
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: true,
            properties: {
              ...style.HEADLINE,
              ...get(content, "main_headline.style", {}),
            },
            html: content.main_headline.html,
          },
          {
            type: ComponentTypes.TEXT,
            canHaveChildren: true,
            properties: {
              ...style.TEXT,
              ...get(content, "sub_headline.style", {}),
            },
            html: content.sub_headline.html,
          },
        ],
      },
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: true,
        properties: {
          padding: 50,
          mobile: {
            desktop: { padding: 50 },
            smartphone: { padding: 15 },
          },
        },
        children: [
          {
            type: ComponentTypes.COLUMNS,
            canHaveChildren: false,
            properties: {
              width: "100%",
              marginTop: -40,
            },
            children: [
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: 25,
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  getFtrGroup({
                    name: "feature",
                    data: {
                      title: "01",
                      text: "Lorem ipsum dolor sit amet",
                    },
                  }),
                  getFtrGroup({
                    name: "feature",
                    data: {
                      title: "02",
                      text: "Consectetur adipiscing elit, sed do eiusmod",
                    },
                  }),
                  getFtrGroup({
                    name: "feature",
                    data: {
                      title: "03",
                      text: "Tempor incididunt ut labore et dolore magna",
                    },
                  }),
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: 50,
                properties: { height: "100%", minHeight: 20 },
                children: [
                  {
                    type: ComponentTypes.CONTAINER,
                    canHaveChildren: true,
                    properties: {
                      padding: 50,
                      marginTop: 90,
                      mobile: {
                        desktop: { padding: 50 },
                        smartphone: { padding: 15 },
                      },
                    },
                    children: [
                      getImagePlaceholder({
                        type: ImagePlaceholderTypes.NONE,
                        src:
                          "https://www.att.com/catalog/en/idse/Apple/Apple%20iPhone%2011/Purple-hero-zoom.png",
                        style: {
                          // marginTop: 60,
                        },
                      }),
                    ],
                  },
                ],
              },
              {
                type: ComponentTypes.COLUMN,
                canHaveChildren: true,
                preventDelete: true,
                md: 25,
                properties: { height: "100%", padding: 0, minHeight: 20 },
                children: [
                  getFtrGroup({
                    name: "feature",
                    data: {
                      title: "04",
                      text: "Aliqua ut enim ad minim veniam quis",
                    },
                  }),
                  getFtrGroup({
                    name: "feature",
                    data: {
                      title: "05",
                      text: "Nostrud exercitation ullamco laboris",
                    },
                  }),
                  getFtrGroup({
                    name: "feature",
                    data: {
                      title: "06",
                      text: "Nisi ut aliquip ex ea commodo consequat",
                    },
                  }),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
