import React, { useEffect, useState } from "react";
import { Analytics } from "@vercel/analytics/react";
import { ThemeProvider } from "@material-ui/core/styles";
import { ClerkProvider } from "@clerk/clerk-react";
import { dark } from '@clerk/themes'
import { CopilotKit } from "@copilotkit/react-core"; 

import { AppContext } from "@/AppContext";
import { AppRoutes, RootRoutes } from "@/Routes";
// import { EDITOR_DOMAIN } from "@launchos/shared-utils/env";
// import { getSessionToken } from "./api/auth";
import { getUserPermissions } from "./api/users";

import {
  initGA,
  isSystemDarkMode,
  logPageView,
  syncSystemDarkMode,
} from "./utils";
// import { OnboardingVideo } from "./OnboardingVideo";
import { muiThemeDark, muiThemeLight } from "./ui/theme";
// import Modal from "./ui/Modal";
// import CmdK from "./ui/CommunicationView/CmdK";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const App: React.FC = () => {
  // Set up state for the sidebar and right sidebar
  const [darkMode, setDarkMode] = useState<boolean>(
    isSystemDarkMode()
    // Boolean(localStorage.getItem("darkMode") === "true")
  );
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    Boolean(localStorage.getItem("sidebarOpen") === "true")
  );
  const [rightSidebarOpen, setRightSidebarOpen] = useState<boolean>(
    Boolean(localStorage.getItem("rightSidebarOpen") === "true")
  );
  const [copilotOpen, setCopilotOpen] = useState<boolean>(false);
  const [copilotContext, setCopilotContext] = useState<Record<string, unknown>[]>([]);
  const [currentlyViewing, setCurrentlyViewing] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [pluginUrls, setPluginUrls] = useState<string[]>([
    // "http://localhost:3000/onListItems.js",
    // "http://localhost:3000/bundle.js",
    // "http://localhost:61931/bundle.js",
    // "https://ambid-backups.s3.amazonaws.com/miscellaneous/testProjectId-bundle.js",
    // "https://ambid-backups.s3.amazonaws.com/miscellaneous/example1.js",
    // "https://ambid-backups.s3.amazonaws.com/miscellaneous/example2.js",
  ]);

  const menu = "";
  const layout = "";

  const contextValue = {
    darkMode,
    sidebarOpen,
    rightSidebarOpen,
    pluginUrls,
    permissions,
    copilotOpen,
    copilotContext,
    currentlyViewing,
    setCurrentlyViewing,
    setPermissions,
    setDarkMode,
    setSidebarOpen,
    setRightSidebarOpen,
    setCopilotContext,
    setCopilotOpen,
    setPluginUrls,
    disableRightSidebar: true,
    app: {
      loadingImage:
        "https://s-media-cache-ak0.pinimg.com/originals/dd/67/4f/dd674f89d713bb2645a3292510219998.gif",
      logoIcon: "https://flowbite.com/docs/images/logo.svg",
    },
  };

  // const getIframeMessage = () => ({
  //   type: "onLoad",
  //   darkMode,
  //   pageWrapStyle: {
  //     backgroundColor: darkMode ? `rgba(22, 22, 22)` : "#F7F7F7", // rgba(22, 22, 22, 0.8)
  //     width: "100%",
  //   },
  // });

  // Detect when dark mode is changed and set local storage
  useEffect(() => {
    // console.log("Dark Mode Changed:", darkMode);
    localStorage.setItem("darkMode", darkMode ? "true" : "false");
    initGA("G-CMMH8E7PSL");
    logPageView();

    // send message to iframe if one exists
    const iframes = document.getElementsByClassName("page-editor");
    Array.from(iframes).forEach((iframe) => {
      if (iframe instanceof HTMLIFrameElement) {
        iframe.contentWindow?.postMessage(getIframeMessage(), "*");
      }
    });
  }, [darkMode]);

  useEffect(() => {
    console.log("setContextForView", "copilotContext changed", copilotContext)
  }, [copilotContext])

  // Listen for messages from the client iframe
  useEffect(() => {
    // window.addEventListener(
    //   "message",
    //   (event) => {
    //     // Check the origin of the message for security
    //     if (event.origin !== EDITOR_DOMAIN) return;

    //     // console.log("In Launch OS App - Data from sandcastle iframe", event.data);

    //     // Check the message type
    //     if (event.data.type === "getState") {
    //       const iframes = document.getElementsByClassName("page-editor");
    //       Array.from(iframes).forEach((iframe) => {
    //         if (iframe instanceof HTMLIFrameElement) {
    //           iframe.contentWindow?.postMessage(getIframeMessage(), "*");
    //         }
    //       });
    //     }
    //   },
    //   false
    // );

    (async () => {
      // get user permissions
      const token = localStorage.getItem("token");
      const permissions = await getUserPermissions({ token });
      if (permissions) setPermissions(permissions);
    })();

    // Clean up
    // return () => {
    //   window.removeEventListener("message", () => {});
    // };
  }, []);

  // listen for dark mode changes
  useEffect(() => {
    syncSystemDarkMode(setDarkMode);
  }, []);

  const toggleLeftSidebar = () => {
    localStorage.setItem("sidebarOpen", !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  // const toggleRightSidebar = () => {
  //   localStorage.setItem("rightSidebarOpen", !rightSidebarOpen);
  //   setRightSidebarOpen(!rightSidebarOpen);
  // };

  return (
    <AppContext.Provider value={contextValue}>
      <ThemeProvider theme={darkMode ? muiThemeDark : muiThemeLight}>
        <ClerkProvider
          publishableKey={PUBLISHABLE_KEY}
          afterSignOutUrl="/"
          appearance={{ baseTheme: darkMode ? dark : undefined }}
        >
            <div className={darkMode ? "dark" : "light"}>
              <div
                className={`
              antialiased relative font-inter 
              bg-white dark:bg-black
              text-black dark:text-white 
              overflow-x-hidden vertical 
              ${sidebarOpen ? "toggle-sidebar" : ""} 
              ${rightSidebarOpen ? "right-sidebar" : ""} 
              ${menu} ${layout} body min-h-screen
            `}
              >
                {/* Start Menu Sidebar Overlay */}
                <div
                  className={`fixed inset-0 bg-[black]/60 z-40 ${
                    !sidebarOpen ? "hidden" : "lg:hidden"
                  }`}
                  onClick={toggleLeftSidebar}
                ></div>
                {/* End Menu Sidebar Overlay */}

                {/* Start Right Sidebar Overlay */}
                {/* {!contextValue.disableRightSidebar && (
              <div
                className={`fixed inset-0 bg-[black]/60 z-50 ${
                  !rightSidebarOpen ? "hidden" : "2xl:hidden"
                }`}
                onClick={toggleRightSidebar}
              ></div>
            )} */}
                {/* End Right Sidebar Overlay */}

                {/* Start Main Content */}
                <div className={`main-container navbar-sticky flex`}>
                  <AppRoutes />
                </div>
                {/* End Main Content */}
              </div>
            </div>
        </ClerkProvider>
      </ThemeProvider>
      <Analytics />
    </AppContext.Provider>
  );
};

export default App;
