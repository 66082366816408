import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActiveTabFromLocalStorage } from "@/ui/TabsComponent/TabsComponent";

import { Page } from "@/ui/Layout/Page";
import { TabsComponent } from "@/ui/TabsComponent";
import { Play } from "@phosphor-icons/react";
import { TopbarButton } from "@/ui/Layout/Topbar/Topbar";
import { ButtonTypes } from "@/ui/types";
import { Variants } from "@/ui/types";
import Button from "@/ui/Button";
import { getSessionToken } from "@/api/auth";
import { getCampaign } from "@/api/campaigns";
import Modal, { Title } from "@/ui/Modal";
import { PopupSizes } from "@/ui/Modal/types";
import { ChatBot } from "../AITools";
import { CampaignBuilder } from "../Funnels";
import { Settings } from "../Dashboards/Settings";
import { DashboardPage } from "../Dashboards/DashboardPage";
import { AI_DOMAIN } from "@launchos/shared-utils/env";
import { Loading } from "@/ui/Layout/Loading";
import { useChat } from "ai/react";

const AgentPublishPopup = ({
  setIsPublishPopupOpen = () => {},
  id = "",
  runs = 1,
  setNumRuns = () => {},
}) => {
  const [count, setCount] = useState(0);

  const {
    messages,
    append,
    isLoading: isChatLoading,
  } = useChat({
    api: `${AI_DOMAIN}/api/agents/chat/${id}`,
    // body: {
    //   token: getSessionToken(),
    // },
  });

  useEffect(() => {
    const runSequentialChats = async () => {
      for (let i = 0; i < runs; i++) {
        try {
          setCount(i + 1);

          await append({
            role: "user",
            content: "Run workflow",
          });
        } catch (error) {
          console.error("Error running workflow:", error);
        }
      }
      // setIsPublishPopupOpen(false);
    };

    runSequentialChats();
  }, []);

  return (
    <Modal
      title="Runs"
      onClose={() => {
        setIsPublishPopupOpen(false);
        setNumRuns(1);
      }}
      onSuccess={() => {
        setIsPublishPopupOpen(false);
        setNumRuns(1);
      }}
    >
        <div className="p-4">
      {isChatLoading ? (<>
        <Title>
          Please wait while we execute your workflow ({count} of {runs})...
        </Title>
        <Loading type="gallery" />
      </>) : (
        <Title>
          Workflow execution complete! All {runs} runs finished successfully.
        </Title>
      )}
        </div>
    </Modal>
  );
};

export const Dashboard = ({ name = "" }) => {
  const [activeTab, setActiveTab] = useState(
    getActiveTabFromLocalStorage() || "canvas"
  );
  const { id } = useParams();
  const [isPublishPopupOpen, setIsPublishPopupOpen] = useState(false);
  const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);
  const [campaignData, setCampaignData] = useState<{ name?: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [numRuns, setNumRuns] = useState<number>(1);

  // Fetch the agent data from the API when the component mounts
  useEffect(() => {
    const token = getSessionToken();
    setIsLoading(true);
    (async () => {
      const data = await getCampaign({ token, id });
      setCampaignData(data);
      setIsLoading(false);
    })();
  }, []);

  return (
    <Page
      topBar={{
        page: [
          { label: name, href: "/agents" },
          {
            label: campaignData?.name || "•••",
            href: `#`,
          },
        ],
        items: (
          <>
            <TopbarButton onClick={() => setIsPreviewPopupOpen(true)}>
              <Play />
            </TopbarButton>
            <Button
              label={<span>Publish</span>}
              onClick={() => {
                const runs = window.prompt(
                  "How many times would you like to run this agent workflow?"
                );
                setNumRuns(runs);
                setIsPublishPopupOpen(true);
              }}
              type={ButtonTypes.OUTLINED}
              variant={Variants.INFO}
            />
          </>
        ),
      }}
    >
      {isPreviewPopupOpen && (
        <Modal
          size={PopupSizes.XXXLARGE}
          title="Chat Preview"
          onClose={() => setIsPreviewPopupOpen(false)}
        >
          <div className="p-3">
            <ChatBot
              showHeader={false}
              sessionId={id}
              pageSettings={{}}
              title="Ask a question..."
              createOrUpdate={false}
              systemPromptOverride={campaignData?.settings?.systemPrompt || ""}
              endPoint={`${AI_DOMAIN}/api/agents/chat/${id}`}
            />
          </div>
        </Modal>
      )}
      {isPublishPopupOpen && (
        <AgentPublishPopup
          setIsPublishPopupOpen={setIsPublishPopupOpen}
          runs={numRuns}
          setNumRuns={setNumRuns}
          id={id}
        />
      )}
      <div className={`p-6 w-full`}>
        <div className="tabs flex flex-col">
          <TabsComponent
            tabs={[
              // { label: "Dashboard", value: "overview" },
              { label: "Workflow", value: "canvas" },
              { label: "Context", value: "context" },
              { label: "Settings", value: "settings" },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            actionSection={false}
          />
          {activeTab === "overview" && (
            <DashboardPage
              campaignData={campaignData}
              unitName="agent"
              // panelTitle="Pages in this program"
              widgets={["kpi"]}
            />
          )}
          {activeTab === "context" && (
            <DashboardPage
              campaignData={campaignData}
              unitName="agent"
              widgets={["prompt", "planner"]}
            />
          )}
          {activeTab === "canvas" && (
            <div className="h-[calc(100vh-174px)]">
              <CampaignBuilder
                id={id}
                campaignData={campaignData}
                direction="vertical"
              />
            </div>
          )}
          {activeTab === "settings" && (
            <Settings
              campaignData={campaignData}
              setCampaignData={setCampaignData}
              unitName="Agent"
              widgets={["name", "deploySettings", "delete"]}
            />
          )}
        </div>
      </div>
    </Page>
  );
};
