import { AGENTS_DOMAIN } from "@launchos/shared-utils/env";
import { saveOnboardingData } from "./entities";
import { cloneCampaign, updateCampaign } from "./campaigns";

const createProfileCampaign = async ({ type, name, token }) => {

  const campaignId = "cjz0ij1qz0ems0792n1kpu5ac"; // id of the "blank" campaign template
  const response = await cloneCampaign(campaignId, {
    token,
    name,
  });

  const profileCampaignId = response?.data?.cloneCampaign?.response?.id;

  // update the type of the campaign to be a tracking campaign
  await updateCampaign(profileCampaignId, {
    token,
    settings: {
      type: "planning",
      settings: { type },
    },
  });

  return profileCampaignId;
}

export const generatePlansFromPrompt = async ({ prompt, token }) => {

  const saveDataAndCreatePlan = async ({ type, name, payload }) => {
    console.log("Ready to build a plan around this payload", payload);
    let answers = {};
    
    // Create the Profile
    const profileCampaignId = await createProfileCampaign({ type, name, token })

    // Flatten the Payload
    for(let i=0; i<payload.length; i++) { const q = payload[i]; answers[q.id] = q.answer; }

    // Save the data
    const cA_response = await saveOnboardingData(token, answers, profileCampaignId)
    console.log("Response from plan creation", cA_response)

    // Respond with success
    return profileCampaignId;
  };

  const cleanAndParse = (str) => {
    const justJson = str
      .replace(/^'''json/, "")
      .replace(/^```json/, "")
      .replace(/'''$/, "")
      .replace(/```$/, "");
    console.log({ justJson });
    return JSON.parse(justJson);
  };

  // pass to agent server
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  try {
    
    // Call the Agent
    // const response = await fetch(`${AGENTS_DOMAIN}/plan`, {
    const response = await fetch(`${AGENTS_DOMAIN}/08734faf-b5e8-4f3c-8f7e-5c1fddc3f8dd`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ question: prompt }),
      redirect: "follow",
    });

    const resp = await response.json();
    const result = cleanAndParse(resp?.text);


    // console.log(result);
    // console.log(result.company_task);
    // console.log(cleanAndParse(result.company_task))
    
    // create smart plans    
    const companyPlannerId = await saveDataAndCreatePlan({ type: 'company', name: 'Company Profile (Auto-Generated)', payload: result?.companyQuestions })
    const customerPlannerId = await saveDataAndCreatePlan({ type: 'customer', name: 'ICP Profile (Auto-Generated)', payload: result?.icpQuestions })
    const messagingPlannerId = await saveDataAndCreatePlan({ type: 'messaging', name: 'Messaging Profile (Auto-Generated)', payload: result?.messagingQuestions })
    const pitchPlannerId = await saveDataAndCreatePlan({ type: 'pitch', name: 'Pitch Profile (Auto-Generated)', payload: result?.pitchQuestions })
    const offerPlannerId = await saveDataAndCreatePlan({ type: 'offer', name: 'Offer Profile (Auto-Generated)', payload: result?.offerQuestions })

    return [
      companyPlannerId,
      customerPlannerId,
      messagingPlannerId,
      pitchPlannerId,
      offerPlannerId,
    ]

  } catch (error) {
    console.error(error);
  }

}

// http://localhost:5173/setup/pages/MWE0MWEwYzAtNWMzNy0xMWVmLTk3NDktMjlmNzI3YzMxMjdi/Hi%2C%20my%20name%20is%20Kyle%20Graham.%20Please%20generate%20a%20landing%20page%20for%20my%20sales%20coaching%20business.%20It's%20called%20Growing%20Unicorns%2C%20LLC.%20(GrowingUnicornz.com)%20and%20we're%20in%20Austin%2C%20TX./%7B%7D