import { createContext } from "react";

export type ContextValueType = {
  darkMode: boolean;
  sidebarOpen: boolean;
  rightSidebarOpen: boolean;
  currentlyViewing: Record<string, unknown>[];
  copilotOpen: boolean;
  copilotContext: Record<string, unknown>[];
  pluginUrls: string[];
  permissions: string[];
  setDarkMode: () => void;
  setSidebarOpen: () => void;
  setRightSidebarOpen: () => void;
  setPluginUrls: () => void;
  setPermissions: () => void;
  setCurrentlyViewing: () => void;
  setCopilotOpen: () => void;
  setCopilotContext: () => void;
  app: Record<string, unknown>;
};

export const AppContext = createContext<ContextValueType>({
  darkMode: false,
  sidebarOpen: false,
  rightSidebarOpen: false,
  copilotOpen: false,
  pluginUrls: [],
  permissions: [],
  setPermissions: () => {},
  setDarkMode: () => {},
  setSidebarOpen: () => {},
  setRightSidebarOpen: () => {},
  setPluginUrls: () => [],
  setCopilotOpen: () => [],
  currentlyViewing: [],
  copilotContext: [],
  setCurrentlyViewing: () => {},
  setCopilotContext: () => {},
  app: {}
});

export const AppConfig = AppContext;