// import React from "react";
// import { EmptyState } from '@launchos/modules/editor/Builder/EditorObject';

import LiveColumn from "./component";
import { CURSOR_ID } from "./column";
import { EmptyState } from "@/Apps/Pages/EditorObject";

export const Content = (props) => {
  const childContent = props.pageContent.filter(
    (itm) => itm.parent === props.settings.id
  );
  let isEmpty = false;
  if (childContent.length) {
    isEmpty = !!(childContent.length === 1 && childContent[0].id === CURSOR_ID);
  } else isEmpty = true;

  const defaultStyle = isEmpty ? { padding: 40 } : {};

  return <LiveColumn isEmpty={isEmpty} {...props} style={defaultStyle} EmptyState={EmptyState} />;
};
