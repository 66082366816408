import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { doIShow, combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.TWENTY,
  layout: HeaderLayouts.TWENTY,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header20.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "87oxpydo6b5",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: startCase(lorem.generateWords(7)),
      },

      {
        type: ComponentTypes.TEXT,
        canHaveChildren: false,
        properties: {
          ...style.TEXT,
        },
        html: startCase(lorem.generateWords(20)),
      },
      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: "100%",
          marginTop: 20,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 80,
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  // height: 105,
                },
                // playIconStyle: { fontSize: "28pt" },
              }),
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: 20,
            properties: { height: "100%", padding: 10, minHeight: 20 },
            children: [
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  // padding: "40px 70px",
                  // marginTop: 15,
                },
                playIconStyle: { fontSize: "32pt" },
              }),
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  // padding: "40px 70px",
                  marginTop: 15,
                },
                playIconStyle: { fontSize: "32pt" },
              }),
              getVideoPlaceholder({
                type: VideoPlaceholderTypes.NONE,
                style: {
                  ...style.VIDEO,
                  // padding: "40px 70px",
                  marginTop: 15,
                },
                playIconStyle: { fontSize: "32pt" },
              }),
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
