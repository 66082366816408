import _ from "lodash";

import { NiceMenu } from "../Helpers/NiceMenu";
import style from "@/Apps/Pages/Properties/style";
import ListItem from "@/ui/_old/ListItem";

const listComponents = (content = [], filterOut = [], filterOnly = []) => {
  const iconMap = {
    Container: "select_all",
    Columns: "view_column",
    Collection: "format_list_numbered",
    p: "text_fields",
    Paragraph: "text_fields",
    Video: "play_circle_outline",
    Picture: "image",
    Headline: "title",
    Button: "crop_16_9",
    TextInput: "input",
    Popup: "aspect_ratio",
  };

  const filteredList = _.filter(content, (obj) => {
    return _.findIndex(filterOut, (i) => obj[i[0]] === i[1]) === -1;
  });

  const showOnly = _.filter(filteredList, (obj) => {
    if (filterOnly.length)
      return _.findIndex(filterOnly, (i) => obj[i[0]] === i[1]) > -1;
    return true;
  });

  return _.map(showOnly, (obj) => {
    const name = _.get(obj, "name", obj.type);
    let html = _.has(obj, "html")
      ? obj.html.replace(/<(?:.|\n)*?>/gm, "")
      : name;

    if (_.has(obj, "placeholder"))
      html = obj.placeholder.replace(/<(?:.|\n)*?>/gm, "");

    return {
      id: obj.id,
      icon: _.get(iconMap, obj.type, "widgets"),
      html: _.get(obj, "label", html),
      settings: obj,
    };
  });
};

export const ComponentSelector = (props) => {
  const {
    // listComponents,
    changeState,
    pageContent = [],
    filterOut,
    filterOnly,
    onChange,
  } = props;

  const components = listComponents(pageContent, filterOut, filterOnly);

  return (
    <NiceMenu style={{ marginTop: 10 }} label={props.label}>
      {_.map(components, (itm) => (
        <ListItem
          key={itm.settings.id}
          onClick={() => onChange(itm)}
          leftIcon={_.get(itm, "icon", false)}
          onMouseEnter={() => changeState(itm.settings.id, "hover")}
          onMouseLeave={() => changeState(itm.settings.id, "normal")}
        >
          {_.get(itm, "html", "")}
        </ListItem>
      ))}
      {components.length ? <div style={style.divider} /> : null}
      {props.children}
    </NiceMenu>
  );
};
