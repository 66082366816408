import { ChatConversationProps } from "./types";
import PromptMessageInput from "./PromptMessageInput";
import PromptMessageContainer from "./PromptMessageContainer";
import { Sparkle } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
// import FileInput from "./FileInput";
// import SearchInput from "./SearchInput";

export const ChatConversation: React.FC<ChatConversationProps> = ({
  containerType = "Minimal", // default container type is "Minimal"
  title = "How can I help you today?",
  subtitle,
  inputSettings = {}, // default prompt input settings is an empty object
  containerSettings = {}, // default prompt container settings is an empty object
  // fileInputSettings = {}, // default file input settings is an empty object
  // searchInputSettings = {}, // default search input settings is an empty object
  // onPromptSubmit = () => {}, // default onPromptSubmit function is an empty function
  // onFileSubmit = () => {}, // default onFileSubmit function is an empty function
  isLoading = false, // is a chat currently being processed?
  children = <></>
}) => {
  const [activePrompt, setActivePrompt] = useState<string>(inputSettings?.value);
  const { messages = [] } = containerSettings;

  return (
    <div
      data-testid="ChatConversation"
      className={`${containerType} h-full w-full flex flex-col gap-2`}
    >
      {/* The prompt message container component */}
      <div
        className="overflow-y-auto w-full h-full flex-1 flex-col"
        id="chat-conversation-container"
      >
        {/* empty state */}
        {messages.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full p-16">
            <Sparkle
              size={64}
              className="rounded-full bg-white text-black p-3"
            />
            <p className="dark:text-white/80 text-3xl p-4">
              {title.split("").map((char: string, index: number) => (
                <span
                  key={index}
                  style={{
                    animation: `slideIn 0.5s ease-in-out ${index * 0.03}s forwards`,
                    opacity: 0,
                    display: "inline-block",
                    marginRight: char === " " ? "0.05em" : "0",
                  }}
                >
                  {char === " " ? "\u00A0" : char}
                </span>
              ))}
            </p>
            <style>{`
              @keyframes slideIn {
                0% { opacity: 0; transform: translateX(-10px); }
                100% { opacity: 1; transform: translateX(0); }
              }
            `}</style>
            <p className="dark:text-white/80 text-md">{subtitle}</p>
            <form
              id="chat-form"
              className="max-w-3xl w-full my-10"
              onSubmit={inputSettings.onSubmit}
            >
              <PromptMessageInput
                data-testid="PromptMessageInput" // test id for the prompt message input component
                {...{ ...inputSettings, value: activePrompt, suggestions: [] }} // spread operator to pass prompt input settings as props
                onChange={(e) => {
                  setActivePrompt(e.target.value);
                  inputSettings?.onChange?.(e);
                }}
                onKeyDown={(e) => {
                  inputSettings?.onKeyDown?.(e);
                }}
              />
              {/* {JSON.stringify(inputSettings?.suggestions)} */}
              <div className="flex gap-4 px-6 py-12">
                {inputSettings?.suggestions?.map(
                  ({ label, icon, background = "" }, key) => {
                    const Icon = icon;
                    return (
                      <div
                        key={key}
                        onClick={() => {
                          setActivePrompt(label);
                          inputSettings?.onChange?.({
                            target: { value: label },
                          });
                          // document.getElementById("chat-form")?.dispatchEvent(new Event('submit'));
                          // document.getElementById("chat-form")?.submit();
                        }}
                        className="rounded-xl bg-lightwhite dark:bg-white/5 p-5 cursor-pointer hover:bg-black/10 hover:dark:bg-white/10"
                      >
                        {label}
                        {icon && (
                          <div className="flex justify-end mt-8">
                            <div className={`rounded p-1 ${background}`}>
                              <Icon />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              {children}
            </form>
          </div>
        )}

        {/* Render the prompt message container */}
        {messages.length > 0 && (
          <div className="h-full">
            <PromptMessageContainer isLoading={isLoading} {...containerSettings} />
          </div>
        )}
      </div>

      {/* The file input component */}
      {/* <FileInput
        data-testid="FileInput" // test id for the file input component
        onChange={onFileSubmit} // function to be called when file is submitted
        {...fileInputSettings} // spread operator to pass file input settings as props
      /> */}

      {/* The prompt message input component */}
      {messages.length > 0 && (
        <div className="">
          <form
            // className="absolute bottom-full left-0 right-0"
            id="chat-form"
            onSubmit={inputSettings.onSubmit}
          >
            <div className="py-3">
              {/* <input 
                value={inputSettings.value} 
                onChange={inputSettings.onChange} 
                className="w-full max-w-md border border-gray-300 rounded mb-8 shadow-xl p-2 dark:text-black" 
              /> */}
              <PromptMessageInput
                data-testid="PromptMessageInput" // test id for the prompt message input component
                // onChange={onPromptSubmit} // function to be called when prompt message is submitted
                // onChange={(e) => {
                //   console.log(e.target.value);
                //   setPromptValue(e.target.value);
                // }}
                // onSuggestionClick={(str: string) => {
                //   console.log(str);
                //   setPromptValue(str);
                //   // submit the form using the provided str
                //   // (
                //   //   document.getElementById("chat-form") as HTMLFormElement
                //   // )?.submit();
                // }}
                isLoading={isLoading}
                {...inputSettings} // spread operator to pass prompt input settings as props
                // value={promptValue}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatConversation;
