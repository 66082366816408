import Panel from "@/ui/Panel"
import { useEffect, useState } from "react";
import { getSessionToken } from "@/api/auth";
import { updateCampaign } from "@/api/campaigns";
import Calout from "@/ui/Calout";
import { Switch } from "@/ui/Switch";

export const UseWithAIToggle = ({id, campaignData = {}, objectData = {}, unitName = "project"}) => {

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {

    const withAI = campaignData?.settings?.withAI;
    if (withAI !== undefined) {
      setIsChecked(withAI);
    }

  }, []);
  
  const handleToggle = async () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    const campaignId = campaignData?.id;
    const token = getSessionToken();

    const response = await updateCampaign(campaignId, {
      token,
      settings: {
        settings: {
          ...(campaignData?.settings || {}),
          withAI: newCheckedState,
        },
      },
    });

    console.log("response", response);
  }

  return (
    <Panel
      title="Activate Smart Copy"
      actionButton={
        <Switch
          onChange={handleToggle}
          htmlFor={`toggleAI`}
          checked={isChecked}
        />
      }
    >
      <Calout
        title={`Determine whether or not to auto generate content for this ${unitName.toLowerCase()}.`}
      />
    </Panel>
  );
}