import remarkBreaks from "remark-breaks";

import { getSessionToken } from "@/api/auth";
import { createCampaignAndObject } from "@/api/campaigns";
import Button from "@/ui/Button";
import { ButtonTypes } from "@/ui/types";
import Markdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import { BuilderArtifactProps } from "./types";
import { useEffect } from "react";
import { markdownComponents } from "@/ui/CommunicationView/ChatConversation/PromptMessageContainer/markdown";

export const ContentBuilderArtifact: React.FC<BuilderArtifactProps> = ({
  id = "",
  campaignId = "",
  title = "",
  prompt = "",
  isLoading = false,
  setTopBarButtons = () => [],
  setArtifactOptions = () => {
    isShowing: false;
  },
  artifactExists = () => false,
  append = () => {},
}) => {
  const navigate = useNavigate();

  const content = prompt;

  const handleSmartContentOpen = async () => {
    console.log("ARTIFACT CONTAINER: handleSmartContentOpen");
    const token = getSessionToken();
    // need to handle conditionally (what if it already exists?)
    const { campaign, object } = await createCampaignAndObject({
      token,
      name: title,
      type: "content",
      nodeType: "ContentComponent",
      settings: { name: title, content, type: "Content Post" },
    });
    navigate(`/content/${campaign?.id}/${object?.id}`);
  };

  useEffect(() => {
    setTopBarButtons([
      <Button
        type={ButtonTypes.OUTLINED}
        label="Open in Smart Content Planner"
        onClick={handleSmartContentOpen}
      />,
    ]);
  }, []);

  return (
    <div className="w-full">
      <div className="p-9 flex flex-col">
        <Markdown
          children={String(content)}
          remarkPlugins={[remarkBreaks]}
          components={markdownComponents}
        />
      </div>
    </div>
  );
};
