import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { HeaderLayouts } from "../types";
import { doIShow, combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: HeaderLayouts.EIGHTEEN,
  layout: HeaderLayouts.EIGHTEEN,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/headers/Header18.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkvbt5fn1xt30780aydnx76w",
    itemId: "eokna5oosjo",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.HEADER,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.CONTAINER,
        canHaveChildren: false,
        properties: {
          // float: "right",
          display: "inline-block",
          textAlign: "right",
          width: 500,
          mobile: {
            desktop: { width: 500 },
            smartphone: { width: "100%" },
          },
        },
        children: [
          {
            type: ComponentTypes.CONTAINER,
            canHaveChildren: false,
            properties: { textAlign: "center" },
            children: [
              {
                type: ComponentTypes.PICTURE,
                canHaveChildren: false,
                properties: {
                  width: 200,
                  padding: 20,
                  textAlign: "center",
                  display: "inline-block",
                  margin: "0 auto",
                  mobile: {
                    desktop: { width: 200, maxWidth: "100%" },
                    smartphone: { width: "100%", maxWidth: "100%" },
                  },
                },
                src: theme.logoLight,
              },
            ],
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.HEADLINE,
              color: theme.foregroundColor,
            },
            html: "Free Masterclass",
          },
          {
            type: ComponentTypes.HEADLINE,
            canHaveChildren: false,
            properties: {
              ...style.SUBHEADLINE,
            },
            html: `Learn How to ${startCase(lorem.generateWords(12))}`,
          },
          {
            type: ComponentTypes.BUTTON,
            canHaveChildren: false,
            properties: style.BUTTON.style,
            caption1: "Join the Masterclass Now",
            caption1style: style.BUTTON.caption1,
          },
        ],
      },
    ],
  },
};

export default settings;
