import { ReactLiquid, useLiquid } from "react-liquid";

import { remapCampaignVariables } from "../utils";
import { parseValue } from "./scripts";
import { useContext } from "react";
import { PageContext } from "./PageContext";
import { ContainerContext } from "../WebComponents/v1/container/ContainerContext";
import StripePaymentElement from "../WebComponents/v2/PaymentElement/live";

export const shouldParseLiquid = (html: string): boolean => {
  const pageContext = useContext(PageContext);
  
  // console.log(
  //   "shouldParseLiquid",
  //   html,
  //   pageContext?.parseLiquid,
  //   html.includes("{{"),
  //   html.includes("}}")
  // );

  if (!html) return false;
  return pageContext?.parseLiquid && html.includes("{{") && html.includes("}}");
};

export const LiquidRenderer = ({ html, data = {} }) => {
  const pageContext = useContext(PageContext);
  const containerContext = useContext(ContainerContext);

  // console.log("LiquidRendererComponent", html, shouldParseLiquid(html))
  if (!shouldParseLiquid(html)) return html; // why is this rendering opposite

  if (html.includes("{{ stripe_payment_element }}")) return <StripePaymentElement />;

  return (
    <ReactLiquid
      template={parseValue(html)}
      data={{
        ...remapCampaignVariables(pageContext?.campaignSettings?.contentVariables),
        ...remapCampaignVariables(pageContext?.campaignSettings?.variables),
        ...data,
        ...containerContext,
      }}
      html
    />
  );
};

export const getRepeatContent = (var_name) => {
  if (!var_name) return;
  const pageContext = useContext(PageContext);
  const data = {
    ...remapCampaignVariables(pageContext?.campaignSettings?.contentVariables),
    ...remapCampaignVariables(pageContext?.campaignSettings?.variables),
  };
  const toLoopAround = data?.[var_name];
  // console.log({ toLoopAround, var_name, data });
  return toLoopAround;
};

export const parseLiquidString = (str: string) => {
  if (!str) return str;

  if (!shouldParseLiquid(str)) return str;

  const pageContext = useContext(PageContext);
  const data = {
    ...remapCampaignVariables(pageContext?.campaignSettings?.contentVariables),
    ...remapCampaignVariables(pageContext?.campaignSettings?.variables),
  };
  const { markup } = useLiquid(str, data);
  return markup || str;
};