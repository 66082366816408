import { Workflows } from "./Workflows";
import { Dashboard } from "./Dashboard";
import { Lightning } from "@phosphor-icons/react";

import { CopilotHooks, HookObject, ListItemsHooks } from "@/plugins/types";
import get_workflows from "./prompts/get_workflows";
import get_objects from "./prompts/get_objects";

export const onListItems = (hook: HookObject, _, __, { permissions = [] }) => {
  if (hook.id === ListItemsHooks.APP_DRAWER && hook.position === "top") {
    if (!permissions.includes("workflows")) return [];

    return [
      {
        label: "Workflows",
        icon: <Lightning className="text-lg" />,
        href: "/workflows",
        order: 9,
      },
    ];
  }

  if (hook.id === ListItemsHooks.ROUTES) {
    if (!permissions.includes("workflows")) return [];

    return [
      {
        path: "/workflows",
        element: <Workflows name="Workflows" />,
        children: [
          {
            path: ":id",
            element: <Dashboard name="Workflows" />,
          },
        ],
      },
    ];
  }
};

export const onChatPrompt = (
  hook: HookObject,
  { context },
  { copilotContext }
) => {
  if (
    hook.id === CopilotHooks.GET_SYSTEM_PROMPT &&
    context[0]?.label === "Workflows" &&
    copilotContext
  ) {
    console.log("(Workflows) About to System Prompt", context, copilotContext);

    // retrieve the page content
    let systemPrompt = "";

    if (context[0]?.label === "Workflows") systemPrompt += get_workflows(copilotContext["Workflows"]);
    if (copilotContext['Workflow Dashboard']) systemPrompt += get_objects(copilotContext["Workflow Dashboard"]);


    return systemPrompt;
  }
};

export { Workflows };
