import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";

import LiveHeadline from "./component";

export const onComponentRender = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT && payload.type === ComponentTypes.HEADLINE) {
    return [LiveHeadline];
  }
};
