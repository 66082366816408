import { get, startCase, uniqueId } from "lodash";
import {
  ILayoutSettings,
  PageSectionCategory,
} from "@/Apps/Pages/Sections/WebLayouts/types";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { ContentLayouts } from "../types";
import { combineData, lorem } from "../../utils";
import { default as defaultData } from "./data.json";
import { style } from "./style";
import {
  getImagePlaceholder,
  ImagePlaceholderTypes,
  getVideoPlaceholder,
  VideoPlaceholderTypes,
} from "../../Placeholders";
import theme from "../../theme";
import { CTALayouts } from "../types";

const content = combineData(defaultData);

const settings: ILayoutSettings = {
  label: CTALayouts.TWENTYTWO,
  layout: CTALayouts.TWENTYTWO,
  previewImage:
    "https://sandcastleassets.s3.amazonaws.com/blocks/cta/CTA22.png",
  loadFromExt: true,
  loadFromExtSettings: {
    pageId: "ckkxavpzp265c0780f0peetj7",
    itemId: "ecjthruufd5",
  },
  default: {
    type: ComponentTypes.SECTION,
    label: PageSectionCategory.CTA,
    bodyOnly: true,
    canHaveChildren: false,
    properties: {
      ...style.SECTION,
      ...style.SECTION_INNER,
    },
    children: [
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.HEADLINE,
        },
        html: `Would you like your comprehensive guide now?`,
      },
      {
        type: ComponentTypes.HEADLINE,
        canHaveChildren: false,
        properties: {
          ...style.SUBHEADLINE,
        },
        html: `Uncover the secrets that the professionals don't want you to know.  Save $50 through Monday.`,
      },

      {
        type: ComponentTypes.COLUMNS,
        canHaveChildren: false,
        properties: {
          width: 500,
          maxWidth: "100%",
          margin: "0 auto",
          marginTop: 40,
        },
        children: [
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: {
                  ...style.BUTTON.style,
                  width: 200,
                  backgroundColor: "#5ec55e",
                },
                caption1: "Yes!",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
          {
            type: ComponentTypes.COLUMN,
            canHaveChildren: true,
            preventDelete: true,
            md: parseInt((6 * 100) / 12),
            properties: { height: "100%", padding: 0, minHeight: 20 },
            children: [
              {
                type: ComponentTypes.BUTTON,
                canHaveChildren: false,
                properties: { ...style.BUTTON.style, width: 200 },
                caption1: "No!",
                caption1style: style.BUTTON.caption1,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default settings;
