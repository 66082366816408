import { createContext } from "react";
import { ISettings } from "./types";
import { MobileState } from "../MobileResponsiveToggle/types";

interface EditorContextType {
  pageId: string;
  objectId: string;
  campaignId: string;
  content: ISettings[];
  mobileState: MobileState;
}

export const EditorContext = createContext<EditorContextType>({
  pageId: "",
  objectId: "",
  campaignId: "",
  content: [],
  mobileState: MobileState.FULLSCREEN
});